@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.global-export-modal .ep-modal__content {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  min-height: 300px;
  max-height: 670px;
}
.global-export-modal .global-export-modal__fetch_error {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 20px;
}
.global-export-modal .global-export-modal__fetch_error .fetch-error__header {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
.global-export-modal .global-export-modal__fetch_error .fetch-error__description {
  width: 100%;
}
.global-export-modal .global-export-modal__fetch_error .fetch-error__buttons-row {
  width: 50%;
}
.global-export-modal .global-export-modal__data-selection {
  min-height: 200px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__question_list {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__question_list .question-list__header {
  margin: 5px 0;
  font-weight: bold;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__question_list .questions-selector__questions {
  max-height: 180px;
  padding-right: 5px;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__documents_and_formats {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  margin: 10px 0 20px;
  border-top: 1px solid #bfbfbf;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__documents_and_formats > div {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__documents_and_formats > div > div {
  width: 33.333333333333336%;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__documents_and_formats > div > div.item-with-checkbox .item-with-checkbox__item {
  width: 100%;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__documents_and_formats > div.documents_and_formats__header {
  margin: 5px 0;
}
.global-export-modal .global-export-modal__data-selection .global-export-modal__documents_and_formats > div.documents_and_formats__header > div {
  font-weight: bold;
}
.global-export-modal .global-export-modal__data-selection .ep-select-with-checkboxes__trigger {
  margin: 2px 0;
  height: 30px;
}
.global-export-modal .global-export-modal__data-selection .ep-select-with-checkboxes__trigger .ep-expand-button {
  margin: 0;
}
.global-export-modal .global-export-modal__data-selection .ep-select-with-checkboxes__trigger .ep-expand-button svg {
  fill: #3783a5;
}
.global-export-modal .global-export-modal__export-progress {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.global-export-modal .global-export-modal__export-progress .export-progress__header {
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
  color: #0089ba;
  font-size: 16px;
}
.global-export-modal .global-export-modal__export-progress .export-progress__export-bar-description {
  width: 75%;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: bold;
}
.global-export-modal .global-export-modal__export-progress .export-progress__export-completed {
  width: 100%;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.global-export-modal .global-export-modal__export-progress .export-progress__export-completed .export-completed__file {
  width: 100px;
  height: 120px;
  margin: 20px 0;
  transition: opacity 0.4s ease-in-out;
}
.global-export-modal .global-export-modal__export-progress .export-progress__export-completed .export-completed__file:hover {
  opacity: 0.6;
}
.global-export-modal .global-export-modal__export-progress .export-progress__export-completed .export-completed__file a {
  display: block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-decoration: none;
  text-align: center;
  color: #3783a5;
  font-size: 100px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.global-export-modal .global-export-modal__export-progress .export-progress__export-completed .export-completed__file a:before {
  font-family: 'gdt';
  content: '\e092';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.global-export-modal .global-export-modal__export-progress .export-progress__export-completed .export-completed__file a * {
  margin-left: 0.5rem;
}
.global-export-modal .global-export-modal__export-progress .export-progress__export-completed .export-completed__file a span {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  margin: 0;
}
.global-export-modal.global-export-modal-error {
  width: 500px !important;
}
.global-export-modal.global-export-modal-error .ep-modal__content {
  color: #fff;
  background: #000;
  transition: color 0.4s ease-in-out, background 0.4s ease-in-out;
}
.global-export-modal.global-export-modal-error .global-export-modal__export-error {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.global-export-modal.global-export-modal-error .global-export-modal__export-error .export-error__header {
  font-size: 88px;
  font-style: italic;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  line-height: 88px;
}
.global-export-modal.global-export-modal-error .global-export-modal__export-error .export-error__description {
  font-size: 19px;
  font-family: 'PTSans', sans-serif;
  line-height: 19px;
  margin-top: 20px;
}
.global-export-modal.global-export-modal-error .global-export-modal__export-error .export-error__image {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  width: 100%;
  height: 225px;
  background: url("../images/global-export-error-negative.svg") no-repeat;
  background-position: center;
  transition: background 0.4s ease-in-out;
  margin: 30px 0;
}
.global-export-modal.global-export-modal-error:hover .ep-modal__content {
  background: #fff;
  color: initial;
}
.global-export-modal.global-export-modal-error:hover .global-export-modal__export-error .export-error__image {
  background: url("../images/global-export-error-positive.svg") no-repeat;
  background-position: center;
}
