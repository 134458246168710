@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.toolbar-publication-platform {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
}
.toolbar-publication-platform .toolbar-publication-platform__title {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.toolbar-publication-platform .toolbar-publication-platform__buttons {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.publication-platform-questions-table .publication-platform-questions-table__search-bar {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.publication-platform-questions-table .publication-platform-questions-table__search-bar .search-bar__search {
  width: 30%;
}
.publication-platform-questions-table .publication-platform-questions-table__search-bar .search-bar__search input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.publication-platform-questions-table .publication-platform-questions-table__search-bar .search-bar__search button.search {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.publication-platform-questions-table .publication-platform-questions-table__search-bar .search-bar__controls {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.publication-platform-questions-table .publication-platform-questions-table__search-bar .search-bar__controls .status-filter__text {
  margin-right: 10px;
}
.publication-platform-questions-table .publication-platform-questions-table__search-bar .search-bar__controls .status-filter__select .select-input {
  width: 150px;
  border: 1px solid #009cca;
  border-radius: 4px;
}
.publication-platform-questions-table__table .questions-list-container__questions-group {
  margin-bottom: 10px;
}
.publication-platform-questions-table__table .questions-list-container__questions-group:not(.with-caption) {
  margin: 0 11px 0 0;
}
.publication-platform-questions-table__table .questions-list-container__question {
  border-bottom: 0 !important;
  border-top: 0 !important;
  height: 50px;
}
.publication-platform-questions-table__table .questions-list-container__question:before {
  width: 40px;
  height: 50px;
  line-height: 50px !important;
}
.publication-platform-questions-table__table .questions-list-container__question .publication-platform-questions-table__row .publication-platform-questions-table__cell:not(.question):not(.checkbox) {
  background: #fff;
}
.publication-platform-questions-table__table .questions-list-container__question:hover .publication-platform-questions-table__row:before {
  background: #dedede;
}
.publication-platform-questions-table__table .questions-list-container__question:hover .publication-platform-questions-table__row .publication-platform-questions-table__cell {
  background: none;
}
.publication-platform-questions-table__table .questions-list-container__question:not(:first-child):before {
  border-top: 2px solid #fff !important;
}
.publication-platform-questions-table__table .questions-list-container__question:not(:first-child) .publication-platform-questions-table__cell:not(.question) {
  border-top: 2px solid #f2f2f2;
}
.publication-platform-questions-table__table .questions-list-container__question:not(:first-child) .publication-platform-questions-table__cell.question,
.publication-platform-questions-table__table .questions-list-container__question:not(:first-child) .publication-platform-questions-table__cell.checkbox {
  border-top: 2px solid #fff;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: content-box;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell .cell-content {
  width: 100%;
  padding: 5px;
  text-align: center;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell.question {
  width: 100%;
  min-width: 450px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell.status {
  -webkit-flex: 0 0 200px;
  -ms-flexbox: 0 0 200px;
  flex: 0 0 200px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell.actions,
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell.publicationDate {
  -webkit-flex: 0 0 150px;
  -ms-flexbox: 0 0 150px;
  flex: 0 0 150px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell.workflow {
  -webkit-flex: 0 0 250px;
  -ms-flexbox: 0 0 250px;
  flex: 0 0 250px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell.checkbox {
  -webkit-flex: 0 0 20px;
  -ms-flexbox: 0 0 20px;
  flex: 0 0 20px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell.checkbox .cell-content {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.publication-platform-questions-table__table .publication-platform-questions-table__row .publication-platform-questions-table__cell.checkbox .cell-content input {
  cursor: pointer;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header {
  margin: 20px 0;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header .publication-platform-questions-table__row {
  height: 40px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header .publication-platform-questions-table__row .publication-platform-questions-table__cell {
  background: #dedede;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header .publication-platform-questions-table__row .publication-platform-questions-table__cell:not(:last-child) {
  border-right: 2px solid #fff;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header .publication-platform-questions-table__row .publication-platform-questions-table__cell.no {
  -webkit-flex: 0 0 40px;
  -ms-flexbox: 0 0 40px;
  flex: 0 0 40px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header .publication-platform-questions-table__row .publication-platform-questions-table__cell.actions {
  -webkit-flex: 0 0 161px;
  -ms-flexbox: 0 0 161px;
  flex: 0 0 161px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header .publication-platform-questions-table__row .publication-platform-questions-table__cell.workflow {
  -webkit-flex: 0 0 261px;
  -ms-flexbox: 0 0 261px;
  flex: 0 0 261px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header .publication-platform-questions-table__row .publication-platform-questions-table__cell.status {
  -webkit-flex: 0 0 200px;
  -ms-flexbox: 0 0 200px;
  flex: 0 0 200px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header.not-in-master .publication-platform-questions-table__row .publication-platform-questions-table__cell.status {
  -webkit-flex: 0 0 211px;
  -ms-flexbox: 0 0 211px;
  flex: 0 0 211px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__header.in-master .publication-platform-questions-table__row .publication-platform-questions-table__cell.status {
  -webkit-flex: 0 0 150px;
  -ms-flexbox: 0 0 150px;
  flex: 0 0 150px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body {
  position: relative;
  height: calc(100vh - 235px);
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row {
  height: 50px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell {
  height: 50px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell:not(:last-child):not(.question):not(.checkbox) {
  border-right: 2px solid #f2f2f2;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.checkbox {
  border-right: 2px solid #fff;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.question {
  border-right: 2px solid #fff;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.question .cell-content {
  text-align: left;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.status {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.status .status__square {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 8px;
  margin-left: 10px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.status .status__square.published {
  background: #0089ba;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.status .status__square.pending {
  background: #ff5186;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.status .status__square.unpublished {
  background: #d1d1d1;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.actions .cell-content {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.actions .cell-content .btn-icon-container button:before {
  font-size: 16px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .publication-platform-questions-table__row .publication-platform-questions-table__cell.actions .cell-content .btn-icon-container .label-text {
  font-size: 12px;
  margin-top: 3px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body.in-master .publication-platform-questions-table__row .publication-platform-questions-table__cell.status {
  -webkit-flex: 0 0 150px;
  -ms-flexbox: 0 0 150px;
  flex: 0 0 150px;
}
.publication-platform-questions-table__table .publication-platform-questions-table__body .questions-list-container__question:hover .publication-platform-questions-table__row .publication-platform-questions-table__cell:not(:last-child) {
  border-right: 2px solid #fff;
}
.publication-platform-dialog .publication-platform-dialog__progress-bar {
  margin-bottom: 10px;
  width: 100%;
  height: 30px;
}
