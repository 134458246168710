@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.algorithms-view {
  overflow: auto;
}
.algorithms-menu {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 5px;
  width: 155px;
  height: auto;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 1000;
}
.algorithms-menu > li:hover {
  background-color: #eee;
}
.menu_item_rect {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
  border: 1px solid #d9d9d9;
}
.bg-yellow-300 {
  background: #fcd34d;
}
.bg-yellow-500 {
  background: #f59e0b;
}
.bg-red-500 {
  background: #ef4444;
}
.bg-green-300 {
  background: #6ee7b7;
}
.bg-green-700 {
  background: #047857;
}
.bg-blue-300 {
  background: #93c5fd;
}
.bg-indigo-500 {
  background: #6366f1;
}
.bg-pink-300 {
  background: #f9a8d4;
}
.bg-gray-400 {
  background: #9ca3af;
}
.bg-black {
  background: #000;
}
.unidirectional-node {
  padding: 12.5px;
  min-height: 42px;
  font-size: 1.25rem;
  text-align: center;
  white-space: pre-wrap;
}
.unidirectional-node a {
  color: inherit;
}
.unidirectional-node-handle {
  width: 10px !important;
  height: 10px !important;
}
.react-flow__handle-top {
  top: -5px !important;
}
.react-flow__handle-right {
  right: -5px !important;
}
.react-flow__handle-bottom {
  bottom: -5px !important;
}
.react-flow__handle-left {
  left: -5px !important;
}
.react-flow__edge-path {
  stroke-width: 2 !important;
}
.algorithms-modal {
  display: flex;
  flex-direction: column;
}
.algorithms-modal__title {
  display: block;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
}
.algorithms-modal__list-item {
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  margin-bottom: 2px;
}
.algorithms-modal__checkbox {
  margin: 1rem;
  justify-content: center;
  vertical-align: middle;
}
.algorithms-modal__item-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.algorithms-modal__buttons {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.algorithms-modal__section {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.algorithm-section-node {
  z-dndex: -1;
  width: 200px;
  height: 34px;
  transform: rotate(-90deg);
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}
.algorithm-node-menu__colors-section {
  margin: 0.5rem 0;
}
.algorithms-list {
  max-height: 100%;
  overflow-y: scroll;
}
.algorithms-list__item {
  background: #f2f2f2;
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
}
.algorithms-list__item-order-number {
  min-width: 6rem;
  display: flex;
  justify-content: center;
}
.algorithms-list__item-title {
  flex-grow: 1;
  cursor: pointer;
}
.algorithms-list__item-title > input {
  width: 100%;
}
.algorithms-list__item-controls {
  margin: 0 1rem;
  flex-shrink: 0;
}
.algorithms-list__item-controls .btn-icon-container {
  margin-left: 1rem;
}
.algorithms-list__item-controls--editing {
  margin: 0 1rem;
  min-width: 5rem;
  flex-shrink: 0;
}
.algorithms-list__item-controls--editing .btn-icon-container {
  margin-left: 1rem;
}
.algorithm-topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.algorithm-topbar__title {
  flex-grow: 1;
}
.algorithm-topbar__actions {
  flex-shrink: 0;
}
.new-algorithm-element {
  margin-left: 0.5rem;
}
.unidirectional-node .unidirectional-node-handle {
  visibility: hidden;
}
.unidirectional-node:hover > .unidirectional-node-handle {
  visibility: initial;
  width: 10px !important;
  height: 10px !important;
}
#algorithms-sidebar-inner-container {
  padding-top: 30px;
  position: relative;
  z-index: 2;
  background: #fff;
  box-shadow: 0 5px 5px 2px rgba(0,0,0,0.2);
  height: 100%;
  overflow: clip;
}
#algorithms-sidebar-inner-container.absolute-expand,
#algorithms-sidebar-inner-container.expanded {
  width: 380px !important;
  transition: width 0.5s;
}
#algorithms-sidebar-inner-container.absolute-expand.es,
#algorithms-sidebar-inner-container.expanded.es {
  width: 210px !important;
}
#algorithms-sidebar-inner-container.absolute-expand.it,
#algorithms-sidebar-inner-container.expanded.it,
#algorithms-sidebar-inner-container.absolute-expand.pt,
#algorithms-sidebar-inner-container.expanded.pt {
  width: 190px !important;
}
#algorithms-sidebar-inner-container.absolute-expand.ja,
#algorithms-sidebar-inner-container.expanded.ja {
  width: 200px !important;
}
#algorithms-sidebar-inner-container.absolute-collapsed,
#algorithms-sidebar-inner-container.collapsed {
  width: 55px;
  transition: width 0.5s;
}
@media (max-height: 732px) {
  #algorithms-sidebar-inner-container {
    overflow-y: scroll;
  }
  #algorithms-sidebar-inner-container.absolute-collapsed,
  #algorithms-sidebar-inner-container.collapsed {
    overflow: hidden;
  }
}
#algorithms-sidebar-inner-container .submenu.expanded li {
  height: 38px;
  transition: 0.5s height;
}
#algorithms-sidebar-inner-container .submenu.collapsed li {
  height: 0px;
  transition: 0.5s height;
}
#algorithms-sidebar-inner-container .flex-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}
#algorithms-sidebar-inner-container .top {
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 12px;
}
#algorithms-sidebar-inner-container .top button {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("../images/svg/expand2.svg") no-repeat 50% 50%;
}
#algorithms-sidebar-inner-container .top button.btn-collapsed {
  transition: transform 0.5s;
  transform: rotate(0deg);
}
#algorithms-sidebar-inner-container .top button.btn-expanded {
  transition: transform 0.5s;
  transform: rotate(180deg);
}
#algorithms-sidebar-inner-container #algorithms-sidebar {
  background: #fff;
  flex-grow: 1;
  margin-right: 12px;
}
.algorithms-sidebar__action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}
.algorithms-sidebar__action-buttons .label-text {
  font-size: 13px !important;
}
.algorithms-sidebar__action-buttons .algorithms-sidebar__action-buttons__export {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.algorithms-sidebar__action-buttons .algorithms-sidebar__action-buttons__add-elements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.algorithms-sidebar__editor-title {
  width: 100%;
  height: 32px;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  border-top: 1px solid #d9d9d9;
  padding: 0 0.5rem;
}
.algorithms-sidebar__editor-row {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.algorithms-sidebar__editor-row button {
  border-radius: 16px;
  min-height: 32px;
  width: 100% !important;
}
.algorithms-sidebar__editor-row textarea {
  min-height: 120px;
}
.algorithms-sidebar__editor-row .menu_item_rect:first-child {
  margin-left: 0;
}
.algorithms-sidebar__editor-row .menu_item_rect:last-child {
  margin-right: 0;
}
.algorithms-sidebar__editor-row .menu_item_rect.selected {
  box-shadow: 0 4px 6px rgba(0,0,0,0.271);
}
.algorithms-sidebar__editor-row:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}
.algorithms-sidebar__editor-row label {
  font-weight: 700;
}
.algorithms-sidebar__radio-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.algorithms-sidebar__edge-editor-shapes {
  list-style: none;
  padding: 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
}
.algorithms-sidebar__edge-editor-shape {
  background: #fbfaf8;
  width: 50px;
  height: 32px;
  margin: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.algorithms-sidebar__edge-editor-shape:hover {
  background: #f2f2f2;
}
.algorithms-sidebar__edge-editor-shape:first-child {
  margin-left: 0;
}
.algorithms-sidebar__edge-editor-shape:last-child {
  margin-right: 0;
}
.algorithms-sidebar__edge-editor-shape-selected {
  background: #f3f1e9;
}
.algorithms-sidebar__edge-editor-shape-selected:hover {
  background: #f3f1e9;
}
.algorithm-toolbar {
  margin-left: 10px;
}
.algorithm {
  border: 1px solid #000;
  box-shadow: 0 0 20px 0 #ccc;
  margin-right: 50px;
  margin-bottom: 50px;
}
.bg-section-white {
  background: #fff;
}
.bg-section-blue {
  background: #d8ebfa;
}
.bg-section-green {
  background: #d8fad8;
}
.bg-section-yellow {
  background: #fcf2c9;
}
.bg-section-red {
  background: #fadbd8;
}
.bg-section-gray {
  background: #fbfaf8;
}
.algorithms-sidebar__label-offset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.algorithms-sidebar__label-offset .algorithms-sidebar__label {
  margin-right: 1rem;
  cursor: default;
}
.algorithms-sidebar__label-offset .algorithms-sidebar__label-input {
  max-width: 120px;
}
.algorithm-sidebar__editor-pt {
  padding-top: 4rem;
}
