@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.mdg-create-question-dialog .extraction-form {
  margin: 10px 0;
}
.mdg-create-question-dialog .extraction-form .extraction-form__group {
  margin: 0;
}
.mdg-create-question-dialog .extraction-form .extraction-form__group .extraction-field .extraction-field__name {
  width: 90px;
}
.mdg-create-question-dialog .extraction-form .extraction-form__group .extraction-field .extraction-field__value {
  margin-right: 0;
  max-width: initial;
}
.mdg-create-question-dialog .extraction-form .extraction-form__group .extraction-field .extraction-field__value > div {
  max-width: initial;
}
.mdg-create-question-dialog .intervention-population-field .intervention-population-field__name {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.mdg-create-question-dialog .intervention-population-field .intervention-population-field__name input {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.mdg-create-question-dialog .intervention-population-field .intervention-population-field__short-name-codes {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.mdg-create-question-dialog .intervention-population-field .intervention-population-field__short-name-codes .short-name-codes__short-name {
  width: 50%;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-create-question-dialog .intervention-population-field .intervention-population-field__short-name-codes .short-name-codes__short-name input {
  margin-left: 5px;
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  height: 30px;
}
.mdg-create-question-dialog .intervention-population-field .intervention-population-field__short-name-codes .short-name-codes__codes {
  width: calc(50% - 5px);
  margin-left: 5px;
}
.mdg-create-question-dialog .intervention-population-field .intervention-population-field__short-name-codes .short-name-codes__codes .add-code-trigger:not(:first-child) {
  margin-left: 5px;
}
.mdg-tables-edit-form {
  font-size: 14px;
}
.mdg-tables-edit-form .extraction-form__group {
  margin: 0;
}
.mdg-tables-edit-form .extraction-form__group .extraction-field .extraction-field__name {
  width: 200px;
}
.mdg-tables-edit-form .extraction-form__group .extraction-field .extraction-field__value {
  margin-right: 0;
  max-width: initial;
}
.mdg-tables-edit-form .extraction-form__group .extraction-field .extraction-field__value > div {
  max-width: initial;
}
.mdg-tables-edit-form .sample-size-field {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-tables-edit-form .sample-size-field .sample-size-field__count {
  margin: 0 10px 0 5px;
  -webkit-flex: 0 0 75px;
  -ms-flexbox: 0 0 75px;
  flex: 0 0 75px;
}
.mdg-tables-edit-form .sample-size-field .sample-size-field__description {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.mdg-tables-edit-form .age-field {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-tables-edit-form .age-field .age-field__type .options-select-container {
  width: 84px;
}
.mdg-tables-edit-form .age-field .age-field__value {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-left: 10px;
}
.mdg-tables-edit-form .age-field .age-field__value .value__value {
  width: 100%;
}
.mdg-tables-edit-form .age-field .age-field__value .value__range {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-tables-edit-form .age-field .age-field__value .value__range .range__valueFrom {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin: 0 10px;
}
.mdg-tables-edit-form .age-field .age-field__value .value__range .range__valueTo {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-left: 10px;
}
.mdg-tables-edit-form .sex-field {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.mdg-tables-edit-form .sex-field .sex-field__groups {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group:not(:first-child) {
  margin-top: 10px;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group .group__selected {
  margin-right: 10px;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group .group__selected input {
  cursor: pointer;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group .group__name {
  margin-right: 10px;
  -webkit-flex: 0 0 45px;
  -ms-flexbox: 0 0 45px;
  flex: 0 0 45px;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group .group__count {
  margin-right: 10px;
  -webkit-flex: 0 0 75px;
  -ms-flexbox: 0 0 75px;
  flex: 0 0 75px;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group .group__value {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group .group__value input {
  width: 100%;
}
.mdg-tables-edit-form .sex-field .sex-field__groups .groups__group .group__remove-group-button {
  margin-left: 10px;
  width: 12px;
}
.mdg-tables-edit-form .sex-field .sex-field__add-group-button {
  margin-left: 10px;
}
.mdg-tables-edit-form .coi-field .coi-field__no-conflict {
  margin-bottom: 10px;
}
.mdg-tables-edit-form .coi-field .coi-field__no-conflict input {
  margin-right: 10px;
  cursor: pointer;
}
.mdg-tables-edit-form .coi-field .coi-field__conflict {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-tables-edit-form .coi-field .coi-field__conflict .conflict__selected {
  margin-right: 10px;
  cursor: pointer;
}
.mdg-tables-edit-form .coi-field .coi-field__conflict .conflict__value {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.mdg-tables-edit-form .score-field .score-field__score {
  background: #fff;
  cursor: pointer;
  border: 1px solid #bfbfbf;
  padding: 0 8px;
  height: 32px;
  line-height: 30px;
}
.mdg-tables-edit-form .reference-field .reference-field__value {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  min-height: 26px;
}
.mdg-tables-edit-form .reference-field .reference-field__value .value__select-reference {
  margin-right: 15px;
}
.mdg-tables-edit-form .reference-field .reference-field__value .value__select-reference,
.mdg-tables-edit-form .reference-field .reference-field__value .value__add-manually {
  -webkit-flex: 1 0 40%;
  -ms-flexbox: 1 0 40%;
  flex: 1 0 40%;
}
.mdg-tables-edit-form .reference-field .reference-field__value .value__text {
  padding: 6px 0;
}
.mdg-tables-edit-form .reference-field .reference-field__value .value__clear {
  -webkit-flex: 0 0 12px;
  -ms-flexbox: 0 0 12px;
  flex: 0 0 12px;
  margin-left: 6px;
}
.score-field-modal {
  width: 470px !important;
}
.score-field-modal .score-field-modal__criteria {
  margin-bottom: 10px;
}
.score-field-modal .score-field-modal__criteria .criteria__criterion {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 4px;
  transition: background 0.2s ease-in-out;
}
.score-field-modal .score-field-modal__criteria .criteria__criterion.disabled {
  background: #d1d1d1;
}
.score-field-modal .score-field-modal__criteria .criteria__criterion:hover {
  background: #eaeaea;
}
.score-field-modal .score-field-modal__criteria .criteria__criterion:not(:first-child) {
  margin-top: 3px;
}
.score-field-modal .score-field-modal__criteria .criteria__criterion .criterion__name {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.score-field-modal .score-field-modal__criteria .criteria__criterion .criterion__value {
  -webkit-flex: 0 0 62px;
  -ms-flexbox: 0 0 62px;
  flex: 0 0 62px;
  margin-left: 10px;
}
.score-field-modal .score-field-modal__criteria .criteria__criterion .criterion__value .value__select {
  width: 62px;
}
.score-field-modal .score-field-modal__criteria .epidemiological-criteria .ep-select-with-checkboxes__trigger {
  height: 35px;
  border: 1px solid #bfbfbf;
}
.score-field-modal .score-field-modal__criteria .epidemiological-criteria .ep-select-with-checkboxes__trigger .ep-expand-button {
  margin-top: 0px;
}
.score-field-modal .score-field-modal__criteria .epidemiological-criteria .ep-select-with-checkboxes__trigger .ep-expand-button svg {
  fill: #3883a5;
}
.score-field-modal .score-field-modal__criteria .epidemiological-criteria .criterion__value {
  -webkit-flex: 0 0 150px;
  -ms-flexbox: 0 0 150px;
  flex: 0 0 150px;
}
.score-field-modal .score-field-modal__criteria .epidemiological-criteria .criterion__value .value__select {
  width: 150px;
}
.score-field-modal .score-field-modal__comments .comments__value textarea {
  width: 100%;
  height: 64px;
}
.score-field-modal .score-field-modal__score {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 10px 0;
}
.score-field-modal .score-field-modal__score .score__inner {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 125px;
  font-weight: bold;
}
.score-field-modal .score-field-modal__score .score__inner .inner__text {
  margin-right: 6px;
}
.score-field-modal .score-field-modal__score .score__inner .inner__value {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #f2f2f2;
}
.reference-select-modal {
  width: 900px !important;
  min-height: 200px;
}
.reference-select-modal .reference-select-modal__search {
  margin: 10px 0;
}
.reference-select-modal .reference-select-modal__references {
  overflow-y: auto;
  height: 400px;
}
.reference-select-modal .reference-select-modal__references .references__reference {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}
.reference-select-modal .reference-select-modal__references .references__reference:not(:first-child) .reference__inner {
  margin-top: 2px;
}
.reference-select-modal .reference-select-modal__references .references__reference .reference__radio-button input {
  cursor: pointer;
}
.reference-select-modal .reference-select-modal__references .references__reference .reference__inner {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-left: 6px;
  background: #f2f2f2;
  padding: 5px;
  transition: background 0.2s ease-in-out;
}
.reference-select-modal .reference-select-modal__references .references__reference .reference__inner .inner__text {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.reference-select-modal .reference-select-modal__references .references__reference .reference__inner .inner__attachment {
  -webkit-flex: 0 0 75px;
  -ms-flexbox: 0 0 75px;
  flex: 0 0 75px;
  margin-left: 6px;
}
.reference-select-modal .reference-select-modal__references .references__reference:nth-child(even) .reference__inner {
  background: #e9e9e9;
}
.reference-select-modal .reference-select-modal__references .references__reference:hover .reference__inner {
  background: #fafafa;
}
.reference-select-modal .buttons-react {
  margin-top: 10px;
}
.mdg-table-toolbar {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 10px;
}
.mdg-table-toolbar .mdg-table-toolbar__questions {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.mdg-table-toolbar .mdg-table-toolbar__questions .caption-row {
  cursor: pointer;
}
.mdg-table-toolbar .mdg-table-toolbar__questions .collapsed-content-row {
  position: absolute;
  top: 41px;
  margin-left: -10px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  z-index: 100;
  width: 100%;
}
.mdg-table-toolbar .mdg-table-toolbar__questions .collapsed-content-row .questions-list-container {
  padding: 0 5px;
  margin-right: 5px;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
}
.mdg-table-toolbar .mdg-table-toolbar__questions .collapsed-content-row .toolbar-mdg-question {
  width: 100%;
  background: #f2f2f2;
  font-size: 14px;
  padding: 0 5px;
}
.mdg-table-toolbar .mdg-table-toolbar__questions .collapsed-content-row .toolbar-mdg-question:hover {
  background-color: #f7f7f7;
}
.mdg-table-toolbar .mdg-table-toolbar__questions .collapsed-content-row .toolbar-mdg-question .question-text {
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}
.mdg-table-toolbar .mdg-table-toolbar__questions .collapsed-content-row .toolbar-mdg-question a {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  color: initial;
}
.mdg-table-toolbar .mdg-table-toolbar__buttons {
  -webkit-flex: 0 0 250px;
  -ms-flexbox: 0 0 250px;
  flex: 0 0 250px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: 10px;
}
.mdg-table-toolbar .mdg-table-toolbar__buttons .label-text {
  font-size: 14px;
  color: #3883a5;
}
.mdg-table-toolbar .mdg-table-toolbar__buttons button::before {
  margin-right: 5px;
}
.mdg-table-toolbar .mdg-table-toolbar__buttons .buttons__add-new-source {
  -webkit-flex: 0 0 125px;
  -ms-flexbox: 0 0 125px;
  flex: 0 0 125px;
  padding-top: 9.5px;
}
.mdg-table-toolbar .mdg-table-toolbar__buttons .buttons__export-table {
  -webkit-flex: 0 0 115px;
  -ms-flexbox: 0 0 115px;
  flex: 0 0 115px;
  margin-left: 10px;
  padding-top: 9.5px;
}
.mdg-table-view {
  overflow-y: auto;
  max-height: calc(100vh - 115px);
  padding: 10px;
}
.mdg-table-view .mdg-table-edit-component .mdg-table-edit-component__controls {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.mdg-table-view .mdg-table-edit-component .mdg-table-edit-component__controls > div:not(:first-child) {
  margin-left: 15px;
}
.mdg-table-view .mdg-table-edit-component .mdg-table-edit-component__controls .label-text {
  font-size: 14px;
  color: #3883a5;
}
.mdg-table-view .mdg-table-edit-component .mdg-table-edit-component__controls button {
  font-size: 14px;
}
.mdg-table-view .mdg-table-edit-component .mdg-table-edit-component__controls button::before {
  margin-right: 5px;
}
.mdg-search-strategy-view .tabs-container .tabspane .tab {
  -webkit-flex: 0 0 144px;
  -ms-flexbox: 0 0 144px;
  flex: 0 0 144px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 10px 0;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__general-information {
  -webkit-flex: 0 0 195px;
  -ms-flexbox: 0 0 195px;
  flex: 0 0 195px;
  background: #f2f2f2;
  margin-right: 10px;
  position: relative;
  padding: 10px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__general-information .general-information__edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__general-information .general-information__field {
  font-size: 14px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__general-information .general-information__field:not(:first-child) {
  margin-top: 5px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__general-information .general-information__field .field__title {
  font-weight: bold;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__general-information .general-information__field .field__value {
  word-break: break-word;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  padding: 5px 10px;
  overflow-y: auto;
  max-height: calc(100vh - 155px);
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .inner__section {
  padding: 10px 0;
  border-top: 1px solid #d9d9d9;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .gdt-collapsible-chapter:not(.expanded):hover {
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .gdt-collapsible-chapter.expanded .caption-row {
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .caption-row {
  height: 70px;
  background: #fff;
  color: #000;
  text-transform: uppercase;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .controls button:before {
  color: #3783a5;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div {
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 150px;
  column-gap: 10px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div .type__column {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div .type__column .column__title {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
.mdg-search-strategy-view .mdg-search-strategy-tab .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div .type__column textarea {
  width: 100%;
  height: 150px;
  border-radius: 5px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab.pubMed .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div,
.mdg-search-strategy-view .mdg-search-strategy-tab.scopus .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div {
  grid-template-columns: repeat(2, minmax(150px, 1fr)) 150px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab.diagnostic .rct .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div {
  grid-template-columns: repeat(3, minmax(150px, 1fr)) repeat(2, 100px);
}
.mdg-search-strategy-view .mdg-search-strategy-tab.diagnostic .diagnosisRCTTreatment .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div {
  grid-template-columns: repeat(4, minmax(150px, 1fr)) 150px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab.scopus .mdg-search-strategy-tab__inner .inner__section.diagnosisRCTTreatment .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div {
  grid-template-columns: repeat(3, minmax(150px, 1fr)) minmax(150px, 2fr) 200px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab.scopus.diagnostic .mdg-search-strategy-tab__inner .inner__section.diagnosisRCTTreatment .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div {
  grid-template-columns: repeat(4, minmax(150px, 1fr)) 150px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab.ebsco .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div {
  grid-template-columns: minmax(200px, 1fr) 150px;
}
.mdg-search-strategy-view .mdg-search-strategy-tab.summary .mdg-search-strategy-tab__inner .gdt-collapsible-chapter .collapsible-edit-container .collapsed-content > div {
  grid-template-columns: minmax(200px, 1fr);
}
.mdg-search-strategy-general-information-modal {
  width: 800px !important;
}
.mdg-search-strategy-general-information-modal .extraction-form {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-bottom: 10px;
}
.mdg-search-strategy-general-information-modal .extraction-form .extraction-form__group {
  margin: 0;
}
.mdg-search-strategy-general-information-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__name {
  width: 150px;
}
.mdg-search-strategy-general-information-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value {
  margin-right: 0;
  max-width: initial;
}
.mdg-search-strategy-general-information-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value > div {
  max-width: initial;
}
.mdg-search-strategy-general-information-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value textarea {
  height: 100px;
  resize: vertical;
}
.mdg-search-strategy-general-information-modal .search-date-field {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: visible;
}
.mdg-search-strategy-general-information-modal .search-date-field .search-date-field__separator {
  margin: 0 10px;
}
.parent-study-selector-dialog .parent-study-selector-dialog__description {
  margin-bottom: 10px;
  font-weight: bold;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection {
  margin-bottom: 10px;
  max-height: 200px;
  overflow-y: auto;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item {
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item > div {
  background: #f2f2f2;
  transition: background 0.2s ease-in-out;
  height: 100%;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item .item__radio {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 40px;
  margin-right: 2px;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item .item__radio input {
  margin: 0;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item .item__study-name {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  padding: 0 2px;
  line-height: 40px;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item:not(:first-child) {
  margin-top: 2px;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item:nth-child(even) > div {
  background: #e9e9e9;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item:hover > div {
  background: #f7f7f7;
}
.parent-study-selector-dialog .parent-study-selector-dialog__selection .selection__item:hover:nth-child(even) > div {
  background: #f2f2f2;
}
