.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in-up {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in-down {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fade-in-right {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fade-in-left {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}


div.hopscotch-bubble {
  position: absolute;
  z-index: 999999;
}

div.hopscotch-bubble.animate {
  -moz-transition-property: top, left;
  -moz-transition-duration: 1s;
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-property: top, left;
  -ms-transition-duration: 1s;
  -ms-transition-timing-function: ease-in-out;
  -o-transition-property: top, left;
  -o-transition-duration: 1s;
  -o-transition-timing-function: ease-in-out;
  -webkit-transition-property: top, left;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-property: top, left;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
}
div.hopscotch-bubble.invisible {
  opacity: 0;
}
div.hopscotch-bubble.hide,
div.hopscotch-bubble .hide,
div.hopscotch-bubble .hide-all {
  display: none;
}
div.hopscotch-bubble h3 {
  color: #000;
  font-family: Helvetica, Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: -1px 15px 0 0;
  padding: 0;
}
div.hopscotch-bubble .hopscotch-bubble-container {
  position: relative;
  -webkit-font-smoothing: antialiased;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container {
  position: absolute;
  width: 34px;
  height: 34px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container .hopscotch-bubble-arrow,
div.hopscotch-bubble .hopscotch-bubble-arrow-container .hopscotch-bubble-arrow-border {
  width: 0;
  height: 0;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up {
  top: -22px;
  left: 10px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid #ffffff;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  position: relative;
  top: -10px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid #000000;
  border-bottom: 17px solid rgba(0, 0, 0, 0.5);
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down {
  bottom: -39px;
  left: 10px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid #ffffff;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  position: relative;
  top: -24px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid #000000;
  border-top: 17px solid rgba(0, 0, 0, 0.5);
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left {
  top: 10px;
  left: -22px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  border-bottom: 17px solid transparent;
  border-right: 17px solid #ffffff;
  border-top: 17px solid transparent;
  position: relative;
  left: 7px;
  top: -34px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid #000000;
  border-right: 17px solid rgba(0, 0, 0, 0.5);
  border-bottom: 17px solid transparent;
  border-top: 17px solid transparent;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right {
  top: 10px;
  right: -39px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-bottom: 17px solid transparent;
  border-left: 17px solid #ffffff;
  border-top: 17px solid transparent;
  position: relative;
  left: -7px;
  top: -34px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid #000000;
  border-left: 17px solid rgba(0, 0, 0, 0.5);
  border-bottom: 17px solid transparent;
  border-top: 17px solid transparent;
}
