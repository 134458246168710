@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
#document-sections-from-template-container {
  height: 100%;
  overflow-y: auto;
}
#document-sections-from-template-container .document-sections__chapter .document-sections__chapter--separator {
  display: table;
  margin-bottom: 25px;
}
#document-sections-from-template-container .chapter-section-header {
  display: table;
  width: 100%;
  table-layout: fixed;
  cursor: pointer;
}
#document-sections-from-template-container .chapter-section-header td {
  z-index: 1;
  position: relative;
  padding: 10px 5px !important;
  border: 0px solid #fff !important;
  -webkit-box-shadow: 0px 5px 10px rgba(0,0,0,0.5) !important;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5) !important;
}
#document-sections-from-template-container .chapter-section-header .chapter-section-header__title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  text-transform: uppercase;
  line-height: 45px;
  font-weight: bold;
}
#document-sections-from-template-container .chapter-section-header--collapsed {
  background: #f2f2f2;
  color: #2d3236;
}
#document-sections-from-template-container .chapter-section-header--collapsed:hover {
  background: #005aa5;
  color: #fff;
}
#document-sections-from-template-container .chapter-section-title-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-top: 4px;
  margin-right: 4px;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
#document-sections-from-template-container .chapter-section-title-row__title {
  font-weight: bold;
  padding: 4px 8px 2px;
}
#document-sections-from-template-container .document-sections__chapter-settings {
  border: 2px solid #51a0c5;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  background: #fff;
  padding: 0;
  margin: 0;
  list-style: none;
}
#document-sections-from-template-container .document-sections__chapter-settings li {
  padding: 4px 8px;
}
#document-sections-from-template-container .document-sections__chapter-settings li:hover {
  cursor: pointer;
  color: #51a0c5;
}
#document-sections-from-template-container .document-sections__chapter-editor-card {
  overflow: initial;
}
#document-sections-from-template-container .document-sections__chapter-editor-card .gdt-editor-container {
  border: none;
}
.document_sections__insert-image-modal {
  width: 370px;
  top: 30vh;
}
.document_sections__insert-image-modal h2 {
  text-align: center;
}
.document_sections__insert-image-modal .document_sections__insert-image-modal-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 12px 0;
}
.document_sections__insert-image-modal .document_sections__insert-image-modal-content #file {
  display: none;
}
.document_sections__insert-image-modal .document_sections__insert-image-modal-content .document_sections__insert-image-button {
  white-space: normal;
  line-height: 24px;
  padding: 5px;
  min-width: 200px;
}
.document_sections__edit-chapter-subsection-modal {
  width: 370px;
  top: 30vh;
}
.document_sections__edit-chapter-subsection-modal h2 {
  text-align: center;
}
.document_sections__edit-chapter-subsection-modal .document_sections__edit-chapter-modal-content {
  margin: 12px 0;
}
.document_sections__edit-chapter-subsection-modal .document_sections__edit-chapter-modal-content .document_sections__edit-chapter-title-input {
  width: 100%;
}
.document_sections__edit-chapter-subsection-modal .document_sections__edit-chapter-modal-content .document_sections__edit-chapter-title-checkbox {
  display: inline-block;
  margin-top: 8px;
}
.document_sections__edit-chapter-subsection-modal .document_sections__edit-chapter-modal-content .document_sections__edit-chapter-title-checkbox input {
  margin-right: 4px;
}
.document-sections-from-template-toolbar {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0 10px;
}
.document-sections-from-template-toolbar button {
  margin: 0 8px;
}
.doc-sections-from-template-header {
  background: #f2f2f2;
  margin-bottom: 8px;
  padding-bottom: 6px;
}
.doc-sections-from-template-header__sections-table .doc-sections-from-template-header__section-label-cell {
  font-weight: bold;
  text-transform: uppercase;
  text-align: right;
  width: 15%;
  padding-right: 8px;
}
.doc-sections-from-template-header__sections-table .doc-sections-from-template-header__section-value-cell {
  padding-bottom: 8px;
}
.doc-sections-from-template-header__sections-table .doc-sections-from-template-header__section-textarea {
  width: 100%;
  height: 100px;
}
.doc-sections-from-template-header__sections-table .doc-sections-from-template-header__section-input {
  width: 100%;
}
.doc-sections-from-template-export-dialog {
  top: 10vh;
  width: 350px;
}
