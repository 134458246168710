@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.source-of-dx-data {
  padding-bottom: 10px;
}
.source-of-dx-data .question-mark-sign {
  border-width: 0;
}
.source-of-dx-data .question-mark-sign:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.source-of-dx-data .question-mark-sign * {
  margin-left: 0.5rem;
}
.source-of-dx-data .title {
  margin-right: 15px;
}
.source-of-dx-data label {
  margin-right: 15px;
}
.source-of-dx-data label input {
  vertical-align: middle;
  margin-right: 0;
}
