@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.status-matrix {
  overflow: auto;
}
.status-matrix table {
  width: 99%;
}
.status-matrix tbody.status-matrix__group {
  border: 1px solid #009cca;
}
.status-matrix tr.status-matrix__empty-group-row td {
  padding: 5px;
}
.status-matrix td.status-matrix__row-label {
  padding: 10px;
}
.status-matrix td.status-matrix__group-caption {
  font-weight: bold;
  padding: 10px;
}
.status-matrix td.status-matrix__label-cell {
  border-bottom: 1px solid #bfbfbf;
}
.status-matrix tr.status-matrix__checkboxes-row td {
  background-color: #f8faf9;
}
