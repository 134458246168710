@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#visual-guidelines .add-link {
  color: #0b6dad;
  font-style: italic;
  cursor: pointer;
}
#visual-guidelines .chapters-list .chapters-list-items .chapter-item .caption-row.chapter-caption {
  border-bottom: none !important;
}
#visual-guidelines .chapters-list .chapters-list-items .chapter-item .caption-row.chapter-caption h2.chapter-header-text {
  color: #000 !important;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}
#visual-guidelines .chapters-list .chapters-list-items .chapter-item .chapter-content {
  border-bottom: none !important;
}
#visual-guidelines .document-sections-title.acp_recommendations_populations {
  margin-bottom: 30px;
}
#visual-guidelines .document-sections-title.acp_recommendations_populations .title-text {
  display: inline-block;
  color: #0b6dad;
  font-size: 1.3em;
}
#visual-guidelines .chapter-section-management .status-content {
  display: none;
}
