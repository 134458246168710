@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.share-or-send-to-modal {
  background: #fff;
  border: 1px solid #bfbfbf;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  margin-top: 10%;
  width: 550px;
}
.share-or-send-to-modal h2 {
  text-align: center;
}
.access-rights {
  position: relative;
}
.share-or-send-to-modal {
  text-align: left;
}
.share-or-send-to-modal .block-label {
  font-weight: bold;
  margin-bottom: 5px;
}
.share-or-send-to-modal .co-owners-container {
  max-height: 120px;
  overflow-y: auto;
}
.share-or-send-to-modal .already-shared-with-table {
  width: 100%;
  background-color: #f2f2f2;
}
.share-or-send-to-modal .already-shared-with-table tr {
  height: 40px;
  line-height: 40px;
}
.share-or-send-to-modal .already-shared-with-table tr td {
  padding: 0 5px;
}
.share-or-send-to-modal .new-invitation {
  width: 90%;
  margin: 0 auto;
}
.share-or-send-to-modal .new-invitation tr {
  border: none;
}
.share-or-send-to-modal .new-invitation tr td input[type="text"] {
  height: 36px;
  line-height: 36px;
  color: #5c5d5f;
  width: 100%;
}
.share-or-send-to-modal .new-invitation .invitation-label {
  vertical-align: middle;
  font-weight: bold;
}
.share-or-send-to-modal .new-invitation .invitation-label span.info-sign {
  display: inline-block;
  position: relative;
  top: -4px;
  margin-left: 5px;
}
.share-or-send-to-modal .new-invitation .access-rights .select-with-annotations-container .options-select-container {
  width: 100%;
}
.share-or-send-to-modal .new-invitation .access-rights .select-with-annotations-container .options-select-container .select-input {
  font-size: 16px;
}
.share-or-send-to-modal .new-invitation .access-rights .select-with-annotations-container .options-select-container .options-container {
  font-size: 14px;
}
.share-or-send-to-modal .new-invitation .access-rights .select-with-annotations-container .options-select-container .options-container .option {
  line-height: 26px;
}
.share-or-send-to-modal .new-invitation .access-rights .select-option-annotation {
  font-size: 14px;
}
.share-or-send-to-modal input:invalid {
  background-color: #fff;
}
