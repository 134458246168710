@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.popup-menu {
  display: none;
  position: fixed;
  z-index: 1010;
  background: #fff;
  border: 1px solid #29abe2;
  padding: 15px;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
}
.popup-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  min-width: 140px;
}
.popup-menu ul li {
  float: left;
  clear: left;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  line-height: 36px;
}
.popup-menu ul li.disabled {
  cursor: default;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.popup-menu ul li:hover {
  background: #d2dfed;
}
.popup-menu ul li label {
  display: block;
}
.popup-menu ul li.active {
  font-weight: bold;
}
.popup-menu ul li.submenu:before {
  font-family: 'gdt';
  content: '\e029';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.popup-menu ul li.submenu * {
  margin-left: 0.5rem;
}
.popup-menu ul li.submenu:before {
  font-size: 10px;
  float: left;
  margin: 0;
  line-height: 38px;
}
.popup-menu ul li.submenu span.text {
  padding-left: 10px;
}
.popup-menu ul li span.checkbox {
  display: inline-block;
  width: 25px;
}
.popup-menu ul li span.text {
  padding-left: 25px;
}
.popup-menu ul li span.link {
  padding-left: 20px;
}
.popup-menu ul li.current {
  background: #ddeaf9;
}
.popup-menu ul.settings li,
.popup-menu ul.submenu-list li {
  font-size: 14px;
}
.popup-menu ul.submenu-list li span.text {
  padding-left: 0;
}
.popup-menu ul.submenu-list li {
  max-width: 280px;
  white-space: normal;
  line-height: 30px;
}
.popup-menu ul.submenu-list li .checkbox {
  float: left;
}
.popup-menu ul.submenu-list li .checkbox-text {
  overflow: hidden;
  line-height: 30px;
  display: block;
}
