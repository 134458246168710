@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
#isof-export-dialog {
  padding: 10px;
}
#isof-export-dialog textarea {
  width: 100%;
  height: 110px;
}
.embeddableCodeDialog {
  width: 800px;
  padding: 30px;
}
.embeddableCodeDialog div {
  margin-left: 0;
  margin-right: 0;
}
.embeddableCodeDialog textarea {
  width: 100%;
  height: 80px;
}
.embeddableCodeDialog .buttons div {
  padding: 0;
}
.embeddableCodeDialog .buttons button {
  width: 100%;
}
#panel-voice-export-dialog,
#outcomes-export-dialog,
#isof-export-dialog {
  font-size: 14px;
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
}
#panel-voice-export-dialog .title,
#outcomes-export-dialog .title,
#isof-export-dialog .title {
  font-weight: normal;
  margin: 0;
  padding: 0 15px 10px;
  font-size: 24px;
  text-align: center;
}
#panel-voice-export-dialog .choose-etd-view-title,
#outcomes-export-dialog .choose-etd-view-title,
#isof-export-dialog .choose-etd-view-title,
#panel-voice-export-dialog .choose-format-title,
#outcomes-export-dialog .choose-format-title,
#isof-export-dialog .choose-format-title,
#panel-voice-export-dialog .choose-outcomes-title,
#outcomes-export-dialog .choose-outcomes-title,
#isof-export-dialog .choose-outcomes-title,
#panel-voice-export-dialog .choose-appendices-title,
#outcomes-export-dialog .choose-appendices-title,
#isof-export-dialog .choose-appendices-title,
#panel-voice-export-dialog .choose-orientation-title,
#outcomes-export-dialog .choose-orientation-title,
#isof-export-dialog .choose-orientation-title {
  display: block;
  font-weight: bold;
  padding: 10px 15px;
}
#panel-voice-export-dialog .choose-format-title,
#outcomes-export-dialog .choose-format-title,
#isof-export-dialog .choose-format-title,
#panel-voice-export-dialog .choose-orientation-title,
#outcomes-export-dialog .choose-orientation-title,
#isof-export-dialog .choose-orientation-title {
  padding-bottom: 5px;
}
#panel-voice-export-dialog label,
#outcomes-export-dialog label,
#isof-export-dialog label {
  font-size: 14px;
  padding: 2px 15px;
}
#panel-voice-export-dialog .view-options label,
#outcomes-export-dialog .view-options label,
#isof-export-dialog .view-options label,
#panel-voice-export-dialog .formats-list label,
#outcomes-export-dialog .formats-list label,
#isof-export-dialog .formats-list label,
#panel-voice-export-dialog .choose-format label,
#outcomes-export-dialog .choose-format label,
#isof-export-dialog .choose-format label,
#panel-voice-export-dialog .choose-orientation label,
#outcomes-export-dialog .choose-orientation label,
#isof-export-dialog .choose-orientation label {
  display: block;
  max-width: 350px;
}
#panel-voice-export-dialog .view-options label input[type="radio"],
#outcomes-export-dialog .view-options label input[type="radio"],
#isof-export-dialog .view-options label input[type="radio"],
#panel-voice-export-dialog .formats-list label input[type="radio"],
#outcomes-export-dialog .formats-list label input[type="radio"],
#isof-export-dialog .formats-list label input[type="radio"],
#panel-voice-export-dialog .choose-format label input[type="radio"],
#outcomes-export-dialog .choose-format label input[type="radio"],
#isof-export-dialog .choose-format label input[type="radio"],
#panel-voice-export-dialog .choose-orientation label input[type="radio"],
#outcomes-export-dialog .choose-orientation label input[type="radio"],
#isof-export-dialog .choose-orientation label input[type="radio"] {
  position: relative;
  top: 3px;
}
#panel-voice-export-dialog .view-options label .question-mark-sign,
#outcomes-export-dialog .view-options label .question-mark-sign,
#isof-export-dialog .view-options label .question-mark-sign,
#panel-voice-export-dialog .formats-list label .question-mark-sign,
#outcomes-export-dialog .formats-list label .question-mark-sign,
#isof-export-dialog .formats-list label .question-mark-sign,
#panel-voice-export-dialog .choose-format label .question-mark-sign,
#outcomes-export-dialog .choose-format label .question-mark-sign,
#isof-export-dialog .choose-format label .question-mark-sign,
#panel-voice-export-dialog .choose-orientation label .question-mark-sign,
#outcomes-export-dialog .choose-orientation label .question-mark-sign,
#isof-export-dialog .choose-orientation label .question-mark-sign {
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  vertical-align: top;
}
#panel-voice-export-dialog .view-options label .question-mark-sign:before,
#outcomes-export-dialog .view-options label .question-mark-sign:before,
#isof-export-dialog .view-options label .question-mark-sign:before,
#panel-voice-export-dialog .formats-list label .question-mark-sign:before,
#outcomes-export-dialog .formats-list label .question-mark-sign:before,
#isof-export-dialog .formats-list label .question-mark-sign:before,
#panel-voice-export-dialog .choose-format label .question-mark-sign:before,
#outcomes-export-dialog .choose-format label .question-mark-sign:before,
#isof-export-dialog .choose-format label .question-mark-sign:before,
#panel-voice-export-dialog .choose-orientation label .question-mark-sign:before,
#outcomes-export-dialog .choose-orientation label .question-mark-sign:before,
#isof-export-dialog .choose-orientation label .question-mark-sign:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#panel-voice-export-dialog .view-options label span,
#outcomes-export-dialog .view-options label span,
#isof-export-dialog .view-options label span {
  display: table-cell;
}
#panel-voice-export-dialog .icon-orientation-landscape,
#outcomes-export-dialog .icon-orientation-landscape,
#isof-export-dialog .icon-orientation-landscape,
#panel-voice-export-dialog .icon-orientation-portrait,
#outcomes-export-dialog .icon-orientation-portrait,
#isof-export-dialog .icon-orientation-portrait {
  font-size: 18px;
  margin-left: 3px;
}
#panel-voice-export-dialog .icon-orientation-landscape:before,
#outcomes-export-dialog .icon-orientation-landscape:before,
#isof-export-dialog .icon-orientation-landscape:before {
  content: "";
}
#panel-voice-export-dialog .icon-orientation-landscape:after,
#outcomes-export-dialog .icon-orientation-landscape:after,
#isof-export-dialog .icon-orientation-landscape:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e030';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#panel-voice-export-dialog .icon-orientation-portrait:before,
#outcomes-export-dialog .icon-orientation-portrait:before,
#isof-export-dialog .icon-orientation-portrait:before {
  content: "";
}
#panel-voice-export-dialog .icon-orientation-portrait:after,
#outcomes-export-dialog .icon-orientation-portrait:after,
#isof-export-dialog .icon-orientation-portrait:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e031';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#panel-voice-export-dialog fieldset,
#outcomes-export-dialog fieldset,
#isof-export-dialog fieldset {
  margin-bottom: 20px;
  text-align: center;
}
#panel-voice-export-dialog .buttons,
#outcomes-export-dialog .buttons,
#isof-export-dialog .buttons {
  margin: 0px 15px;
}
#panel-voice-export-dialog .appendices-to-export,
#outcomes-export-dialog .appendices-to-export,
#isof-export-dialog .appendices-to-export,
#panel-voice-export-dialog .outcomes-to-export,
#outcomes-export-dialog .outcomes-to-export,
#isof-export-dialog .outcomes-to-export {
  max-width: 550px;
  max-height: 450px;
  overflow-y: auto;
}
#panel-voice-export-dialog .appendices-to-export ul,
#outcomes-export-dialog .appendices-to-export ul,
#isof-export-dialog .appendices-to-export ul,
#panel-voice-export-dialog .outcomes-to-export ul,
#outcomes-export-dialog .outcomes-to-export ul,
#isof-export-dialog .outcomes-to-export ul {
  background: #eaeaea;
  padding: 0;
  margin: 0;
  list-style: none;
}
#panel-voice-export-dialog .appendices-to-export ul li,
#outcomes-export-dialog .appendices-to-export ul li,
#isof-export-dialog .appendices-to-export ul li,
#panel-voice-export-dialog .outcomes-to-export ul li,
#outcomes-export-dialog .outcomes-to-export ul li,
#isof-export-dialog .outcomes-to-export ul li {
  position: relative;
  line-height: 20px;
  padding: 5px 15px;
}
#panel-voice-export-dialog .appendices-to-export ul li.selected,
#outcomes-export-dialog .appendices-to-export ul li.selected,
#isof-export-dialog .appendices-to-export ul li.selected,
#panel-voice-export-dialog .outcomes-to-export ul li.selected,
#outcomes-export-dialog .outcomes-to-export ul li.selected,
#isof-export-dialog .outcomes-to-export ul li.selected {
  background-color: #fff;
}
#panel-voice-export-dialog .appendices-to-export ul li input,
#outcomes-export-dialog .appendices-to-export ul li input,
#isof-export-dialog .appendices-to-export ul li input,
#panel-voice-export-dialog .outcomes-to-export ul li input,
#outcomes-export-dialog .outcomes-to-export ul li input,
#isof-export-dialog .outcomes-to-export ul li input {
  position: relative;
  top: 6px;
  float: left;
}
#panel-voice-export-dialog .appendices-to-export ul li input[type="checkbox"],
#outcomes-export-dialog .appendices-to-export ul li input[type="checkbox"],
#isof-export-dialog .appendices-to-export ul li input[type="checkbox"],
#panel-voice-export-dialog .outcomes-to-export ul li input[type="checkbox"],
#outcomes-export-dialog .outcomes-to-export ul li input[type="checkbox"],
#isof-export-dialog .outcomes-to-export ul li input[type="checkbox"] {
  background-color: #fff;
}
#panel-voice-export-dialog .appendices-to-export ul li input[type="checkbox"]:checked,
#outcomes-export-dialog .appendices-to-export ul li input[type="checkbox"]:checked,
#isof-export-dialog .appendices-to-export ul li input[type="checkbox"]:checked,
#panel-voice-export-dialog .outcomes-to-export ul li input[type="checkbox"]:checked,
#outcomes-export-dialog .outcomes-to-export ul li input[type="checkbox"]:checked,
#isof-export-dialog .outcomes-to-export ul li input[type="checkbox"]:checked {
  background-color: #f2f2f2;
}
#panel-voice-export-dialog .appendices-to-export ul li label,
#outcomes-export-dialog .appendices-to-export ul li label,
#isof-export-dialog .appendices-to-export ul li label,
#panel-voice-export-dialog .outcomes-to-export ul li label,
#outcomes-export-dialog .outcomes-to-export ul li label,
#isof-export-dialog .outcomes-to-export ul li label {
  display: block;
  padding-left: 20px;
}
.export-dialog ul {
  background: #eaeaea;
  padding: 0;
  margin: 0;
  list-style: none;
}
.export-dialog ul li {
  position: relative;
  line-height: 20px;
  padding: 5px 15px;
}
.export-dialog .studies-to-export__studies {
  max-height: 50vh;
  overflow: auto;
}
#panel-voice-export-dialog {
  width: 540px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}
#panel-voice-export-dialog span.title {
  font-size: 14px;
  padding: 0;
}
#panel-voice-export-dialog .results-toggle-with-text,
#panel-voice-export-dialog .judgment-toggle-with-text {
  position: relative;
}
#panel-voice-export-dialog .results-toggle-with-text label,
#panel-voice-export-dialog .judgment-toggle-with-text label {
  margin: 0;
  padding: 0;
}
#panel-voice-export-dialog .results-toggle-with-text label.disabled,
#panel-voice-export-dialog .judgment-toggle-with-text label.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#panel-voice-export-dialog .choose-etd-view-title {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: flex-row;
  -ms-flex-direction: flex-row;
  flex-direction: flex-row;
}
#panel-voice-export-dialog .choose-etd-view-title .title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  padding: 0;
  text-align: left;
}
#panel-voice-export-dialog .choose-etd-view-title .judgment,
#panel-voice-export-dialog .choose-etd-view-title .results {
  font-weight: normal;
  padding: 0 20px;
}
#panel-voice-export-dialog .choose-etd-view-title .judgment.disabled,
#panel-voice-export-dialog .choose-etd-view-title .results.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#panel-voice-export-dialog .view-options label span {
  display: inline-block;
  font-weight: normal;
}
#panel-voice-export-dialog .view-options label.section-label {
  display: block;
  max-width: 100%;
  line-height: 30px;
  margin-bottom: 1px;
}
#panel-voice-export-dialog .view-options label.section-label input[type='radio'] {
  position: relative;
  top: 0px;
}
#panel-voice-export-dialog .view-options label.section-label.selected {
  background-color: #e9faff;
}
#panel-voice-export-dialog .view-options label.section-label.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#panel-voice-export-dialog .view-options label.section-label span.title {
  display: inline-block;
  text-align: left;
  width: 190px;
  padding-left: 0px;
  margin-left: 0px;
}
#panel-voice-export-dialog .view-options label.section-label .judgment,
#panel-voice-export-dialog .view-options label.section-label .results {
  height: 17px;
  position: relative;
  top: 5px;
  text-align: right;
  width: 100px;
  margin-right: 25px;
  display: inline-block;
}
#panel-voice-export-dialog.panel-voice-export-dialog--react span.judgment,
#panel-voice-export-dialog.panel-voice-export-dialog--react span.results {
  width: 160px;
  padding: 0;
  text-align: center;
}
#panel-voice-export-dialog.panel-voice-export-dialog--react span.judgment.disabled,
#panel-voice-export-dialog.panel-voice-export-dialog--react span.results.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#panel-voice-export-dialog.panel-voice-export-dialog--react .export-section-options label.selected {
  background-color: #e9faff;
}
#panel-voice-export-dialog.panel-voice-export-dialog--react .export-section-options label.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#panel-voice-export-dialog.panel-voice-export-dialog--react .export-section-options label span.title {
  text-align: left;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
#panel-voice-export-dialog.panel-voice-export-dialog--react .export-section-options input[type='radio'] {
  margin: 0 15px 0px 0px;
  width: 13px;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
  height: 13px;
}
#panel-voice-export-dialog.panel-voice-export-dialog--react .export-section-options input[type='radio'].selected {
  background-color: #e9faff;
}
#panel-voice-export-dialog.panel-voice-export-dialog--react .export-section-options input[type='radio'].disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#panel-voice-export-dialog.panel-voice-export-dialog--react .switcher-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
#panel-voice-export-dialog.panel-voice-export-dialog--react .switcher-container .switcher {
  top: 1px;
}
#panel-voice-export-dialog.panel-voice-export-dialog--react label input {
  top: 0px;
  margin: 0px;
  margin-right: 20px;
}
