@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.selection-button-container .btn:before {
  content: "";
}
.selection-button-container .btn:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.selection-button-container .btn.opened:before {
  content: "";
}
.selection-button-container .btn.opened:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e017';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.selection-button-container .opened-list {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #bfbfbf;
}
.selection-button-container .opened-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.selection-button-container .opened-list ul li {
  padding: 5px;
  line-height: 36px;
  cursor: pointer;
}
.selection-button-container .opened-list ul li:hover {
  background-color: #e9e9e9;
}
.selection-button-container .opened-list ul li.disabled {
  opacity: 0.3;
}
