@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/montserrat/regular/montserrat-v12-latin-regular.eot");
  src: url("./../fonts/montserrat/regular/montserrat-v12-latin-regular.eot?#iefix") format('embedded-opentype'), url("./../fonts/montserrat/regular/montserrat-v12-latin-regular.woff") format('woff'), url("./../fonts/montserrat/regular/montserrat-v12-latin-regular.ttf") format('truetype'), url("./../fonts/montserrat/regular/montserrat-v12-latin-regular.svg") format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/montserrat/italic/montserrat-v12-latin-italic.eot");
  src: url("./../fonts/montserrat/italic/montserrat-v12-latin-italic.eot?#iefix") format('embedded-opentype'), url("./../fonts/montserrat/italic/montserrat-v12-latin-italic.woff") format('woff'), url("./../fonts/montserrat/italic/montserrat-v12-latin-italic.ttf") format('truetype'), url("./../fonts/montserrat/italic/montserrat-v12-latin-italic.svg") format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/montserrat/bold/montserrat-v12-latin-bold.eot");
  src: url("./../fonts/montserrat/bold/montserrat-v12-latin-bold.eot?#iefix") format('embedded-opentype'), url("./../fonts/montserrat/bold/montserrat-v12-latin-bold.woff") format('woff'), url("./../fonts/montserrat/bold/montserrat-v12-latin-bold.ttf") format('truetype'), url("./../fonts/montserrat/bold/montserrat-v12-latin-bold.svg") format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url("./../fonts/montserrat/bolditalic/montserrat-v12-latin-bolditalic.eot");
  src: url("./../fonts/montserrat/bolditalic/montserrat-v12-latin-bolditalic.eot?#iefix") format('embedded-opentype'), url("./../fonts/montserrat/bolditalic/montserrat-v12-latin-bolditalic.woff") format('woff'), url("./../fonts/montserrat/bolditalic/montserrat-v12-latin-bolditalic.ttf") format('truetype'), url("./../fonts/montserrat/bolditalic/montserrat-v12-latin-bolditalic.svg") format('svg');
  font-weight: bold;
  font-style: italic;
}
