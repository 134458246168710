@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.notifier.new-reference-modal-container .notifier-inner {
  padding: 20px;
  font-size: 16px;
  font-size: 1.6rem;
}
.notifier.new-reference-modal-container .notifier-inner .info-icon {
  margin-left: 6px;
}
.references-usage {
  max-height: 300px;
  overflow-y: auto;
}
.references-usage > div {
  color: #f67c61;
}
.references-usage > div .link-to-usage {
  cursor: pointer;
}
.references-usage > div .link-to-usage:hover {
  opacity: 0.75;
}
.references-usage > div label {
  color: #000;
  font-weight: bold;
}
.new-reference-modal,
.reference-edit-modal {
  position: relative;
  min-width: 300px;
}
.new-reference-modal h2,
.reference-edit-modal h2 {
  text-align: center;
}
.new-reference-modal .edit-container,
.reference-edit-modal .edit-container {
  margin-top: 20px;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  box-sizing: border-box;
}
.new-reference-modal table,
.reference-edit-modal table {
  width: 100%;
}
.new-reference-modal table td,
.reference-edit-modal table td {
  vertical-align: middle;
  padding: 10px;
}
.new-reference-modal table .field-name,
.reference-edit-modal table .field-name {
  width: 160px;
  font-weight: bold;
}
.new-reference-modal table .field-name.authors,
.reference-edit-modal table .field-name.authors {
  vertical-align: top;
  padding-top: 10px;
}
.new-reference-modal table .field-name .info-icon,
.reference-edit-modal table .field-name .info-icon {
  margin-left: 10px;
  font-size: 12px;
}
.new-reference-modal table .field-name .info-icon:before,
.reference-edit-modal table .field-name .info-icon:before {
  vertical-align: super;
}
.new-reference-modal table .field-edit.authors,
.reference-edit-modal table .field-edit.authors {
  padding-top: 0px;
}
.new-reference-modal table .field-edit textarea,
.reference-edit-modal table .field-edit textarea,
.new-reference-modal table .field-edit input[type="text"],
.reference-edit-modal table .field-edit input[type="text"] {
  width: 100%;
  min-height: 35px;
  background-color: #f7f7f7;
}
.new-reference-modal table .field-edit textarea:focus,
.reference-edit-modal table .field-edit textarea:focus,
.new-reference-modal table .field-edit input[type="text"]:focus,
.reference-edit-modal table .field-edit input[type="text"]:focus {
  background-color: #d2dfed;
}
.new-reference-modal table .field-edit textarea,
.reference-edit-modal table .field-edit textarea {
  min-height: 35px;
}
.new-reference-modal .buttons,
.reference-edit-modal .buttons {
  padding: 0 10px;
  margin-top: 10px;
}
.new-reference-modal .buttons button,
.reference-edit-modal .buttons button {
  width: 100%;
}
.reference-edit-modal {
  width: 45vw;
}
.reference-edit-modal .reference-authors-edit label,
.reference-edit-modal .reference-authors-edit input {
  width: 250px;
}
.references .buttons {
  padding-bottom: 2rem;
}
.references .buttons button {
  margin-right: 10px;
}
.selection-controls {
  display: inline-block;
}
.selection-controls button {
  margin-right: 10px;
}
.references-container {
  min-width: 800px;
  overflow-x: auto;
}
.references-container .references-header-container {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 20px;
}
.references-container .references-header-container .search-container {
  -webkit-flex: 0 0 33%;
  -ms-flexbox: 0 0 33%;
  flex: 0 0 33%;
}
.references-container .references-header-container .btn-icon-container {
  margin-left: 20px;
  text-align: center;
  color: #f67c61;
}
.references-container .references-header-container .btn-icon-container .btn-icon:before {
  color: #f67c61;
}
.references-container .references-header-container .buttons {
  position: absolute;
  right: 0;
}
.references-container .references-header-container .buttons .btn {
  margin-left: 10px;
}
.reference-attachment-link {
  transition: all 0.2s ease-in-out;
}
.reference-attachment-link button {
  opacity: 1 !important;
}
.reference-attachment-link button:before {
  color: #009cca;
  margin-right: 5px !important;
}
.reference-attachment-link .label-text {
  color: #009cca;
  font-size: 14px;
}
.reference-attachment-link:hover {
  opacity: 0.7;
}
.references-table .table-header,
.references-table .table-body .reference {
  display: grid;
  grid-template-columns: 15% 1fr 5% 20% 7% 80px;
  grid-gap: 2px;
}
.references-table .table-header > div,
.references-table .table-body .reference > div {
  padding: 5px;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}
.references-table .table-header .authors,
.references-table .table-body .reference .authors {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.references-table .table-header .attachment .attachment__inner,
.references-table .table-body .reference .attachment .attachment__inner {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.references-table .table-header .attachment .attachment__delete-button,
.references-table .table-body .reference .attachment .attachment__delete-button {
  margin-left: 5px;
  font-size: 8px;
  color: #009cca;
}
.references-table .table-header .attachment .attachment__delete-button button,
.references-table .table-body .reference .attachment .attachment__delete-button button {
  line-height: initial;
}
.references-table .table-header .attachment .attachment__delete-button button:before,
.references-table .table-body .reference .attachment .attachment__delete-button button:before {
  color: #009cca;
}
.references-table .table-header .attachment .attachment__upload,
.references-table .table-body .reference .attachment .attachment__upload {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.references-table .table-header .attachment .attachment__upload button,
.references-table .table-body .reference .attachment .attachment__upload button {
  transition: all 0.2s ease-in-out;
  color: #009cca;
}
.references-table .table-header .attachment .attachment__upload button:hover,
.references-table .table-body .reference .attachment .attachment__upload button:hover {
  opacity: 0.7;
}
.references-table.with-attachments .table-header,
.references-table.with-attachments .reference {
  grid-template-columns: 15% 1fr 5% 20% 7% 100px 80px;
}
.references-table .table-header {
  cursor: pointer;
}
.references-table .table-header.with-scroll-offset {
  width: calc(100% - 10px);
}
.references-table .table-header > div {
  background: #dedede;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}
.references-table .table-header > div.sorted {
  font-weight: bold;
}
.references-table .table-header > div.sorted.asc:after {
  content: '▲';
}
.references-table .table-header > div.sorted.desc:after {
  content: '▼';
}
.references-table .table-body {
  height: calc(100vh - 233px);
  overflow-y: auto;
}
.references-table .table-body .reference {
  width: 100%;
  height: 56px;
}
.references-table .table-body .reference > div {
  background: #f2f2f2;
  word-break: break-word;
}
.references-table .table-body .reference .researchResults {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.references-table .table-body .reference .researchResults .reference-controls {
  position: absolute;
  right: 0;
  top: 0;
}
.references-table .table-body .reference .researchResults .research-results-checkmark {
  display: inline-block;
  vertical-align: top;
  line-height: 44px;
}
.references-table .table-body .reference .researchResults .research-results-checkmark:before {
  font-family: 'gdt';
  content: '\e060';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.references-table .table-body .reference .researchResults .research-results-checkmark * {
  margin-left: 0.5rem;
}
.references-table .table-body .reference .title {
  width: 100%;
}
.references-table .table-body .reference .title > div {
  display: grid;
  grid-template-columns: 1fr 25px;
  grid-gap: 10px;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.references-table .table-body .reference:hover > div {
  background-color: #f7f7f7;
}
.references-table .table-body .reference.selected > div {
  background-color: #d2dfed;
}
.references-toolbar {
  padding-left: 10px;
}
