@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.dbep-modal .ep-modal__content {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  min-height: 200px;
  max-height: 670px;
}
.dbep-modal .dbep-modal__questions-list {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  margin-bottom: 10px;
}
.dbep-modal .dbep-modal__questions-list .questions-selector__questions {
  max-height: 380px;
  padding-right: 5px;
}
.dbep-modal .dbep-modal__validation {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.dbep-modal .dbep-modal__validation .validation__progress-bar {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin-bottom: 10px;
}
.dbep-modal .dbep-modal__validation .validation__override,
.dbep-modal .dbep-modal__validation .validation__top {
  margin-bottom: 5px;
}
.dbep-modal .dbep-modal__validation .validation__errors {
  max-height: 150px;
  overflow-y: auto;
}
.dbep-screen {
  margin-top: 10px;
}
.dbep-questions-table .dbep-questions-table__search-bar {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__search {
  width: 30%;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__search input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__search button.search {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls .controls__buttons {
  margin-right: 50px;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls .controls__buttons .btn-icon-container:not(:last-child) {
  margin-right: 10px;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls .controls__buttons .btn-icon-container button.eye:before {
  font-size: 12px;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls .controls__buttons .btn-icon-container button.bookmark:before {
  font-size: 16px;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls .controls__buttons .btn-icon-container .label-text {
  font-size: 12px;
  margin-top: 3px;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls .controls__status-filter {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls .controls__status-filter .status-filter__text {
  margin-right: 10px;
}
.dbep-questions-table .dbep-questions-table__search-bar .search-bar__controls .controls__status-filter .status-filter__select .select-input {
  width: 250px;
  border: 1px solid #009cca;
  border-radius: 4px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__questions-group {
  margin-bottom: 10px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__questions-group:not(.with-caption) {
  margin: 0 11px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__question {
  border-bottom: 0 !important;
  border-top: 0 !important;
  height: 50px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__question:before {
  width: 40px;
  height: 50px;
  line-height: 50px !important;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__question .dbep-table__row .dbep-table__cell:not(.question) {
  background: #fff;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__question:hover .dbep-table__row:before {
  background: #dedede;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__question:hover .dbep-table__row .dbep-table__cell {
  background: none;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__question:not(:first-child):before {
  border-top: 2px solid #fff !important;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__question:not(:first-child) .dbep-table__cell:not(.question) {
  border-top: 2px solid #f2f2f2;
}
.dbep-questions-table .dbep-questions-table__dbep-table .questions-list-container__question:not(:first-child) .dbep-table__cell.question {
  border-top: 2px solid #fff;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__row {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__row .dbep-table__cell {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: content-box;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__row .dbep-table__cell .cell-content {
  width: 100%;
  padding: 5px;
  text-align: center;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__row .dbep-table__cell.question {
  width: calc(100% - 352px);
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__row .dbep-table__cell.status {
  width: 200px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__row .dbep-table__cell.actions {
  width: 150px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__header {
  margin: 20px 0;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__header .dbep-table__row {
  height: 40px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__header .dbep-table__row .dbep-table__cell {
  background: #dedede;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__header .dbep-table__row .dbep-table__cell:not(:last-child) {
  border-right: 2px solid #fff;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__header .dbep-table__row .dbep-table__cell.actions {
  width: 160px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__header .dbep-table__row .dbep-table__cell.question {
  width: calc(100% - 362px);
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body {
  position: relative;
  height: calc(100vh - 235px);
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row {
  height: 50px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell {
  height: 50px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell:not(:last-child):not(.question) {
  border-right: 2px solid #f2f2f2;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.question {
  border-right: 2px solid #fff;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.question .cell-content {
  text-align: left;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__status {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__status .status__square {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 8px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__status .status__square.published {
  background: #0089ba;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__status .status__square.in_review {
  background: #ff5186;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__status .status__square.unpublished {
  background: #d1d1d1;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a {
  font-size: 15px;
  transition: all 0.2s ease-in-out;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a:before {
  font-family: 'gdt';
  content: '\e095';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a * {
  margin-left: 0.5rem;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a,
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a:link,
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a:visited,
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a:hover,
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a:active {
  color: inherit;
  text-decoration: none;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a:hover {
  opacity: 0.7;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.status .question-status .question-status__link a:before {
  color: #3783a5;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.actions .cell-content {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.actions .cell-content .btn-icon-container:not(:last-child) {
  margin-right: 20px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.actions .cell-content .btn-icon-container button.eye:before {
  font-size: 12px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.actions .cell-content .btn-icon-container button.bookmark:before {
  font-size: 16px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .dbep-table__row .dbep-table__cell.actions .cell-content .btn-icon-container .label-text {
  font-size: 12px;
  margin-top: 3px;
}
.dbep-questions-table .dbep-questions-table__dbep-table .dbep-table__body .questions-list-container__question:hover .dbep-table__row .dbep-table__cell:not(:last-child) {
  border-right: 2px solid #fff;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) 150px 150px 100px;
  height: 50px;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-items: center;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row:not(:last-child) {
  border-bottom: 2px solid #fff;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row > div {
  width: 100%;
  height: 100%;
  padding: 0 17px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background: #fff;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row.header,
.mdg-publication-screen .mdg-publication-screen__table .table__row .row__title {
  font-weight: bold;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row:not(.header) > div {
  border-bottom: 2px solid #f2f2f2;
  transition: all 0.2s ease-in-out;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row:not(.header) > div:not(:last-child) {
  border-right: 2px solid #f2f2f2;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row:not(.header) > div.row__title {
  border-bottom: 2px solid #fff;
  background: #f2f2f2;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-right: 2px solid #fff;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row:not(.header):hover > div:not(.row__title) {
  background: #f2f2f2;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row:not(.header):hover > div:not(.row__title):not(:last-child) {
  border-right: 2px solid #fff;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row:not(.header):hover .row__title {
  border-bottom: 2px solid #f2f2f2;
  background: #fff;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row.header {
  border-bottom: 2px solid #fff;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row.header > div {
  background: #dedede;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row.header > div:not(:last-child) {
  border-right: 2px solid #fff;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row .row__status .status__square {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 8px;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row .row__status .status__square.published {
  background: #0089ba;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row .row__status .status__square.unpublished {
  background: #d1d1d1;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row .row__last-update .last-update__icon {
  margin-right: 5px;
}
.mdg-publication-screen .mdg-publication-screen__table .table__row .row__last-update .last-update__icon:before {
  font-family: 'gdt';
  content: '\e103';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  color: #f67c61;
}
.mdg-publication-modal .mdg-publication-modal__progress {
  width: 100%;
  margin: 10px 0;
  height: 30px;
}
.mdg-publication-modal .mdg-publication-modal__button button {
  width: 100%;
}
