@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.over-review-view {
  height: 100%;
  overflow-y: auto;
}
.grade-dispute-module .module-title {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}
.grade-dispute-module .module-intro,
.grade-dispute-module .step-title,
.grade-dispute-module .editable-text-item.item-label {
  font-size: 14px;
  line-height: 20px;
}
.grade-dispute-module .module-intro {
  margin: 15px 0 30px;
}
.grade-dispute-module .step-title {
  margin-bottom: 10px;
  font-weight: bold;
}
.grade-dispute-module .reviewed-outcomes-list .reviewed-outcome:not(:last-child) {
  margin-bottom: 2px;
}
.grade-dispute-module .reviewed-outcomes-list .bottom-buttons {
  margin: 10px 40px 16px 121px;
}
.grade-dispute-module .reviewed-outcomes-list .bottom-buttons .btn {
  width: 100%;
}
.grade-dispute-module .buttons-row .btn {
  margin-right: 40px;
  width: 150px;
  float: right;
}
.grade-dispute-module .over-review-sources-list .source-item:not(:last-child) {
  margin-bottom: 2px;
}
.grade-dispute-module .over-review-sources-list .bottom-buttons {
  margin: 10px 40px 16px 121px;
}
.grade-dispute-module .over-review-sources-list .bottom-buttons .btn {
  width: 50%;
}
.grade-dispute-module .over-review-sources-list .bottom-buttons .btn.btn-import {
  margin-left: 10px;
}
.grade-dispute-module .editable-text-item {
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.grade-dispute-module .editable-text-item.editing .item-content {
  background: #fff;
  border: 1px solid #bfbfbf;
}
.grade-dispute-module .editable-text-item .item-label {
  width: 121px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.grade-dispute-module .editable-text-item .item-content {
  position: relative;
  height: 100%;
  padding: 0 8px;
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #f2f2f2;
}
.grade-dispute-module .editable-text-item .item-content input {
  width: 100%;
  padding: 0;
  border: 0;
  background: transparent;
}
.grade-dispute-module .editable-text-item .item-content .btn-icon-container {
  position: absolute;
  top: 6px;
  right: 0;
}
.grade-dispute-module .editable-text-item .btn-icon-container {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 10px;
}
.grade-dispute-module .import-source-input-file {
  display: none;
}
.grade-dispute-module .over-review-tabs .over-review-question {
  border: 1px solid #009cca;
  margin-bottom: 25px;
}
.grade-dispute-module .over-review-tabs .over-review-question .collapsible-edit-container .caption-row {
  min-height: 50px;
}
.grade-dispute-module .over-review-tabs .over-review-question .collapsible-edit-container .controls {
  -webkit-flex: 0 0 50px;
  -ms-flexbox: 0 0 50px;
  flex: 0 0 50px;
}
.grade-dispute-module .over-review-tabs .over-review-question .reviewed-outcomes .title,
.grade-dispute-module .over-review-tabs .over-review-question .reviewed-sources .title {
  font-weight: bold;
}
.grade-dispute-module .over-review-tabs .over-review-question .reviewed-sources {
  margin: 21px 0 10px;
}
.grade-dispute-module .over-review-tabs .over-review-question .items-list .items-list-item {
  position: relative;
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #f2f2f2;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.grade-dispute-module .over-review-tabs .over-review-question .items-list .items-list-item:not(:first-child) {
  margin-top: 2px;
}
.grade-dispute-module .over-review-tabs .over-review-question .items-list .items-list-item:before {
  position: absolute;
  display: block;
  height: 100%;
  content: '';
  left: 40px;
  width: 2px;
  background-color: #fff;
}
.grade-dispute-module .over-review-tabs .over-review-question .items-list .items-list-item .item-count {
  width: 40px;
  text-align: center;
}
.grade-dispute-module .over-review-tabs .over-review-question .items-list .items-list-item .item-content {
  padding: 0 10px;
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab h2,
.grade-dispute-module .over-review-tabs .over-review-studies-tab h2 {
  font-size: 16px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab h2 > div,
.grade-dispute-module .over-review-tabs .over-review-studies-tab h2 > div {
  font-weight: normal;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content {
  padding: 26px 10px 20px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-table::-webkit-scrollbar,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping-table::-webkit-scrollbar {
  height: 6px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-table::-webkit-scrollbar-track,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping-table::-webkit-scrollbar-track {
  background-color: #cbcbcb;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-table::-webkit-scrollbar-thumb,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #3883a5;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-row:first-child .question-field-name,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-row:last-child .question-field-name,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-row:first-child .question-field-value,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-row:last-child .question-field-value,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-row:first-child .review-quality-cell,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-row:last-child .review-quality-cell {
  background-color: #d2dfed;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-row:first-child .gdt-flex-table-cell.source-name-cell {
  background-color: #3783a5;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-row.review-quality-row {
  margin-top: 30px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell {
  min-width: 185px;
  min-height: 40px;
  border: 1px solid #fff;
  background-color: #e9e9e9;
  font-size: 14px;
  text-align: center;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.question-title-cell {
  background-color: #fff;
  margin-right: 8px;
  font-weight: bold;
  text-transform: uppercase;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.question-title-cell,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.review-quality-cell,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.review-comment-cell {
  -webkit-flex: 0 0 287px;
  -ms-flexbox: 0 0 287px;
  flex: 0 0 287px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.question-field-name,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.review-quality-cell,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.review-comment-cell {
  text-transform: uppercase;
  font-weight: bold;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.question-field-value,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.review-quality-cell,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.review-comment-cell {
  margin-right: 8px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.question-field-value,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.source-field-value {
  width: 185px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.question-field-name {
  min-width: initial;
  -webkit-flex: 0 0 102px;
  -ms-flexbox: 0 0 102px;
  flex: 0 0 102px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.question-field-value {
  -webkit-flex: 0 0 185px;
  -ms-flexbox: 0 0 185px;
  flex: 0 0 185px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.review-quality-cell {
  z-index: 99;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.source-field-value,
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .sources-pico-summary .sources-pico-table .gdt-flex-table-cell.source-review-comment-cell {
  background-color: #f2f2f2;
  vertical-align: middle;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping .outcomes-mapping-table .gdt-flex-table-row:first-child .gdt-flex-table-cell {
  height: 40px;
  border: 1px solid #fff;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping .outcomes-mapping-table .gdt-flex-table-row:first-child .gdt-flex-table-cell:first-child {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping .outcomes-mapping-table .gdt-flex-table-row:first-child .gdt-flex-table-cell.source-name-cell {
  background-color: #3783a5;
  color: #fff;
  font-weight: bold;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping .outcomes-mapping-table .gdt-flex-table-row .gdt-flex-table-cell {
  min-width: 185px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #fff;
  background-color: #e9e9e9;
  vertical-align: middle;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping .outcomes-mapping-table .gdt-flex-table-row .gdt-flex-table-cell.outcome-name {
  z-index: 98;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping .outcomes-mapping-table .gdt-flex-table-row .gdt-flex-table-cell.source-mapping {
  background-color: #f2f2f2;
  vertical-align: middle;
  width: 185px;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping .outcomes-mapping-table .gdt-flex-table-row .gdt-flex-table-cell.source-mapping .mapped-outcome-name {
  min-height: 40px;
  width: 100%;
  cursor: pointer;
}
.grade-dispute-module .over-review-tabs .over-review-sources-tab .card-content .outcomes-mapping .outcomes-mapping-table .gdt-flex-table-row .gdt-flex-table-cell.source-mapping .mapped-outcome-name.active {
  border: 1px solid #009cca;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix .status-matrix__group {
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix td.status-matrix__group-caption,
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix td.status-matrix__row-label {
  padding-left: 20px;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix td.status-matrix__row-label {
  position: relative;
  width: 250px;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix td.status-matrix__row-label > div {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix td.status-matrix__row-label > div label input[type="checkbox"] {
  position: absolute;
  top: 15px;
  left: 0;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix .status-matrix__label-cell > div {
  cursor: pointer;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix .over-review-matrix-cell__status-possible {
  background-color: #999;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix .over-review-matrix-cell__status-included {
  background-color: #8cde8c;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix .over-review-matrix-cell__status-excluded {
  background-color: #fbb03b;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .status-matrix .over-review-matrix-cell__status-none {
  background-color: #f2f2f2;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend {
  width: 350px;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend h4 {
  text-align: center;
  margin: 0;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item {
  margin-top: 16px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item.included .status-value {
  background-color: #8cde8c;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item.excluded .status-value {
  background-color: #fbb03b;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item.possible .status-value {
  background-color: #999;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item.possible .status-value-legend .status-value-description {
  margin-top: 0;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item .status-value {
  width: 24px;
  height: 24px;
  margin: 0 15px;
  -webkit-flex: 0 0 24px;
  -ms-flexbox: 0 0 24px;
  flex: 0 0 24px;
  background-color: #f2f2f2;
  borderRadius: '2px';
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item .status-value-legend {
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item .status-value-legend .status-value-title {
  text-decoration: underline;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .status-marix-with-legend .statux-matrix-legend .legend-item .status-value-legend .status-value-description {
  font-size: 14px;
  margin-top: 12px;
}
.grade-dispute-module .over-review-tabs .over-review-studies-tab .merge-buttons .btn-cancel {
  margin-right: 20px;
}
.grade-dispute-module .over-review-tabs .over-review-evidence-table-tab {
  margin-top: 15px;
}
.grade-dispute-module .over-review-tabs .over-review-evidence-table-tab .outcomes-container table thead th {
  background-color: #3783a5;
  border: 3px solid #fff;
  font-size: 13px;
  color: #fff;
}
.grade-dispute-module .over-review-tabs .over-review-evidence-table-tab .outcomes-container table tbody td {
  background-color: #f2f2f2;
  border: 3px solid #fff;
}
.grade-dispute-module .over-review-tabs .over-review-evidence-table-tab .outcomes-container table tbody td.outcome-name {
  padding: 15px 5px 5px;
  font-weight: bold;
  background-color: #fff;
}
.editable-text-item {
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.editable-text-item.editing .item-content {
  background: #fff;
  border: 1px solid #bfbfbf;
}
.editable-text-item .item-label {
  width: 121px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.editable-text-item .item-content {
  position: relative;
  height: 100%;
  padding: 0 8px;
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #f2f2f2;
}
.editable-text-item .item-content input {
  width: 100%;
  padding: 0;
  border: 0;
  background: transparent;
}
.editable-text-item .item-content .btn-icon-container {
  position: absolute;
  top: 6px;
  right: 0;
}
.editable-text-item .btn-icon-container {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 10px;
}
.over-review-revman-import,
.over-review-source-edit-modal {
  top: 20vh;
  width: 750px;
}
.over-review-revman-import h2,
.over-review-source-edit-modal h2 {
  margin: 0 0 15px;
  text-align: center;
}
.over-review-revman-import .outcomes-and-sources,
.over-review-source-edit-modal .outcomes-and-sources {
  border: none;
  max-height: 40vh;
  overflow: auto;
}
.over-review-revman-import .field-block:not(:first-child),
.over-review-source-edit-modal .field-block:not(:first-child),
.over-review-revman-import .editable-text-item:not(:first-child),
.over-review-source-edit-modal .editable-text-item:not(:first-child),
.over-review-revman-import .source-studies-list > div:not(:first-child),
.over-review-source-edit-modal .source-studies-list > div:not(:first-child) {
  margin-top: 10px;
}
.over-review-revman-import .field-block,
.over-review-source-edit-modal .field-block {
  width: 100%;
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.over-review-revman-import .field-block .field-name,
.over-review-source-edit-modal .field-block .field-name {
  font-weight: bold;
  -webkit-flex: 0 0 100px;
  -ms-flexbox: 0 0 100px;
  flex: 0 0 100px;
}
.over-review-revman-import .field-block input,
.over-review-source-edit-modal .field-block input {
  height: 100%;
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
}
.over-review-revman-import .source-outcomes,
.over-review-source-edit-modal .source-outcomes,
.over-review-revman-import .source-studies,
.over-review-source-edit-modal .source-studies {
  margin-top: 10px;
}
.over-review-revman-import .source-outcomes .section-title,
.over-review-source-edit-modal .source-outcomes .section-title,
.over-review-revman-import .source-studies .section-title,
.over-review-source-edit-modal .source-studies .section-title {
  font-weight: bold;
}
.over-review-revman-import .source-outcomes .add-item,
.over-review-source-edit-modal .source-outcomes .add-item,
.over-review-revman-import .source-studies .add-item,
.over-review-source-edit-modal .source-studies .add-item {
  text-align: right;
  margin: 10px 0;
}
.over-review-revman-import .source-outcomes .add-item button,
.over-review-source-edit-modal .source-outcomes .add-item button,
.over-review-revman-import .source-studies .add-item button,
.over-review-source-edit-modal .source-studies .add-item button {
  color: #29abe2;
  font-style: italic;
}
.over-review-revman-import .source-studies-list > div,
.over-review-source-edit-modal .source-studies-list > div {
  position: relative;
}
.over-review-revman-import .suggestions,
.over-review-source-edit-modal .suggestions {
  position: absolute;
  width: calc(100% - 40px);
  padding: 5px;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #009cca;
  border-top: 0;
}
.over-review-revman-import .row.buttons-react,
.over-review-source-edit-modal .row.buttons-react {
  margin-top: 20px;
}
.over-review-study-edit-comfirmation-modal {
  top: 30vh;
  width: 750px;
}
.over-review-study-edit-comfirmation-modal h2 {
  text-align: center;
}
.over-review-study-edit-comfirmation-modal .buttons {
  margin-top: 20px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: space-evenly;
  -moz-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.over-review-revman-import .quesitons-list {
  max-height: 55vh;
  margin-top: 20px;
  overflow: auto;
}
.over-review-revman-import .quesitons-list .question:not(:first-child),
.over-review-revman-import .outcomes-list .question:not(:first-child),
.over-review-revman-import .quesitons-list .outcome:not(:first-child),
.over-review-revman-import .outcomes-list .outcome:not(:first-child) {
  margin-top: 2px;
}
.over-review-revman-import .quesitons-list .question label,
.over-review-revman-import .outcomes-list .question label,
.over-review-revman-import .quesitons-list .outcome label,
.over-review-revman-import .outcomes-list .outcome label {
  height: 40px;
  padding-left: 20px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #f2f2f2;
  background-clip: content-box;
}
.over-review-revman-import .quesitons-list .question label .item-select,
.over-review-revman-import .outcomes-list .question label .item-select,
.over-review-revman-import .quesitons-list .outcome label .item-select,
.over-review-revman-import .outcomes-list .outcome label .item-select {
  position: relative;
  left: -20px;
  width: 20px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.over-review-revman-import .quesitons-list .question label .item-text,
.over-review-revman-import .outcomes-list .question label .item-text,
.over-review-revman-import .quesitons-list .outcome label .item-text,
.over-review-revman-import .outcomes-list .outcome label .item-text {
  margin-left: -20px;
  padding: 0 10px;
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  line-height: normal;
}
.over-review-revman-import .quesitons-list .question-details,
.over-review-revman-import .outcomes-list .question-details {
  margin-top: 10px;
  padding-left: 20px;
}
.over-review-revman-import .quesitons-list .question-details .field-name,
.over-review-revman-import .outcomes-list .question-details .field-name,
.over-review-revman-import .quesitons-list .question-details .selection-title,
.over-review-revman-import .outcomes-list .question-details .selection-title {
  -webkit-flex: 0 0 130px;
  -ms-flexbox: 0 0 130px;
  flex: 0 0 130px;
}
.over-review-revman-import .quesitons-list .question-details .context-help-icon,
.over-review-revman-import .outcomes-list .question-details .context-help-icon {
  margin-left: 10px;
}
.over-review-revman-import .quesitons-list .question-details .outcomes-selection,
.over-review-revman-import .outcomes-list .question-details .outcomes-selection {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 10px 0 15px;
}
.over-review-revman-import .quesitons-list .question-details .outcomes-selection .selection-title,
.over-review-revman-import .outcomes-list .question-details .outcomes-selection .selection-title {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  font-weight: bold;
}
.over-review-revman-import .quesitons-list .question-details .outcomes-selection .outcomes-list,
.over-review-revman-import .outcomes-list .question-details .outcomes-selection .outcomes-list {
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
}
.over-review-revman-import .quesitons-list .question-details .outcomes-selection .outcomes-list .outcome label,
.over-review-revman-import .outcomes-list .question-details .outcomes-selection .outcomes-list .outcome label {
  height: 30px;
}
.outcomes-mapping-select-box {
  width: 500px;
  padding: 5px;
  background-color: #fff;
  text-align: left;
  border: 1px solid #009cca;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
}
.outcomes-mapping-select-box input[disabled] ~ span {
  color: #e9e9e9;
}
.outcomes-mapping-select-box button {
  line-height: 30px;
  margin-left: 20px;
  margin-top: 10px;
}
.outcomes-mapping-select-box h4 {
  padding: 0 5px;
  margin: 5px 0;
}
.outcomes-mapping-select-box label {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0 5px;
  line-height: 30px;
}
.outcomes-mapping-select-box label.selected {
  background-color: #d2dfed;
}
.outcomes-mapping-select-box label input[type="radio"] {
  position: relative;
  top: 2px;
}
.study-status-select {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 16px;
  background-color: #fff;
}
.study-status-select .study-status-select-option {
  width: 24px;
  height: 24px;
  -webkit-flex: 0 0 24px;
  -ms-flexbox: 0 0 24px;
  flex: 0 0 24px;
  background-color: #f2f2f2;
  borderRadius: '2px';
  cursor: pointer;
}
.study-status-select .study-status-select-option:not(:last-child) {
  margin-right: 8px;
}
.study-status-select .study-status-select-option.excluded {
  background-color: #fbb03b;
}
.study-status-select .study-status-select-option.possible {
  background-color: #999;
}
.study-status-select .study-status-select-option.included {
  background-color: #8cde8c;
}
.comment-edit {
  background-color: #fff;
  border: 1px solid #009cca;
  padding: 20px;
  width: 400px;
}
.comment-edit textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
}
