@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.cadth-guidelines-toolbar {
  padding: 0 10px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.cadth-guidelines-toolbar h3 {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  margin: 0;
}
.cadth-guidelines-toolbar .cadth-guidelines-toolbar__button {
  -webkit-flex: 0 0 150px;
  -ms-flexbox: 0 0 150px;
  flex: 0 0 150px;
}
.cadth-guidelines-toolbar .cadth-guidelines-toolbar__button button {
  width: 100%;
}
.cadth-guidelines {
  overflow-y: auto;
  max-height: calc(100vh - 115px);
}
.cadth-guidelines .cadth-guidelines__table {
  width: 100%;
  margin-bottom: 10px;
}
.cadth-guidelines .cadth-guidelines__table th.source,
.cadth-guidelines .cadth-guidelines__table td.source {
  width: 25%;
}
.cadth-guidelines .cadth-guidelines__table th.publicationYear,
.cadth-guidelines .cadth-guidelines__table th.guidelineGroup,
.cadth-guidelines .cadth-guidelines__table th.countryOrRegion,
.cadth-guidelines .cadth-guidelines__table th.qualityOfGuideline,
.cadth-guidelines .cadth-guidelines__table td.publicationYear,
.cadth-guidelines .cadth-guidelines__table td.guidelineGroup,
.cadth-guidelines .cadth-guidelines__table td.countryOrRegion,
.cadth-guidelines .cadth-guidelines__table td.qualityOfGuideline {
  width: 10%;
}
.cadth-guidelines .cadth-guidelines__table th.actions,
.cadth-guidelines .cadth-guidelines__table td.actions {
  width: 65px;
}
.cadth-guidelines .cadth-guidelines__table thead tr {
  background: #cdcdcd;
  border-bottom: 2px solid #fff;
}
.cadth-guidelines .cadth-guidelines__table thead tr th {
  font-weight: normal;
  border-right: 2px solid #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  vertical-align: middle;
}
.cadth-guidelines .cadth-guidelines__table thead tr th:last-child {
  border-right: none;
}
.cadth-guidelines .cadth-guidelines__table thead tr th:hover {
  background: #e1e1e1;
}
.cadth-guidelines .cadth-guidelines__table thead tr th.sorted {
  font-weight: bold;
}
.cadth-guidelines .cadth-guidelines__table thead tr th.sorted.asc:after {
  content: '▲';
}
.cadth-guidelines .cadth-guidelines__table thead tr th.sorted.desc:after {
  content: '▼';
}
.cadth-guidelines .cadth-guidelines__table tbody tr {
  background: #dedede;
  border-bottom: 2px solid #fff;
  transition: background 0.2s ease-in-out;
}
.cadth-guidelines .cadth-guidelines__table tbody tr:hover {
  background: #ebebeb;
}
.cadth-guidelines .cadth-guidelines__table tbody tr:nth-child(even) {
  background: #cdcdcd;
}
.cadth-guidelines .cadth-guidelines__table tbody tr:nth-child(even):hover {
  background: #ebebeb;
}
.cadth-guidelines .cadth-guidelines__table tbody tr.table__no-guidelines-row,
.cadth-guidelines .cadth-guidelines__table tbody tr.table__no-guidelines-row:hover {
  background: #fff;
}
.cadth-guidelines .cadth-guidelines__table tbody tr:last-child {
  border-bottom: none;
}
.cadth-guidelines .cadth-guidelines__table tbody tr td {
  border-right: 2px solid #fff;
  padding: 5px;
  vertical-align: middle;
}
.cadth-guidelines .cadth-guidelines__table tbody tr td:last-child {
  border-right: none;
}
.cadth-guidelines .cadth-guidelines__table tbody tr td.actions > div {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.cadth-guidelines .cadth-guidelines__table tbody tr td.actions > div > div:not(:last-child) {
  margin-right: 5px;
}
.cadth-guidelines .cadth-guidelines__table tbody tr td a.sourceLink {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #009cca;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.cadth-guidelines .cadth-guidelines__table tbody tr td a.sourceLink:after {
  font-family: 'gdt';
  content: '\e095';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.cadth-guidelines .cadth-guidelines__table tbody tr td a.sourceLink:hover {
  opacity: 0.7;
}
.cadth-guideline-modal .extraction-form,
.cadth-guidelines-field-modal .extraction-form {
  margin: 10px 0;
}
.cadth-guideline-modal .extraction-form .extraction-form__group,
.cadth-guidelines-field-modal .extraction-form .extraction-form__group {
  margin: 0;
}
.cadth-guideline-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__name,
.cadth-guidelines-field-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__name {
  width: 140px;
}
.cadth-guideline-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value,
.cadth-guidelines-field-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value {
  margin-right: 0;
  max-width: initial;
}
.cadth-guideline-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value > div,
.cadth-guidelines-field-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value > div {
  max-width: initial;
}
.cadth-guidelines-field-modal .options-select-container {
  height: 55px;
}
.cadth-guidelines-field-modal .select-value {
  height: 40px;
  max-width: 476px;
}
.cadth-guidelines-field-modal .select-value > div {
  white-space: initial;
}
.cadth-guidelines-field.loading {
  min-height: 60px;
  position: relative;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list {
  margin: 6px 0 10px;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list .guidelines-list__item {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: background 0.2s ease-in-out;
  margin-bottom: 10px;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list .guidelines-list__item:last-child {
  margin-bottom: 0;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list .guidelines-list__item:hover {
  background: #d7d7d7;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list .guidelines-list__item .item__inner {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  background: initial;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list .guidelines-list__item .item__inner .inner__title {
  font-size: 16px;
  font-weight: bold;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list .guidelines-list__item .item__inner .inner__quality {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex: 0 0 120px;
  -ms-flexbox: 0 0 120px;
  flex: 0 0 120px;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list .guidelines-list__item .item__inner .inner__quality .quality__input {
  margin-left: 5px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.cadth-guidelines-field .cadth-guidelines-field__guidelines-list .guidelines-list__item .item__remove {
  margin-left: 10px;
  -webkit-flex: 0 0 11px;
  -ms-flexbox: 0 0 11px;
  flex: 0 0 11px;
}
.cadth-guidelines-field .cadth-guidelines-field__add-guideline {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.cadth-guidelines-field .cadth-guidelines-field__add-guideline .btn-icon-container .btn {
  top: 2px;
}
.cadth-guidelines-field .cadth-guidelines-field__add-guideline .btn-icon-container .label-text {
  font-size: 14px;
  line-height: 20px;
}
