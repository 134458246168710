@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#presentations-export-dialog {
  margin: 0;
  padding: 18px 16px 10px;
}
#presentations-export-dialog h2 {
  margin: 0;
  text-align: center;
}
#presentations-export-dialog input[type=radio] {
  position: relative;
  top: -1px;
  margin: 0;
  margin-right: 7px;
  vertical-align: middle;
}
#presentations-export-dialog .choose-format-title {
  font-weight: bold;
}
#presentations-export-dialog .choose-presentation-format {
  margin-top: 38px;
}
#presentations-export-dialog .choose-presentation-format .presentation-options {
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
#presentations-export-dialog .choose-presentation-format .presentation-appearance-option {
  margin: 10px 5px;
  text-align: center;
}
#presentations-export-dialog .choose-presentation-format .presentation-appearance-option .image-with-preview {
  position: relative;
  width: 220px;
  height: 129px;
  margin-bottom: 10px;
  border: 1px solid #ddeaf9;
}
#presentations-export-dialog .choose-presentation-format .presentation-appearance-option .image-with-preview:hover .preview {
  opacity: 0.7;
}
#presentations-export-dialog .choose-presentation-format .presentation-appearance-option .image-with-preview img {
  position: relative;
  top: 17px;
  height: 95px;
}
#presentations-export-dialog .choose-presentation-format .presentation-appearance-option .image-with-preview .preview {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  line-height: 41px;
  opacity: 0;
  background-color: #ddeaf9;
  transition: opacity 0.5s;
}
#presentations-export-dialog .choose-format {
  margin-top: 38px;
}
#presentations-export-dialog .choose-format .formats-list {
  margin-top: 21px;
}
#presentations-export-dialog .choose-format .formats-list label {
  padding-left: 9px;
  margin-bottom: 13px;
}
#presentations-export-dialog .choose-format .formats-list label:last-child {
  margin-bottom: 33px;
}
#presentations-module {
  height: 100%;
}
#presentations-module .presentation-type-select-container {
  padding: 14px 24px 37px;
  margin: 0px auto;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
#presentations-module .presentation-type-select-container .context-help-icon {
  vertical-align: text-bottom;
}
#presentations-module .presentation-type-select-container .options-select-container {
  width: 194px;
  height: 30px;
  outline: none;
}
#presentations-module .presentation-type-select-container .options-select-container.showing-options {
  border-bottom: none;
}
#presentations-module .presentation-type-select-container .options-select-container.showing-options .select-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#presentations-module .presentation-type-select-container .options-select-container .select-input {
  height: 30px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 15px;
  border-color: #009cca;
  transition: border-radius 0.1s;
}
#presentations-module .presentation-type-select-container .options-select-container .select-input .select-sign {
  color: #009cca;
}
#presentations-module .presentation-type-select-container .presentation-type-select-label {
  margin: 0 12px;
  font-weight: bold;
  font-size: 18px;
}
#presentations-module .exclamation-mark:before {
  content: "";
}
#presentations-module .presentation-content__premium .sections .section {
  max-width: calc(50% - 10px);
}
#presentations {
  height: 100%;
  background: #fff;
  padding: 0px 20px;
}
#presentations .content {
  border: none;
}
#presentations .content.static {
  padding: 0;
}
#presentations .buttons .btn {
  color: #fff !important;
  background-color: #9dbfcc;
}
#presentations .buttons .btn:hover {
  background-color: #a4c3cf !important;
}
#presentations .buttons .btn.btn-success {
  color: #fff !important;
  background-color: #009cca !important;
}
#presentations .buttons .btn.btn-success:hover {
  background-color: #00a4d4 !important;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .recommendation__icons .recommendation__icons__icon .recommendation__icon__text {
  text-align: left;
}
#presentations.pdf-export.presentations-container .justification > .detailed-content.clinicians,
#presentations.pdf-export.presentations-container .draft-editor,
#presentations.pdf-export.presentations-container .for-recommendation-content,
#presentations.pdf-export.presentations-container .public-DraftEditor-content {
  min-height: 52px;
}
#presentations.pdf-export.presentations-container .main-outcomes .gdt-editor-container,
#presentations.pdf-export.presentations-container .label-with-contenteditable .gdt-editor-container {
  margin-top: -5px;
  margin-left: -5px;
}
#presentations.pdf-export.presentations-container .background label {
  width: 150px;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .section .section__header {
  display: table-row;
  vertical-align: middle;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .section .section__header h2 {
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .section .section-icon {
  display: table-cell;
  vertical-align: middle;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .content.static {
  margin-top: 20px;
}
#presentations.pdf-export.presentations-container .presentation-content__premium h1 {
  font-size: 20px;
}
#presentations.pdf-export.presentations-container .presentation-content__premium h1.presentation-title {
  letter-spacing: 0.96px;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .recommendation__description {
  padding-top: 20px;
  display: table-row;
  vertical-align: middle;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .recommendation__icons,
#presentations.pdf-export.presentations-container .presentation-content__premium .recommendation__text {
  display: table-cell;
  vertical-align: top;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .recommendation__text {
  padding-top: 30px;
  padding-left: 10px;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .recommendation__icons .recommendation__icons__icon {
  width: 65px;
  margin-left: 10px;
}
#presentations.pdf-export.presentations-container .presentation-content__premium .recommendation__icons .recommendation__icon__text {
  width: 100px;
  padding-top: 60px;
  text-align: left;
}
.options-select-container__options.presentation-options {
  width: 194px;
  outline: none;
  border-color: #009cca;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.options-select-container__options.presentation-options .option {
  padding: 0 15px;
}
.options-select-container__options.presentation-options .option:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.options-select-container__options.presentation-options .option.selected {
  background-color: #ddeaf9;
}
