@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#dashboard-container {
  padding: 0px 10px;
}
.dashboard-toolbar {
  padding: 0px 20px;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.dashboard-toolbar h2 {
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
}
.review-statuses input[type="checkbox"] {
  margin-right: 5px;
}
.review-statuses .review-label {
  font-weight: bold;
  width: 175px;
}
.review-statuses .review-status-label {
  text-transform: uppercase;
}
.review-statuses .review-status-label.complete {
  color: #008000;
}
.review-statuses .review-status-label.incomplete {
  color: #f00;
}
.stats-label {
  width: 300px;
  font-weight: bold;
}
