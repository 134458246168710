@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.add-outcome-modal {
  position: relative;
  margin-top: 5%;
  width: 800px;
  max-height: calc(100vh - 14%);
  overflow: auto;
  padding: 15px;
}
.add-outcome-modal .modal-close {
  top: 5px;
  right: 15px;
}
.add-outcome-modal .header {
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  text-align: center;
}
.add-outcome-modal .details {
  margin-top: 10px;
}
.add-outcome-modal .details input[type=text] {
  width: 100%;
}
.add-outcome-modal .details .add-to {
  margin-top: 15px;
  font-weight: bold;
}
.add-outcome-modal .details ul {
  margin: 10px 0;
  padding: 0;
  max-height: 60vh;
  overflow: auto;
  list-style-type: none;
}
.add-outcome-modal .details ul hr {
  margin: 5px 0;
}
.add-outcome-modal .details ul li input {
  margin-right: 5px;
}
.add-outcome-modal .buttons {
  width: 100%;
  margin-top: 15px;
}
.add-outcome-modal .buttons button {
  width: 49%;
}
.add-outcome-modal .buttons button.btn-success {
  margin-left: 2%;
}
.add-outcome-modal .outcome-list li label {
  cursor: pointer;
  font-weight: normal;
}
.add-outcome-modal .outcome-list li.all label {
  font-weight: bold;
}
.group-name {
  font-weight: bold;
  max-height: 22px;
}
.group-name input {
  line-height: 20px;
  width: 90%;
}
.group-name button {
  float: right;
  width: 20px;
  margin-left: 10px;
  border-left: 2px solid #fff;
}
.outcomes-generation-container {
  background-color: #fff;
  padding-bottom: 20px;
  position: relative;
  font-size: 14px;
}
.outcomes-generation-container .standard-list {
  font-size: 14px;
}
.outcomes-generation-container [draggable=true] {
  position: relative;
  z-index: 0;
}
.outcomes-generation-container .intro-text a,
.outcomes-generation-container .intro-text a:visited {
  color: inherit;
  text-decoration: none;
}
.outcomes-generation-container .intro-text:hover a,
.outcomes-generation-container .intro-text:hover a:visited {
  color: #00f;
}
.outcomes-generation-container .button-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 2px solid #fff;
  width: 42px;
  height: 32px;
  z-index: 333;
}
.outcomes-generation-container .arrow-up:before {
  position: absolute;
  right: 20px;
  bottom: -5px;
  font-family: 'gdt';
  font-size: 20px;
  content: '\e017';
  margin-right: 5px;
}
.outcomes-generation-container .draggable:hover:before {
  position: absolute;
  left: -10px;
  font-family: 'gdt';
  content: '\e042';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.outcomes-generation-container .information {
  margin-bottom: 10px;
}
.outcomes-generation-container .responded {
  line-height: 36px;
}
.outcomes-generation-container .approval-table,
.outcomes-generation-container .questions-rating {
  margin-top: 10px;
}
.outcomes-generation-container .approval-table-legacy {
  width: 100%;
}
.outcomes-generation-container .approval-table-legacy .name-column {
  width: 30%;
}
.outcomes-generation-container .approval-table-legacy thead {
  background: #dedede;
}
.outcomes-generation-container .approval-table-legacy th,
.outcomes-generation-container .approval-table-legacy td {
  border: 1px solid #bfbfbf;
  padding: 3px;
}
.outcomes-generation-container .approval-table-legacy tbody tr.agreed {
  background: #c3d1ac;
}
.outcomes-generation-container .approval-table-legacy tbody tr.disagreed {
  background: #edbebe;
}
.outcomes-generation-container .questions-container {
  max-height: calc(100vh - 325px);
  margin-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  overflow: auto;
  position: relative;
}
.outcomes-generation-container .questions-container.hovered {
  background: #d2dfed;
}
.outcomes-generation-container .questions-container .question-group {
  position: relative;
  padding: 10px;
  padding-left: 15px;
  margin-bottom: 40px;
  border: 1px solid #009cca;
  background: #fff;
  will-change: transform;
}
.outcomes-generation-container .questions-container .question-group:first-child {
  margin-top: 0;
}
.outcomes-generation-container .questions-container .question-group .buttons {
  display: inline-block;
  float: right;
}
.outcomes-generation-container .questions-container .question-group .questions {
  margin-bottom: 10px;
  min-height: 24px;
}
.outcomes-generation-container .questions-container .question-group .question {
  position: relative;
}
.outcomes-generation-container .questions-container .question-group .question.dragging {
  background: #d2dfed;
  color: #d2dfed;
  border: 1px solid #bfbfbf;
}
.outcomes-generation-container .questions-container .question-group .outcomes {
  position: relative;
}
.outcomes-generation-container .questions-container .question-group .outcomes > div {
  line-height: 40px;
  border-bottom: 2px solid #fff;
  background-color: #f2f2f2;
}
.outcomes-generation-container .questions-container .question-group .outcomes > div:last-child {
  border-bottom: 0;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome {
  position: relative;
  padding-left: 15px;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container:hover,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome:hover {
  background-color: #f7f7f7;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container.dragging,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome.dragging {
  background: #d2dfed !important;
  color: #d2dfed !important;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container.dragging button,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome.dragging button {
  border: 1px solid #d2dfed !important;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container.draggable:hover:before,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome.draggable:hover:before {
  left: 4px;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container.with-comments,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome.with-comments {
  border-bottom: 0;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .mark,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .mark {
  position: absolute;
  top: 0;
  left: -15px;
  background-color: #f7bf9c;
  width: 4px;
  height: 100%;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .comment-blank-hide,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .comment-blank-hide,
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .comment-blank,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .comment-blank,
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .edit,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .edit {
  float: right;
  height: 40px;
  width: 40px;
  border-left: 2px solid #fff;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container input,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome input {
  line-height: 20px;
  font-size: 14px;
  width: 85%;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .buttons button.copy,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .buttons button.copy,
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .buttons button.delete,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .buttons button.delete,
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .buttons button.save,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .buttons button.save,
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .buttons button.edit,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .buttons button.edit {
  margin-left: 0;
  width: 40px;
  text-align: center;
  height: 40px;
  border-left: 2px solid #fff;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .outcome-child,
.outcomes-generation-container .questions-container .question-group .outcomes .outcome .outcome-child {
  position: relative;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container {
  position: relative;
  border: 0;
  margin-right: 38px;
  border-right: 2px solid #fff;
  background-color: transparent;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container > div {
  width: 92%;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .comment-blank {
  width: 32px;
  bottom: -5px;
  top: initial;
}
.outcomes-generation-container .questions-container .question-group .outcomes .comments-container .comment-blank:before {
  position: absolute;
  bottom: 0;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container {
  position: relative;
  width: 100%;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating {
  width: 100%;
  border: 0;
  background-color: #f2f2f2;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating tbody.critical > tr:first-child {
  background-color: #fda;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating tbody.important > tr:first-child {
  background-color: #fff0bb;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating tbody.not-important > tr:first-child {
  background-color: #fff8de;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td,
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating th {
  font-weight: normal;
  width: 10%;
  border: 2px solid #fff;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.c-5,
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating th.c-5 {
  width: 50%;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.c-2,
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating th.c-2 {
  width: 20%;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td label,
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating th label {
  display: inline-block;
  width: 100%;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td label input[type=radio],
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating th label input[type=radio] {
  margin-top: 3px;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.name {
  text-align: left;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.comments {
  border-top: 0;
  vertical-align: bottom;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary {
  text-align: left;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .collapsible-toggle {
  top: -5px;
  left: 0;
  margin-left: calc(14% - 15px);
  font-size: 20px;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .step-name {
  font-weight: bold;
  padding-left: 14%;
  margin-bottom: 5px;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .comments-section {
  display: block;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .title,
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .members-comments,
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .admin-comment {
  display: inline-block;
  padding-bottom: 10px;
  vertical-align: top;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .title {
  width: 14%;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .members-comments,
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .admin-comment {
  width: 85%;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .members-comments {
  border-top: 1px solid #bfbfbf;
}
.outcomes-generation-container .questions-container .question-group .outcomes-rating-container .outcomes-rating td.proposal-comments-summary .member-comment {
  border: 1px solid #bfbfbf;
  border-top: 0;
  padding: 5px;
}
.outcomes-generation-container .questions-container .question-group .target {
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
}
.outcomes-generation-container .finished-outcomes-container,
.outcomes-generation-container .outcomes-approval-container {
  position: relative;
  width: 100%;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval {
  position: relative;
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  border: 1px solid #bfbfbf;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval thead,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval thead {
  background: #f2f2f2;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval tbody,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval tbody {
  position: relative;
  background: #e9e9e9;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval tbody:nth-child(odd),
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval tbody:nth-child(odd) {
  background-color: #f2f2f2;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-marker,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-marker {
  width: 10px;
  border: none;
  borderRight: 1px solid #bfbfbf;
  padding: 0;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-marker.critical,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-marker.critical {
  background-color: #fda;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-marker.important,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-marker.important {
  background-color: #fff0bb;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-marker.not-important,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-marker.not-important {
  background-color: #fff8de;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-outcome,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-outcome {
  position: relative;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-outcome.collapsed:hover,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-outcome.collapsed:hover {
  background-color: #fff;
  cursor: url("../images/openhand.cur"), auto;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-outcome.collapsed:hover div.with-drag-marker,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-outcome.collapsed:hover div.with-drag-marker {
  position: relative;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-outcome.collapsed:hover div.with-drag-marker:before,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-outcome.collapsed:hover div.with-drag-marker:before {
  position: absolute;
  left: -10px;
  font-family: 'gdt';
  content: '\e042';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  left: -7px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-outcome.dragging tr,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-outcome.dragging tr {
  border: 1px solid #bfbfbf;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-outcome.dragging tr td,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-outcome.dragging tr td {
  background: #d2dfed;
  border: none;
  color: #d2dfed;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approval-outcome.dragging tr td .red,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approval-outcome.dragging tr td .red {
  color: #d2dfed;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval td,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval td,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval th,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval th {
  border: 1px solid #bfbfbf;
  padding: 10px;
  font-size: 14px;
  vertical-align: middle;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-title-row td,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-title-row td {
  font-size: 16px;
  border-bottom: none;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details-row td,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details-row td {
  border-top: none;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approved,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approved,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .comments,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .comments {
  width: 65px;
  font-weight: normal;
  text-align: center;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approved .comment-blank,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approved .comment-blank,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .comments .comment-blank,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .comments .comment-blank {
  float: initial;
  margin: 0;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approved .comment-blank:before,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approved .comment-blank:before,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .comments .comment-blank:before,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .comments .comment-blank:before {
  vertical-align: initial;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approved.no-border-right,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approved.no-border-right,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .comments.no-border-right,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .comments.no-border-right {
  border-right: none;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approved.no-border-left,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approved.no-border-left,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .comments.no-border-left,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .comments.no-border-left {
  border-left: none;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approved,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approved {
  line-height: 14px;
  font-size: 14px;
  vertical-align: middle;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .approved .todos-count,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .approved .todos-count {
  font-size: 11px;
  line-height: 11px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details {
  width: 97%;
  position: relative;
  font-size: 14px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .step-name,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .step-name {
  padding: 0;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block {
  display: inline-block;
  vertical-align: top;
  width: 360px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos {
  margin-left: 40px;
  width: calc(100% - 400px);
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .title,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .title {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .admin-comment,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .admin-comment {
  display: inline-block;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .admin-comment,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .admin-comment,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .todos-component-container,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .todos-component-container,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos textarea,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos textarea {
  width: calc(100% - 100px);
  margin-bottom: 10px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .todos-component-container,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .extended-details-container .extended-details-block.with-todos .todos-component-container {
  display: inline-block;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .comments-section,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .comments-section {
  margin-top: 10px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .comments-section .title,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .comments-section .title {
  line-height: 20px;
  font-weight: bold;
  width: 185px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .comments-section .members-comments,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .comments-section .members-comments,
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .comments-section .admin-comment,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .comments-section .admin-comment {
  width: calc(100% - 185px);
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .collapsible-container .step-name,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .collapsible-container .step-name {
  display: inline-block;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .collapsible-container .collapsible-toggle,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .collapsible-container .collapsible-toggle {
  position: relative;
  margin-left: 0;
  width: 20px;
  text-align: left;
  top: 0;
  left: 0;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .approval-details,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .approval-details {
  margin: 10px 0;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .approval-details .group-changed,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .approval-details .group-changed {
  background: #d2dfed;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .approval-details .original-importance,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .approval-details .original-importance {
  width: 175px;
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .outcome-details .approval-details .approval-stats,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .outcome-details .approval-details .approval-stats {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 0;
  font-weight: bold;
  width: 175px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .button-container,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .button-container {
  width: 97%;
  text-align: right;
  padding-bottom: 10px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .button-container .save-close,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .button-container .save-close {
  width: 190px;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .drop-area,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .drop-area {
  padding: 5px;
  height: 43px;
  text-align: center;
  vertical-align: middle;
  background: #e9e9e9;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .drop-area.hovered,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .drop-area.hovered {
  background: #d2dfed;
}
.outcomes-generation-container .finished-outcomes-container table.outcomes-approval .drop-area div,
.outcomes-generation-container .outcomes-approval-container table.outcomes-approval .drop-area div {
  border: 1px dashed #bfbfbf;
  line-height: 30px;
}
.outcomes-generation-container .target {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f3fcff;
  border: 1px solid #009cca;
  border-style: dashed;
}
.outcomes-generation-container .target.about-to-drop {
  background: #d2dfed;
}
.outcomes-generation-container .target.hovered {
  background: #d2dfed;
}
.outcomes-generation-container .footer {
  margin-top: 10px;
}
.outcomes-generation-container .footer .buttons {
  margin-top: 15px;
  margin-bottom: 0;
  width: 100%;
  position: relative;
}
.outcomes-generation-container .footer .buttons button {
  width: 49%;
  display: inline-block;
}
.outcomes-generation-container .footer .buttons button.add-outcome {
  margin-right: 1%;
}
.outcomes-generation-container .footer .buttons button.send {
  margin-left: 1%;
}
.outcomes-generation-container .goto-comparisons-btn,
.outcomes-generation-container .close-step {
  margin-top: 15px;
  width: 100%;
}
.outcomes-generation-container .outcomes-with-comments {
  position: relative;
}
.outcomes-generation-container .brainstorming-tab .questions-container {
  max-height: calc(100vh - 305px);
}
.outcomes-generation-container .questions-rating {
  max-height: calc(100vh - 305px);
  overflow: auto;
  padding-right: 10px;
}
.outcomes-generation-container .completed-list-tab .questions-container {
  max-height: calc(100vh - 355px);
}
.outcomes-generation-container .completed-list-tab .outcomes .button-placeholder {
  width: 47px;
}
.outcomes-generation-container .completed-list-tab .outcomes .outcome-child .edit,
.outcomes-generation-container .completed-list-tab .outcomes .outcome-child .buttons {
  height: 40px !important;
}
.outcomes-generation-container .completed-list-tab .outcomes .comments-container {
  position: absolute;
  padding-left: 0 !important;
  left: 0;
}
.outcomes-generation-container .completed-list-tab .outcomes .comments-container .comment-blank {
  right: 5px !important;
}
.outcomes-generation-container .proposal-tab .questions-container {
  max-height: calc(100vh - 350px);
}
.outcomes-generation-container .proposal-tab .comment-blank {
  margin-right: 0;
  float: initial;
}
.outcomes-generation-container .proposal-tab .arrow-up:before {
  position: initial;
}
.outcomes-generation-container .buttons {
  margin-right: 10px;
}
.outcomes-generation-container .top-buttons {
  text-align: right;
  margin-right: 10px;
}
.outcomes-generation-container .top-buttons button {
  color: #fff;
  line-height: 36px;
  font-size: 16px;
  margin-left: 10px;
  min-width: 150px;
}
