.hidden {
  display: none;
}
.gdt-editor-container .alignment--left .public-DraftStyleDefault-block {
  text-align: left;
}
.gdt-editor-container .alignment--center .public-DraftStyleDefault-block {
  text-align: center;
}
.gdt-editor-container .alignment--right .public-DraftStyleDefault-block {
  text-align: right;
}
.gdt-editor-container .style-controls-container {
  position: absolute;
  top: -35px;
  background-color: #dedede;
  box-shadow: rgba(0,0,0,0.118) 0px 1px 6px, rgba(0,0,0,0.118) 0px 1px 4px;
  border: 1px solid #bfbfbf;
  border-right: 0;
  z-index: 3;
}
.gdt-editor-container .style-controls-container .sub-controls {
  position: absolute;
  top: 28px;
  left: 0;
  background-color: #dedede;
  min-width: 25px;
}
.gdt-editor-container .style-controls-container .sub-controls .style-control {
  display: block;
  width: 80px;
  text-align: left;
  border-right: 0;
}
.gdt-editor-container .style-controls-container .sub-controls .style-control.icon {
  width: 25px;
  text-align: center;
}
.gdt-editor-container .style-controls-container .style-control {
  display: inline-block;
  position: relative;
  height: 100%;
  min-width: 25px;
  text-align: center;
  font-weight: bold;
  padding: 4px;
  border-right: 1px solid #808080;
}
.gdt-editor-container .style-controls-container .style-control.with-nested {
  min-width: 25px;
  border-bottom: 0;
}
.gdt-editor-container .style-controls-container .style-control:hover {
  cursor: pointer;
  background-color: #cbcbcb;
}
.gdt-editor-container .style-controls-container .style-control.active {
  background-color: #cbcbcb;
  font-weight: bold;
}
.gdt-editor-container .style-controls-container .style-control span {
  font-size: 10px;
}
.gdt-editor-container .style-controls-container .style-control span.small {
  font-size: 10px;
}
.gdt-editor-container .style-controls-container .style-control span.normal {
  font-size: 14px;
}
.gdt-editor-container .style-controls-container .style-control span.large {
  font-size: 18px;
}
.gdt-editor-container .style-controls-container .style-control span.huge {
  content: 'huge';
  font-size: 32px;
}
.gdt-editor-container input[type=file] {
  display: none;
}
.public-DraftEditor-content {
  border: none;
}
.public-DraftEditor-content .inserted-table_icon {
  display: flex;
  height: 60px;
  margin-top: 20px;
}
.public-DraftEditor-content .inserted-table_icon .icon {
  display: inline-block;
}
.public-DraftEditor-content .inserted-table_icon .icon svg {
  height: 60px;
}
.public-DraftEditor-content .inserted-table_icon .inserted-table_expand-btn {
  margin-top: 8px;
}
.public-DraftEditor-content .inserted-table_icon .inserted-table_new-window-btn {
  margin-top: 10px;
}
.public-DraftEditor-content .inserted-table_icon .buttons {
  flex-grow: 1;
}
.public-DraftEditor-content .inserted-table_button {
  line-height: 16px;
  text-align: left;
  width: 100%;
  padding-left: 5px;
  height: 14px;
  border: none;
  background: transparent;
  width: auto;
}
.public-DraftEditor-content .inserted-table_button .button-label {
  position: relative;
  padding-left: 2px;
  top: -2px;
}
.public-DraftEditor-content .inserted-table_button svg {
  display: inline-block;
  height: 100%;
}
.public-DraftEditor-content .inserted-table-icon-svg {
  height: 60px;
  fill: #3783a5;
}
.public-DraftEditor-content .inserted-table_buttons-row {
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: right;
}
.public-DraftEditor-content .inserted-table_expand-btn {
  display: block;
}
.public-DraftEditor-content .inserted-table_collapse-btn {
  display: inline-block;
  margin-right: 10px;
}
.public-DraftEditor-content .inserted-table_new-window-btn {
  display: inline-block;
}
.public-DraftEditor-content .reference-link {
  color: initial;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.public-DraftEditor-content .reference-link:hover {
  color: #3783a5;
  opacity: 0.7;
}
.public-DraftEditor-content .reference-link svg {
  display: inline-block;
  height: 14px;
  margin-left: 5px;
}


/*# sourceMappingURL=Editor.css.map*/