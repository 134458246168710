.opentip-container {
  overflow: visible;
  z-index: 10010;
}
.opentip-container.style-tip {
  max-width: 600px;
}
.opentip-container .opentip {
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 8px 10px;
}
.opentip-container .opentip .ot-content {
  white-space: pre-wrap;
}
