@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.multi-comparisons-toolbar .export {
  width: 20px;
  margin: 7px 15px;
  float: right;
}
.multi-comparisons-export-modal {
  top: 10vh;
  width: 350px;
}
.multi-comparisons-export-modal .advanced-choose-format .collapsible-container-caption {
  font-weight: bold;
  padding: 10px 15px 5px;
  cursor: pointer;
}
.multi-comparisons-export-modal .advanced-choose-format .collapsible-toggle {
  top: 2px;
  left: -100px;
}
.multi-comparisons-view {
  height: 100%;
  overflow-y: auto;
}
.multi-comparisons-view .multi-comparisons-top-container {
  margin-right: 10px;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.multi-comparisons-view .multi-comparisons-top-container .mc-info {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-right: 30px;
}
.multi-comparisons__confirmation .question {
  margin-left: 10px;
  text-align: left !important;
  font-weight: 600;
  font-size: 18px;
}
.multi-comparisons__confirmation .message {
  margin: 20px 10px;
}
.bottom-buttons {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.bottom-buttons .divider {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.bottom-buttons .btn {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  min-width: 160px;
}
.multi-comparison__overarching-question-select {
  margin: 0px 10px;
  position: absolute;
  width: calc(100vw - 200px);
}
.multi-comparison__overarching-question-select .select-input {
  border: none;
}
.multi-comparison__overarching-question-select .select-input .select-sign {
  color: #3783a5;
}
.multi-comparison__overarching-question-select .select-input .text-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-comparison__overarching-question-select.showing-options .select-input .select-sign.full-triangle:before {
  font-family: 'gdt';
  content: '\e071';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.multi-comparison__overarching-question-select.showing-options .select-input .select-sign.full-triangle * {
  margin-left: 0.5rem;
}
.multi-comparisons__overarching-question {
  display: flex;
}
.multi-comparisons__overarching-question .label-text {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  line-height: 30px;
  margin-right: 30px;
  font-weight: bold;
}
.multi-comparisons__overarching-question .question-value {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.multi-comparisons__comparisons-selection .multi-comparisons__selection-step hr {
  margin-left: 30px;
  margin-right: 30px;
}
.multi-comparisons__comparisons-selection .multi-comparisons__selection-step .step-title {
  font-weight: bold;
}
.multi-comparisons__comparisons-selection .multi-comparisons__selection-step .options-select-container .select-input {
  overflow: hidden;
}
.multi-comparisons__comparisons-selection .multi-comparisons__selection-step .options-select-container .select-input .select-sign {
  background: #fff;
  float: none;
  position: absolute;
  right: 8px;
  top: 8px;
}
.multi-comparisons__comparisons-selection .multi-comparisons__selection-step .options-select-container .options-container {
  display: none;
  overflow-y: auto;
}
.multi-comparisons__comparisons-selection .multi-comparisons__selection-step .options-select-container .options-container.visible {
  display: block;
}
.multi-comparisons__comparisons-selection .multi-comparisons__selection-step .options-select-container .options-container .option {
  white-space: normal;
  height: 26px;
  overflow: hidden;
}
.multi-comparisons__comparisons-selection .selected-question-row {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.multi-comparisons__comparisons-selection .selected-question-row .selected-question {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.multi-comparisons__comparisons-selection .selected-question-row .btn-remove-question {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  margin: 0 10px;
}
.multi-comparisons__comparisons-selection .selected-question-row .btn-remove-question button {
  line-height: 34px;
}
.multi-comparisons__comparisons-selection .selected-question-row .btn-remove-question button:before {
  color: #ddd;
}
.multi-comparisons__comparisons-selection .question-no {
  display: inline-block;
  white-space: nowrap;
  line-height: 34px;
  margin-right: 8px;
}
.multi-comparisons__comparisons-selection .question-no--hidden {
  visibility: hidden;
}
.multi-comparisons__comparisons-selection .btn-question-select {
  position: relative;
  text-align: left;
  min-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-comparisons__comparisons-selection .btn-question-select:after {
  position: absolute;
  right: 5px;
}
.multi-comparisons__comparisons-selection .btn-add-question {
  text-align: center;
}
.multi-comparisons__comparisons-selection .btn-add-question-container {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  width: 100px;
  margin-right: 40px;
}
.multi-comparisons__comparisons-selection .questions-comparison-table {
  width: 100%;
}
.multi-comparisons__comparisons-selection .questions-comparison-table__question-attr {
  background-color: #ddd;
  border: 7px solid #fff;
}
.multi-comparisons__comparisons-review-summary {
  margin-right: 10px;
}
.multi-comparisons__comparisons-review-summary .multi-comparisons__textareas-box {
  padding: 10px;
  border: none;
}
.multi-comparisons__comparisons-review-summary .multi-comparisons__textarea-label {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 5px;
}
.multi-comparisons__comparisons-review-summary .multi-comparisons__overarching-question-title {
  font-weight: 600;
}
.multi-comparisons__comparisons-review-summary .multi-comparisons__grading-information-text {
  text-align: center;
  margin: 10px 0 30px;
}
.multi-comparisons__comparisons-review-summary .multi-comparisons__grading-information-rating-star {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 5px;
  width: 18px;
  height: 20px;
  background: url("../images/rating-star.svg") center center no-repeat;
}
.multi-comparisons__textarea {
  min-height: 100px;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #bfbfbf;
}
.multi-comparisons__textarea p {
  margin: 1px;
}
.multi-comparisons__textarea.small {
  min-height: 25px;
}
.multi-comparisons__questions-container {
  border: 1px solid #009cca;
  margin-top: 10px;
  margin-right: 10px;
}
.multi-comparisons__questions-container .btn-icon-container {
  text-align: center;
  line-height: 30px;
}
.multi-comparisons__questions-container .btn-icon-container .label-text {
  line-height: 12px;
}
.multi-comparisons__questions-container .expand-icon {
  font-family: gdt;
}
.multi-comparisons__questions-container .expand-icon:before {
  font-family: 'gdt';
  content: '\e072';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.multi-comparisons__questions-container .expand-icon * {
  margin-left: 0.5rem;
}
.multi-comparisons__questions-container .collapse-icon {
  font-family: gdt;
}
.multi-comparisons__questions-container .collapse-icon:before {
  font-family: 'gdt';
  content: '\e071';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.multi-comparisons__questions-container .collapse-icon * {
  margin-left: 0.5rem;
}
.multi-comparisons__questions-container .table-icon {
  font-family: gdt;
}
.multi-comparisons__questions-container .table-icon:before {
  font-family: 'gdt';
  content: '\e085';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.multi-comparisons__questions-container .table-icon * {
  margin-left: 0.5rem;
}
.multi-comparisons__questions-container .multi-comparisons__content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  width: calc(100% - 50px);
}
.multi-comparisons__questions-container .multi-comparisons__content ol {
  width: 100%;
  overflow: hidden;
}
.multi-comparisons__questions-container .multi-comparisons__content .multi-comparisons__comparisons-selection {
  margin-top: 10px;
}
.multi-comparisons__questions-container .multi-comparisons__actions {
  margin-top: 7px;
  text-align: center;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  width: 50px;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.multi-comparisons__questions-container .multi-comparisons__actions button {
  height: 20px;
  font-size: 14px;
}
.multi-comparisons__questions-container .multi-comparisons__actions .delete {
  margin-top: 15px;
}
.multi-comparisons__questions-container .multi-comparisons__collapse-expand-icon {
  margin-bottom: 10px;
}
.multi-comparisons__questions-container .multi-comparisons__header {
  display: flex;
}
.multi-comparisons__questions-container .multi-comparisons__header-label {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #2d3236;
  line-height: 20px;
  font-weight: bold;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  cursor: pointer;
}
.multi-comparisons__questions-container .multi-comparisons__questions-list.standard-list {
  display: block;
}
.multi-comparisons__questions-container .multi-comparisons__questions-list li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-comparisons__recommendations-box {
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.multi-comparisons__recommendations-box-header {
  display: flex;
  font-weight: bold;
}
.multi-comparisons__recommendations-box-title {
  margin: 5px 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.multi-comparisons__recommendations-remove {
  margin: 5px 0;
}
.multi-comparisons__recommendation-strength-text {
  display: inline-block;
  margin-right: 20px;
  font-weight: bold;
}
.multi-comparisons__recommendation-strength-select .select-input {
  color: #009cca;
  line-height: 18px;
}
.multi-comparisons__add-recommendation-button {
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.multi-comparisons__add-recommendation-button .label-text {
  font-size: 13px;
}
.multi_comparisons__review-table .review-table__header-cell {
  position: relative;
  vertical-align: top;
  padding: 10px;
}
.multi_comparisons__review-table .review-table__header-cell--sortable {
  position: relative;
  color: #fff;
  overflow: hidden;
  background-color: #275c74;
}
.multi_comparisons__review-table .review-table__header-cell--sortable .review-table__header-cell-content {
  min-height: 40px;
  max-height: 70px;
}
.multi_comparisons__review-table .review-table__header-cell--sortable .review-table__move-column {
  display: none;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 3px;
  background-color: #2caac4;
}
.multi_comparisons__review-table .review-table__header-cell--sortable .review-table__move-column-button {
  font-size: 25px;
  font-weight: bold;
  margin: 0 5px;
}
.multi_comparisons__review-table .review-table__header-cell--sortable .review-table__show-source-button {
  font-size: 20px;
  border: 1px solid #bfbfbf;
  line-height: 30px;
  border-color: #fff;
  border-radius: 20px;
  padding: 1px 7px;
}
.multi_comparisons__review-table .review-table__header-cell--sortable .review-table__show-source-button:hover {
  opacity: 1;
  color: #fff;
  background-color: #00c3fd;
  border-color: #00c3fd;
}
.multi_comparisons__review-table .review-table__header-cell--sortable:hover .review-table__move-column {
  display: flex;
  position: absolute;
}
.multi_comparisons__review-table .review-table__header-cell--blank {
  background-color: #fff;
}
.multi_comparisons__review-table .review-table__toggle-column-button {
  display: inline-block;
}
.multi_comparisons__review-table .review-table__toggle-column-button button:before {
  color: #ddd;
}
.multi_comparisons__review-table .review-table__toggle-column-button--hidden {
  line-height: 15px;
  font-size: 15px;
}
.multi_comparisons__review-table .review-table__cell {
  border: 2px solid #fff f;
  background-color: #f2f2f2;
  padding: 5px;
}
.multi_comparisons__review-table .review-table__cell.balanceOfEffects {
  border-bottom: none;
}
.multi_comparisons__review-table .review-table__cell.certaintyOfEvidence {
  border-top: none;
  background: #ddeaf9;
}
.multi_comparisons__review-table .review-table__hide-row-button {
  text-align: left;
  display: none;
  position: absolute;
  bottom: 2px;
  left: 5px;
  background: #ddeaf9;
  opacity: 0.8;
}
.multi_comparisons__review-table .review-table__hide-row-button:hover {
  display: block;
}
.multi_comparisons__review-table .review-table__hide-row-button .btn-icon.blind-eye {
  background-color: #fff;
  font-size: 16px;
}
.multi_comparisons__review-table .review-table__hide-row-button .btn-icon.eye {
  font-size: 10px;
}
.multi_comparisons__review-table .review-table__hide-row-button--section-hidden {
  bottom: -5px;
  background: none;
}
.multi_comparisons__review-table .review-table__cell--section-name {
  position: relative;
  background: #e1e1e1;
  text-transform: uppercase;
  text-align: right;
}
.multi_comparisons__review-table .review-table__cell--section-name.balanceOfEffects {
  border-bottom: none;
}
.multi_comparisons__review-table .review-table__cell--section-name.certaintyOfEvidence {
  border-top: none;
  background: #ddeaf9;
  text-transform: none;
}
.multi_comparisons__review-table .review-table__cell--section-name:hover {
  background-color: #ddeaf9;
}
.multi_comparisons__review-table .review-table__cell--section-name:hover .review-table__hide-row-button {
  display: block;
}
.multi_comparisons__review-table .review-table__cell--importance,
.multi_comparisons__review-table .review-table__header--importance {
  border-left: 6px solid #fff;
}
.multi_comparisons__review-table .review-table__cell--grading-importance {
  vertical-align: middle;
}
.multi_comparisons__review-table .review-table__cell--importance {
  text-align: center;
  background-color: #f2f2f2;
}
.multi_comparisons__review-table .review-table__cell--low-importance {
  background-color: #fff8de;
}
.multi_comparisons__review-table .review-table__cell--moderate-importance {
  background-color: #ffe697;
}
.multi_comparisons__review-table .review-table__cell--high-importance {
  background-color: #ffc278;
}
.multi_comparisons__review-table .review-table__importance-for-decision-dropdown {
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
}
.multi_comparisons__review-table .review-table__importance-for-decision-dropdown:after {
  position: absolute;
  top: 0;
  right: 5px;
}
.multi_comparisons__review-table .review-table__importance-for-decision-dropdown .select-input {
  border: none;
}
.multi_comparisons__review-table .review-table__rating {
  vertical-align: middle;
}
.multi_comparisons__review-table .review-table__rating:hover {
  background-color: #ddeaf9;
}
.multi_comparisons__review-table .review-table__cell-comment {
  text-align: center;
}
.multi_comparisons__review-table .review-table__comment-button {
  text-align: center;
}
.multi_comparisons__review-table .review-table__add-comment-button {
  background-color: transparent;
  font-style: italic;
  color: #8c8c8c;
}
.multi_comparisons__review-table .review-table__comment-dialog {
  padding: 24px 24px 15px 24px;
  border: 1px solid #009cca;
  background-color: #fff;
  box-shadow: 0px 3px 10px 3px #ccc;
}
.multi_comparisons__review-table .text-area-container {
  border: 0;
}
.multi_comparisons__review-table .text-area-container textarea {
  width: 300px;
  height: 118px;
  overflow-y: auto;
  padding: 5px;
  border: 1px solid #bfbfbf;
  text-align: left;
}
.multi_comparisons__review-table .text-area-container .buttons {
  display: flex;
  margin-top: 15px;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.multi_comparisons__review-table .text-area-container .buttons button {
  width: 120px;
}
.multi-comparisons__table-name {
  margin-top: 35px;
  padding-left: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.multi-comparisons__card {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 7px;
  box-shadow: 0px 3px 10px 3px #ccc;
  padding: 26px 13px 30px 13px;
  margin: 5px 0;
}
.multi-comparisons__card-with-textareas {
  margin-top: 38px;
  padding-top: 0;
}
.multi-comparisons__card-with-textareas .public-DraftEditor-content {
  min-height: 117px;
}
.options-select-container__options.standard-list {
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 38px;
  padding: 5px;
  box-shadow: 0px 3px 10px 3px #ccc;
}
.options-select-container__options.standard-list .option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.options-select-container__options.btn-questions-selection-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.options-select-container__options.multi-comparisons-recommendation-options {
  color: #009cca;
}
.options-select-container__options.multi-comparisons__questions-list ul {
  padding-left: 10px;
}
.options-select-container__options.multi-comparisons__questions-list li {
  list-style: none;
}
.options-select-container__options.multi-comparisons__questions-list li.options-group {
  padding: 5px;
}
.options-select-container__options.multi-comparisons__questions-list li .options-group__options ul li {
  height: auto;
  max-width: calc(100vw - 400px);
}
