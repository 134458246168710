@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.todos-component-container {
  display: block;
  position: relative;
}
.todos-component-container .todos {
  display: table;
  margin-bottom: 10px;
  width: 100%;
  border-collapse: collapse;
}
.todos-component-container .todos .todo {
  display: table-row;
  border: 1px solid #bfbfbf;
  height: 40px;
}
.todos-component-container .todos .todo .toggle,
.todos-component-container .todos .todo .todo-text,
.todos-component-container .todos .todo .todo-buttons {
  display: table-cell;
  vertical-align: middle;
}
.todos-component-container .todos .todo .toggle {
  width: 30px;
  text-align: center;
}
.todos-component-container .todos .todo .todo-text {
  width: auto;
}
.todos-component-container .todos .todo .todo-text input {
  width: 100%;
}
.todos-component-container .todos .todo .strike-through {
  text-decoration: line-through;
}
.todos-component-container .todos .todo .todo-buttons {
  text-align: right;
  width: 100px;
}
.todos-component-container .todos .todo .todo-buttons button {
  margin: 0;
  float: initial;
  height: 40px;
  width: 40px;
  border-left: 1px solid #bfbfbf;
}
.red {
  color: #f00;
}
.scope-view {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
}
.scope-view .scope-table {
  max-width: 1500px;
}
.scope-view .scope-table td {
  padding: 4px;
  border: none;
}
.scope-view .scope-table .title-cell {
  width: 200px;
  text-align: left;
}
.scope-view .scope-table .value-cell {
  padding-bottom: 10px;
  padding-right: 10px;
}
.scope-view .scope-table .value-cell textarea {
  width: 100%;
  min-height: 75px;
}
.scope-view .scope-table .description-cell {
  width: 33%;
  border: 1px solid #fff;
  color: #a9a9a9;
  font-size: 14px;
}
.scope-view .scope-table div[contenteditable=true] {
  border: 1px solid #bfbfbf;
  min-height: 114px;
}
.questions-generation-container {
  background-color: #fff;
  padding-bottom: 20px;
}
.questions-generation-container .standard-list {
  font-size: 14px;
}
.questions-generation-container .bottom-bar {
  font-size: 16px;
}
.questions-generation-container button {
  font-size: 16px;
}
.questions-generation-container .questions {
  max-height: calc(100vh - 435px);
  overflow: auto;
}
.questions-generation-container .members {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.questions-generation-container .gdt-limits-content-box {
  margin-bottom: 10px;
}
.questions-generation-container .questions-initial-draft,
.questions-generation-container .questions-moderation {
  font-size: 14px;
  min-width: 920px;
}
.questions-generation-container .questions-initial-draft .standard-list div.expanded,
.questions-generation-container .questions-moderation .standard-list div.expanded,
.questions-generation-container .questions-initial-draft input,
.questions-generation-container .questions-moderation input {
  font-size: 14px;
}
.questions-generation-container .questions-initial-draft button.input-button,
.questions-generation-container .questions-moderation button.input-button {
  right: 0;
}
.questions-generation-container .questions-initial-draft div.expanded .separated,
.questions-generation-container .questions-moderation div.expanded .separated {
  padding-left: 0;
  margin-left: 4px;
}
.questions-generation-container .questions-initial-draft li .caption:hover div,
.questions-generation-container .questions-moderation li .caption:hover div {
  text-decoration: none;
}
.questions-generation-container .questions-initial-draft li.with-comments,
.questions-generation-container .questions-moderation li.with-comments {
  height: initial;
}
.questions-generation-container .questions-initial-draft li div.buttons,
.questions-generation-container .questions-moderation li div.buttons {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.questions-generation-container .questions-initial-draft li.is-dragging,
.questions-generation-container .questions-moderation li.is-dragging {
  background: #d2dfed;
  color: #d2dfed;
  border: 0;
}
.questions-generation-container .questions-initial-draft button.edit:before,
.questions-generation-container .questions-moderation button.edit:before {
  border-color: #d2dfed;
}
.questions-generation-container .questions-initial-draft button.edit:hover,
.questions-generation-container .questions-moderation button.edit:hover {
  background: #d2dfed;
  color: #d2dfed;
  border: 0;
}
.question-comments-container {
  margin-right: 38px;
  border-right: 2px solid #fff;
  line-height: initial;
  padding-top: 16px;
  font-size: 14px;
}
.question-comments-container .admin-comment .buttons {
  margin: 0;
}
.question-comments-container .admin-comment .buttons button {
  display: inline-block;
  float: initial;
}
.question-comments-container .text-area-container,
.question-comments-container textarea {
  min-height: 150px;
}
.questions-brainstorming li.brainstorming:before {
  background-color: BUTTON_SOLMON_COLOR;
}
.questions-brainstorming li.with-comments {
  height: initial;
}
.questions-rating li.completed:before {
  background-color: #c9dac2;
}
.questions-rating li.new:before {
  background-color: #fbeed5;
}
.questions-approval .responded {
  line-height: 36px;
  display: inline-block;
}
.questions-proposal,
.questions-approval,
.questions-finished {
  font-size: 14px;
}
.questions-proposal .all-phases-comments,
.questions-approval .all-phases-comments,
.questions-finished .all-phases-comments {
  background-color: #f6f6f6;
}
.questions-proposal .all-phases-comments .question-comments-container,
.questions-approval .all-phases-comments .question-comments-container,
.questions-finished .all-phases-comments .question-comments-container {
  width: 100%;
}
.questions-proposal .all-phases-comments .title,
.questions-approval .all-phases-comments .title,
.questions-finished .all-phases-comments .title {
  padding-right: 20px;
  text-align: right;
}
.questions-proposal .all-phases-comments .collapsible-toggle,
.questions-approval .all-phases-comments .collapsible-toggle,
.questions-finished .all-phases-comments .collapsible-toggle {
  z-index: 9999;
  margin-left: calc(14% - 10px);
  top: -6px;
}
.questions-proposal .all-phases-comments .text-area-container,
.questions-approval .all-phases-comments .text-area-container,
.questions-finished .all-phases-comments .text-area-container,
.questions-proposal .all-phases-comments textarea,
.questions-approval .all-phases-comments textarea,
.questions-finished .all-phases-comments textarea {
  min-height: 40px;
}
.questions-proposal .all-phases-comments h4,
.questions-approval .all-phases-comments h4,
.questions-finished .all-phases-comments h4 {
  margin-left: 14%;
  margin-bottom: 5px;
  margin-top: 10px;
}
.questions-proposal .all-phases-comments div,
.questions-approval .all-phases-comments div,
.questions-finished .all-phases-comments div {
  position: relative;
}
.questions-proposal tbody.accepted,
.questions-approval tbody.accepted,
.questions-finished tbody.accepted {
  background-color: #fda;
}
.questions-proposal tbody.mentioned,
.questions-approval tbody.mentioned,
.questions-finished tbody.mentioned {
  background-color: #fff0bb;
}
.questions-proposal tbody.rejected,
.questions-approval tbody.rejected,
.questions-finished tbody.rejected {
  background-color: #fff8de;
}
.questions-proposal td,
.questions-approval td,
.questions-finished td {
  padding: 3px 5px;
}
.questions-proposal td:not(:first-child),
.questions-approval td:not(:first-child),
.questions-finished td:not(:first-child) {
  text-align: center;
}
.questions-proposal td:not(:first-child) label,
.questions-approval td:not(:first-child) label,
.questions-finished td:not(:first-child) label {
  display: inline-block;
  width: 100%;
}
.questions-proposal td.comments-details,
.questions-approval td.comments-details,
.questions-finished td.comments-details {
  background-color: #f6f6f6;
  display: none;
  vertical-align: bottom;
}
.questions-proposal td.comments-details.collapse,
.questions-approval td.comments-details.collapse,
.questions-finished td.comments-details.collapse {
  text-align: left;
  padding-left: 14px;
}
.questions-proposal td.comments-details.collapse button.comment-blank,
.questions-approval td.comments-details.collapse button.comment-blank,
.questions-finished td.comments-details.collapse button.comment-blank {
  font-family: 'gdt';
  font-size: 20px;
  margin-right: 15px;
}
.questions-proposal td.comments-details.collapse button.comment-blank:before,
.questions-approval td.comments-details.collapse button.comment-blank:before,
.questions-finished td.comments-details.collapse button.comment-blank:before {
  font-family: 'gdt';
  content: '\e017\e040';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.questions-proposal td.comments-details.collapse button.comment-blank *,
.questions-approval td.comments-details.collapse button.comment-blank *,
.questions-finished td.comments-details.collapse button.comment-blank * {
  margin-left: 0.5rem;
}
.questions-proposal td,
.questions-approval td,
.questions-finished td,
.questions-proposal th,
.questions-approval th,
.questions-finished th {
  padding: 10px;
  width: 85px;
}
.questions-proposal td:first-child,
.questions-approval td:first-child,
.questions-finished td:first-child,
.questions-proposal th:first-child,
.questions-approval th:first-child,
.questions-finished th:first-child {
  width: auto;
}
.questions-proposal .comments-phase,
.questions-approval .comments-phase,
.questions-finished .comments-phase {
  display: block;
  width: 100%;
}
.questions-proposal .comments-phase h4,
.questions-approval .comments-phase h4,
.questions-finished .comments-phase h4 {
  margin-left: 15.25%;
  margin-bottom: 5px;
  margin-top: 10px;
}
.questions-proposal .comments-phase .comments-type,
.questions-approval .comments-phase .comments-type,
.questions-finished .comments-phase .comments-type,
.questions-proposal .comments-phase .members-comments,
.questions-approval .comments-phase .members-comments,
.questions-finished .comments-phase .members-comments,
.questions-proposal .comments-phase .comment-input,
.questions-approval .comments-phase .comment-input,
.questions-finished .comments-phase .comment-input {
  display: inline-block;
}
.questions-proposal .comments-phase .comments-type,
.questions-approval .comments-phase .comments-type,
.questions-finished .comments-phase .comments-type {
  width: 15%;
  height: 100%;
  margin-top: 10px;
  padding-right: 15px;
  vertical-align: top;
  text-align: right;
}
.questions-proposal .comments-phase .members-comments,
.questions-approval .comments-phase .members-comments,
.questions-finished .comments-phase .members-comments,
.questions-proposal .comments-phase .comment-input,
.questions-approval .comments-phase .comment-input,
.questions-finished .comments-phase .comment-input {
  margin-top: 10px;
  width: 84.5%;
}
.questions-proposal .comments-phase .members-comments div.content,
.questions-approval .comments-phase .members-comments div.content,
.questions-finished .comments-phase .members-comments div.content,
.questions-proposal .comments-phase .comment-input div.content,
.questions-approval .comments-phase .comment-input div.content,
.questions-finished .comments-phase .comment-input div.content {
  min-height: 40px;
}
.questions-proposal .comments-phase .member-comment:first-child,
.questions-approval .comments-phase .member-comment:first-child,
.questions-finished .comments-phase .member-comment:first-child,
.questions-proposal .comments-phase .member-comment,
.questions-approval .comments-phase .member-comment,
.questions-finished .comments-phase .member-comment {
  border: 1px solid #bfbfbf;
}
.questions-proposal .comments-phase .member-comment,
.questions-approval .comments-phase .member-comment,
.questions-finished .comments-phase .member-comment {
  border-top: none;
}
.question-edit > .management,
.question-edit > .diagnostic {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: inline-flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: calc(100% - 130px);
  line-height: 40px;
  vertical-align: top;
  position: relative;
}
.question-edit > .management span.input-with-button,
.question-edit > .diagnostic span.input-with-button,
.question-edit > .management input,
.question-edit > .diagnostic input {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  margin: 0 5px;
}
.question-edit > .management select,
.question-edit > .diagnostic select {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 5px;
}
.question-edit > .management span.input-with-button,
.question-edit > .diagnostic span.input-with-button {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: inline-flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.question-edit > .management span.input-with-button input,
.question-edit > .diagnostic span.input-with-button input {
  margin: 0;
  width: calc(100% - 32px);
}
.question-edit > .management span.input-with-button button.input-button,
.question-edit > .diagnostic span.input-with-button button.input-button {
  position: static;
  top: auto;
  botttom: auto;
}
.question-edit > .diagnostic select {
  width: 100px;
}
.question-edit > .freeform input {
  width: calc(100% - 125px);
}
.question-edit .buttons button:before {
  vertical-align: baseline;
}
.questions-moderation .with-comments .question-edit {
  position: relative;
}
.questions-moderation .with-comments .question-edit .buttons {
  z-index: 1000;
}
.questions-moderation .with-comments .question-edit .buttons button {
  float: initial;
}
.questions-moderation .with-comments .buttons .comment {
  position: relative;
  height: 40px;
}
.questions-moderation .side-spacer {
  height: 100%;
  width: 40px;
  right: 0;
  bottom: 0;
  position: absolute;
  border-left: 2px solid #fff;
}
.questions-approval,
.questions-finished {
  font-size: 14px;
}
.questions-approval .approval-questions-group,
.questions-finished .approval-questions-group {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
}
.questions-approval .approval-questions-group:last-child,
.questions-finished .approval-questions-group:last-child {
  margin-bottom: 0;
}
.questions-approval .approval-questions-group table,
.questions-finished .approval-questions-group table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
}
.questions-approval .approval-questions-group table tbody,
.questions-finished .approval-questions-group table tbody {
  background: #f2f2f2;
}
.questions-approval .approval-questions-group table tbody:hover:not(.expanded),
.questions-finished .approval-questions-group table tbody:hover:not(.expanded),
.questions-approval .approval-questions-group table tbody.current,
.questions-finished .approval-questions-group table tbody.current {
  background-color: #f7f7f7;
}
.questions-approval .approval-questions-group table tbody.draggable:hover .question:before,
.questions-finished .approval-questions-group table tbody.draggable:hover .question:before {
  position: absolute;
  left: -10px;
  font-family: 'gdt';
  content: '\e042';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  left: 3px;
}
.questions-approval .approval-questions-group table tbody.is-dragging,
.questions-finished .approval-questions-group table tbody.is-dragging {
  background: #d2dfed;
  color: #d2dfed;
  border: 2px solid #fff;
}
.questions-approval .approval-questions-group table tbody.is-dragging td,
.questions-finished .approval-questions-group table tbody.is-dragging td {
  border-color: #d2dfed;
}
.questions-approval .approval-questions-group table tbody.is-dragging td .red,
.questions-finished .approval-questions-group table tbody.is-dragging td .red {
  color: #d2dfed;
}
.questions-approval .approval-questions-group table tbody.is-dragging:hover,
.questions-finished .approval-questions-group table tbody.is-dragging:hover {
  background: #d2dfed;
  color: #d2dfed;
  border: 2px solid #fff;
}
.questions-approval .approval-questions-group table tbody.drop-target.hovered,
.questions-finished .approval-questions-group table tbody.drop-target.hovered {
  background: #d2dfed;
}
.questions-approval .approval-questions-group table tbody.drop-target tr,
.questions-finished .approval-questions-group table tbody.drop-target tr {
  border: 2px solid #fff;
}
.questions-approval .approval-questions-group table tbody.drop-target tr td,
.questions-finished .approval-questions-group table tbody.drop-target tr td {
  padding: 5px;
  text-align: center;
}
.questions-approval .approval-questions-group table tbody.drop-target tr td div,
.questions-finished .approval-questions-group table tbody.drop-target tr td div {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f3fcff;
  border: 1px solid #009cca;
  border-style: dashed;
  height: 30px;
  line-height: 30px;
}
.questions-approval .approval-questions-group table tbody.drop-target tr td div.about-to-drop,
.questions-finished .approval-questions-group table tbody.drop-target tr td div.about-to-drop {
  background: #d2dfed;
}
.questions-approval .approval-questions-group table tbody.drop-target tr td div:hover,
.questions-finished .approval-questions-group table tbody.drop-target tr td div:hover {
  background: #f3fcff;
}
.questions-approval .approval-questions-group table tr,
.questions-finished .approval-questions-group table tr {
  font-size: 14px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}
.questions-approval .approval-questions-group table tr.caption,
.questions-finished .approval-questions-group table tr.caption,
.questions-approval .approval-questions-group table tr.question-row,
.questions-finished .approval-questions-group table tr.question-row {
  line-height: 40px;
}
.questions-approval .approval-questions-group table tr.caption.group-changed,
.questions-finished .approval-questions-group table tr.caption.group-changed,
.questions-approval .approval-questions-group table tr.question-row.group-changed,
.questions-finished .approval-questions-group table tr.question-row.group-changed {
  background: #d2dfed;
}
.questions-approval .approval-questions-group table .approved,
.questions-finished .approval-questions-group table .approved {
  line-height: 14px;
}
.questions-approval .approval-questions-group table .approved .todos-count,
.questions-finished .approval-questions-group table .approved .todos-count {
  font-size: 11px;
}
.questions-approval .approval-questions-group table .group-title,
.questions-finished .approval-questions-group table .group-title,
.questions-approval .approval-questions-group table .question,
.questions-finished .approval-questions-group table .question,
.questions-approval .approval-questions-group table .approved,
.questions-finished .approval-questions-group table .approved,
.questions-approval .approval-questions-group table .comments,
.questions-finished .approval-questions-group table .comments {
  border: 2px solid #fff;
  text-align: center;
  vertical-align: middle;
  padding: 0 10px;
}
.questions-approval .approval-questions-group table .group-title.no-border-right,
.questions-finished .approval-questions-group table .group-title.no-border-right,
.questions-approval .approval-questions-group table .question.no-border-right,
.questions-finished .approval-questions-group table .question.no-border-right,
.questions-approval .approval-questions-group table .approved.no-border-right,
.questions-finished .approval-questions-group table .approved.no-border-right,
.questions-approval .approval-questions-group table .comments.no-border-right,
.questions-finished .approval-questions-group table .comments.no-border-right {
  border-right: none;
}
.questions-approval .approval-questions-group table .group-title.no-border-left,
.questions-finished .approval-questions-group table .group-title.no-border-left,
.questions-approval .approval-questions-group table .question.no-border-left,
.questions-finished .approval-questions-group table .question.no-border-left,
.questions-approval .approval-questions-group table .approved.no-border-left,
.questions-finished .approval-questions-group table .approved.no-border-left,
.questions-approval .approval-questions-group table .comments.no-border-left,
.questions-finished .approval-questions-group table .comments.no-border-left {
  border-left: none;
}
.questions-approval .approval-questions-group table .question,
.questions-finished .approval-questions-group table .question {
  text-align: left;
}
.questions-approval .approval-questions-group table .caption,
.questions-finished .approval-questions-group table .caption {
  background: #ddd;
}
.questions-approval .approval-questions-group table .question-row.question-only .question,
.questions-finished .approval-questions-group table .question-row.question-only .question {
  border-right: none;
  border-bottom: none;
}
.questions-approval .approval-questions-group table .question-row.question-only .approved,
.questions-finished .approval-questions-group table .question-row.question-only .approved,
.questions-approval .approval-questions-group table .question-row.question-only .comments,
.questions-finished .approval-questions-group table .question-row.question-only .comments {
  border: none;
}
.questions-approval .approval-questions-group table .question-details-row,
.questions-finished .approval-questions-group table .question-details-row {
  border-top: none;
}
.questions-approval .approval-questions-group table .question-details-row td,
.questions-finished .approval-questions-group table .question-details-row td {
  padding-bottom: 0;
}
.questions-approval .approval-questions-group table .question-details-row .question-details,
.questions-finished .approval-questions-group table .question-details-row .question-details {
  width: 97%;
  position: relative;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block,
.questions-finished .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block {
  display: inline-block;
  vertical-align: top;
  width: 360px;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos,
.questions-finished .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos {
  margin-left: 40px;
  width: calc(100% - 400px);
}
.questions-approval .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .title,
.questions-finished .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .title {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .admin-comment,
.questions-finished .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .admin-comment {
  display: inline-block;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .admin-comment,
.questions-finished .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .admin-comment,
.questions-approval .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .todos-component-container,
.questions-finished .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .todos-component-container,
.questions-approval .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos textarea,
.questions-finished .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos textarea {
  width: calc(100% - 100px);
  margin-bottom: 10px;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .todos-component-container,
.questions-finished .approval-questions-group table .question-details-row .question-details .extended-details-container .extended-details-block.with-todos .todos-component-container {
  display: inline-block;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .approval-details,
.questions-finished .approval-questions-group table .question-details-row .question-details .approval-details {
  margin: 10px 0;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .approval-details .group-changed,
.questions-finished .approval-questions-group table .question-details-row .question-details .approval-details .group-changed {
  background: #d2dfed;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .approval-details .approval-stats,
.questions-finished .approval-questions-group table .question-details-row .question-details .approval-details .approval-stats {
  margin-top: 10px;
}
.questions-approval .approval-questions-group table .question-details-row .question-details #disagreement,
.questions-finished .approval-questions-group table .question-details-row .question-details #disagreement {
  position: relative;
  top: 1px;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .step-name,
.questions-finished .approval-questions-group table .question-details-row .question-details .step-name,
.questions-approval .approval-questions-group table .question-details-row .question-details .original-priority,
.questions-finished .approval-questions-group table .question-details-row .question-details .original-priority,
.questions-approval .approval-questions-group table .question-details-row .question-details .approval-stats,
.questions-finished .approval-questions-group table .question-details-row .question-details .approval-stats {
  padding-left: 0;
  font-weight: bold;
  display: inline-block;
  width: 175px;
  margin-right: 10px;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .members-comments,
.questions-finished .approval-questions-group table .question-details-row .question-details .members-comments,
.questions-approval .approval-questions-group table .question-details-row .question-details .admin-comment,
.questions-finished .approval-questions-group table .question-details-row .question-details .admin-comment {
  width: calc(100% - 185px);
}
.questions-approval .approval-questions-group table .question-details-row .question-details .question-comments-container,
.questions-finished .approval-questions-group table .question-details-row .question-details .question-comments-container {
  width: 100%;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .question-comments-container .title,
.questions-finished .approval-questions-group table .question-details-row .question-details .question-comments-container .title,
.questions-approval .approval-questions-group table .question-details-row .question-details .comments-section .title,
.questions-finished .approval-questions-group table .question-details-row .question-details .comments-section .title {
  line-height: 20px;
  font-weight: bold;
  width: 185px;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .comments-section,
.questions-finished .approval-questions-group table .question-details-row .question-details .comments-section {
  margin-top: 10px;
}
.questions-approval .approval-questions-group table .question-details-row .question-details .collapsible-container .collapsible-toggle,
.questions-finished .approval-questions-group table .question-details-row .question-details .collapsible-container .collapsible-toggle {
  position: relative;
  margin-left: 0;
  width: 20px;
  text-align: left;
  top: 0;
  left: 0;
}
.questions-approval .approval-questions-group table .question-details-row .button-container,
.questions-finished .approval-questions-group table .question-details-row .button-container {
  width: 97%;
  text-align: right;
  padding-bottom: 10px;
}
.questions-approval .approval-questions-group table .question-details-row .button-container .save-close,
.questions-finished .approval-questions-group table .question-details-row .button-container .save-close {
  width: 190px;
}
.questions-finished button.ep-btn,
.questions-approval button.ep-btn,
.questions-proposal button.ep-btn,
.questions-rating button.ep-btn,
.questions-moderation button.ep-btn,
.questions-brainstorming button.ep-btn {
  color: #fff;
  line-height: 36px;
  font-size: 16px;
  margin-left: 10px;
  min-width: 150px;
}
.questions-finished button.ep-btn.comment-blank-hide:before,
.questions-approval button.ep-btn.comment-blank-hide:before,
.questions-proposal button.ep-btn.comment-blank-hide:before,
.questions-rating button.ep-btn.comment-blank-hide:before,
.questions-moderation button.ep-btn.comment-blank-hide:before,
.questions-brainstorming button.ep-btn.comment-blank-hide:before {
  text-align: center;
  font-size: 25px;
}
.questions-finished div.information,
.questions-approval div.information,
.questions-proposal div.information,
.questions-rating div.information,
.questions-moderation div.information,
.questions-brainstorming div.information {
  margin-bottom: 10px;
  font-size: 14px;
}
.questions-finished div.buttons,
.questions-approval div.buttons,
.questions-proposal div.buttons,
.questions-rating div.buttons,
.questions-moderation div.buttons,
.questions-brainstorming div.buttons {
  text-align: right;
  margin-bottom: 10px;
}
.questions-finished span.icon,
.questions-approval span.icon,
.questions-proposal span.icon,
.questions-rating span.icon,
.questions-moderation span.icon,
.questions-brainstorming span.icon {
  display: inline-block;
}
.questions-finished span.icon.comments:before,
.questions-approval span.icon.comments:before,
.questions-proposal span.icon.comments:before,
.questions-rating span.icon.comments:before,
.questions-moderation span.icon.comments:before,
.questions-brainstorming span.icon.comments:before {
  font-family: 'gdt';
  content: '\e070';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.questions-finished span.icon.comments *,
.questions-approval span.icon.comments *,
.questions-proposal span.icon.comments *,
.questions-rating span.icon.comments *,
.questions-moderation span.icon.comments *,
.questions-brainstorming span.icon.comments * {
  margin-left: 0.5rem;
}
.questions-finished .comments-toggle,
.questions-approval .comments-toggle,
.questions-proposal .comments-toggle,
.questions-rating .comments-toggle,
.questions-moderation .comments-toggle,
.questions-brainstorming .comments-toggle,
.questions-finished .with-comments,
.questions-approval .with-comments,
.questions-proposal .with-comments,
.questions-rating .with-comments,
.questions-moderation .with-comments,
.questions-brainstorming .with-comments {
  position: relative;
}
.questions-finished .comments-toggle .comment,
.questions-approval .comments-toggle .comment,
.questions-proposal .comments-toggle .comment,
.questions-rating .comments-toggle .comment,
.questions-moderation .comments-toggle .comment,
.questions-brainstorming .comments-toggle .comment,
.questions-finished .with-comments .comment,
.questions-approval .with-comments .comment,
.questions-proposal .with-comments .comment,
.questions-rating .with-comments .comment,
.questions-moderation .with-comments .comment,
.questions-brainstorming .with-comments .comment {
  position: relative;
  margin: 0px auto;
  width: 40px;
}
.questions-finished .comments-toggle:last-child .comments-block,
.questions-approval .comments-toggle:last-child .comments-block,
.questions-proposal .comments-toggle:last-child .comments-block,
.questions-rating .comments-toggle:last-child .comments-block,
.questions-moderation .comments-toggle:last-child .comments-block,
.questions-brainstorming .comments-toggle:last-child .comments-block,
.questions-finished .with-comments:last-child .comments-block,
.questions-approval .with-comments:last-child .comments-block,
.questions-proposal .with-comments:last-child .comments-block,
.questions-rating .with-comments:last-child .comments-block,
.questions-moderation .with-comments:last-child .comments-block,
.questions-brainstorming .with-comments:last-child .comments-block {
  padding-bottom: 20px;
}
div.show-comments {
  margin-bottom: 20px;
  margin-right: 5px;
  display: inline-block;
  float: right;
}
.scope-toolbar {
  position: relative;
}
.scope-toolbar .breadcrumbs-container {
  margin-right: 40px;
}
.scope-toolbar .breadcrumbs-container .breadcrumbs {
  display: table;
  width: 100%;
}
.scope-toolbar .breadcrumbs-container .breadcrumbs li.breadcrumbs-tab {
  display: table-cell !important;
  width: 14%;
}
.scope-toolbar .breadcrumbs-container .breadcrumbs li.breadcrumbs-tab:last-child {
  display: table-cell;
  width: auto;
}
.scope-toolbar .toolbar-menu {
  width: 40px;
  background-color: #f2f2f2;
  position: absolute;
  right: 0;
  top: 0;
}
.scope-toolbar .toolbar-menu.finished,
.scope-toolbar .toolbar-menu.questions-finished {
  background-color: #fff;
}
.scope-toolbar.general-scope .toolbar-menu {
  background-color: transparent;
}
.formats-list label {
  display: block;
}
.action-buttons {
  margin-bottom: 10px;
  width: 100%;
  text-align: right;
  line-height: 36px;
}
.action-buttons button {
  width: 190px;
  margin-left: 10px;
}
.collapsible-toggle {
  top: -5px;
  left: 0;
  margin-left: 190px;
  font-size: 20px;
}
.step-name {
  font-weight: bold;
  padding-left: 14%;
  margin-bottom: 5px;
}
.general-scope-table-export,
.export-comments-modal {
  width: 350px;
}
.general-scope-table-export h2,
.export-comments-modal h2 {
  text-align: center;
}
.individual-ratings-modal {
  max-height: 585px;
  display: table;
}
.individual-ratings-modal h2 {
  text-align: center;
}
.individual-ratings-modal .questions-container {
  max-height: 440px;
  max-width: calc(100vw - 246px);
  padding-right: 15px;
  overflow: auto;
}
.individual-ratings-modal .questions-container .question-group {
  margin-top: 25px;
}
.individual-ratings-modal .questions-container .question-group:first-child {
  margin-top: 0;
}
.individual-ratings-modal .questions-container .question-group .questions {
  margin: 10px 0;
}
.individual-ratings-modal table.individual-ratings {
  position: relative;
  border: 2px solid #fff;
}
.individual-ratings-modal table.individual-ratings th,
.individual-ratings-modal table.individual-ratings td {
  width: 50px;
  padding: 5px;
  border: 2px solid #fff;
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
}
.individual-ratings-modal table.individual-ratings th:hover,
.individual-ratings-modal table.individual-ratings td:hover {
  cursor: default;
}
.individual-ratings-modal table.individual-ratings td {
  position: relative;
}
.individual-ratings-modal table.individual-ratings td .comment-blank {
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
}
.individual-ratings-modal table.individual-ratings th.labels {
  min-width: 400px;
  height: 40px;
}
.individual-ratings-modal table.individual-ratings th.labels .diag-line {
  position: absolute;
  top: 19px;
  left: -1px;
  border-top: 2px solid #fff;
  width: 402px;
  webkit-transform: rotate(8.3deg);
  moz-transform: rotate(8.3deg);
  o-transform: rotate(8.3deg);
  ms-transform: rotate(8.3deg);
  transform: rotate(5.6deg);
}
.individual-ratings-modal table.individual-ratings th.labels span {
  font-weight: bold;
}
.individual-ratings-modal table.individual-ratings th.labels .outcomes,
.individual-ratings-modal table.individual-ratings th.labels .questions {
  float: left;
}
.individual-ratings-modal table.individual-ratings th.labels .members {
  float: right;
}
.individual-ratings-modal table.individual-ratings .stats {
  width: 60px;
  font-weight: bold;
}
.individual-ratings-modal table.individual-ratings .mean {
  border-left-width: 3px;
}
.individual-ratings-modal table.individual-ratings td.item-text {
  text-align: left;
}
.individual-ratings-modal table.individual-ratings.outcomes td.rating-1,
.individual-ratings-modal table.individual-ratings.questions td.rating-1,
.individual-ratings-modal table.individual-ratings.outcomes td.rating-2,
.individual-ratings-modal table.individual-ratings.questions td.rating-2,
.individual-ratings-modal table.individual-ratings.outcomes td.rating-3,
.individual-ratings-modal table.individual-ratings.questions td.rating-3 {
  background-color: #fff8de;
}
.individual-ratings-modal table.individual-ratings.outcomes td.rating-4,
.individual-ratings-modal table.individual-ratings.questions td.rating-4,
.individual-ratings-modal table.individual-ratings.outcomes td.rating-5,
.individual-ratings-modal table.individual-ratings.questions td.rating-5,
.individual-ratings-modal table.individual-ratings.outcomes td.rating-6,
.individual-ratings-modal table.individual-ratings.questions td.rating-6 {
  background-color: #fff0bb;
}
.individual-ratings-modal table.individual-ratings.outcomes td.rating-7,
.individual-ratings-modal table.individual-ratings.questions td.rating-7,
.individual-ratings-modal table.individual-ratings.outcomes td.rating-8,
.individual-ratings-modal table.individual-ratings.questions td.rating-8,
.individual-ratings-modal table.individual-ratings.outcomes td.rating-9,
.individual-ratings-modal table.individual-ratings.questions td.rating-9 {
  background-color: #ffd494;
}
.individual-ratings-modal table.individual-ratings.outcomes td.high,
.individual-ratings-modal table.individual-ratings.questions td.high {
  background-color: #ffb671;
}
.individual-ratings-modal table.individual-ratings.outcomes td.moderate,
.individual-ratings-modal table.individual-ratings.questions td.moderate {
  background-color: #ffcea1;
}
.individual-ratings-modal table.individual-ratings.outcomes td.low,
.individual-ratings-modal table.individual-ratings.questions td.low {
  background-color: #ffe4cc;
}
.individual-ratings-modal .buttons {
  margin-top: 15px;
}
.individual-ratings-modal .buttons button {
  width: 100%;
}
.questions-generation-approved-questions-select-modal .gdt-multi-select__option label > * {
  transition: background-color 0.3s;
}
.questions-generation-approved-questions-select-modal .gdt-multi-select__option label[disabled] > * {
  background-color: #ddd;
}
.questions-generation-approved-questions-select-modal .questions-limit-info .items-counter {
  float: right;
}
.questions-generation-approved-questions-select-modal .questions-limit-status {
  margin-top: 10px;
  padding: 20px 15px;
  text-align: left;
}
.questions-generation-approved-questions-select-modal .questions-limit-status::before {
  margin-right: 20px;
  vertical-align: bottom;
  color: #3883a5;
  font-family: 'gdt';
  content: '\e066';
}
.questions-generation-approved-questions-select-modal .buttons-react {
  margin-top: 20px;
}
