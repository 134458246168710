@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.projects-overview #loading-indicator .loader-bar {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 50px;
  height: 11px;
  background-image: url("../images/loader-bar.gif");
  background-position: center center;
  background-repeat: no-repeat;
}
.projects-overview #name {
  width: 90%;
}
.projects-overview li .caption {
  padding-right: 100px;
}
.projects-overview li button.export {
  display: none;
}
.projects-overview .import-json-block {
  display: none;
}
