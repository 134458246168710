@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.decision-aids-module {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
}
.decision-aids-module dl {
  margin: 0;
}
.decision-aids-module dl dt {
  font-size: 21px;
}
.decision-aids-module dl dd {
  font-size: 14px;
  margin: 0;
}
.decision-aids-module .buttons-react {
  margin: 40px 0 0;
}
.decision-aids-module .new-decision-aid,
.decision-aids-module .decision-aids-list,
.decision-aids-module .decision-aid-details {
  max-height: calc(100% - 110px);
  overflow: auto;
}
.decision-aids-module .new-decision-aid .appearance-details h4,
.decision-aids-module .decision-aid .appearance-details h4,
.decision-aids-module .new-decision-aid .labeled-input__label,
.decision-aids-module .decision-aid .labeled-input__label {
  width: 125px;
  font-weight: bold;
  text-align: right;
  font-size: 14px;
}
.decision-aids-module .new-decision-aid .edit-block {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 10px;
}
.decision-aids-module .new-decision-aid .edit-block .labeled-input {
  width: 100%;
}
.decision-aids-module .new-decision-aid h4 {
  margin: 0;
}
.decision-aids-module .new-decision-aid .process-step {
  margin-top: 20px;
}
.decision-aids-module .new-decision-aid .process-step h4 {
  margin-bottom: 15px;
}
.decision-aids-module .new-decision-aid .process-step .options-select-container {
  width: 100%;
}
.decision-aids-module .new-decision-aid .process-step .decision-aids-appearance-select:not(:last-child) {
  margin-bottom: 15px;
}
.decision-aids-module .decision-aid-details {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  max-height: calc(100% - 35px);
}
.decision-aids-module .decision-aid-details > * {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.decision-aids-module .decision-aid-details .decision-aid {
  border: none;
  background-color: #f2f2f2;
}
.decision-aids-module .decision-aid-details .decision-aid input[type="text"]:disabled {
  background-color: #f2f2f2;
}
.decision-aids-module .decision-aid-details .chapters-list {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  overflow: auto;
}
.decision-aids-module .decision-aid-details .draggable-chapter-title,
.decision-aids-module .decision-aid-details .chapter-title {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.decision-aids-module .decision-aid-details .draggable-chapter-title:hover .title-edit .btn-icon-container,
.decision-aids-module .decision-aid-details .chapter-title:hover .title-edit .btn-icon-container {
  opacity: 1;
}
.decision-aids-module .decision-aid-details .draggable-chapter-title .chapter-icon,
.decision-aids-module .decision-aid-details .chapter-title .chapter-icon {
  height: 40px;
  margin-right: 10px;
}
.decision-aids-module .decision-aid-details .draggable-chapter-title {
  min-height: 40px;
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #d2dfed;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
  cursor: grab;
}
.decision-aids-module .decision-aid-details .draggable-chapter-title.dragging {
  visibility: hidden;
}
.decision-aids-module .decision-aid-details .chapter-title {
  width: 100%;
  height: 100%;
}
.decision-aids-module .decision-aid-details .chapter-title:hover .title-edit .btn-icon-container {
  opacity: 1;
}
.decision-aids-module .decision-aid-details .chapter-title .title-edit {
  margin-left: 10px;
}
.decision-aids-module .decision-aid-details .chapter-title .title-edit .btn-icon-container {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.decision-aids-module .decision-aid-details .chapter-title .title-edit .btn-icon-container button:before {
  font-size: 20px;
  vertical-align: top;
  color: #ffa745;
}
.decision-aids-module .decision-aid-details .subchapter-header {
  margin: 10px 0 5px;
  padding: 0 15px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.decision-aids-module .decision-aid-details .subchapter-header .subchapter-controls {
  -webkit-flex: 0 0 0;
  -ms-flexbox: 0 0 0;
  flex: 0 0 0;
  text-align: right;
}
.decision-aids-module .decision-aid-details .subchapter-header .subchapter-title {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
}
.decision-aids-module .decision-aid-details .decision-aid__chapter-editor-card {
  overflow: visible;
}
.decision-aids-module .decision-aid-details .decision-aid__chapter-editor-card .gdt-editor-container {
  border: none;
}
.decision-aids-module .decision-aid-details .chapters-ordering-apply-cancel {
  margin-top: 30px;
}
.decision-aids-module .decision-aid-details .chapters-ordering-apply-cancel .buttons-react {
  margin: 0;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container.align-right {
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container.align-right .illustration {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container.align-right .illustration-controls {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  margin: 0 10px 0 0;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container:hover .illustration-controls {
  display: block;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container .illustration {
  height: 25px;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container .illustration img {
  height: 100%;
  margin-right: 15px;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container .illustration span {
  vertical-align: middle;
  font-size: 14px;
  color: #0058d0;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container .illustration-controls {
  display: none;
  margin: 0 0 0 10px;
}
.decision-aids-module .decision-aid-details .subchapter-illustration-container .illustration-controls .btn-icon-container:not(:last-child) {
  margin-right: 5px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome {
  margin-bottom: 25px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcomes-table__header {
  margin-bottom: 5px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row,
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row__what-happens,
.decision-aids-module .decision-aid-details .outcomes-table .outcomes-table__header {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .input-with-controls,
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row__what-happens .input-with-controls,
.decision-aids-module .decision-aid-details .outcomes-table .outcomes-table__header .input-with-controls {
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
  margin-right: 5px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row__outcome-name {
  -webkit-flex: 0 0 300px;
  -ms-flexbox: 0 0 300px;
  flex: 0 0 300px;
  margin-right: 5px;
  padding: 5px;
  text-align: right;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row__certainty {
  width: 100px;
  text-align: center;
  background-color: #f4f4f4;
  color: #9f9f9f;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row__certainty .quality-text {
  display: block;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row {
  margin-bottom: 5px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row-container {
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__dich-cell {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__dich-cell .outcome-row__cells {
  height: 100%;
  margin-bottom: 5px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__dich-cell .outcome-row__cells:last-child {
  margin-bottom: 0px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__dich-cell .outcome-row__label {
  text-align: center;
  font-weight: bold;
  margin: 5px 0px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__outcome-name {
  position: relative;
  background-color: #e6e6e6;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__outcome-name:hover .btn-icon-container {
  display: block;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__outcome-name .btn-icon-container {
  display: none;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__narrative-content {
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0px 5px;
  margin-right: 5px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__dich-content,
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__cont-content {
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .what-happens__label {
  text-transform: uppercase;
  font-weight: bold;
  width: 300px;
  padding: 7px 5px;
  margin-right: 5px;
  text-align: right;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .what-happens__content {
  cursor: text;
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
  border: 1px solid #ccc;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .what-happens__content .gdt-editor-container .public-DraftEditor-content {
  min-height: 50px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__cells {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__cells .cell {
  text-align: center;
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #f4f4f4;
  padding: 5px;
  margin-right: 5px;
}
.decision-aids-module .decision-aid-details .outcomes-table .outcome-row .outcome-row__cells .cell .radio-with-label {
  margin: 15px 20px 5px;
  padding-top: 5px;
  border-top: 2px solid #009cca;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.decision-aids-module .decision-aid-details .subchapter-table {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column {
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  word-break: break-all;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column:not(:last-child) {
  margin-right: 5px;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column .header {
  position: relative;
  -webkit-flex: 0 0 90px;
  -ms-flexbox: 0 0 90px;
  flex: 0 0 90px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 60px;
  padding: 10px 15px;
  margin-bottom: 5px;
  text-align: center;
  background-color: #275c74;
  color: #fff;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column .header:hover .column-controls {
  display: block;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column .header .header-text {
  cursor: pointer;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column .header input {
  width: 100%;
  line-height: 38px;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column .header .column-controls {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column .header .column-controls button:before {
  color: #fff;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column .content {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  min-height: 50px;
}
.decision-aids-module .decision-aid-details .subchapter-table .subchapter-table__column .column-illustration {
  position: relative;
  height: 25px;
  margin-top: 5px;
  text-align: right;
}
.decision-aids-module .decision-aid {
  border: 1px solid #009cca;
  margin-bottom: 10px;
  padding: 5px 0;
  font-size: 14px;
}
.decision-aids-module .decision-aid .decision-aid__title {
  font-weight: bold;
  cursor: pointer;
}
.decision-aids-module .decision-aid .edit-block {
  margin-bottom: 5px;
}
.decision-aids-module .decision-aid .labeled-input__input:disabled {
  line-height: 32px;
  border: none;
  background-color: #fff;
  color: inherit;
  opacity: 1;
}
.decision-aids-module .decision-aid .appearance-details {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.decision-aids-module .decision-aid .appearance-details h4 {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.decision-aids-module .decision-aid .appearance-details .decision-aids-appearance-select__option {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
}
.decision-aids-module .decision-aids-appearance-select {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.decision-aids-module .decision-aids-appearance-select__option input[type="radio"] {
  display: none;
}
.decision-aids-module .decision-aids-appearance-select__option input[type="radio"]:checked + label {
  border: 1px solid #009cca;
  background-color: #fffbe3;
}
.decision-aids-module .decision-aids-appearance-select__option label {
  position: relative;
  display: block;
  width: 86px;
  height: 86px;
  padding: 10px;
  border-radius: 14px;
  text-align: center;
}
.decision-aids-module .decision-aids-appearance-select__option label img {
  max-height: 100%;
  max-width: 100%;
}
.subchapter-controls-list hr {
  border-color: #29abe2;
  margin: 10px 0;
}
.subchapter-controls-list .subchapter-control {
  cursor: pointer;
  line-height: 30px;
}
.subchapter-controls-list .subchapter-control:hover {
  font-weight: bold;
}
.decision-aid-title-edit-modal .new-chapter-caption {
  margin-bottom: 10px;
}
.decision-aid-title-edit-modal .title-edit-inputs {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.decision-aid-title-edit-modal .title-edit-inputs .options-select-container {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 10px;
}
.decision-aid-title-edit-modal .title-edit-inputs .validation-container {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
}
.decision-aid-title-edit-modal .title-edit-inputs .validation-container input {
  width: 100%;
  line-height: 33px;
}
.decision-aid-title-edit-modal .delete-control {
  margin: 15px 0 25px;
  text-align: right;
}
.decision-aid-title-edit-modal .delete-control .btn {
  font-size: 16px;
}
.decision-aid-title-edit-modal .delete-control .label-text {
  font-size: 14px;
  font-style: italic;
}
.chapter-icon {
  height: 23px;
}
.chapter-icon-options .option {
  padding-top: 5px;
  padding-bottom: 5px;
}
.decision-aid-subchapter-illustration-modal .illustration-options {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.decision-aid-subchapter-illustration-modal .illustration-options .da-subchapter-illustration-option {
  height: 95px;
  width: 95px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.decision-aid-subchapter-illustration-modal .illustration-options .da-subchapter-illustration-option input[type='radio'] {
  display: none;
}
.decision-aid-subchapter-illustration-modal .illustration-options .da-subchapter-illustration-option input[type="radio"]:checked + label img {
  height: 95px;
  border-radius: 47.5px;
  border: 2px dotted #c92dd4;
  padding: 3px;
}
.decision-aid-subchapter-illustration-modal .illustration-options .da-subchapter-illustration-option img {
  height: 75px;
  transition: height 0.3s ease;
}
.decision-aid-subchapter-illustration-modal .illustration-options .da-subchapter-illustration-option img:hover {
  height: 95px;
}
.decision-aid-subchapter-illustration-modal .position-setting {
  margin-top: 20px;
}
.decision-aid-subchapter-illustration-modal .position-setting > span {
  font-weight: bold;
  margin-right: 10px;
}
.decision-aid-subchapter-illustration-modal .position-setting .options-select-container {
  width: 130px;
}
.decision-aid-subchapter-illustration-modal .buttons-react {
  margin-top: 20px;
}
.decision-aid-new-subchapter-modal input[type="text"],
.decision-aid-subchapter-title-edit-modal input[type="text"] {
  width: 100%;
}
.decision-aid-new-subchapter-modal label,
.decision-aid-subchapter-title-edit-modal label {
  display: block;
  line-height: 20px;
  margin-top: 10px;
}
.decision-aid-new-subchapter-modal label span,
.decision-aid-subchapter-title-edit-modal label span {
  vertical-align: middle;
}
.decision-aid-new-subchapter-modal label input[type="checkbox"],
.decision-aid-subchapter-title-edit-modal label input[type="checkbox"] {
  margin-right: 10px;
  vertical-align: middle;
}
.decision-aid-new-subchapter-modal .options-select-container,
.decision-aid-subchapter-title-edit-modal .options-select-container {
  width: 100%;
  margin-top: 10px;
}
.decision-aid-new-subchapter-modal .buttons-react,
.decision-aid-subchapter-title-edit-modal .buttons-react {
  margin-top: 20px;
}
.outcome-name__edit-modal label {
  font-weight: bold;
}
.outcome-name__edit-modal .outcome-name-row {
  margin-bottom: 15px;
}
.outcome-name__edit-modal .outcome-name-row label {
  display: block;
}
.outcome-name__edit-modal .outcome-name-row input[type='text'] {
  width: 100%;
}
.outcome-name__edit-modal .denominator-row {
  margin-bottom: 15px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.outcome-name__edit-modal .denominator-row .options-select-container {
  margin-left: 5px;
  width: 150px;
}
.decision-aids-header {
  padding: 0 10px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.decision-aids-header .controls {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.decision-aids-header h2 {
  margin: 0;
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
}
.decision-aids-header .btn.create {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  width: 200px;
}
.decision-aids-header .chapter-options button.btn-icon.settings {
  font-size: 25px;
}
.decision-aids-header .chapter-options,
.decision-aids-header .preview-control {
  text-align: right;
  font-size: 20px;
}
.decision-aids-header .chapter-options .label-text,
.decision-aids-header .preview-control .label-text {
  font-size: 16px;
  font-style: italic;
}
.chapter-options-popover button {
  display: block;
}
.chapter-options-popover button:not(:last-child) {
  margin-bottom: 10px;
}
.decision-aid-outcomes-management-modal .outcomes-select {
  position: relative;
  padding-top: 20px;
}
.decision-aid-outcomes-management-modal .outcomes-select .error {
  position: absolute;
  top: 0;
  left: 0;
}
.decision-aid-outcomes-management-modal .outcomes-select .gdt-multi-select__option input {
  transition: border 0.3s ease, box-shadow 0.7s ease;
}
.decision-aid-outcomes-management-modal .outcomes-select.invalid .gdt-multi-select__option input {
  border: 1px solid #f00;
  box-shadow: 0 0 5px #f00;
}
.decision-aid-outcomes-management-modal .buttons-react {
  margin-top: 20px;
}
