@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.todos-calendar-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}
.todos-calendar-wrapper #todos-container {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  margin-right: 20px;
  height: 100%;
  overflow: hidden;
}
.todos-calendar-wrapper #todos-container .separated {
  padding-right: 50px;
}
.todos-calendar-wrapper #todos-container .standard-list {
  display: block;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}
.todos-calendar-wrapper #todos-container li.expanded {
  height: 80px;
}
.todos-calendar-wrapper #todos-container li input.title {
  width: 100%;
  margin-right: 100px !important;
}
.todos-calendar-wrapper #todos-container li a.title.done {
  text-decoration: line-through;
}
.todos-calendar-wrapper #todos-container .caption {
  padding-left: 40px;
}
.todos-calendar-wrapper #todos-container input[type=text] {
  margin-left: 40px;
}
.todos-calendar-wrapper #todos-container input[type=checkbox] {
  position: absolute;
  top: 15px;
  left: 15px;
}
.todos-calendar-wrapper #todos-container .details input[type=text] {
  margin-left: 5px;
}
.todos-calendar-wrapper #calendar-container {
  background: #fff;
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
}
.todos-calendar-wrapper #calendar-container #calendar table.fc-header td {
  border: none;
}
.tasks-table tr td,
.tasks-table tr th {
  border: 1px solid #fff;
  font-size: 14px;
  text-align: left;
  padding: 4px 10px;
}
.tasks-table tr th {
  background: #dadada;
}
.tasks-table tr td {
  background: #f2f2f2;
}
.tasks-table tr td.done {
  text-align: center;
}
.tasks-table tr td.dueDate .date-picker-component {
  position: absolute;
  min-width: 100px;
}
.tasks-table tr td.actions {
  min-width: 60px;
}
.column-sorters {
  font-size: 10px;
}
.column-sorters .btn-icon {
  line-height: 12px;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.column-sorters .active .btn-icon {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.tasks-table-container {
  -webkit-flex-basis: 67%;
  flex-basis: 67%;
  margin-right: 20px;
}
.tasks-table-container table {
  width: 100%;
}
.tasks-table-container table thead {
  width: calc(100% - 10px);
}
.tasks-table-container thead,
.tasks-table-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.tasks-table-container tbody {
  display: block;
  overflow-y: scroll;
  table-layout: fixed;
  max-height: calc(100vh - 135px);
}
.tasks-calendar-container {
  -webkit-flex-basis: 33%;
  flex-basis: 33%;
}
.tasks-modal .extraction-form {
  margin: 10px 0;
}
.tasks-modal .extraction-form .extraction-form__group {
  margin: 0;
}
.tasks-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__name {
  width: 90px;
}
.tasks-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value {
  margin-right: 0;
  max-width: initial;
}
.tasks-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value > div {
  max-width: initial;
}
.tasks-modal .extraction-form.in-modal .extraction-field__value.date .date-picker-component {
  top: 95px;
  position: absolute;
}
