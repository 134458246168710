@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.dialog-box {
  display: none;
  background: #f6f6f6;
  border: 1px solid #bfbfbf;
  padding: 0;
  font-size: 14px;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
  z-index: 1000;
  position: absolute;
  margin: 0;
  min-width: 300px;
  max-width: 770px;
  width: 400px;
}
.dialog-box .dot {
  display: none;
}
.dialog-box .dot.options-dialog {
  display: block;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #f6f6f6;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 19px;
  position: absolute;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
