@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.mda-table-modal .extraction-form,
.mda-topics-field-modal .extraction-form,
.mda-workflow-modal .extraction-form {
  margin: 10px 0;
}
.mda-table-modal .extraction-form .extraction-form__group,
.mda-topics-field-modal .extraction-form .extraction-form__group,
.mda-workflow-modal .extraction-form .extraction-form__group {
  margin: 0;
}
.mda-table-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__name,
.mda-topics-field-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__name,
.mda-workflow-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__name {
  width: 90px;
}
.mda-table-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value,
.mda-topics-field-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value,
.mda-workflow-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value {
  margin-right: 0;
  max-width: initial;
}
.mda-table-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value > div,
.mda-topics-field-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value > div,
.mda-workflow-modal .extraction-form .extraction-form__group .extraction-field .extraction-field__value > div {
  max-width: initial;
}
.mda-table-toolbar {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.mda-table-toolbar .caption-row {
  cursor: pointer;
}
.mda-table-toolbar .collapsed-content-row {
  position: absolute;
  top: 41px;
  margin-left: -10px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  z-index: 100;
  width: 100%;
}
.mda-table-toolbar .collapsed-content-row .questions-list-container {
  padding: 0 5px;
  margin-right: 5px;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
}
.mda-table-toolbar .collapsed-content-row .toolbar-mda-table {
  width: 100%;
  background: #f2f2f2;
  font-size: 14px;
  padding: 0 5px;
}
.mda-table-toolbar .collapsed-content-row .toolbar-mda-table:hover {
  background-color: #f7f7f7;
}
.mda-table-toolbar .collapsed-content-row .toolbar-mda-table .table-name {
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}
.mda-table-toolbar .collapsed-content-row .toolbar-mda-table a {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  color: initial;
}
.mda-table-list .mda-table-list__item .table-name {
  padding-left: 10px;
  cursor: pointer;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mda-table-list .mda-table-list__item .table-name > div {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.mda-table-list .mda-table-list__item .table-name .not-used-flag {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
  display: inline-flex;
  padding: 1px 5px;
  height: 25px;
  line-height: 22px;
  background: #919191;
  color: #fff;
  border-radius: 3px;
}
.mda-table-list .mda-table-list__item .collapsed-content-row .controls {
  margin-bottom: 5px;
}
.mda-table .content.inactive {
  cursor: pointer;
}
.mda-table .content .epiditor--container .apply-cancel-editor-controls {
  display: inline-flex;
  height: 25px;
  direction: row;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.mda-table .content .epiditor--container .apply-cancel-editor-controls button {
  padding: 0 15px;
  border-radius: 3px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
.mda-table .content .epiditor--container .apply-cancel-editor-controls button.cancel-btn {
  background: #d9d9d9;
  color: #134d5e;
  margin-right: 5px;
}
.mda-table .content .epiditor--container .apply-cancel-editor-controls button.cancel-btn:hover {
  background: rgba(0,0,0,0.233);
}
.mda-table .content .epiditor--container .apply-cancel-editor-controls button.apply-btn {
  background: #0089ba;
  color: #fff;
  font-weight: bold;
}
.mda-table .content .epiditor--container .apply-cancel-editor-controls button.apply-btn:hover {
  background: #035b7a;
}
.mda-table .mda-table__topics a {
  cursor: pointer;
}
.mda-workflow-modal .ep-modal__content {
  min-height: 250px;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__select {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__search {
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__search .search-container,
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__search .project-selector {
  -webkit-flex: 0 0 40%;
  -ms-flexbox: 0 0 40%;
  flex: 0 0 40%;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__info {
  margin-bottom: 10px;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__spinner {
  margin-bottom: 10px;
  min-height: 100px;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table {
  display: grid;
  grid-template-columns: 20px 6fr 1fr 2fr;
  margin-bottom: 10px;
  max-height: 292px;
  overflow-y: auto;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__header {
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 40px;
  position: sticky;
  text-align: center;
  top: 0;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__header.name {
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__header.tables {
  cursor: initial;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__header.asc:after {
  content: '▲';
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__header.desc:after {
  content: '▼';
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__topic {
  background: #f2f2f2;
  cursor: pointer;
  display: grid;
  grid: inherit;
  grid-column: 1/-1;
  grid-gap: inherit;
  height: 40px;
  margin-top: 2px;
  transition: background 0.2s ease-in-out;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__topic > div {
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__topic > div.name {
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__topic > div.name > div {
  padding: 5px 0 5px 5px !important;
}
.mda-workflow-modal .mda-workflow-modal__content .content__topics .topics__table .table__topic:hover {
  background: #f7f7f7;
}
.mda-assign-member-modal .members .checkbox {
  width: 20px;
}
.mda-assign-member-modal .members .member-row {
  padding: 7px 10px;
  background: #f2f2f2;
}
.mda-assign-member-modal .members .member-row:hover {
  background: #f7f7f7;
}
.member-assign-row .member-assign-row__unassign-member {
  cursor: pointer;
  margin-left: 4px;
  margin-top: -5px;
}
.verification-table {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  table-layout: fixed;
  border-collapse: collapse;
}
.verification-table thead tr {
  display: block;
}
.verification-table tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.verification-table tbody tr {
  background: #f2f2f2;
}
.verification-table tbody tr:hover {
  background: #f7f7f7;
}
.verification-table th,
.verification-table td {
  padding: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: calc(100vw * 1 / 3);
}
.verification-table th:first-child,
.verification-table td:first-child {
  width: calc(100vw * 2 / 3);
}
.mda-add-code-modal .mda-add-code-modal__codes-list {
  position: relative;
  -webkit-flex: 1 0 500px;
  -ms-flexbox: 1 0 500px;
  flex: 1 0 500px;
  min-width: 500px;
  max-width: 500px;
  margin: 0px 10px;
}
.mda-add-code-modal .mda-add-code-modal__codes-list .codes-list--header {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 15px 0 5px;
}
.mda-add-code-modal .mda-add-code-modal__codes-list .code-types-selector {
  font-size: 12px;
  background: #fff;
  border: #eaeaea;
  width: 85px;
  padding: 0;
}
.mda-add-code-modal .mda-add-code-modal__codes-list .codes-list--scrollable {
  min-height: 450px;
  max-height: 500px;
  overflow: auto;
  margin: 10px 0;
  padding: 0;
  list-style-type: none;
}
.mda-add-code-modal .mda-add-code-modal__codes-list .codes-list--scrollable table tbody tr {
  border-bottom: 2px solid #fff;
  background: #f2f2f2;
}
.mda-add-code-modal .mda-add-code-modal__codes-list .codes-list--scrollable table tbody tr:hover {
  background: #f7f7f7;
}
.mda-add-code-modal .mda-add-code-modal__codes-list .codes-list--scrollable table tbody tr.included {
  background: #f7bf9c;
}
.mda-add-code-modal .mda-add-code-modal__codes-list .code-type,
.mda-add-code-modal .mda-add-code-modal__codes-list .code-value {
  white-space: nowrap;
  width: 85px;
}
.mda-add-code-modal .mda-add-code-modal__codes-list .highlight {
  background: #fad468;
}
