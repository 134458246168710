@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
div.hopscotch-bubble {
  color: #2d3236;
  background: #fff;
  border: 1px solid #b3b4b5;
  z-index: 99999 !important;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.3);
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
div.hopscotch-bubble .hopscotch-content {
  font-size: 14px;
  font-family: 'PTSans', sans-serif;
}
div.hopscotch-bubble .hopscotch-content ul.icons {
  padding: 0;
  margin: 0;
  list-style: none;
}
div.hopscotch-bubble .hopscotch-content ul.icons li {
  margin-top: 10px;
  display: table;
}
div.hopscotch-bubble .hopscotch-content ul.icons li span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
div.hopscotch-bubble .hopscotch-content ul.icons li span.icon {
  display: inline-block;
  margin-right: 7px;
}
div.hopscotch-bubble .hopscotch-content ul.icons li span.icon.settings:before {
  font-family: 'gdt';
  content: '\e001';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
div.hopscotch-bubble .hopscotch-content ul.icons li span.icon.settings * {
  margin-left: 0.5rem;
}
div.hopscotch-bubble .hopscotch-content ul.icons li span.icon.feedback:before {
  font-family: 'gdt';
  content: '\e002';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
div.hopscotch-bubble .hopscotch-content ul.icons li span.icon.feedback * {
  margin-left: 0.5rem;
}
div.hopscotch-bubble .hopscotch-content ul.icons li span.icon.help:before {
  font-family: 'gdt';
  content: '\e003';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
div.hopscotch-bubble .hopscotch-content ul.icons li span.icon.help * {
  margin-left: 0.5rem;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container {
  width: 20px !important;
  height: 20px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container .hopscotch-bubble-arrow,
div.hopscotch-bubble .hopscotch-bubble-arrow-container .hopscotch-bubble-arrow-border {
  top: auto !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  position: absolute !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up {
  top: -10px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow,
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom-width: 9px !important;
  border-left-width: 9px !important;
  border-right-width: 9px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom-color: #fff;
  bottom: 8px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom-color: #b3b4b5;
  bottom: 10px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right {
  right: -18px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow,
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left-width: 9px !important;
  border-bottom-width: 9px !important;
  border-top-width: 9px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left-color: #fff;
  right: 10px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left-color: #b3b4b5;
  right: 8px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down {
  bottom: -10px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow,
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top-width: 9px !important;
  border-left-width: 9px !important;
  border-right-width: 9px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top-color: #fff;
  top: 8px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top-color: #b3b4b5;
  top: 10px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left {
  left: -18px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow,
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-bottom-width: 9px !important;
  border-right-width: 9px !important;
  border-top-width: 9px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  border-right-color: #fff;
  left: 10px !important;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right-color: #b3b4b5;
  left: 8px !important;
}
div.hopscotch-bubble .hopscotch-bubble-content {
  padding: 10px 15px;
}
div.hopscotch-bubble .hopscotch-actions {
  padding: 10px 15px;
  background-color: #eaeaea;
}
div.hopscotch-bubble .hopscotch-actions .col-3 {
  font-weight: bold;
}
div.hopscotch-bubble .hopscotch-actions .col-9 {
  text-align: right;
}
div.hopscotch-bubble .hopscotch-actions,
div.hopscotch-bubble .hopscotch-actions button {
  font-size: 14px;
  line-height: 32px;
}
