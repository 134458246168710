@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.box.compound-edit-box,
.box.selection-list-box {
  padding: 20px;
  border: 1px solid #29abe2;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #fff;
  -webkit-box-shadow: -1px 0px 4px rgba(0,0,0,0.5);
  box-shadow: -1px 0px 4px rgba(0,0,0,0.5);
}
.box.compound-edit-box .content p,
.box.selection-list-box .content p {
  margin: 0;
}
.box.compound-edit-box .content .compound-edit {
  padding: 0;
}
.box.compound-edit-box .content .edit-input-block {
  margin: 10px 0 0;
}
.box.compound-edit-box .content .edit-input-block.active {
  background: none;
}
.box.compound-edit-box .content .edit-input-block.active label {
  background-color: #ddeaf9;
}
.box.compound-edit-box .content .edit-input-block label {
  margin: 0;
  padding: 0 5px;
}
.box.selection-list-box .content .edit-options-list .edit-option {
  background: none;
  padding: 0 5px;
}
.box.selection-list-box .content .edit-options-list .edit-option:hover,
.box.selection-list-box .content .edit-options-list .edit-option.active:not(.clear-option) {
  background-color: #ddeaf9;
}
.box.selection-list-box .content .edit-options-list .edit-option .compass-icon {
  color: #3883a5;
  font-size: 20px;
}
.box.selection-list-box .content .edit-options-list .edit-option .compass-icon:before {
  font-family: 'gdt';
  content: '\e090';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.box.selection-list-box .content .edit-options-list .edit-option .compass-icon * {
  margin-left: 0.5rem;
}
.box.selection-list-box .content .edit-options-list .clear-option {
  margin: 10px 0 0;
  background: #009cca;
}
.box.selection-list-box .content .edit-options-list hr {
  margin: 5px 0;
  border: 1px solid #009cca;
  border-botom: none;
}
.box .warning {
  background-color: #f7bf9c;
}
.box .content .edit-options-list .inner-options-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.box .content .edit-options-list .inner-options-list li.inactive-option {
  color: #909293;
  padding: 5px 7px;
  width: 100%;
}
.box .content .edit-options-list .inner-options-list li.edit-option {
  padding: 5px 17px;
}
.box .content .edit-options-list .inner-options-list li.edit-option.btn-popup {
  padding: 0;
}
.box .content .compound-edit {
  padding: 0 10px;
}
.box .content .compound-edit.noOfParticipantsStudiesDiagnostic .context-help:before {
  right: 10px;
}
.box .content .compound-edit ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.box .content .compound-edit textarea {
  height: 150px;
  background-color: #fff;
}
.box .content .compound-edit textarea.short {
  height: 60px;
}
.box .content .compound-edit input[type="text"] {
  font-size: 14px;
  background-color: #fff;
}
.box .content .compound-edit input.short {
  width: 35px;
}
.box .content .compound-edit .text-with-limit {
  display: block;
}
.box .content .compound-edit label.attr-name,
.box .content .compound-edit .edit-input-block label {
  position: relative;
}
.box .content .dich-effect-measure .selection-list-value {
  width: 150px;
  display: inline-block;
}
.box .content .short-select .selection-list-value {
  max-width: 75px;
}
.box .content .field {
  float: left;
  margin-right: 5px;
}
.box .content .number-input {
  width: 100px;
}
.box .content .sof-quality-info {
  display: inline-block;
  border-top: 1px solid #bfbfbf;
  padding: 2px 8px;
}
.input-error {
  border: 2px solid #f00;
}
.select-input-container.non-event .selection-list-value {
  max-width: 100%;
}
.select-input-container.non-event input#non-event {
  width: 100%;
}
.selection-list-value {
  display: block;
  max-width: 145px;
  width: 100%;
  padding-left: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  line-height: 30px;
  height: 30px;
  white-space: nowrap;
  vertical-align: bottom;
}
.selection-list-value .selection-text {
  display: block;
  padding-right: 25px;
  overflow: hidden;
  text-transform: lowercase;
}
.selection-list-value .selection-text.uppercase {
  text-transform: uppercase;
}
.selection-list-value .selection-text.no-transform {
  text-transform: none;
}
.selection-list-value:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  float: right;
  margin-right: 5px;
  font-size: 20px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
}
.selection-list-value.focused {
  border: 1px #9bbde0 solid;
}
.selection-list-value[disabled] {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.narrative-desc-react-view {
  min-width: 300px;
  background: #f6f6f6;
  cursor: text;
}
