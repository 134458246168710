@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.presentations-toolbar,
.evidence-syntheses-toolbar {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.presentations-toolbar #es-toolbar-questions,
.evidence-syntheses-toolbar #es-toolbar-questions {
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.presentations-toolbar #es-toolbar-questions .questions,
.evidence-syntheses-toolbar #es-toolbar-questions .questions {
  padding: 0;
  width: 100%;
}
.presentations-toolbar {
  padding-right: 5px;
}
#evidence-table-toolbar-container,
#toolbar-container .evidence-syntheses-toolbar {
  padding-right: 5px;
}
#evidence-table-toolbar-container menu[type="toolbar"],
#toolbar-container .evidence-syntheses-toolbar menu[type="toolbar"] {
  margin-left: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
#evidence-table-toolbar-container menu[type="toolbar"] .toggle-footnotes-pane,
#toolbar-container .evidence-syntheses-toolbar menu[type="toolbar"] .toggle-footnotes-pane {
  width: auto;
  float: none;
  margin-left: 0;
}
#evidence-table-toolbar-container menu[type="toolbar"].context-buttons button,
#toolbar-container .evidence-syntheses-toolbar menu[type="toolbar"].context-buttons button {
  width: auto;
}
#evidence-table-toolbar-container menu[type="toolbar"].context-buttons li.footnotes-button-container,
#toolbar-container .evidence-syntheses-toolbar menu[type="toolbar"].context-buttons li.footnotes-button-container {
  display: inline-block;
  position: relative;
}
#evidence-table-toolbar-container menu[type="toolbar"].context-buttons li.footnotes-button-container .disabled-tooltip,
#toolbar-container .evidence-syntheses-toolbar menu[type="toolbar"].context-buttons li.footnotes-button-container .disabled-tooltip {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#evidence-table-toolbar-container .revman-logo,
#toolbar-container .evidence-syntheses-toolbar .revman-logo {
  display: inline-block;
  float: left;
  width: 95.2px;
  height: 30.599999999999998px;
  margin-top: 2.700000000000001px;
  background: url("../images/svg/revman-web-logo.svg");
  background-repeat: no-repeat;
  -webkit-background-size: 95.2px 30.599999999999998px;
  -moz-background-size: 95.2px 30.599999999999998px;
  background-size: 95.2px 30.599999999999998px;
}
#evidence-table-toolbar-container .revman-sync-status,
#toolbar-container .evidence-syntheses-toolbar .revman-sync-status {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 74px;
  height: 20px;
  margin: 8px 10px;
  background: #f3f3f3;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 4px;
}
#evidence-table-toolbar-container .revman-sync-status .revman-sync-status__image,
#toolbar-container .evidence-syntheses-toolbar .revman-sync-status .revman-sync-status__image {
  display: block;
  width: 15px;
  height: 11px;
  background-position: center;
}
#evidence-table-toolbar-container .revman-sync-status .revman-sync-status__text,
#toolbar-container .evidence-syntheses-toolbar .revman-sync-status .revman-sync-status__text {
  font-size: 13px;
  line-height: 13px;
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  text-align: center;
}
#evidence-table-toolbar-container .revman-sync-status.revman-sync-status__success .revman-sync-status__image,
#toolbar-container .evidence-syntheses-toolbar .revman-sync-status.revman-sync-status__success .revman-sync-status__image {
  background: url("../images/revman_status_success.svg");
}
#evidence-table-toolbar-container .revman-sync-status.revman-sync-status__error .revman-sync-status__image,
#toolbar-container .evidence-syntheses-toolbar .revman-sync-status.revman-sync-status__error .revman-sync-status__image {
  background: url("../images/revman_status_error.svg");
  width: 9px;
}
#evidence-table-toolbar-container .revman-sync-status.revman-sync-status__syncing,
#toolbar-container .evidence-syntheses-toolbar .revman-sync-status.revman-sync-status__syncing {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
#evidence-table-toolbar-container .revman-sync-status.revman-sync-status__syncing .revman-sync-status__image,
#toolbar-container .evidence-syntheses-toolbar .revman-sync-status.revman-sync-status__syncing .revman-sync-status__image {
  background: url("../images/revman_status_syncing.svg");
  -webkit-animation: revman-rotating 2s linear infinite;
  -moz-animation: revman-rotating 2s linear infinite;
  -ms-animation: revman-rotating 2s linear infinite;
  -o-animation: revman-rotating 2s linear infinite;
  -webkit-animation: revman-rotating 2s linear infinite;
  -moz-animation: revman-rotating 2s linear infinite;
  -o-animation: revman-rotating 2s linear infinite;
  -ms-animation: revman-rotating 2s linear infinite;
  animation: revman-rotating 2s linear infinite;
}
@-webkit-keyframes revman-rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.create-questions-container {
  margin-top: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.create-questions-container button.btn {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-right: 5px;
}
.create-questions-container button.btn:last-child {
  margin-right: 0px;
}
@-moz-keyframes revman-rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes revman-rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes revman-rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes revman-rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
