@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.checklist-container {
  height: 100%;
}
.checklist-container .help-text {
  font-size: 14px;
  font-style: italic;
  color: #8c8c8c;
}
.checklist-container .checklist-header {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -mox-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.checklist-container .checklist-header h3 {
  line-height: 36px;
  margin: 0;
  margin-bottom: 25px;
  font-size: 16px;
  color: #2d3236;
  font-weight: bold;
}
.checklist-container .checklist-topics {
  max-height: calc(100% - 100px);
  overflow: auto;
  counter-reset: topics;
}
.checklist-container .checklist-topics .checklist-topic {
  border: 1px solid #009cca;
}
.checklist-container .checklist-topics .checklist-topic:not(:first-child) {
  margin-top: 18px;
}
.checklist-container .checklist-topics .checklist-topic .caption-row {
  min-height: 50px;
}
.checklist-container .checklist-topics .checklist-topic .caption-row:hover {
  cursor: pointer;
}
.checklist-container .checklist-topics .checklist-topic .topic-name {
  counter-increment: topics;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
}
.checklist-container .checklist-topics .checklist-topic .topic-name:before {
  display: inline-block;
  width: 40px;
  text-align: center;
  content: counter(topics) ".";
}
.checklist-container .checklist-topics .checklist-topic .topic-steps {
  counter-reset: topicSteps;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step {
  position: relative;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 2px;
  counter-increment: topicSteps;
  background-color: #f2f2f2;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step:before {
  position: absolute;
  display: block;
  width: 40px;
  height: calc(100% - 15px);
  border-right: 2px solid #fff;
  padding-top: 15px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  content: counter(topicSteps, decimal-leading-zero);
  text-align: center;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step > div {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  padding-left: 40px;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details {
  padding: 15px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  font-size: 14px;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-title {
  margin-bottom: 15px;
  font-weight: bold;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-responsible-person {
  margin-bottom: 15px;
  font-style: italic;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-text {
  margin-bottom: 15px;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-text a {
  color: #00f;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-sources {
  text-align: right;
  margin-bottom: 15px;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-tools {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #ddeaf9;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-tools > div {
  -webkit-flex: 1 1 50%;
  -ms-flexbox: 1 1 50%;
  flex: 1 1 50%;
  padding: 15px;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-tools > div p {
  margin: 0;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-tools > div a,
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-tools > div a:visited,
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-tools > div a:hover,
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-details .topic-step-tools > div a:active {
  text-decoration: none;
  color: #009cca;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-actions {
  margin: 2px;
  -webkit-flex: 0 0 160px;
  -ms-flexbox: 0 0 160px;
  flex: 0 0 160px;
  background-color: #fff;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-actions .topic-step-comment {
  line-height: 56px;
  text-align: center;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-actions .topic-step-comment .help-text {
  outline: none;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-actions .topic-step-status {
  padding: 15px;
  border-top: 2px solid #f2f2f2;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-actions .topic-step-status label {
  display: block;
}
.checklist-container .checklist-topics .checklist-topic .topic-steps .topic-step .topic-step-actions .topic-step-status label input {
  margin-right: 10px;
}
.step-sources-modal {
  top: 20vh;
  width: 750px;
  overflow: auto;
}
.step-sources-modal ol {
  max-height: 60vh;
  margin-top: 30px;
  padding-left: 25px;
  overflow: auto;
}
.step-sources-modal ol li {
  word-break: break-word;
}
.step-commment-edit {
  width: 250px;
  padding: 5px;
  border: 1px solid #009cca;
  background-color: #fff;
}
.step-commment-edit textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  outline: none;
}
.process-diagram-modal {
  width: 60%;
  top: 8%;
}
.process-diagram-modal > div {
  text-align: center;
}
.process-diagram-modal > div img {
  width: 100%;
}
