@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.steps-container .buttons-react.not-wrapped {
  text-align: center;
}
.steps-container .buttons-react.not-wrapped button {
  display: inline-block !important;
  width: auto !important;
  min-width: 150px;
  margin: 0px 10px;
}
.steps-container .steps-indicator {
  margin: 20px auto;
}
.steps-container .steps-indicator .labels {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.steps-container .steps-indicator .labels .container {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  position: relative;
  text-align: center;
}
.steps-container .steps-indicator .labels .container .step-content {
  width: 100%;
  text-align: center;
}
.steps-container .steps-indicator .labels .container .step-content .step-label {
  white-space: nowrap;
  margin: 0 10px;
}
.steps-container .steps-indicator .labels .container .step-content .indicator {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.steps-container .steps-indicator .labels .container .step-content .indicator .space-left,
.steps-container .steps-indicator .labels .container .step-content .indicator .space-right {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  height: 2px;
  top: 9px;
  position: relative;
  background-color: #d2d6da;
}
.steps-container .steps-indicator .labels .container .step-content .indicator .space-left {
  margin-right: 5px;
}
.steps-container .steps-indicator .labels .container .step-content .indicator .space-right {
  margin-left: 5px;
}
.steps-container .steps-indicator .labels .container:first-child .step-content .space-left {
  background-color: #fff;
}
.steps-container .steps-indicator .labels .container:last-child .step-content .space-right {
  background-color: #fff;
}
.steps-container .steps-indicator .labels .step {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff url("../images/svg/step-empty.svg") no-repeat center center;
}
.steps-container .steps-indicator .labels .step.filled {
  background: #fff url("../images/svg/step-filled.svg") no-repeat center center;
}
