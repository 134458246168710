@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.quality-indicators {
  font-size: 14px;
  overflow: auto;
  height: calc(100vh - 100px);
}
.quality-indicators input[type="text"] {
  font-size: 14px;
}
.quality-indicators .quality-indicators__table {
  height: calc(100vh - 150px);
  overflow: auto;
}
table.quality-indicators-table td,
table.quality-indicators-table th {
  padding: 5px;
}
table.quality-indicators-table td > div,
table.quality-indicators-table th > div {
  padding: 5px;
}
table.quality-indicators-table thead {
  background: #dedede;
}
table.quality-indicators-table thead th {
  padding: 10px 0px;
  text-align: center;
  background: #3783a5;
  color: #fff;
}
table.quality-indicators-table .quality-indicators__name {
  padding-top: 20px;
}
table.quality-indicators-table .quality-indicator-row {
  background: #f2f2f2;
}
table.quality-indicators-table .quality-indicator-row .text-container {
  white-space: pre-wrap;
}
table.quality-indicators-table .quality-indicator-edit-row {
  background: #d1deec;
}
table.quality-indicators-table .quality-indicator-edit-row .options-select-container,
table.quality-indicators-table .quality-indicator-edit-row input {
  background: #fff;
  width: 100%;
}
table.quality-indicators-table .quality-indicator-edit-row .text-area-container,
table.quality-indicators-table .quality-indicator-edit-row textarea {
  min-height: 100px;
}
.quality-indicators-monitoring-and-evaluation table.quality-indicators-table th.empty {
  background: #fff;
}
.quality-indicators-monitoring-and-evaluation table.quality-indicators-table td.label-cell {
  line-height: 36px;
  width: 250px;
  background: #e6e6e6;
}
.quality-indicators-monitoring-and-evaluation table.quality-indicators-table td.value-cell {
  background: #f4f4f4;
}
.quality-indicators-monitoring-and-evaluation table.quality-indicators-table td.value-cell .options-select-container {
  width: 100%;
  text-align: center;
}
.quality-indicators-monitoring-and-evaluation table.quality-indicators-table td.value-cell .options-select-container .select-input {
  border: none;
}
.quality-indicators-monitoring-and-evaluation table.quality-indicators-table td.value-cell .options-select-container__options {
  width: 100%;
}
.quality-indicators-table .quality-indicator-row .gdt-editor-container.readonly {
  border: none;
}
.quality-indicators .conclusions-section .gdt-editor-container.readonly {
  border: none;
}
.qi-select-option {
  width: 100%;
}
.quality-indicators-checkbox {
  padding-left: 20px;
  background: url("../images/svg/empty.svg") no-repeat 0 2px;
}
.quality-indicators-checkbox.quality-indicators-checkbox--checked {
  background: url("../images/svg/check.svg") no-repeat 0 2px;
}
