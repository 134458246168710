@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.directness-box .title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
}
.directness-box .directness-table {
  font-size: 14px;
  background-color: #fff;
}
.directness-box .directness-table .input-block {
  display: inline-block;
  text-align: center;
  margin: 0px 5px;
}
.directness-box .directness-table .input-block input[type="radio"] {
  margin: 0px auto;
}
.directness-box .directness-table .domain {
  width: 29%;
}
.directness-box .directness-table .description {
  width: 42%;
}
.directness-box .directness-table .judgement {
  width: 29%;
}
.directness-box .directness-table .grey-bg {
  background-color: #dedede;
}
.directness-box .directness-table .no-border {
  border: none;
}
.directness-box .directness-table thead {
  border-bottom: 2px solid #999;
}
.directness-box .directness-table thead td {
  vertical-align: middle;
}
.directness-box .directness-table td {
  padding: 6px;
}
.directness-box .directness-table td.inputs-cell {
  vertical-align: middle;
}
.directness-box .directness-table tr.final-judgement .input-block {
  margin: 0px 25px;
}
.directness-box .directness-table tr.final-judgement .input-block:last-child {
  margin-right: 38px;
}
.directness-box .directness-table .editable {
  height: 70px;
}
.directness-box .directness-table .editable textarea {
  width: 100%;
  height: 100%;
  padding: 0;
}
