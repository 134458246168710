@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
table.diagnostic-pooled-values {
  width: 400px;
}
table.diagnostic-pooled-values.comparatorTest {
  width: 800px;
}
table.diagnostic-pooled-values tr {
  height: 20px;
}
table.diagnostic-pooled-values td {
  background-color: #e9e9e9;
  line-height: 20px;
  padding: 2px 3px;
}
table.diagnostic-pooled-values .label-cell {
  cursor: default;
  text-transform: capitalize;
}
table.diagnostic-pooled-values .value-cell {
  background-color: #f2f2f2;
  cursor: pointer;
}
table.diagnostic-pooled-values .value-cell:hover {
  background-color: #ddeaf9;
}
table.diagnostic-pooled-values .dx-tests td {
  cursor: default;
  background-color: #3783a5;
  color: #fff;
}
