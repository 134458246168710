@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.comparison-questions-list {
  height: 100%;
}
.comparison-questions-list > div:first-child {
  max-height: calc(100% - 50px);
  overflow: auto;
}
.comparison-questions-list .spinner-el {
  height: 40px;
}
.questions-view {
  padding-bottom: 20px;
  background-color: #fff;
  min-width: 950px;
  counter-reset: questionItem;
}
.questions-view li.expanded {
  font-size: 14px;
  padding-top: 5px;
  height: 385px;
}
.questions-view li.expanded.diagnostic-index {
  height: 643px;
}
.questions-view li.expanded.diagnostic-comparator {
  height: 704px;
}
.questions-view li.expanded .separated {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  position: relative;
  width: calc(100% - 50px);
  padding: 10px 49px 0 20px;
  font-size: 14px;
  line-height: 30px;
}
.questions-view li.expanded .separated.diagnostic {
  white-space: nowrap;
}
.questions-view li.expanded .separated input,
.questions-view li.expanded .separated select {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
}
.questions-view li.expanded .separated select.prognostic {
  width: 80px;
}
.questions-view li.expanded .separated select.prognostic.default {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.questions-view li.expanded .separated .with-footnotes,
.questions-view li.expanded .separated .with-annotation {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  margin: 0 5px;
}
.questions-view li.expanded .separated .with-footnotes input,
.questions-view li.expanded .separated .with-annotation input {
  width: 100%;
}
.questions-view li.expanded .separated .annotation {
  line-height: 15px;
  font-size: 12px;
}
.questions-view li.expanded .separated #populationOther {
  margin-left: 0;
}
.questions-view li.expanded .separated select {
  margin-left: 5px;
}
.questions-view li.expanded .separated #population {
  margin-right: 5px;
}
.questions-view li.expanded .separated span.input-with-button input {
  margin: 0;
  width: calc(100% - 32px);
}
.questions-view li.expanded .separated .sup {
  position: absolute;
  display: block;
  right: 0px;
  top: -18px;
}
.questions-view li.expanded .add-comparator-test,
.questions-view li.expanded .remove-comparator-test {
  position: relative;
  top: 0px;
  right: 5px;
}
.questions-view li.expanded .add-comparator-test:before,
.questions-view li.expanded .remove-comparator-test:before {
  line-height: normal;
}
.questions-view li.expanded .details {
  padding-right: 95px;
}
.questions-view li.expanded .details fieldset {
  position: relative;
  margin: 25px 0 10px 0;
  line-height: 0px;
}
.questions-view li.expanded .details fieldset button {
  border: none;
}
.questions-view li.expanded .details fieldset > label {
  position: relative;
  display: inline-block;
  line-height: 16px;
  padding-left: 30px;
}
.questions-view li.expanded .details fieldset > label .question-mark-sign {
  border-width: 0;
  position: absolute;
  top: -1px;
  left: 10px;
  font-size: 16px;
}
.questions-view li.expanded .details fieldset > label .question-mark-sign:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.questions-view li.expanded .details fieldset > label .question-mark-sign * {
  margin-left: 0.5rem;
}
.questions-view li.expanded .details fieldset textarea {
  padding: 8px 10px !important;
  line-height: 16px;
  height: 50px;
}
.questions-view li.expanded .details fieldset textarea[disabled] {
  background-color: transparent;
}
.questions-view li.expanded .details fieldset .sup {
  position: absolute;
  display: block;
  right: 5px;
  top: -3px;
}
.questions-view li.expanded .details fieldset .sof-title {
  position: relative;
  display: inline-block;
}
.questions-view li.expanded .details fieldset .sof-title textarea {
  height: 85px;
  padding-bottom: 30px !important;
}
.questions-view li.expanded .details fieldset .sof-title button.auto-manual-switch {
  height: auto;
  position: absolute;
  padding: 6px;
  line-height: 16px;
  right: 10px;
  bottom: 10px;
  z-index: 100;
  width: 160px;
}
.questions-view .last-update {
  line-height: 16px;
  font-size: 14px;
  padding-right: 5px;
}
.questions-view .add-question-buttons li {
  width: 50%;
}
.questions-view .add-management-question span,
.questions-view .add-diagnostic-question span {
  background: url("../images/new-project.png") left center no-repeat;
  padding-left: 30px;
}
.questions-view .import-questions span {
  background: url("../images/import-project.png") left center no-repeat;
  padding-left: 30px;
}
.questions-view .disabled-sof-title {
  position: relative;
}
.questions-view .disabled-sof-title div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.questions-view ul.standard-list li.questions-wo-group {
  position: static;
  height: auto;
  padding: 0;
  background: none;
  border: none;
}
.questions-view ul.standard-list li.questions-wo-group ul.grouped-questions {
  margin: 0;
}
.questions-view ul.standard-list li.question-group {
  height: auto;
  min-height: 50px;
  margin-bottom: 40px;
  background: #fff;
  border: 1px solid #009cca;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view {
  padding-right: 10px;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view .caption {
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  padding-left: 0.5em;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view button {
  border-right: none;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view input {
  font-size: 14px;
  margin-top: 10px;
  width: 90%;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view.expanded {
  height: 50px;
}
.questions-view ul.standard-list ul.grouped-questions {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0 10px 10px 0;
  min-height: 10px;
}
.questions-view ul.standard-list ul.grouped-questions li {
  padding-left: 50px;
}
.questions-view ul.standard-list ul.grouped-questions li:before {
  line-height: 40px;
  content: counter(questionItem);
  counter-increment: questionItem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
  border-right: 2px solid #fff;
  text-align: center;
}
.questions-view ul.standard-list li.active ul.grouped-questions.empty {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f3fcff;
  border: 1px solid #009cca;
  border-style: dashed;
}
.questions-view ul.standard-list li.active ul.grouped-questions.empty.about-to-drop {
  background: #d2dfed;
}
.questions-view .new-question-group-container {
  background: #fff;
  padding-top: 10px;
  position: relative;
  cursor: pointer;
  margin-top: -50px;
  z-index: 103;
}
.questions-view .new-question-group-container #new-question-group {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f3fcff;
  border: 1px solid #009cca;
  border-style: dashed;
}
.questions-view .new-question-group-container #new-question-group.about-to-drop {
  background: #d2dfed;
}
.questions-view .questions-container .question-footnotes-attr {
  display: none;
}
.questions-view .questions-container.drop-area-visible ul.standard-list {
  margin-bottom: 50px;
}
.question-tables-navigation a {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 100%;
  padding: 0 16px;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.question-tables-navigation a,
.question-tables-navigation a:hover,
.question-tables-navigation a:visited,
.question-tables-navigation a:active {
  text-decoration: none;
  color: currentColor;
}
.question-navigation-with-progress {
  width: 100%;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.question-navigation-with-progress .question-text {
  width: 100%;
}
.question-navigation-with-progress.locked {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.question-progress {
  cursor: pointer;
  margin-top: 5px;
  position: relative;
}
.question-progress .question-progress__rects {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}
.question-progress__rects__rect {
  width: 23px;
  height: 23px;
  color: #000;
  background: #fff;
  border: 1px solid #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  margin: 0px 3px;
}
.question-progress__rects__rect:hover {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.module-progress__box {
  min-width: 100px;
  background: #eaeaea;
}
.mda-claim-button {
  margin-left: 5px;
  min-width: 100px;
}
.module-progress__box--done-d-r,
.question-progress-form__box--done-d-r,
.question-progress__rects__rect--done-d-r,
.module-progress__box--copy-editing,
.question-progress-form__box--copy-editing,
.question-progress__rects__rect--copy-editing {
  background: #94b7c8;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #94b7c8;
}
.mda-claim-button,
.module-progress__box--done,
.question-progress-form__box--done,
.question-progress__rects__rect--done {
  background: #0089ba;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #0089ba;
}
.module-progress__box--in-progress,
.question-progress-form__box--in-progress,
.question-progress__rects__rect--in-progress,
.module-progress__box--mar,
.question-progress-form__box--mar,
.question-progress__rects__rect--mar,
.module-progress__box--duration-review1,
.question-progress-form__box--duration-review1,
.question-progress__rects__rect--duration-review1,
.module-progress__box--duration-review2,
.question-progress-form__box--duration-review2,
.question-progress__rects__rect--duration-review2,
.module-progress__box--duration-review3,
.question-progress-form__box--duration-review3,
.question-progress__rects__rect--duration-review3 {
  background: #ffba6c;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #ffba6c;
}
.module-progress__box--in-review,
.question-progress-form__box--in-review,
.question-progress__rects__rect--in-review {
  background: #ff5186;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #ff5186;
}
.module-progress__box--medical-coding,
.question-progress-form__box--medical-coding,
.question-progress__rects__rect--medical-coding,
.module-progress__box--duration-editing,
.question-progress-form__box--duration-editing,
.question-progress__rects__rect--duration-editing,
.module-progress__box--medical-editing,
.question-progress-form__box--medical-editing,
.question-progress__rects__rect--medical-editing {
  background: #567bc4;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #567bc4;
}
.module-progress__box--,
.question-progress-form__box--,
.question-progress__rects__rect--,
.module-progress__box--inactive,
.question-progress-form__box--inactive,
.question-progress__rects__rect--inactive {
  background: #eaeaea;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #eaeaea;
}
.module-progress__box--final-duration-check,
.question-progress-form__box--final-duration-check,
.question-progress__rects__rect--final-duration-check,
.module-progress__box--final-editing-check,
.question-progress-form__box--final-editing-check,
.question-progress__rects__rect--final-editing-check {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #a356c4;
  background: #a356c4;
}
.module-progress__box--new,
.question-progress-form__box--new,
.question-progress__rects__rect--new {
  background: #eaeaea;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #eaeaea;
}
.module-progress__box--edit-setup,
.question-progress-form__box--edit-setup,
.question-progress__rects__rect--edit-setup,
.module-progress__box--duration-setup,
.question-progress-form__box--duration-setup,
.question-progress__rects__rect--duration-setup,
.module-progress__box--completed,
.question-progress-form__box--completed,
.question-progress__rects__rect--completed {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #56c47c;
  background: #56c47c;
}
.status-selector {
  background: #fff;
  border: none;
  width: 147px;
  font-size: 14px;
}
.status-selector .options-select-container__options {
  width: 147px;
}
.status-selector .options-select-container__options .option {
  width: 147px;
  font-size: 14px;
  padding: 2px 10px;
}
.status-selector .options-select-container__options .option:hover.new {
  color: #000;
}
.status-selector .options-select-container__options .option:hover.in-review,
.status-selector .options-select-container__options .option:hover.in-progress,
.status-selector .options-select-container__options .option:hover.done {
  color: #fff;
}
.status-selector .options-select-container__options .option:hover.new {
  background: #fff;
}
.status-selector .options-select-container__options .option:hover.in-review {
  background: #ff5186;
}
.status-selector .options-select-container__options .option:hover.in-progress {
  background: #ffba6c;
}
.status-selector .options-select-container__options .option:hover.done {
  background: #0089ba;
}
.question-progress-form {
  background: #fff;
  padding: 10px;
  width: 220px;
  border: 1px solid #009cca;
}
.question-progress-form .options-select-container {
  height: 25px;
  width: 220px;
}
.question-progress-form .options-select-container .select-value {
  font-size: 14px;
}
.question-progress-form .options-select-container .select-input.new .select-sign {
  color: #000;
}
.question-progress-form .options-select-container .select-input.in-review .select-sign,
.question-progress-form .options-select-container .select-input.in-progress .select-sign,
.question-progress-form .options-select-container .select-input.done .select-sign {
  color: #fff;
}
.question-progress-form .options-select-container .select-input.new {
  color: #000;
}
.question-progress-form .options-select-container .select-input.in-review,
.question-progress-form .options-select-container .select-input.in-progress,
.question-progress-form .options-select-container .select-input.done {
  color: #fff;
}
.question-progress-form .options-select-container .select-input.new {
  background: #fff;
}
.question-progress-form .options-select-container .select-input.in-review {
  background: #ff5186;
}
.question-progress-form .options-select-container .select-input.in-progress {
  background: #ffba6c;
}
.question-progress-form .options-select-container .select-input.done {
  background: #0089ba;
}
.question-progress-form .done-label span {
  color: #000;
}
.question-progress-form.mda-topics {
  width: 220px;
}
.question-progress-form.mdg-research-team,
.question-progress-form.mdg-reed-group {
  width: 320px;
}
.question-progress-form .question-progress-form__title {
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
}
.question-progress-form .question-progress-form__completed,
.question-progress-form .question-progress-form__inputs {
  margin: 10px 0px;
}
.question-progress-form .question-progress-form__completed .selector-label,
.question-progress-form .question-progress-form__inputs .selector-label {
  color: #000;
  font-size: 14px;
  white-space: nowrap;
  width: 220px;
}
.question-progress-form .question-progress-form__completed label,
.question-progress-form .question-progress-form__inputs label {
  margin-bottom: 3px;
  color: #fff;
  width: 100%;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.question-progress-form .question-progress-form__completed label span,
.question-progress-form .question-progress-form__inputs label span {
  min-width: 110px;
  width: 100%;
  padding: 0px 15px;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  margin-left: 5px;
}
.question-progress-form .question-progress-form__completed label input,
.question-progress-form .question-progress-form__inputs label input {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
  width: 11px;
  height: 11px;
  cursor: pointer;
}
.question-progress-form .question-progress-form__completed {
  border-top: 1px solid #eaeaea;
  margin-top: 5px;
  padding-top: 5px;
  margin-bottom: 0px;
}
.question-progress-form .question-progress-form__completed input[type="checkbox"] {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
  margin-top: 4px;
  width: 12px !important;
  height: 12px;
}
.question-progress-form .question-progress-form__completed label {
  margin-top: 2px;
}
.question-progress-form .question-progress-form__completed label span {
  text-align: left;
  padding: 0;
  margin-left: 10px;
  color: #000;
}
.question-pico-fields .question-navigation {
  margin-left: 10px;
}
.question-pico-fields .question-pico-fields__edit {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  position: relative;
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  line-height: 30px;
}
.question-pico-fields .question-pico-fields__edit.diagnostic {
  white-space: nowrap;
}
.question-pico-fields .question-pico-fields__edit input,
.question-pico-fields .question-pico-fields__edit select {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  background-color: #fff;
}
.question-pico-fields .question-pico-fields__edit select.prognostic {
  width: 80px;
}
.question-pico-fields .question-pico-fields__edit select.prognostic.default {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes,
.question-pico-fields .question-pico-fields__edit .with-annotation {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  margin: 0 5px;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes.with-comparator-test-toggle,
.question-pico-fields .question-pico-fields__edit .with-annotation.with-comparator-test-toggle {
  margin-right: 37px;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes input,
.question-pico-fields .question-pico-fields__edit .with-annotation input {
  width: 100%;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes input[type="checkbox"],
.question-pico-fields .question-pico-fields__edit .with-annotation input[type="checkbox"] {
  position: absolute;
  width: 32px;
  height: 32px;
  color: #3883a5;
  border: 1px solid #bfbfbf;
  border-left: 0;
  background-color: #ebebe4;
  background-image: none;
  text-align: center;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes input[type="checkbox"]:before,
.question-pico-fields .question-pico-fields__edit .with-annotation input[type="checkbox"]:before {
  font-family: 'gdt';
  content: '\e025';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes input[type="checkbox"] *,
.question-pico-fields .question-pico-fields__edit .with-annotation input[type="checkbox"] * {
  margin-left: 0.5rem;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes input[type="checkbox"]:before,
.question-pico-fields .question-pico-fields__edit .with-annotation input[type="checkbox"]:before {
  line-height: 32px;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes input:checked:before,
.question-pico-fields .question-pico-fields__edit .with-annotation input:checked:before {
  font-family: 'gdt';
  content: '\e026';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.question-pico-fields .question-pico-fields__edit .with-footnotes input:checked *,
.question-pico-fields .question-pico-fields__edit .with-annotation input:checked * {
  margin-left: 0.5rem;
}
.question-pico-fields .question-pico-fields__edit .annotation {
  line-height: 15px;
  font-size: 12px;
}
.question-pico-fields .question-pico-fields__edit #populationOther {
  margin-left: 0;
}
.question-pico-fields .question-pico-fields__edit select {
  margin-left: 5px;
}
.question-pico-fields .question-pico-fields__edit #population {
  margin-right: 5px;
}
.question-pico-fields .question-pico-fields__edit span.input-with-button input {
  margin: 0;
  width: calc(100% - 32px);
}
.question-pico-fields .question-pico-fields__edit .sup {
  position: absolute;
  display: block;
  right: 0px;
  top: -18px;
}
.question-pico-fields .question-text {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.question-pico-fields .question-text > div {
  cursor: pointer;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.question-pico-fields .question-text .question-tables-navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 40px - 120px);
  height: 100%;
  background-image: -webkit-linear-gradient(left, transparent, #ddeaf9 45%);
  background-image: -moz-linear-gradient(left, transparent, #ddeaf9 45%);
  background-image: -o-linear-gradient(left, transparent, #ddeaf9 45%);
  background-image: -ms-linear-gradient(left, transparent, #ddeaf9 45%);
  background-image: linear-gradient(to right, transparent, #ddeaf9 45%);
  text-align: right;
  -webkit-animation-name: fade-in;
  -moz-animation-name: fade-in;
  -o-animation-name: fade-in;
  -ms-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: 0.7s;
  -moz-animation-duration: 0.7s;
  -o-animation-duration: 0.7s;
  -ms-animation-duration: 0.7s;
  animation-duration: 0.7s;
}
.question-icons button:before {
  color: #3b4d88;
}
.question-meta-fields {
  padding-right: 12px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.question-meta-fields .code-item.editable {
  position: relative;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  overflow: hidden;
  background: #fff;
}
.question-meta-fields .code-item.editable span {
  vertical-align: middle;
}
.question-meta-fields .code-item.editable button {
  position: relative;
  top: -1px;
}
.question-meta-fields .add-code-trigger {
  margin-left: 10px;
  margin-bottom: 10px;
}
.question-meta-fields .field {
  display: table;
  width: 100%;
}
.question-meta-fields .field:not(:last-child) {
  margin-bottom: 25px;
}
.question-meta-fields .field .field__label,
.question-meta-fields .field .field__text {
  display: table-cell;
  padding-left: 15px;
  position: relative;
}
.question-meta-fields .field .field__label {
  width: 16.66%;
}
.question-meta-fields .field .field__label label {
  position: relative;
}
.question-meta-fields .field .field__label label .info-sign {
  left: -15px;
  top: 3px;
}
.question-meta-fields .field .field__text .field__input.tableTitle {
  position: relative;
}
.question-meta-fields .field .field__text .field__input.tableTitle input {
  padding-right: 144px;
}
.question-meta-fields .field .field__text .field__input.tableTitle label {
  position: absolute;
  right: 0;
}
.question-meta-fields .field .field__text .field__input.tableTitle label input[type="checkbox"] {
  display: none;
}
.question-meta-fields .field .field__text .field__input.tableTitle label span {
  line-height: 32px;
}
.question-meta-fields .field .field__text .field__input input {
  width: 100%;
  background-color: #fff;
}
.last-update {
  line-height: 16px;
  font-size: 14px;
  padding-right: 5px;
  text-align: right;
  padding-bottom: 10px;
}
