@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.outcome-edit-block {
  width: 100%;
  font-style: normal;
}
.outcome-edit-block label.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.outcome-edit-block .outcome-toggleable-editor {
  position: relative;
  margin: 0;
  display: none;
  background: #d2dfed;
  overflow: hidden;
}
.outcome-edit-block .outcome-label-edit {
  text-align: left;
  background: #d2dfed;
  border-bottom: 1px solid #bfbfbf;
}
.outcome-edit-block .outcome-label-edit button.save {
  margin-top: 5px;
}
.outcome-edit-block .outcome-label-edit .label-input {
  margin-right: 30px;
  padding: 10px;
  border-right: 1px solid #bfbfbf;
}
.outcome-edit-block .outcome-label-edit .label-input input {
  width: 100%;
}
.outcome-edit-block .outcome-sort-delete {
  z-index: 2;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  border-left: 1px solid #bfbfbf;
}
.outcome-edit-block .outcome-edit {
  padding-left: 10px;
  text-align: left;
  background: #d2dfed;
}
.outcome-edit-block .outcome-edit input[type=text] {
  height: 30px;
}
.outcome-edit-block .outcome-edit ul,
.outcome-edit-block .outcome-edit li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 25px;
}
.outcome-edit-block .outcome-edit ul input[type=radio],
.outcome-edit-block .outcome-edit li input[type=radio] {
  margin-top: 3px;
}
.outcome-edit-block .outcome-edit div.edit-container {
  padding-bottom: 10px;
}
.outcome-edit-block .outcome-edit div.left-side-edit {
  padding-bottom: 20px;
  padding-right: 10px;
  border-right: 1px solid #bfbfbf;
}
.outcome-edit-block .outcome-edit div.right-side-edit {
  padding-left: 15px;
}
.outcome-edit-block .outcome-edit div.follow-up-label {
  margin-top: 6px;
}
.outcome-edit-block .outcome-edit div.box-bordered {
  border: 1px solid #bfbfbf;
  padding: 10px;
  *zoom: 1;
}
.outcome-edit-block .outcome-edit div.box-bordered:before,
.outcome-edit-block .outcome-edit div.box-bordered:after {
  content: "";
  display: table;
}
.outcome-edit-block .outcome-edit div.box-bordered:after {
  clear: both;
}
.outcome-edit-block .outcome-edit div.box-bordered ul li input.follow-up-time-unit-input {
  width: 75%;
  display: inline-block;
}
.outcome-edit-block .outcome-edit div.box-bordered ul.horizontal li {
  width: 50%;
  float: left;
}
.outcome-edit-block .outcome-edit .cont-outcome-scores-scale {
  margin-top: 10px;
}
.outcome-edit-block .outcome-edit .cont-outcome-scores-scale .scale-type-select,
.outcome-edit-block .outcome-edit .cont-outcome-scores-scale .range-of-scores,
.outcome-edit-block .outcome-edit .cont-outcome-scores-scale .conventional-scale-limits {
  margin: 0 -5px;
}
.outcome-edit-block .outcome-edit .cont-outcome-scores-scale .scale-type-select {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.outcome-edit-block .outcome-edit .cont-outcome-scores-scale .scale-type-select .selection-list-value {
  margin-left: 20px;
  max-width: 232px;
}
.outcome-edit-block .outcome-edit .cont-outcome-scores-scale .range-of-scores input,
.outcome-edit-block .outcome-edit .cont-outcome-scores-scale .conventional-scale-limits input {
  width: 95px;
}
#follow-up-length-row .selection-list-value,
#follow-up-length-row .inline {
  display: inline-block;
}
#follow-up-length-row .inline {
  line-height: 30px;
}
#follow-up-length-row .selection-list-value,
#follow-up-length-row .short {
  width: 75px;
}
#follow-up-length-row .select-input-container input {
  width: 100px;
}
.select-input-container .selection-list-value {
  display: inline-block;
}
.select-input-container.short-select .selection-list-value {
  width: 100px;
}
.select-input-container.short-select.non-event .selection-list-value,
.select-input-container.short-select.non-event input[type='text'] {
  width: 100%;
}
@media (max-width: 1600px) {
  #follow-up-length-row.ranged input {
    width: 50px;
  }
  #follow-up-length-row.ranged .selection-list-value {
    width: 65px;
  }
  #follow-up-length-row.ranged .select-input-container.other {
    width: 125px;
  }
}
