@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.extraction-form.in-modal {
  max-height: 75vh;
  overflow-y: auto;
}
.extraction-form .extraction-form__group {
  margin: 0 20px;
}
.extraction-form .extraction-form__group:not(:last-child) {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #bfbfbf;
}
.extraction-field {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 10px;
  transition: background 0.2s ease-in-out;
}
.extraction-field:hover {
  background: #eaeaea;
}
.extraction-field.guidelineInterventions,
.extraction-field.guidelinePopulations {
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.extraction-field.guidelineInterventions .extraction-field__name,
.extraction-field.guidelinePopulations .extraction-field__name {
  padding-top: 10px;
}
.extraction-field .extraction-field__name {
  width: 220px;
  padding-top: 6px;
  margin-right: 10px;
  font-weight: bold;
  text-align: right;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
}
.extraction-field .extraction-field__name .context-help-icon {
  position: relative;
  top: -5px;
  margin-right: 5px;
}
.extraction-field .extraction-field__value {
  margin-right: 10px;
  -webkit-flex: 1 0 70%;
  -ms-flexbox: 1 0 70%;
  flex: 1 0 70%;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  max-width: 70%;
}
.extraction-field .extraction-field__value > div {
  max-width: 96%;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.extraction-field .extraction-field__value .clear-btn {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex: 1;
  flex-shrink: 1;
  margin-left: 10px;
}
.extraction-field .extraction-field__value .clear-btn button {
  width: 10px;
  height: 10px;
}
.extraction-field .extraction-field__value.text .text-with-helper {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.extraction-field .extraction-field__value.text .text-with-helper .helper-text {
  color: #757575;
  margin-bottom: 5px;
}
.extraction-field .extraction-field__value.multiText .multiple-inputs .multiple-inputs__input,
.extraction-field .extraction-field__value.custom .multiple-inputs .multiple-inputs__input {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 5px;
}
.extraction-field .extraction-field__value.multiText .multiple-inputs .multiple-inputs__input .input__textarea,
.extraction-field .extraction-field__value.custom .multiple-inputs .multiple-inputs__input .input__textarea {
  word-wrap: break-word;
}
.extraction-field .extraction-field__value.multiText .multiple-inputs .multiple-inputs__input:first-child,
.extraction-field .extraction-field__value.custom .multiple-inputs .multiple-inputs__input:first-child {
  margin-top: 0;
}
.extraction-field .extraction-field__value.multiText .multiple-inputs .multiple-inputs__input .input__textarea,
.extraction-field .extraction-field__value.custom .multiple-inputs .multiple-inputs__input .input__textarea {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.extraction-field .extraction-field__value.multiText .multiple-inputs .multiple-inputs__add,
.extraction-field .extraction-field__value.custom .multiple-inputs .multiple-inputs__add {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 10px;
}
.extraction-field .extraction-field__value.multiText .multiple-inputs .multiple-inputs__add:first-child,
.extraction-field .extraction-field__value.custom .multiple-inputs .multiple-inputs__add:first-child {
  margin-top: 0;
}
.extraction-field .extraction-field__value.multiText .multiple-inputs .multiple-inputs__add .btn-icon-container .label-text,
.extraction-field .extraction-field__value.custom .multiple-inputs .multiple-inputs__add .btn-icon-container .label-text {
  font-size: 14px;
}
.extraction-field .extraction-field__value.select {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.extraction-field .extraction-field__value.select .options-select-container {
  width: 100%;
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.extraction-field .extraction-field__value.select .options-select-container .options-select-container__options {
  width: 100%;
  background: #fff;
}
.extraction-field .extraction-field__value.multiSelect {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.extraction-field .extraction-field__value.multiSelect .ep-select-with-checkboxes__trigger {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  height: 35px;
  border-color: #bfbfbf;
}
.extraction-field .extraction-field__value.multiSelect .ep-select-with-checkboxes__trigger .ep-expand-button {
  margin-top: 0;
}
.extraction-field .extraction-field__value.multiSelect .ep-select-with-checkboxes__trigger .ep-expand-button svg {
  fill: #3883a5;
}
.extraction-field .extraction-field__value.radioOptions .radio-options-container {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.extraction-field .extraction-field__value.radioOptions .radio-options-container .radio-with-label {
  margin-right: 50px;
}
.extraction-field .extraction-field__value.radioOptions .radio-options-container .radio-with-label:last-child {
  margin-right: 0;
}
.extraction-field .extraction-field__value.radioOptions .radio-options-container .radio-with-label input {
  cursor: pointer;
}
.extraction-field .extraction-field__value.radioOptions .radio-options-container .helper-text {
  color: #757575;
}
.extraction-field .extraction-field__value.radioOptions .radio-options-container .other {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.extraction-field .extraction-field__value.radioOptions .radio-options-container .other input[type=radio] {
  cursor: pointer;
}
.extraction-field .extraction-field__value.radioOptions .radio-options-container .other input:not([type=radio]) {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.extraction-field .extraction-field__value.codes .tag-row {
  padding-left: 0px;
}
.extraction-field .extraction-field__value.codes .add-tag-button-container,
.extraction-field .extraction-field__value.codes .input-with-controls {
  min-width: 500px;
  max-width: 500px;
}
.extraction-field .extraction-field__value.codes .add-code-trigger {
  margin-left: 20px;
}
.extraction-field .extraction-field__value.switcher {
  position: relative;
  top: 8px;
}
.extraction-field .extraction-field__value .text-area-container {
  word-break: break-all;
}
.extraction-field .extraction-field__value .text-area-container .start-editing-trigger {
  max-height: 140px;
  overflow: auto;
}
.extraction-field .extraction-field__value .text-area-container textarea {
  max-height: 140px;
}
.extraction-field .extraction-field__value.selectWithOther .selectWithOther__select {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.extraction-field .extraction-field__value.selectWithOther .selectWithOther__select .options-select-container {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.extraction-field .extraction-field__value.selectWithOther .selectWithOther__other {
  margin-top: 3px;
}
.extraction-field .extraction-field__value.string > div {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.extraction-field .extraction-field__value.string > div input {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.extraction-field .extraction-field__value.textarea textarea {
  padding: 4px 8px;
  height: 60px;
  width: 100%;
  font-size: 16px;
}
.extraction-field .extraction-field__value.custom .flex-grow .question {
  overflow: hidden;
  white-space: nowrap;
  height: 30px;
  padding: 5px 5px 5px 5px;
  background: #f2f2f2;
  text-overflow: ellipsis;
}
.extraction-field .btn-icon {
  position: relative;
  top: -2px;
  font-size: 12px;
}
.extraction-field .ep-select-with-checkboxes__trigger {
  width: 100%;
}
