@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
#sidebar-container {
  position: relative;
  float: left;
  height: 100%;
  top: 0;
  z-index: 100;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
#sidebar-inner-container {
  padding-top: 30px;
  position: relative;
  z-index: 2;
  background: #232e51;
  -webkit-box-shadow: 5px 0 5px -2px rgba(0,0,0,0.2);
  box-shadow: 5px 0 5px -2px rgba(0,0,0,0.2);
  height: 100%;
}
#sidebar-inner-container.absolute-expand,
#sidebar-inner-container.expanded {
  width: 190px !important;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -o-transition: width 0.5s;
  -ms-transition: width 0.5s;
  transition: width 0.5s;
}
#sidebar-inner-container.absolute-expand.es,
#sidebar-inner-container.expanded.es {
  width: 210px !important;
}
#sidebar-inner-container.absolute-expand.it,
#sidebar-inner-container.expanded.it,
#sidebar-inner-container.absolute-expand.pt,
#sidebar-inner-container.expanded.pt {
  width: 190px !important;
}
#sidebar-inner-container.absolute-expand.ja,
#sidebar-inner-container.expanded.ja {
  width: 200px !important;
}
#sidebar-inner-container.absolute-collapsed,
#sidebar-inner-container.collapsed {
  width: 55px;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -o-transition: width 0.5s;
  -ms-transition: width 0.5s;
  transition: width 0.5s;
}
#sidebar-inner-container .submenu.expanded li {
  height: 38px;
  -webkit-transition: 0.5s height;
  -moz-transition: 0.5s height;
  -o-transition: 0.5s height;
  -ms-transition: 0.5s height;
  transition: 0.5s height;
}
#sidebar-inner-container .submenu.collapsed li {
  height: 0px;
  -webkit-transition: 0.5s height;
  -moz-transition: 0.5s height;
  -o-transition: 0.5s height;
  -ms-transition: 0.5s height;
  transition: 0.5s height;
}
#sidebar-inner-container .flex-container {
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
#sidebar-inner-container .bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding-right: 12px;
}
#sidebar-inner-container .bottom button {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("../images/svg/expand.svg") no-repeat 50% 50%;
}
#sidebar-inner-container .bottom button.btn-collapsed {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  -ms-transition: -ms-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
#sidebar-inner-container .bottom button.btn-expanded {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  -ms-transition: -ms-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
#sidebar {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin: 0;
}
#sidebar li {
  position: relative;
  color: #fff;
}
#sidebar li.selected > a {
  color: #fff;
  background: #3782a4;
}
#sidebar li.selected .submenu {
  display: block;
  background: #3782a4;
}
#sidebar li.selected .submenu a:hover:not(.disabled) {
  background: #51a0c5;
  color: #fff;
}
#sidebar a {
  display: block;
  width: 100%;
  color: #fff;
  height: 40px;
  line-height: 41px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
#sidebar a,
#sidebar a:link,
#sidebar a:visited,
#sidebar a:hover,
#sidebar a:active {
  color: inherit;
  text-decoration: none;
}
#sidebar a:hover:not(.disabled) {
  background: #3782a4;
  color: #fff;
}
#sidebar a.disabled:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #696f78;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
#sidebar .submenu {
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  background: #3782a4;
}
#sidebar .submenu li {
  width: 100%;
  position: relative;
  overflow: hidden;
}
#sidebar .submenu li.selected a {
  background: #51a0c5;
}
#sidebar .submenu li,
#sidebar .submenu a {
  height: 38px;
  background: #3782a4;
  color: #fff;
}
#sidebar .submenu a {
  line-height: 39px;
  padding: 0 1.4rem;
  padding-left: 50px;
  position: relative;
}
#sidebar .submenu a:before {
  display: none;
}
#sidebar .dashboard:before {
  font-family: 'gdt';
  content: '\e087';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .dashboard * {
  margin-left: 0.5rem;
}
#sidebar .dashboard:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .settings:before,
#sidebar .administration:before {
  font-family: 'gdt';
  content: '\e038';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .settings *,
#sidebar .administration * {
  margin-left: 0.5rem;
}
#sidebar .settings:before,
#sidebar .administration:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .tasks:before {
  font-family: 'gdt';
  content: '\e010';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .tasks * {
  margin-left: 0.5rem;
}
#sidebar .tasks:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .tasks:before {
  font-size: 20px;
}
#sidebar .mda-tables:before {
  font-family: 'gdt';
  content: '\e004';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .mda-tables * {
  margin-left: 0.5rem;
}
#sidebar .mda-tables:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .mda-topics:before {
  font-family: 'gdt';
  content: '\e005';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .mda-topics * {
  margin-left: 0.5rem;
}
#sidebar .mda-topics:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .mda-verification:before {
  font-family: 'gdt';
  content: '\e104';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .mda-verification * {
  margin-left: 0.5rem;
}
#sidebar .mda-verification:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .team:before {
  font-family: 'gdt';
  content: '\e006';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .team * {
  margin-left: 0.5rem;
}
#sidebar .team:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .voting:before {
  font-family: 'gdt';
  content: '\e083';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .voting * {
  margin-left: 0.5rem;
}
#sidebar .voting:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .scope:before {
  font-family: 'gdt';
  content: '\e011';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .scope * {
  margin-left: 0.5rem;
}
#sidebar .scope:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .scope:before {
  font-size: 20px;
}
#sidebar .document-sections:before {
  font-family: 'gdt';
  content: '\e005';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .document-sections * {
  margin-left: 0.5rem;
}
#sidebar .document-sections:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .references:before {
  font-family: 'gdt';
  content: '\e053';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .references * {
  margin-left: 0.5rem;
}
#sidebar .references:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .prognosis:before {
  font-family: 'gdt';
  content: '\e013';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .prognosis * {
  margin-left: 0.5rem;
}
#sidebar .prognosis:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .evidence-syntheses:before {
  font-family: 'gdt';
  content: '\e067';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .evidence-syntheses * {
  margin-left: 0.5rem;
}
#sidebar .evidence-syntheses:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .dissemination:before {
  font-family: 'gdt';
  content: '\e082';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .dissemination * {
  margin-left: 0.5rem;
}
#sidebar .dissemination:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .multi-comparisons:before {
  font-family: 'gdt';
  content: '\e084';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .multi-comparisons * {
  margin-left: 0.5rem;
}
#sidebar .multi-comparisons:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .nma:before {
  font-family: 'gdt';
  content: '\e112';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .nma * {
  margin-left: 0.5rem;
}
#sidebar .nma:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .cadth-guidelines:before {
  font-family: 'gdt';
  content: '\e024';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .cadth-guidelines * {
  margin-left: 0.5rem;
}
#sidebar .cadth-guidelines:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar .algorithms:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .algorithms * {
  margin-left: 0.5rem;
}
#sidebar .algorithms:before {
  display: block;
  float: left;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 4px;
  margin-right: 5px;
  width: 29px;
  line-height: 30px;
  text-align: center;
  position: relative;
  color: #fff;
}
#sidebar.alternate .submenu a {
  padding: 0.4rem 1.4rem 0.3rem 1.4rem;
  font-size: 11px;
  font-size: 1.1rem;
  text-transform: none;
  line-height: 1.1em;
  display: inline-block;
  vertical-align: middle;
  white-space: pre-line;
}
#sidebar.alternate .recommendations {
  background: url("../images/recommendations.png") 8px 60% no-repeat, -webkit-linear-gradient(top, #eaeaea, #5c5d5f);
  background: url("../images/recommendations.png") 8px 60% no-repeat, -moz-linear-gradient(top, #eaeaea, #5c5d5f);
  background: url("../images/recommendations.png") 8px 60% no-repeat, -o-linear-gradient(top, #eaeaea, #5c5d5f);
  background: url("../images/recommendations.png") 8px 60% no-repeat, -ms-linear-gradient(top, #eaeaea, #5c5d5f);
  background: url("../images/recommendations.png") 8px 60% no-repeat, linear-gradient(to bottom, #eaeaea, #5c5d5f);
  padding-left: 40px;
  position: relative;
}
#sidebar.alternate .recommendations.selected,
#sidebar.alternate .recommendations:hover:not(.disabled) {
  background: url("../images/recommendations.png") 8px 60% no-repeat, #fff;
}
