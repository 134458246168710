@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.tag-row {
  padding: 0 10px;
  margin-bottom: 2px;
  background: none;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.tag-row .code-item {
  position: relative;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
}
.tag-row .code-item button {
  position: absolute;
  top: 8px;
  right: -3px;
  margin: 0px 10px 0px 5px;
}
.tag-row .code-item button:before {
  font-size: 12px;
}
.tag-row .tag-row__content {
  padding-left: 10px;
  margin-right: 10px;
  background: #f6f6f6;
  height: 36px;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.tag-row .item-with-checkbox {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-bottom: 10px;
  max-width: 600px;
  min-width: 400px;
}
.tag-row .tag-input {
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-right: 10px;
}
.tag-row .tag-input.input-with-controls.tag-input {
  padding: 2px;
  min-height: 36px;
  background: #f6f6f6;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.tag-row .tag-input.input-with-controls.tag-input input {
  line-height: 36px;
}
.tag-row .tag-input.input-with-controls.tag-input .input-with-controls__initial-text.tag-input {
  padding-left: 10px;
  line-height: 36px;
  color: #2d3236;
}
.tag-row .tag-input.input-with-controls.tag-input .input-with-controls__controls.tag-input {
  margin-top: 3px;
  margin-right: 10px;
}
.tag-row .tag-input.input-with-controls.tag-input .input-with-controls__controls.tag-input .btn-icon-container button {
  margin-left: 5px;
}
.tag-row .tag-input.input-with-controls.tag-input .input-with-controls__controls.tag-input .btn-icon-container button:before {
  color: #3883a5;
}
.add-tag-button-container {
  position: relative;
  max-width: 600px;
  height: 40px;
}
.add-tag-button {
  position: absolute;
  right: 0px;
}
.add-tag-button.btn-icon-container button:before {
  margin-right: 5px;
}
.add-tag-button.btn-icon-container .label-text {
  font-size: 14px;
}
