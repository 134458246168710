@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.import-modal {
  top: 150px;
  width: 600px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.import-modal h2 {
  text-align: center;
  margin: 5px 0 10px;
}
.import-modal .import-choose-source {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.import-modal .import-choose-source .import-source-button-container {
  width: 100%;
  padding: 5px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.import-modal .import-choose-source .import-source-button-container .import-source-input-file {
  display: none;
}
.import-modal .import-choose-source .import-source-button-container .import-source-button {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 90%;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  height: 32px;
  line-height: 32px;
}
.import-modal .import-choose-source .import-source-button-container.active .import-source-button {
  margin-left: 0;
  margin-right: 0;
}
.import-modal .import-choose-source .import-source-button-container.active .btn-icon-container {
  width: 10%;
}
.import-modal .import-choose-source .import-source-button-container.active .btn-icon-container .import-source-cross {
  width: 100%;
  height: 100%;
}
.import-modal .import-choose-source .import-source-button-container.active .btn-icon-container .import-source-cross:before {
  font-size: 20px;
}
.import-modal .import-choose-source .import-source-button-container.active .btn-icon-container .import-source-cross:hover {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.import-modal .import-include-references {
  margin: 10px 0;
}
.import-modal .import-include-references input {
  cursor: pointer;
}
.import-modal .import-include-references button {
  padding-left: 5px;
}
.import-modal .import-project-name {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-bottom: 10px;
}
.import-modal .import-project-name div {
  padding-right: 10px;
  font-weight: bold;
  line-height: 32px;
}
.import-modal .import-project-name input {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.import-modal .import-select-questions {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.import-modal .import-select-questions .import-select-questions-header {
  margin-bottom: 10px;
}
.import-modal .import-select-questions .import-select-questions-list-container {
  border: 1px solid #bfbfbf;
  padding: 5px;
}
.import-modal .import-select-questions .import-select-questions-select-all {
  margin: 0 0 5px;
  padding-bottom: 5px;
  width: 75%;
  border-bottom: 1px solid #bfbfbf;
}
.import-modal .import-select-questions .import-select-questions-select-all span {
  font-weight: bold;
}
.import-modal .import-select-questions .import-select-questions-list .question span,
.import-modal .import-select-questions .import-select-questions-select-all span {
  padding-left: 5px;
  margin-left: 5px;
  text-align: left;
  max-width: 500px;
}
.import-modal .import-select-questions .import-select-questions-list .question input,
.import-modal .import-select-questions .import-select-questions-select-all input {
  cursor: pointer;
}
.import-modal .import-select-questions .import-select-questions-list {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
.import-modal .import-select-questions .import-select-questions-list .question {
  margin: 5px 0;
}
.import-modal .import-select-questions .import-select-questions-list .question label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.import-modal .import-select-questions .import-select-questions-list .question label > * {
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.import-modal .import-select-questions .import-select-questions-list .question label[disabled] > * {
  background-color: #ddd;
}
.import-modal .import-select-questions .import-select-questions-list .question.bold button {
  font-weight: bold;
}
.import-modal .import-select-questions .import-select-questions-list .question.bold .outcome button {
  font-weight: initial;
}
.import-modal .import-select-questions .import-select-questions-list .question .question-select,
.import-modal .import-select-questions .import-select-questions-list .question .outcome {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.import-modal .import-select-questions .import-select-questions-list .question .outcome {
  margin: 5px 0 5px 20px;
}
.import-modal .buttons-react button {
  margin: 0 10px 0 0 !important;
  min-width: calc(50% - 5px) !important;
}
.import-modal .buttons-react button:last-child {
  margin: 0 !important;
}
.import-modal .steps-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  min-height: 200px;
}
.import-modal .steps-container .steps-indicator {
  width: 100%;
}
.import-modal .steps-container .steps {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-bottom: 20px;
}
#import-references-dialog fieldset {
  text-align: center;
}
#import-references-dialog #file {
  visibility: hidden;
  width: 0;
  height: 0;
}
#import-references-dialog .questions-to-import {
  max-height: 120px;
  overflow-y: auto;
  background: #eaeaea;
}
#import-references-dialog .questions-to-import ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#import-references-dialog .questions-to-import ul li {
  font-size: 16px;
  padding-bottom: 3px;
}
#import-references-dialog .questions-to-import ul li.selected {
  background-color: #fff;
}
#import-references-dialog .questions-to-import ul li input {
  display: block;
  float: left;
}
#import-references-dialog .questions-to-import ul li input[type="checkbox"] {
  margin-top: 5px;
  background-color: #fff;
}
#import-references-dialog .questions-to-import ul li input[type="checkbox"]:checked {
  background-color: #f2f2f2;
}
#import-references-dialog .questions-to-import ul li label {
  display: block;
  margin-left: 20px;
}
#import-references-dialog .questions-to-import ul.outcomes-list {
  padding-left: 10px;
}
#import-references-dialog .studies-import {
  margin-top: 15px;
}
#import-references-dialog #rm5-url {
  width: 256px;
}
#import-references-dialog button.file {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
#import-references-dialog .cancel {
  background-color: #9dbfcc;
}
