@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.mail-attachments-form {
  margin-top: 10px;
}
.mail-attachments-form .attachments-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.mail-attachments-form .attachments-list__item {
  display: flex;
  padding: 5px;
  margin: 3px 0;
  line-height: 20px;
  border: none;
  background: #e9faff;
}
.mail-attachments-form .attachments-list__item-name {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.mail-attachments-form .attachments-list__item-remove {
  cursor: pointer;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  font-size: 20px;
}
.mail-attachments-form .attachments-list__item-remove:before {
  content: '\00d7';
}
.mail-attachments-form .attachments-bottom-panel {
  display: flex;
}
.mail-attachments-form .attachments-bottom-panel__attachemts-size {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  line-height: 38px;
}
.mail-attachments-form .info-icon {
  margin-left: 7px;
}
.mail-attachments-form .attachments-bottom-panel__attachemts-size--error {
  color: #f00;
}
.mail-attachments-form .attachments-bottom-panel__add-attachment-btn {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
}
