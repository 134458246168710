@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.webpage-view #webpage-content {
  width: 100%;
  border: none;
  background: #fff;
}
.webpage-view .buttons-block {
  margin-top: 5px;
}
.webpage-view .buttons-block a {
  cursor: pointer;
  display: inline-block;
}
.webpage-view .buttons-block a,
.webpage-view .buttons-block a:link,
.webpage-view .buttons-block a:visited,
.webpage-view .buttons-block a:hover,
.webpage-view .buttons-block a:active {
  color: inherit;
  text-decoration: none;
}
.webpage-view .buttons-block a:hover {
  opacity: 0.7;
}
