@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.nma-questions-list {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
.nma-questions-list .nma-questions-list__question {
  cursor: pointer;
  height: 40px;
  background: #f2f2f2;
  padding: 0 10px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  transition: background 0.2s ease-in-out;
}
.nma-questions-list .nma-questions-list__question:hover {
  background: #fafafa;
}
.nma-questions-list .nma-questions-list__question:not(:last-child) {
  margin-bottom: 5px;
}
.nma-questions-list .nma-questions-list__question .question__title {
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nma-questions-list .nma-questions-list__question .question__menu button {
  height: 40px;
}
.nma-list-toolbar {
  padding: 2px 10px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}
.nma-list-toolbar .toolbar__list {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  font-weight: bold;
}
.nma-list-toolbar .toolbar__add-question-button {
  margin-left: 5px;
}
.nma-questions-list-toolbar {
  padding: 2px 10px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 180px;
  position: relative;
}
.nma-questions-list-toolbar .toolbar__list {
  font-weight: bold;
}
.nma-questions-list-toolbar .toolbar__add-question-button,
.nma-questions-list-toolbar .toolbar__controls {
  margin-left: 5px;
}
.nma-questions-list-toolbar .toolbar__add-question-button button:not(:last-child),
.nma-questions-list-toolbar .toolbar__controls button:not(:last-child) {
  margin-right: 10px;
}
.nma-questions-list-toolbar .nma-questions-list-toolbar__list {
  z-index: 100;
}
.nma-questions-list-toolbar .nma-questions-list-toolbar__list .list__title {
  cursor: pointer;
  font-weight: bold;
}
.nma-questions-list-toolbar .nma-questions-list-toolbar__list .list__questions {
  background: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}
.nma-questions-list-toolbar .nma-questions-list-toolbar__list .list__questions .list__question {
  cursor: pointer;
  background: #f2f2f2;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.nma-questions-list-toolbar .nma-questions-list-toolbar__list .list__questions .list__question:not(:last-child) {
  margin-bottom: 5px;
}
.nma-questions-list-toolbar .nma-questions-list-toolbar__list .list__questions .list__question:hover {
  background: #fafafa;
}
.nma-question .nma-question__header {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}
.nma-question .nma-question__header h3 {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.nma-question .nma-question__header .header__controls {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.nma-question .nma-question__header .header__controls button {
  margin-left: 10px;
}
.nma-question .nma-question__outcomes {
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}
.nma-question .nma-question__outcomes .outcome-in-question {
  background: #f2f2f2;
  cursor: pointer;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 28px;
  transition: background 0.2s ease-in-out;
  position: relative;
}
.nma-question .nma-question__outcomes .outcome-in-question .drag-marker:before {
  cursor: grab;
  left: 10px;
  top: 0;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__name {
  margin-right: 10px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__file-name {
  max-width: 200px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 4px;
  height: 30px;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__file-name div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__file-name div:before {
  font-family: 'gdt';
  content: '\e098';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__file-name div * {
  margin-left: 0.5rem;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__file-name div:before {
  color: #3883a5;
  margin-right: 5px;
  font-size: 15px;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__upload-file button {
  height: 40px;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__menu {
  margin-left: 10px;
}
.nma-question .nma-question__outcomes .outcome-in-question .outcome-in-question__menu button {
  height: 40px;
}
.nma-question .nma-question__outcomes .outcome-in-question:hover {
  background: #fafafa;
}
.nma-question .nma-question__outcomes .outcome-in-question:not(:last-child) {
  margin-bottom: 5px;
}
.nma-question .nma-question__outcomes .outcome-in-question.dragging {
  opacity: 0.2;
}
.nma-question .nma-question__outcomes .outcome-in-question.over {
  background-color: #ddeaf9;
}
.nma-intervention-management-modal .nma-intervention-management-modal__header {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions {
  max-height: 70vh;
  overflow-y: auto;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention {
  background: #f2f2f2;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 14px;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 56px;
  padding: 0 10px;
  transition: background 0.2s ease-in-out;
  cursor: grab;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention .intervention__names {
  margin-right: 10px;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention .intervention__names .intervention__name {
  height: 30px;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention .intervention__names .intervention__short-name {
  height: 26px;
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention .intervention__menu button {
  height: 56px;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention:hover {
  background: #fafafa;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention:not(:last-child) {
  margin-bottom: 5px;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention.dragging {
  opacity: 0.2;
}
.nma-intervention-management-modal .nma-intervention-management-modal__interventions .nma-intervention-management-modal__intervention.over {
  background-color: #ddeaf9;
}
.nma-outcome-toolbar {
  margin: 2px 5px;
  padding: 2px 10px;
  background: #f2f2f2;
  height: 40px;
  line-height: 36px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.nma-outcome-toolbar .toolbar__back {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #d1d1d1;
}
.nma-outcome-toolbar .toolbar__back button:before {
  height: 30px;
  font-size: 20px;
  line-height: 30px;
}
.nma-outcome-toolbar .toolbar__outcome-name {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  font-weight: bold;
}
.nma-outcome .nma-outcome__description {
  margin-bottom: 20px;
}
.nma-outcome .nma-outcome__description h4 {
  margin: 10px 0;
}
.nma-outcome .nma-outcome__league-table .league-table__header {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 10px 0;
}
.nma-outcome .nma-outcome__league-table .league-table__header h4 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin: 0;
}
.nma-outcome .nma-outcome__league-table .league-table__header .header__controls {
  margin-left: 10px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.nma-outcome .nma-outcome__league-table .league-table__header .header__controls .header__file-name {
  max-width: 400px;
  padding: 5px 10px;
  background: #f2f2f2;
  border-radius: 4px;
  height: 30px;
}
.nma-outcome .nma-outcome__league-table .league-table__header .header__controls .header__file-name div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
}
.nma-outcome .nma-outcome__league-table .league-table__header .header__controls .header__file-name div:before {
  font-family: 'gdt';
  content: '\e098';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.nma-outcome .nma-outcome__league-table .league-table__header .header__controls .header__file-name div * {
  margin-left: 0.5rem;
}
.nma-outcome .nma-outcome__league-table .league-table__header .header__controls .header__file-name div:before {
  color: #3883a5;
  margin-right: 5px;
  font-size: 15px;
}
.nma-outcome .nma-outcome__league-table .league-table__header .header__controls .header__add-edit-row {
  margin-left: 10px;
}
.nma-import-outcome-modal .buttons-react {
  display: grid !important;
  margin: 0 !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.nma-import-outcome-modal .buttons-react:before {
  content: initial !important;
}
.nma-import-outcome-modal .buttons-react button {
  margin: 0 !important;
}
.nma-import-outcome-modal .nma-import-outcome-modal__step-description {
  margin-bottom: 10px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__upload-step {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 20px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__upload-step .import-source-input-file {
  display: none;
}
.nma-import-outcome-modal .nma-import-outcome-modal__upload-step .import-source-button {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.nma-import-outcome-modal .nma-import-outcome-modal__upload-step .import-source-cross {
  margin-left: 10px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step {
  margin-bottom: 20px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions {
  max-height: 355px;
  overflow-y: auto;
  padding: 0 5px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__unmatched-interventions {
  font-weight: bold;
  margin: 10px 0;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention {
  background: #f2f2f2;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention:not(:last-child) {
  margin-bottom: 5px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention .intervention__name {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention .intervention__delete {
  margin-left: 10px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention .intervention__delete button {
  height: 40px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  grid-gap: 10px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options > label,
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options > div {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options > label:hover,
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options > div:hover {
  background: #f2f2f2;
  border-color: #f2f2f2;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 0 0 10px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option .assign-option__radio {
  margin-right: 10px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option .assign-option__select {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  border-left: 1px solid #bfbfbf;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option .assign-option__select .options-select-container {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  width: 100%;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option .assign-option__select .options-select-container .select-input {
  border: none;
  width: 100%;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option .assign-option__select .options-select-container .select-input .select-value {
  max-width: 209px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option .assign-option__select .options-select-container .select-input .select-value > div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option:hover .assign-option__select {
  border-color: #f2f2f2;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option.disabled {
  background: #d1d1d1;
  border-color: #d1d1d1;
  cursor: not-allowed;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option.disabled .assign-option__select {
  border-color: #bfbfbf;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options .assign-option.disabled * {
  cursor: not-allowed;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options input[type="radio"] {
  margin-right: 10px;
}
.nma-import-outcome-modal .nma-import-outcome-modal__mapping-step .nma-import-outcome-modal__interventions .nma-import-outcome-modal__intervention-options:not(:last-child) {
  margin-bottom: 5px;
}
.nma-assign-option-select-popover {
  max-width: 240px;
}
.nma-assign-option-select-popover .option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
