@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.comparison-overarching-question,
.toolbar-overarching-question {
  width: 100%;
  background: #fff8de;
  border-bottom: 2px solid #fff;
  font-size: 14px;
  position: relative;
  padding-left: 42px;
}
.comparison-overarching-question:before,
.toolbar-overarching-question:before {
  position: absolute;
  left: 0;
  width: 40px;
  line-height: 40px;
  height: 100%;
  text-align: center;
  border-right: 2px solid #fff;
  color: #fabe03;
  font-family: 'gdt';
  content: '\e097';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.comparison-overarching-question:hover,
.toolbar-overarching-question:hover {
  background-color: #fffbeb;
}
.comparison-overarching-question .question-text,
.toolbar-overarching-question .question-text {
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}
.toolbar-overarching-question a {
  color: initial;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item:not(:last-child) {
  margin-bottom: 25px;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item .item__label,
.comparison-overarching-question .comparison-overarching-question__details .details__item .item__value {
  padding-left: 15px;
  position: relative;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item .item__label {
  width: calc(100% / 6);
}
.comparison-overarching-question .comparison-overarching-question__details .details__item .item__label label {
  position: relative;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item .item__label label .info-sign {
  left: -15px;
  top: 3px;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item .item__value {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item.last-update {
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item.last-update .item__label {
  width: initial;
  padding-left: 0;
  padding-right: 4px;
}
.comparison-overarching-question .comparison-overarching-question__details .details__item.last-update .item__value {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  padding-left: 0;
}
.button-export-container {
  line-height: 40px;
}
