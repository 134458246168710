@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
ul.options-list {
  padding: 0;
  margin: 0;
  list-style: none;
  min-width: 120px;
  position: relative;
}
ul.options-list.no-background li {
  background: none;
}
ul.options-list li {
  float: left;
  clear: left;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  line-height: 36px;
  background: #f6f6f6;
}
ul.options-list li:hover {
  background: #d2dfed;
}
