@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.popup-menu.help-popup {
  display: block;
  width: 625px;
  padding: 20px;
}
.popup-menu.help-popup > div {
  clear: both;
}
.popup-menu.help-popup > hr {
  clear: both;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding-top: 20px;
}
.popup-menu.help-popup ul li:hover {
  background: #d2dfed;
}
.popup-menu.help-popup .capabilities-view #main-menu li {
  padding: 0;
}
.popup-menu.help-popup .capabilities-view #main-menu button.selected,
.popup-menu.help-popup .capabilities-view #main-menu + #submenu,
.popup-menu.help-popup .capabilities-view #main-menu + #submenu li {
  background: #d2dfed;
}
.popup-menu.help-popup .capabilities-view #submenu button {
  white-space: initial;
}
.popup-menu.help-popup .dot {
  border: 0;
}
