.fc {
  direction: ltr;
  text-align: left;
}
.fc table {
  border-collapse: collapse;
  border-spacing: 0;
}
html .fc,
.fc table {
  font-size: 13px;
}
.fc td,
.fc th {
  padding: 0;
  vertical-align: top;
}
.fc-header td {
  white-space: nowrap;
}
.fc-header-left {
  width: 25%;
  text-align: left;
}
.fc-header-center {
  text-align: center;
}
.fc-header-right {
  width: 25%;
  text-align: right;
}
.fc-header-title {
  display: inline-block;
  vertical-align: top;
}
.fc-header-title h2 {
  margin-top: 0;
  white-space: nowrap;
  line-height: 42px;
}
.fc .fc-header-space {
  padding-left: 10px;
}
.fc-header .fc-button {
  margin-bottom: 1em;
  vertical-align: top;
}
.fc-header .fc-button {
  margin-right: -1px;
}
.fc-header .fc-corner-right,
.fc-header .ui-corner-right {
  margin-right: 0;
}
.fc-header .fc-state-hover,
.fc-header .ui-state-hover {
  z-index: 2;
}
.fc-header .fc-state-down {
  z-index: 3;
}
.fc-header .fc-state-active,
.fc-header .ui-state-active {
  z-index: 4;
}
.fc-content {
  clear: both;
}
.fc-view {
  width: 100%;
  overflow: hidden;
}
.fc-widget-header,
.fc-widget-content {
  border: 1px solid #b7b7b7;
}
.fc-state-highlight {
  background: #e9e9e9;
}
.fc-cell-overlay {
  background: #b7b7b7;
  opacity: 0.3;
}
.fc-button {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  overflow: hidden;
  height: 40px;
  line-height: 36px;
  white-space: nowrap;
  cursor: pointer;
}
.fc-state-default {
  border: 1px solid #b7b7b7;
}
.fc-text-arrow {
  margin: 0 0.1em;
  font-size: 2em;
  font-family: "PTSans", Courier, monospace;
  vertical-align: baseline;
}
.fc-button-prev .fc-text-arrow,
.fc-button-next .fc-text-arrow {
  font-weight: bold;
}
.fc-button .fc-icon-wrap {
  position: relative;
  float: left;
  top: 50%;
}
.fc-button .ui-icon {
  position: relative;
  float: left;
  margin-top: -50%;
}
.fc-state-default {
  background-color: #e9e9e9;
  background-repeat: repeat-x;
  border-color: #b7b7b7;
  color: #333;
}
.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333;
  background-color: #fff;
}
.fc-state-hover {
  color: #333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.fc-state-down,
.fc-state-active {
  background-color: #ccc;
  background-image: none;
  outline: 0;
}
.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
}
.fc-event {
  background-color: #d2dfed !important;
  color: #2c3136;
  font-size: 0.85em;
  cursor: default;
}
a.fc-event {
  text-decoration: none;
}
a.fc-event,
.fc-event-draggable {
  cursor: pointer;
}
.fc-rtl .fc-event {
  text-align: right;
}
.fc-event-inner {
  width: 100%;
  overflow: hidden;
}
.fc-event-time,
.fc-event-title {
  padding: 0 1px;
}
.fc .ui-resizable-handle {
  display: block;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
  font-size: 300%;
  line-height: 50%;
}
.fc-event-hori {
  border-width: 1px 0;
  margin-bottom: 1px;
}
.fc-ltr .fc-event-hori.fc-event-start,
.fc-rtl .fc-event-hori.fc-event-end {
  border-left-width: 1px;
}
.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-right-width: 1px;
}
.fc-event-hori .ui-resizable-e {
  top: 0 !important;
  right: -3px !important;
  width: 7px !important;
  height: 100% !important;
  cursor: e-resize;
}
.fc-event-hori .ui-resizable-w {
  top: 0 !important;
  left: -3px !important;
  width: 7px !important;
  height: 100% !important;
  cursor: w-resize;
}
.fc-event-hori .ui-resizable-handle {
  _padding-bottom: 14px /* IE6 had 0 height */;
}
table.fc-border-separate {
  border-collapse: separate;
}
.fc-border-separate th,
.fc-border-separate td {
  border-width: 1px 0 0 1px;
}
.fc-border-separate th.fc-last,
.fc-border-separate td.fc-last {
  border-right-width: 1px;
}
.fc-border-separate tr.fc-last th,
.fc-border-separate tr.fc-last td {
  border-bottom-width: 1px;
}
.fc-border-separate tbody tr.fc-first td,
.fc-border-separate tbody tr.fc-first th {
  border-top-width: 0;
}
.fc-grid th {
  text-align: center;
}
.fc .fc-week-number {
  width: 22px;
  text-align: center;
}
.fc .fc-week-number div {
  padding: 0 2px;
}
.fc-grid .fc-day-number {
  float: right;
  padding: 0 2px;
}
.fc-grid .fc-other-month .fc-day-number {
  opacity: 0.3;
}
.fc-grid .fc-day-content {
  clear: both;
  padding: 2px 2px 1px;
}
.fc-grid .fc-event-time {
  font-weight: bold;
}
.fc-rtl .fc-grid .fc-day-number {
  float: left;
}
.fc-rtl .fc-grid .fc-event-time {
  float: right;
}
.fc-agenda table {
  border-collapse: separate;
}
.fc-agenda-days th {
  text-align: center;
}
.fc-agenda .fc-agenda-axis {
  width: 50px;
  padding: 0 4px;
  vertical-align: middle;
  text-align: right;
  white-space: nowrap;
  font-weight: normal;
}
.fc-agenda .fc-week-number {
  font-weight: bold;
}
.fc-agenda .fc-day-content {
  padding: 2px 2px 1px;
}
.fc-agenda-days .fc-agenda-axis {
  border-right-width: 1px;
}
.fc-agenda-days .fc-col0 {
  border-left-width: 0;
}
.fc-agenda-allday th {
  border-width: 0 1px;
}
.fc-agenda-allday .fc-day-content {
  min-height: 34px;
  _height: 34px;
}
.fc-agenda-divider-inner {
  height: 2px;
  overflow: hidden;
}
.fc-widget-header .fc-agenda-divider-inner {
  background: #eee;
}
.fc-agenda-slots th {
  border-width: 1px 1px 0;
}
.fc-agenda-slots td {
  border-width: 1px 0 0;
  background: none;
}
.fc-agenda-slots td div {
  height: 20px;
}
.fc-agenda-slots tr.fc-slot0 th,
.fc-agenda-slots tr.fc-slot0 td {
  border-top-width: 0;
}
.fc-agenda-slots tr.fc-minor th,
.fc-agenda-slots tr.fc-minor td {
  border-top-style: dotted;
}
.fc-event-vert {
  border-width: 0 1px;
}
.fc-event-vert.fc-event-start {
  border-top-width: 1px;
}
.fc-event-vert.fc-event-end {
  border-bottom-width: 1px;
}
.fc-event-vert .fc-event-time {
  white-space: nowrap;
  font-size: 10px;
}
.fc-event-vert .fc-event-inner {
  position: relative;
  z-index: 2;
}
.fc-event-vert .fc-event-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.3;
}
.fc .ui-draggable-dragging .fc-event-bg,
.fc-select-helper .fc-event-bg {
  display: none;
}
.fc-event-vert .ui-resizable-s {
  bottom: 0 !important;
  width: 100% !important;
  height: 8px !important;
  overflow: hidden !important;
  line-height: 8px !important;
  font-size: 11px !important;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}
