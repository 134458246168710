/*body {margin:0; font:12px Verdana,Arial}*/
.document-sections #input {border:none; margin:0; padding:0; font:14px 'Courier New',Verdana; border:0}
.document-sections .te {border:1px solid #bbb; padding:0; font:12px Verdana,Arial; margin:25px}
.document-sections .te iframe {border:none}
.document-sections .teheader {height:40px; border-bottom:1px solid #bbb; background: #DDDDDD repeat-x;}
.document-sections .teheader select {float:left; margin-top:5px}
.document-sections .tefont {margin-left:12px}
.document-sections .tesize {margin:0 3px}
.document-sections .testyle {margin-right:12px}
.document-sections .tedivider {float:left; width:1px; height:38px; background:#ccc}
.document-sections .tecontrol {float:left; width:34px; height:34px; cursor:pointer; background-image:url(../images/icons.png); border-top: 4px solid #DDDDDD}
.document-sections .tecontrol:hover { background-position:30px 0}
.document-sections .tefooter {height:34px; border-top:1px solid #bbb; background:#DDDDDD}
/*.document-sections .tefooter .toggle {display: none;}*/
.document-sections .toggle {float:left; background:url(../images/icons.png) -34px 2px no-repeat; padding:9px 13px 0 31px; height:23px; cursor:pointer; color:#666}
.document-sections .resize {float:right; height:32px; width:32px; background:url(../images/resize.gif) 15px 15px no-repeat; cursor:s-resize}
.document-sections #editor {cursor:text; margin:10px}
