@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.account-view {
  width: 600px;
}
.account-view h2 {
  text-transform: uppercase;
  text-align: center;
}
.account-view h3 {
  margin: 0;
}
.account-view .form-horizontal .form-group {
  padding: 10px;
  background: #f2f2f2;
}
.account-view .form-horizontal .form-group .value {
  line-height: 36px;
}
.account-view .form-horizontal .form-group .explanation {
  font-size: 14px;
  margin-bottom: 10px;
}
.account-view button.btn.loading {
  background: #2caac4 url("../images/loader.gif") 10px center no-repeat;
}
.archie-token-expired-modal {
  width: 400px;
  top: 100px;
}
.archie-token-expired-modal h2,
.archie-token-expired-modal p,
.archie-token-expired-modal .gdt-logo {
  text-align: center;
}
.archie-token-expired-modal .gdt-logo img {
  height: 30px;
}
.archie-token-expired-modal .password-input-container {
  margin: 10px 0 20px;
}
.archie-token-expired-modal .password-input-container input {
  height: 30px;
  padding: 2px;
  line-height: 26px;
}
