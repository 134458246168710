.feedback-view {
  width: 50rem;
}
.feedback-view .feedback-info-english {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.feedback-view .feedback-info-english span.feedback-info-sign {
  margin-right: 5px;
  width: 17px;
  height: 17px;
  content: url("../images/info.svg");
}
.feedback-view label,
.feedback-view textarea {
  display: block;
  width: 100%;
  margin-top: 1rem;
}
.feedback-view textarea {
  height: 10rem;
}
