@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#new-organization-project-dialog,
#new-project-dialog {
  width: 550px;
}
#new-organization-project-dialog .fields-container,
#new-project-dialog .fields-container {
  margin-top: 20px;
}
#new-organization-project-dialog .fields-container .field,
#new-project-dialog .fields-container .field {
  display: table;
  width: 100%;
  height: 50px;
}
#new-organization-project-dialog .fields-container .field label,
#new-project-dialog .fields-container .field label {
  display: table-row;
}
#new-organization-project-dialog .fields-container .field label span,
#new-project-dialog .fields-container .field label span,
#new-organization-project-dialog .fields-container .field label .input-el,
#new-project-dialog .fields-container .field label .input-el {
  display: table-cell;
  vertical-align: middle;
  padding-right: 20px;
}
#new-organization-project-dialog .fields-container .field label span input,
#new-project-dialog .fields-container .field label span input,
#new-organization-project-dialog .fields-container .field label .input-el input,
#new-project-dialog .fields-container .field label .input-el input {
  height: 32px;
}
#new-organization-project-dialog .fields-container .field label span,
#new-project-dialog .fields-container .field label span {
  width: 150px;
  font-weight: bold;
}
#new-organization-project-dialog .fields-container .field label input,
#new-project-dialog .fields-container .field label input,
#new-organization-project-dialog .fields-container .field label .options-select-container,
#new-project-dialog .fields-container .field label .options-select-container {
  width: 100%;
}
#new-organization-project-dialog .fields-container .field label .select-input,
#new-project-dialog .fields-container .field label .select-input {
  background-color: #fff;
}
#new-organization-project-dialog .buttons-react,
#new-project-dialog .buttons-react {
  margin-top: 20px;
}
