@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.hopscotch-bubble-container.panel-voice-onboarding {
  background-color: #000048;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content {
  padding-bottom: 0;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content .close-button-container {
  text-align: right;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content .close-button-container .hopscotch-close:before {
  font-family: 'gdt';
  content: '\e064';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content .close-button-container .hopscotch-close * {
  margin-left: 0.5rem;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content .close-button-container .hopscotch-close:before {
  color: #fff;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content .hopscotch-title,
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content .hopscotch-content {
  color: #fff;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content .hopscotch-title {
  text-align: center;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-bubble-content .hopscotch-content {
  padding: 10px 20px 0;
  white-space: pre-wrap;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-actions {
  padding-top: 0;
  background-color: #000048;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-actions button:before {
  font-family: 'gdt';
  content: '\e071';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-actions button * {
  margin-left: 0.5rem;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-actions button:before {
  color: #fff;
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-actions .prev {
  transform: rotate(-90deg);
}
.hopscotch-bubble-container.panel-voice-onboarding .hopscotch-actions .next {
  transform: rotate(90deg);
}
div.hopscotch-bubble-arrow-container.panel-voice-onboarding.up div.hopscotch-bubble-arrow-border,
div.hopscotch-bubble-arrow-container.panel-voice-onboarding.up div.hopscotch-bubble-arrow {
  border-bottom-color: #000048;
}
.voting-view {
  background-color: #fff;
  overflow-y: auto;
  height: 100%;
}
.onboarding-notification {
  top: 20vh;
  width: 500px;
}
.resend-info {
  width: 465px;
}
.resend-info label {
  display: block;
  margin-top: 10px;
}
.resend-info label input {
  margin-right: 10px;
}
.wrong-file-extension-modal {
  width: 450px;
  top: 20vh;
}
.wrong-file-extension-modal h4 {
  color: #d53d10;
  text-align: center;
}
.wrong-file-extension-modal .active {
  font-weight: bold;
}
.wrong-file-extension-modal button {
  margin-top: 15px;
}
.voting-notify-modal {
  width: 460px;
}
.voting-notify-modal .buttons {
  text-align: center;
  margin-top: 10px;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.voting-notify-modal .buttons button {
  width: 49%;
}
.close-voting-modal {
  width: 585px;
}
.close-voting-modal .buttons {
  margin-top: 10px;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.close-voting-modal .buttons button {
  width: 49%;
}
.voting-notify-modal,
.close-voting-modal {
  position: relative;
  margin-top: 15%;
  text-align: center;
}
.voting-help-modal img {
  width: 100%;
}
.voting-toolbar .breadcrumbs-container .breadcrumbs li {
  width: 20%;
}
.voting-modal {
  width: 800px !important;
  max-height: 668px;
  overflow: auto;
  margin-top: 2.5%;
  padding-bottom: 80px;
  box-sizing: border-box;
}
.voting-modal .voting-help {
  margin-top: 30px;
}
.voting-modal .voting-message h1 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  font-weight: normal;
}
.voting-modal .voting-message .section-caption {
  font-weight: bold;
}
.voting-modal .voting-message .smaller {
  font-size: 14px;
  font-weight: normal;
}
.voting-modal .voting-message .validation-fault {
  border: 1px solid #f00;
}
.voting-modal .voting-message .validation-text {
  margin-left: 10px;
  color: #f00;
}
.voting-modal .voting-message .message-block {
  position: relative;
}
.voting-modal .voting-message .message-block .message-text {
  max-height: 150px;
  overflow: auto;
  border: 1px solid #cecece;
}
.voting-modal .voting-message .message-block .message-text .gdt-editor-container,
.voting-modal .voting-message .message-block .message-text .fake-link-block {
  background-color: #fff;
  border: none;
}
.voting-modal .voting-message .message-block .message-text .gdt-editor-container:focus,
.voting-modal .voting-message .message-block .message-text .fake-link-block:focus {
  outline: none;
}
.voting-modal .voting-message .message-block .message-text .gdt-editor-container {
  border: none;
}
.voting-modal .voting-message .message-block .message-text .gdt-editor-container .public-DraftEditor-content {
  min-height: initial;
}
.voting-modal .voting-message .message-block .message-text .gdt-editor-container .style-controls-container {
  position: fixed;
  top: calc(2.5% + 80px);
}
.voting-modal .voting-message .message-block .message-text .fake-link-block {
  padding: 0 5px 5px 5px;
  color: #bebebe;
}
.voting-modal .voting-message .meta-block {
  position: relative;
  margin: 15px 0 0;
}
.voting-modal .voting-message .meta-block > div {
  display: inline-block;
  vertical-align: top;
}
.voting-modal .voting-message .meta-block input.date {
  width: 150px;
  height: 30px;
}
.voting-modal .voting-message .meta-block button.date-pick {
  background-color: #cecece;
  height: 30px;
  width: 28px;
}
.voting-modal .voting-message .meta-block button.date-pick:after {
  font-family: 'gdt';
  content: '\e010';
  text-align: center;
}
.voting-modal .voting-message .meta-block .DayPicker-Container {
  position: absolute;
  display: block;
  right: 320px;
  top: -16px;
  width: 250px;
  background-color: #eaeaea;
  border: 1px solid #cecece;
  z-index: 99;
}
.voting-modal .voting-message .meta-block .DayPicker-Container .DayPicker-Day--disabled {
  background-color: inherit;
  color: #cecece;
}
.voting-modal .voting-message .meta-block .contact-email {
  margin-left: 30px;
  width: 510px;
}
.voting-modal .voting-message .meta-block .contact-email input {
  display: block;
  height: 30px;
  width: 100%;
}
.voting-modal .voting-message .details-block .section-caption {
  display: inline-block;
  margin: 15px 0 0;
}
.voting-modal .voting-message .details-block div.questions-list {
  max-height: 160px;
  overflow: auto;
}
.voting-modal .voting-message .details-block div.questions-list span {
  display: block;
}
.voting-modal .voting-message .details-block div.questions-list .question-text,
.voting-modal .voting-message .details-block div.questions-list .due-date {
  display: inline-block;
}
.voting-modal .voting-message .details-block div.questions-list .question-text {
  width: calc(100% - 100px);
}
.voting-modal .voting-message .details-block div.reminder-details .details-captions .section-caption,
.voting-modal .voting-message .details-block div.reminder-details .details-captions .due-date {
  display: inline-block;
}
.voting-modal .voting-message .details-block div.reminder-details .question-text {
  float: left;
  width: 80%;
}
.voting-modal .voting-message .details-block div.reminder-details .due-date {
  float: right;
  text-align: center;
  width: 20%;
}
.voting-modal .voting-message .details-block div.reminder-details .due-date.past-due {
  color: #d7191c;
}
.voting-modal .voting-message .details-block div.reminder-details .due-date.due-soon {
  color: #fdae61;
}
.voting-modal .voting-message .details-block div.coi-questions-info {
  margin-top: 20px;
  font-weight: bold;
}
.voting-modal .voting-message .buttons {
  display: flex;
  margin-top: 10px;
}
.voting-modal .voting-message .buttons button {
  width: 370px;
  margin-right: 10px;
}
.voting-modal .voting-message .buttons button.cancel {
  margin-right: 18px;
}
.voting-container {
  font-size: 14px;
}
.voting-container button {
  font-size: 16px;
}
.voting-container button.feedback-status {
  margin-right: 20px;
  color: #009cca;
  font-size: 12px;
}
.voting-container button.feedback-status:before {
  font-family: 'gdt';
  content: '\e006';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.voting-container button.feedback-status * {
  margin-left: 0.5rem;
}
.voting-container button.feedback-status:before {
  display: block;
  font-size: 22px;
}
.info-link {
  cursor: pointer;
}
.question-voting-status {
  display: inline-block;
}
.question-voting-status span.inactive {
  display: inline-block;
  position: relative;
  padding-right: 15px;
  color: #666;
}
.question-voting-status span.inactive:before {
  content: "";
}
.question-voting-status span.inactive:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e066';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.question-voting-status span.inactive:after {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  color: #3783a5;
}
.question-voting-status .etd-parts-statuses {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 91px;
  box-sizing: border-box;
  height: 100%;
  padding-top: 8px;
}
.question-voting-status .etd-parts-statuses .etd-part {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  vertical-align: middle;
}
.question-voting-status .etd-parts-statuses .etd-part.multi-2 {
  width: 58px;
}
.question-voting-status .etd-parts-statuses .etd-part.multi-3 {
  width: 91px;
}
.question-voting-status .etd-parts-statuses .etd-part .label {
  display: inline-block;
  text-align: center;
  line-height: 25px;
  width: 25px;
}
.question-voting-status .etd-parts-statuses .etd-part.ongoing {
  background-color: #35d4c7;
}
.question-voting-status .etd-parts-statuses .etd-part.closed {
  background-color: #29839d;
}
.question-voting-status .etd-parts-statuses .etd-part.inactive,
.question-voting-status .etd-parts-statuses .etd-part.unsent {
  background-color: #d1d1d1;
}
.question-voting-status .etd-parts-statuses .etd-part.skipped {
  background-color: #999;
  color: #666;
}
.question-voting-status .status-text {
  margin-top: 8px;
  font-size: 14px;
  color: #808080;
  line-height: 14px;
}
ol {
  margin: 7px 0;
  padding-left: 20px;
}
ol .voting-options {
  margin: 7px 0;
}
ol .voting-options label {
  display: block;
  font-size: 14px;
}
ol .voting-options input[disabled] {
  border: 1px solid #bfbfbf;
}
input[type="radio"] {
  margin: 2px 5px 0 0;
  border: 1px solid #808080;
}
.upper-block p,
.phase2-block p {
  margin: 3px 0;
}
.questions-block {
  background: #fff;
  position: relative;
}
.questions-block .due-date {
  width: 100px;
}
.questions-block .due-date.past-due {
  color: #d7191c;
}
.questions-block .due-date.due-soon {
  color: #ff7c10;
}
.questions-block .votes-stats {
  width: 70px;
}
.questions-block .voting-info-only {
  width: 100%;
  text-align: right;
  padding-right: 48px;
}
.questions-block .header {
  margin: 10px 0 5px 22px;
  padding-right: 10px;
  position: relative;
}
.questions-block .header input {
  border: 1px solid #808080;
  margin-right: 10px;
}
.questions-block .header .voting-info {
  display: inline-block;
  float: right;
  width: 170px;
}
.questions-block .header .voting-info .due-date,
.questions-block .header .voting-info .votes-stats {
  display: inline-block;
  text-align: center;
}
.questions-block hr {
  margin: 0;
}
.questions-block ul {
  padding: 0;
  margin: 0;
}
.questions-block ul.standard-list {
  display: block;
  min-height: 44px;
  overflow: auto;
}
.questions-block li.question-group,
.questions-block li.questions-wo-group {
  font-size: 14px;
  height: auto;
  padding: 0;
  border: 0;
}
.questions-block li.question-group li.question,
.questions-block li.questions-wo-group li.question {
  display: table;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #fff;
  padding-right: 10px;
}
.questions-block li.question-group li.question:last-child,
.questions-block li.questions-wo-group li.question:last-child {
  border-bottom: 0;
}
.questions-block li.question-group li.question .toggle,
.questions-block li.questions-wo-group li.question .toggle {
  width: 33p;
}
.questions-block li.question-group li.question .toggle,
.questions-block li.questions-wo-group li.question .toggle,
.questions-block li.question-group li.question .caption,
.questions-block li.questions-wo-group li.question .caption,
.questions-block li.question-group li.question .stats,
.questions-block li.questions-wo-group li.question .stats {
  display: table-cell;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;
}
.questions-block li.question-group li.question .toggle,
.questions-block li.questions-wo-group li.question .toggle {
  width: 21px;
  text-align: right;
  padding-right: 10px;
}
.questions-block li.question-group li.question .toggle input,
.questions-block li.questions-wo-group li.question .toggle input {
  border: 1px solid #808080;
}
.questions-block li.question-group li.question .toggle input[disabled],
.questions-block li.questions-wo-group li.question .toggle input[disabled] {
  border: 1px solid #bfbfbf;
}
.questions-block li.question-group li.question .caption,
.questions-block li.questions-wo-group li.question .caption {
  position: relative;
  cursor: default;
  line-height: 40px;
}
.questions-block li.question-group li.question .caption .caption-text,
.questions-block li.questions-wo-group li.question .caption .caption-text {
  max-height: 40px;
  overflow: hidden;
  position: relative;
}
.questions-block li.question-group li.question .stats,
.questions-block li.questions-wo-group li.question .stats {
  width: 170px;
  position: relative;
}
.questions-block li.question-group li.question .stats .votes-stats,
.questions-block li.questions-wo-group li.question .stats .votes-stats,
.questions-block li.question-group li.question .stats .due-date,
.questions-block li.questions-wo-group li.question .stats .due-date {
  display: inline-block;
  text-align: center;
}
.questions-block li.question-group {
  background-color: #fff;
  border: 1px solid #009cca;
  padding: 0px 10px 10px;
  margin-bottom: 40px;
}
.questions-block li.question-group > .caption {
  cursor: default;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
}
.questions-block li.question-group:hover {
  background-color: #fff;
}
.questions-block li.questions-wo-group {
  border: 0;
}
button.start-voting {
  width: 100%;
  margin-top: 10px;
}
.no-questions {
  margin-top: 25px;
  margin-left: 25px;
}
.voting-summary-container {
  padding-top: 5px;
}
.voting-summary-container .etd-parts-summaries {
  overflow: auto;
  position: relative;
  padding-bottom: 20px;
}
.voting-summary-container .etd-parts-summaries .summary-block {
  margin-top: 10px;
  position: relative;
  border: 1px solid #cecece;
  padding: 10px;
}
.voting-summary-container .etd-parts-summaries .summary-block .section-text {
  margin-bottom: 13px;
}
.voting-summary-container .etd-parts-summaries .summary-block .buttons {
  position: relative;
  margin-top: 10px;
}
.voting-summary-container .etd-parts-summaries .summary-block .buttons button {
  width: 49%;
}
.voting-summary-container .etd-parts-summaries .summary-block .buttons .btn-left {
  margin-right: 1%;
}
.voting-summary-container .etd-parts-summaries .summary-block .buttons .btn-right {
  margin-left: 1%;
}
.phase1-voting-start,
.phase2-voting-start {
  padding-bottom: 20px;
}
.voting-finished {
  position: relative;
  padding-top: 5px;
}
.phase2-block {
  margin-top: 30px;
  border: 1px solid #cecece;
  padding: 10px;
}
.panel-voice-container .top-section .onboarding-target,
.panel-voice-container .panel-voice-questions-container .onboarding-target {
  position: relative;
}
.panel-voice-container .top-section .onboarding-target:before,
.panel-voice-container .panel-voice-questions-container .onboarding-target:before {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  content: ' ';
  border: 2px solid #000048;
}
.panel-voice-container .top-section {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}
.panel-voice-container .top-section .btn-icon-container,
.panel-voice-container .top-section .status-filter-container {
  display: inline-block;
  height: 38px;
}
.panel-voice-container .top-section .btn-icon-container {
  margin-right: 60px;
  text-align: center;
}
.panel-voice-container .top-section .btn-icon-container .label-text {
  padding: 0 10px;
}
.panel-voice-container .top-section .status-filter-container {
  vertical-align: top;
}
.panel-voice-container .top-section .status-filter-container .status-caption {
  margin-right: 10px;
}
.panel-voice-container .top-section .status-filter-container .select-input {
  width: 250px;
  border: 1px solid #009cca;
  border-radius: 4px;
}
.panel-voice-container .top-section .questions-search {
  width: 30%;
}
.panel-voice-container .top-section .questions-search input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.panel-voice-container .top-section .questions-search button.search {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.panel-voice-container .panel-voice-questions-container .questions,
.panel-voice-container .panel-voice-questions-container .question,
.panel-voice-container .panel-voice-questions-container .responses,
.panel-voice-container .panel-voice-questions-container .due-date,
.panel-voice-container .panel-voice-questions-container .status,
.panel-voice-container .panel-voice-questions-container .actions {
  box-sizing: content-box;
}
.panel-voice-container .panel-voice-questions-container .responses {
  width: 83px;
}
.panel-voice-container .panel-voice-questions-container .due-date {
  width: 90px;
}
.panel-voice-container .panel-voice-questions-container .status {
  position: relative;
  width: 144px;
}
.panel-voice-container .panel-voice-questions-container .actions {
  width: 137px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header,
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-question {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header {
  margin-bottom: 5px;
  padding-left: 42px;
  padding-right: 2px;
  background: #dedede;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header.with-groups-offset {
  padding-left: 54px;
  padding-right: 14px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header > div {
  text-align: center;
  line-height: 40px;
  position: relative;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header .questions,
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header .responses,
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header .due-date,
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header .status {
  border-right: 2px solid #fff;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__header .questions {
  width: calc(100% - 462px);
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body {
  position: relative;
  height: calc(100vh - 235px);
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body.with-groups-offset .questions-list-container__questions-group {
  padding: 0 12px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container .items {
  max-height: initial;
  overflow: initial;
  margin-bottom: 0;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__questions-group.with-caption {
  margin-bottom: 10px;
  padding: 0 10px 10px;
  border: 2px solid #009cca;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__questions-group.with-caption .questions-list-container__group-title {
  padding: 15px 15px;
  font-weight: bold;
  line-height: inherit;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__question {
  background: none;
  border-bottom: 0;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__question:hover .panel-voice-question,
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__question:hover:before {
  background: #dedede;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__question:hover .question {
  background: none;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__question:before {
  width: 40px;
  height: 68px;
  text-align: center;
  background: #f2f2f2;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  line-height: 68px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__question .panel-voice-question .question {
  width: calc(100% - 464px);
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .questions-list-container__questions-group .panel-voice-question .question {
  width: calc(100% - 464px);
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question {
  height: 70px;
  box-sizing: border-box;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.overarching {
  position: relative;
  padding-left: 42px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.overarching:before {
  background-color: #f2f2f2;
  position: absolute;
  left: 0;
  width: 40px;
  line-height: 70px;
  height: 100%;
  text-align: center;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  color: #fabe03;
  font-family: 'gdt';
  content: '\e097';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.overarching:hover {
  background-color: #dedede;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.overarching:hover .panel-voice-question__cell {
  border-color: #fff;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.overarching:hover:before,
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.overarching:hover .question {
  background-color: transparent;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.inactive,
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.completely-closed {
  background-color: #f2f2f2;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.inactive .panel-voice-question__cell,
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question.completely-closed .panel-voice-question__cell {
  border-color: #fff;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .panel-voice-question__cell {
  position: relative;
  border-right: 2px solid #f2f2f2;
  border-top: 2px solid #f2f2f2;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .panel-voice-question__cell .responses-active {
  cursor: pointer;
  color: #3783a5;
  font-weight: bold;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .panel-voice-question__cell .responses-active span {
  font-size: 11px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .cell-content {
  width: 100%;
  padding: 5px;
  text-align: center;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .question {
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  background: #f2f2f2;
  width: calc(100% - 504px);
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .question .cell-content {
  text-align: left;
  font-size: 16px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .responses .cell-content {
  font-size: 14px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .actions .cell-content {
  padding: 0;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .actions .question-voting-controls {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .actions .question-voting-controls .btn-icon-container {
  text-align: center;
  width: 75px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .actions .question-voting-controls .btn-icon-container:last-child {
  width: 62px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .actions .question-voting-controls button {
  color: #3783a5;
  height: 25px;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .actions .question-voting-controls button.refresh {
  transform: scale(-1, 1);
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .actions .question-voting-controls button.literal {
  font-size: 15px;
  font-weight: bold;
}
.panel-voice-container .panel-voice-questions-container .panel-voice-questions .panel-voice-questions__body .panel-voice-question .actions .question-voting-controls .label-text {
  font-size: 12px;
}
.panel-members-resend .add-new-member {
  font-size: 14px;
}
.panel-members-resend .member-row {
  padding: 5px 10px;
  background: #f6f6f6;
  border-bottom: 1px solid #fff;
}
.send-test-modal {
  max-width: 600px;
}
.send-test-modal .add-new-member {
  width: auto;
  background-size: 25px 25px;
  background-position: left bottom;
  padding-top: 10px;
  padding-left: 30px;
}
.add-organization-member-modal,
.send-test-modal,
.panel-member-selection {
  font-size: 14px;
}
.add-organization-member-modal .title,
.send-test-modal .title,
.panel-member-selection .title {
  margin-top: 10px;
}
.add-organization-member-modal .select-all.checkbox,
.send-test-modal .select-all.checkbox,
.panel-member-selection .select-all.checkbox {
  margin-bottom: 10px;
}
.add-organization-member-modal input[type=checkbox].select-all,
.send-test-modal input[type=checkbox].select-all,
.panel-member-selection input[type=checkbox].select-all {
  margin-right: 15px;
}
.add-organization-member-modal button,
.send-test-modal button,
.panel-member-selection button {
  font-size: 14px;
}
.add-organization-member-modal .options-row .checkbox,
.send-test-modal .options-row .checkbox,
.panel-member-selection .options-row .checkbox {
  width: auto;
}
.add-organization-member-modal .options-row .checkbox input[type=checkbox],
.send-test-modal .options-row .checkbox input[type=checkbox],
.panel-member-selection .options-row .checkbox input[type=checkbox] {
  margin-right: 15px;
}
.add-organization-member-modal .member-row-with-checkbox .checkbox,
.send-test-modal .member-row-with-checkbox .checkbox,
.panel-member-selection .member-row-with-checkbox .checkbox,
.add-organization-member-modal .external-members .checkbox,
.send-test-modal .external-members .checkbox,
.panel-member-selection .external-members .checkbox,
.add-organization-member-modal .members .checkbox,
.send-test-modal .members .checkbox,
.panel-member-selection .members .checkbox {
  margin-top: 5px;
  float: left;
  width: 20px;
}
.add-organization-member-modal .member-row-with-checkbox .member-row,
.send-test-modal .member-row-with-checkbox .member-row,
.panel-member-selection .member-row-with-checkbox .member-row,
.add-organization-member-modal .external-members .member-row,
.send-test-modal .external-members .member-row,
.panel-member-selection .external-members .member-row,
.add-organization-member-modal .members .member-row,
.send-test-modal .members .member-row,
.panel-member-selection .members .member-row {
  margin-left: 25px;
  padding: 5px 10px;
  background: #f6f6f6;
  border-bottom: 1px solid #fff;
}
.add-organization-member-modal .member-row-with-checkbox .with-coi .member-row,
.send-test-modal .member-row-with-checkbox .with-coi .member-row,
.panel-member-selection .member-row-with-checkbox .with-coi .member-row,
.add-organization-member-modal .external-members .with-coi .member-row,
.send-test-modal .external-members .with-coi .member-row,
.panel-member-selection .external-members .with-coi .member-row,
.add-organization-member-modal .members .with-coi .member-row,
.send-test-modal .members .with-coi .member-row,
.panel-member-selection .members .with-coi .member-row {
  background-color: #fff0bb;
}
.add-organization-member-modal .member-row-with-checkbox input[type="text"],
.send-test-modal .member-row-with-checkbox input[type="text"],
.panel-member-selection .member-row-with-checkbox input[type="text"],
.add-organization-member-modal .external-members input[type="text"],
.send-test-modal .external-members input[type="text"],
.panel-member-selection .external-members input[type="text"],
.add-organization-member-modal .members input[type="text"],
.send-test-modal .members input[type="text"],
.panel-member-selection .members input[type="text"] {
  line-height: 25px;
  font-size: 14px;
  width: 100%;
}
.add-organization-member-modal .external-members,
.send-test-modal .external-members,
.panel-member-selection .external-members {
  margin-top: 10px;
}
.add-organization-member-modal .external-members .member-row,
.send-test-modal .external-members .member-row,
.panel-member-selection .external-members .member-row {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.add-organization-member-modal .external-members .member-row input[type="text"],
.send-test-modal .external-members .member-row input[type="text"],
.panel-member-selection .external-members .member-row input[type="text"] {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-right: 5px;
}
.add-organization-member-modal .external-members .member-row .remove-member,
.send-test-modal .external-members .member-row .remove-member,
.panel-member-selection .external-members .member-row .remove-member {
  position: relative;
  top: -8px;
  font-size: 10px;
}
.add-organization-member-modal .external-members .member-row .remove-member:before,
.send-test-modal .external-members .member-row .remove-member:before,
.panel-member-selection .external-members .member-row .remove-member:before {
  font-family: 'gdt';
  content: '\e064';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.add-organization-member-modal .external-members .member-row .remove-member *,
.send-test-modal .external-members .member-row .remove-member *,
.panel-member-selection .external-members .member-row .remove-member * {
  margin-left: 0.5rem;
}
.feedback-status-modal {
  font-size: 14px;
  max-width: 700px;
}
.feedback-status-modal .legend {
  margin: 10px 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.feedback-status-modal .legend .legend-item {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.feedback-status-modal .legend .legend-item__box {
  borderRadius: 2px;
  width: 24px;
  height: 24px;
  textAlign: center;
}
.feedback-status-modal .legend .description {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 5px;
}
.selecting-questions h3 {
  margin: 0px auto;
}
.selecting-questions .current-question,
.selecting-questions .group-container {
  margin-bottom: 10px;
}
.selecting-questions .current-question .item-with-checkbox__checkbox {
  margin-right: 6px;
}
.selecting-questions .current-question .item-with-checkbox__item {
  padding: 0 5px;
}
.selecting-questions .item-with-checkbox__item .question-row {
  padding: 0 10px;
  background: #f6f6f6;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 40px;
  margin-bottom: 2px;
}
.selecting-questions .item-with-checkbox__item .question-row.overarching-question {
  padding-left: 40px;
  position: relative;
}
.selecting-questions .item-with-checkbox__item .question-row.overarching-question:before {
  background-color: #f6f6f6;
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fabe03;
  font-family: 'gdt';
  content: '\e097';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.section-description {
  padding: 25px 30px 0px;
  color: #8f99a2;
}
.section-selection {
  padding: 20px 70px;
}
.section-selection .head {
  width: 250px;
  margin-bottom: 10px;
}
.section-selection .section-selection-row {
  padding: 5px 20px;
}
.section-selection .section-selection-row.disabled {
  background-color: #ccc;
  opacity: 0.53;
}
.section-selection .section-selection-row.checked {
  background-color: #e9faff;
}
.section-selection .section-selection-row input[type="checkbox"] {
  cursor: pointer;
  margin-top: 5px;
  line-height: 20px;
  margin-right: 20px;
}
.voting-send-modal {
  font-size: 14px;
}
.voting-send-modal .buttons-react {
  margin-top: 20px;
}
.voting-send-modal .validation-fault {
  border: 1px solid #f00;
}
.voting-send-modal .validation-text {
  margin-left: 10px;
  color: #f00;
}
.voting-send-modal .validation-text.email {
  margin-left: 0;
}
.voting-send-modal .message-block {
  position: relative;
}
.voting-send-modal .message-block .message-text {
  width: 100%;
  max-height: 150px;
  overflow: auto;
  border: 1px solid #cecece;
}
.voting-send-modal .message-block .message-text .gdt-editor-container,
.voting-send-modal .message-block .message-text .fake-link-block {
  background-color: #fff;
  border: none;
}
.voting-send-modal .message-block .message-text .gdt-editor-container:focus,
.voting-send-modal .message-block .message-text .fake-link-block:focus {
  outline: none;
}
.voting-send-modal .message-block .message-text .gdt-editor-container {
  border: none;
}
.voting-send-modal .message-block .message-text .gdt-editor-container .public-DraftEditor-content {
  min-height: initial;
}
.voting-send-modal .message-block .message-text .gdt-editor-container .style-controls-container {
  position: fixed;
  top: calc(2.5% + 80px);
}
.voting-send-modal .message-block .message-text .fake-link-block {
  padding: 0 5px 5px 5px;
  color: #bebebe;
}
.voting-send-modal .meta-block {
  margin-top: 10px;
  position: relative;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.voting-send-modal .due-date-block {
  margin-right: 30px;
}
.voting-send-modal input {
  font-size: 14px;
}
.voting-send-modal input.date {
  width: 150px;
  height: 30px;
}
.voting-send-modal button.date-pick {
  background-color: #cecece;
  height: 30px;
  width: 28px;
}
.voting-send-modal button.date-pick:after {
  font-family: 'gdt';
  content: '\e010';
  text-align: center;
}
.voting-send-modal .DayPicker-Container {
  position: absolute;
  display: block;
  right: 320px;
  top: 0px;
  width: 250px;
  background-color: #eaeaea;
  border: 1px solid #cecece;
  z-index: 99;
}
.voting-send-modal .DayPicker-Container .DayPicker-Day--disabled {
  background-color: inherit;
  color: #cecece;
}
.voting-send-modal .contact-email {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.voting-send-modal .contact-email input {
  display: block;
  height: 30px;
  width: 100%;
}
.voting-send-modal .keep-message {
  position: relative;
  z-index: 2;
  float: right;
  line-height: 26px;
  font-weight: bold;
}
.voting-send-modal .keep-message input[type="checkbox"] {
  margin-left: 5px;
}
.reminder-modal {
  width: 700px;
  font-size: 14px;
}
.reminder-modal .send-button {
  margin-top: 25px;
}
.reminder-modal .send-form-new-members {
  margin-top: 25px;
}
.status-legend {
  background: #fff;
  position: absolute;
  z-index: 2;
  top: 5px;
  right: -20px;
  width: 260px;
  color: #2d3236;
  text-align: left;
  padding: 20px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #bfbfbf;
}
.status-legend .arrow {
  position: absolute;
  right: 10px;
  top: -10px;
  width: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #bfbfbf;
}
.status-legend .arrow .inside-arrow {
  position: absolute;
  top: 2px;
  right: -8px;
  width: 8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}
.status-legend h3 {
  line-height: 16px;
  margin: 0px 0px 15px;
}
.status-legend ul,
.status-legend ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.status-legend li {
  line-height: 25px;
  margin: 10px 0px;
}
.status-legend li .bold {
  margin-right: 10px;
}
.status-legend .separator {
  margin: 20px 0px;
  height: 1px;
  background-color: #00f;
}
.status-legend .rect-container,
.status-legend .legend-rects {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.status-legend .rect-container {
  margin-bottom: 20px;
  margin-right: 20px;
}
.status-legend .rect {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 3px;
}
.status-legend .rect.ongoing {
  background-color: #35d4c7;
}
.status-legend .rect.closed {
  background-color: #29839d;
}
.status-legend .rect.inactive,
.status-legend .rect.unsent {
  background-color: #d1d1d1;
}
.status-legend .rect.skipped {
  background-color: #999;
  color: #666;
}
.status-legend .label {
  margin-left: 10px;
  line-height: 30px;
  display: inline-block;
}
.status-legend .label:after {
  content: "";
  clear: both;
}
.panel-voice-feedback-matrix__default-status {
  border: 1px solid #bfbfbf;
  background-color: #f2f2f2;
  text-align: center;
}
.panel-voice-feedback-matrix__sent-status {
  background-color: #a9a9a9;
}
.panel-voice-feedback-matrix__finished-status {
  background-color: #fbb03b;
}
