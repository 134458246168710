@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.fake-hidden {
  overflow: hidden;
  height: 0px;
}
.see-evidence-button {
  color: #0b6dad;
}
#visual-guidelines,
#mda-topic,
#document-sections-v2-container {
  overflow-y: auto;
}
#visual-guidelines .chapters-list,
#mda-topic .chapters-list,
#document-sections-v2-container .chapters-list {
  margin-top: -10px;
}
#visual-guidelines .chapters-list-search,
#mda-topic .chapters-list-search,
#document-sections-v2-container .chapters-list-search {
  max-height: 300px;
  overflow: auto;
  margin-bottom: 10px;
}
#visual-guidelines .chapters-list-search .search-items,
#mda-topic .chapters-list-search .search-items,
#document-sections-v2-container .chapters-list-search .search-items {
  counter-reset: search-item;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#visual-guidelines .chapters-list-search .search-items .title:before,
#mda-topic .chapters-list-search .search-items .title:before,
#document-sections-v2-container .chapters-list-search .search-items .title:before {
  content: counters(search-item, ".") ". ";
  counter-increment: search-item;
}
#visual-guidelines .chapters-list-search .search-items .title > div,
#mda-topic .chapters-list-search .search-items .title > div,
#document-sections-v2-container .chapters-list-search .search-items .title > div {
  display: inline-block;
}
#visual-guidelines .chapters-list-search .search-items .title.selected,
#mda-topic .chapters-list-search .search-items .title.selected,
#document-sections-v2-container .chapters-list-search .search-items .title.selected,
#visual-guidelines .chapters-list-search .search-items .section-title.selected,
#mda-topic .chapters-list-search .search-items .section-title.selected,
#document-sections-v2-container .chapters-list-search .search-items .section-title.selected,
#visual-guidelines .chapters-list-search .search-items .title:hover,
#mda-topic .chapters-list-search .search-items .title:hover,
#document-sections-v2-container .chapters-list-search .search-items .title:hover,
#visual-guidelines .chapters-list-search .search-items .section-title:hover,
#mda-topic .chapters-list-search .search-items .section-title:hover,
#document-sections-v2-container .chapters-list-search .search-items .section-title:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
#visual-guidelines .document-sections-v2,
#mda-topic .document-sections-v2,
#document-sections-v2-container .document-sections-v2 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
#visual-guidelines .document-sections-v2 .chapters-list,
#mda-topic .document-sections-v2 .chapters-list,
#document-sections-v2-container .document-sections-v2 .chapters-list {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-left: 10px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items,
#mda-topic .document-sections-v2 .chapters-list .population-list-items,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items {
  counter-reset: item;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .collapsed-content-row,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .collapsed-content-row,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .collapsed-content-row,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .collapsed-content-row,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .collapsed-content-row,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .collapsed-content-row {
  overflow: visible;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-caption,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-caption,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-caption,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-caption,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-caption,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-caption {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  padding: 0;
  cursor: pointer;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-caption .controls,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-caption .controls,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-caption .controls,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-caption .controls,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-caption .controls,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-caption .controls {
  display: none;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-content,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-content,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-content,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-content {
  border-bottom: 1px solid #d9d9d9;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px 15px 24px 15px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text {
  margin: 0;
  padding: 0;
  color: #0b6dad;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text:before,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text:before,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text:before,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text:before,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text:before,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-header-text:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-from-acoem,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-from-acoem,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-from-acoem,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-from-acoem,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-from-acoem,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-from-acoem {
  background: #d9d9d9;
  padding: 2px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  font-size: 10px;
  height: 18px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-mark-for-panel-review,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-mark-for-panel-review,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-mark-for-panel-review,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-mark-for-panel-review,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-mark-for-panel-review,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .chapter-header-left .chapter-mark-for-panel-review {
  margin: 0px 25px;
  font-weight: bold;
  color: #e756e1;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .settings-toggle,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .settings-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header .settings-toggle,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .settings-toggle,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .settings-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header .settings-toggle {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  font-size: 26px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header:hover .settings-toggle,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header:hover .settings-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header:hover .settings-toggle,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header:hover .settings-toggle,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header:hover .settings-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header:hover .settings-toggle,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header.expanded .settings-toggle,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header.expanded .settings-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header.expanded .settings-toggle,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header.expanded .settings-toggle,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header.expanded .settings-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header.expanded .settings-toggle {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  cursor: pointer;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header.over,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header.over,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-header.over,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header.over,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header.over,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-header.over {
  background-color: #ddeaf9;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections {
  padding: 20px 0 28px 24px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container {
  padding: 5px 0;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container .no-sections-add-text,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container .no-sections-add-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container .no-sections-add-text,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container .no-sections-add-text,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container .no-sections-add-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .no-sections-container .no-sections-add-container .no-sections-add-text {
  color: #0b6dad;
  font-style: italic;
  cursor: pointer;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section.dragging,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section.dragging,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section.dragging,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section.dragging,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section.dragging,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section.dragging {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .editing-by-info:before,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .editing-by-info:before,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .editing-by-info:before,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .editing-by-info:before,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .editing-by-info:before,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .editing-by-info:before {
  content: '';
  display: block;
  height: 11px;
  width: 11px;
  margin-right: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #ff5186;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-text,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-text,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-text,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-text {
  font-weight: bold;
  margin: 2px 4px 0 4px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-input,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-input,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-input,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-input,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-input,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .document-sections-title .title-input {
  margin-left: 4px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count {
  position: relative;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count span,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count span,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count span,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count span,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count span,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count span {
  position: absolute;
  left: -4px;
  width: 16px;
  text-align: center;
  color: #fff;
  font-size: 10px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count .btn-icon:before,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count .btn-icon:before,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count .btn-icon:before,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count .btn-icon:before,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count .btn-icon:before,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .section-comments-count .btn-icon:before {
  color: #ffba6c;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content {
  width: 100px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  height: 15px;
  line-height: 15px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content:hover,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content:hover,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content:hover,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-content:hover {
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.161);
  box-shadow: 0 3px 6px rgba(0,0,0,0.161);
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-no-status,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-no-status,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-no-status,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-no-status,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-no-status,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-no-status {
  background-color: #d9d9d9;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-progress,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-progress,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-progress,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-progress,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-progress,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-progress {
  background-color: #ffba6c;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-review,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-review,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-review,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-review,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-review,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-in-review {
  background-color: #ff5186;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-updated,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-updated,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-updated,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-updated,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-updated,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-updated {
  background-color: #6ec073;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-done,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-done,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-done,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-done,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-done,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .status-done {
  background-color: #0089ba;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .settings-toggle,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .settings-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .settings-toggle,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .settings-toggle,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .settings-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-header .chapter-section-management .settings-toggle {
  cursor: pointer;
  font-size: 26px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content {
  margin: 10px 0 35px 0;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root {
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root {
  -webkit-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  -o-transition: box-shadow 0.3s ease;
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root.ProseMirror-focused .section-link,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root.ProseMirror-focused .section-link,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root.ProseMirror-focused .section-link,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root.ProseMirror-focused .section-link,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root.ProseMirror-focused .section-link,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root.ProseMirror-focused .section-link {
  cursor: initial;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]),
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]),
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]),
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]),
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]),
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]) {
  background-color: #fda;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]) .comment-highlighted,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]) .comment-highlighted,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]) .comment-highlighted,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]) .comment-highlighted,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]) .comment-highlighted,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .ds-discussion-comment:not([data-comment-resolved="true"]) .comment-highlighted {
  background-color: #f99500;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .comment-highlighted,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .comment-highlighted,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .comment-highlighted,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .comment-highlighted,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .comment-highlighted,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .comment-highlighted {
  background-color: rgba(0,0,0,0.169);
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .inserted-references,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .inserted-references,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .inserted-references,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .inserted-references,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .inserted-references,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .inserted-references {
  background-color: #ebebeb;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .section-link,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .section-link,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .section-link,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .section-link,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .section-link,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .section-link,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .chapter-link,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .chapter-link,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .chapter-link,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .chapter-link,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .chapter-link,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .epiditor--pm-editor-root .chapter-link {
  font-weight: bold;
  color: #0080ff;
  text-decoration: underline;
  cursor: pointer;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle {
  white-space: nowrap;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle.disabled,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle.disabled,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle.disabled,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle.disabled,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle.disabled,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .reference-insert-toggle.disabled {
  cursor: not-allowed;
  color: #9a9999;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget {
  position: absolute;
  -webkit-transition: top 0.3s ease;
  -moz-transition: top 0.3s ease;
  -o-transition: top 0.3s ease;
  -ms-transition: top 0.3s ease;
  transition: top 0.3s ease;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add {
  width: 32px;
  height: 32px;
  padding: 4px 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #26c6da;
  -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s ease;
  -moz-transition: -moz-transform 0.2s ease;
  -o-transition: -o-transform 0.2s ease;
  -ms-transition: -ms-transform 0.2s ease;
  transition: transform 0.2s ease;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add:hover,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add:hover,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add:hover,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .add-comment-widget .ds-discussion-add:hover {
  -webkit-transform: scale(1.15, 1.15);
  -moz-transform: scale(1.15, 1.15);
  -o-transform: scale(1.15, 1.15);
  -ms-transform: scale(1.15, 1.15);
  transform: scale(1.15, 1.15);
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor {
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  border: 1px solid #fff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker {
  padding: 15px;
  padding-bottom: 20px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results {
  top: 100%;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .non-ideal-state {
  font-size: 12px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results {
  width: 100%;
  -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  background-color: #fff;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section {
  padding: 10px 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section:hover,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section:hover,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section:hover,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .section-search-results .section:hover {
  background-color: #f2f2f2;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .sections-search,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .sections-search,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .sections-search,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .sections-search,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .sections-search,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .sections-search {
  border: 1px solid #d9d9d9;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  height: 30px;
  padding-right: 26px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .spinner-el--small,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .spinner-el--small,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .spinner-el--small,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .spinner-el--small,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .spinner-el--small,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker .spinner-el--small {
  position: absolute;
  right: 10px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker button.create,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker button.create,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker button.create,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker button.create,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker button.create,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .section-link-editor .sections-picker button.create {
  height: 30px;
  color: #0089ba;
  background: #f2f2f2;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-weight: bold;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  height: 25px;
  direction: row;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls button,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls button,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls button,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls button,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls button,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls button {
  padding: 0 15px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn {
  background-color: #d9d9d9;
  color: #134d5e;
  margin-right: 5px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn:hover,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn:hover,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn:hover,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .cancel-btn:hover {
  background-color: rgba(0,0,0,0.233);
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn {
  background-color: #0089ba;
  color: #fff;
  font-weight: bold;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn:hover,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn:hover,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn:hover,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn:hover,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .chapter-section-content .epiditor--container .apply-cancel-editor-controls .apply-btn:hover {
  background-color: #035b7a;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation {
  position: relative;
  margin: 10px 0 35px 0;
  padding: 5px 15px;
  background-color: #fbfaf8;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .question,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .question,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .question,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .question,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .question,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .question,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .question,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .question,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .question,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .question,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .question,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .question {
  font-size: 18px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .public-DraftEditor-content,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .public-DraftEditor-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .public-DraftEditor-content,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .public-DraftEditor-content,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .public-DraftEditor-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .public-DraftEditor-content,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .public-DraftEditor-content,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .public-DraftEditor-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .public-DraftEditor-content,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .public-DraftEditor-content,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .public-DraftEditor-content,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .public-DraftEditor-content {
  min-height: auto;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-text,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-text,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-text,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-text {
  font-size: 14px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-section .recommendation-section-description,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-section .recommendation-section-description,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-section .recommendation-section-description,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-section .recommendation-section-description,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-section .recommendation-section-description,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-section .recommendation-section-description,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-section .recommendation-section-description,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-section .recommendation-section-description,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-section .recommendation-section-description,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-section .recommendation-section-description,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-section .recommendation-section-description,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .recommendation-section .recommendation-section-description {
  width: 300px;
  font-weight: bold;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .add-section-comment-button,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .add-section-comment-button,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .add-section-comment-button,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .add-section-comment-button,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .add-section-comment-button,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .add-section-comment-button,
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .add-section-comment-button,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .add-section-comment-button,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .add-section-comment-button,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .add-section-comment-button,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .add-section-comment-button,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .mdg-recommendation .add-section-comment-button {
  top: 20px;
  right: -16px;
  position: absolute;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-text {
  font-size: 18px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 12px;
  color: #4e4e4e;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div > div:first-child,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div > div:first-child,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div > div:first-child,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div > div:first-child,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div > div:first-child,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div > div:first-child {
  font-weight: bold;
  color: #545656;
  margin-right: 20px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child {
  margin-right: 5px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child .quality-sign,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child .quality-sign,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child .quality-sign,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child .quality-sign,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child .quality-sign,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:first-child .quality-sign {
  font-size: 12px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:last-child,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:last-child,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:last-child,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:last-child,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:last-child,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-attributes > div .certainty-of-evidence > div:last-child {
  margin-right: 0;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices {
  margin-top: 20px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p {
  font-size: 12px;
  color: #000;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p.recommendation-appendices__title,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p.recommendation-appendices__title,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p.recommendation-appendices__title,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p.recommendation-appendices__title,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p.recommendation-appendices__title,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .chapter-item .chapter-sections .chapter-section .recommendation .recommendation-appendices p.recommendation-appendices__title {
  text-transform: uppercase;
  color: #545656;
  font-weight: bold;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .add-reference-button,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .add-reference-button,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .add-reference-button,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .add-reference-button,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .add-reference-button,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .add-reference-button {
  margin: 0px 10px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .add-reference-button .reference,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .add-reference-button .reference,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .chapters-list-item .add-reference-button .reference,
#visual-guidelines .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .add-reference-button .reference,
#mda-topic .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .add-reference-button .reference,
#document-sections-v2-container .document-sections-v2 .chapters-list .chapters-list-items .chapters-list-item .add-reference-button .reference {
  color: #3783a5;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items,
#mda-topic .document-sections-v2 .chapters-list .population-list-items,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items {
  padding: 10px 0px 10px 10px;
  margin-left: 10px;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .population-group-item.over,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .population-group-item.over,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .population-group-item.over {
  background-color: #ddeaf9;
}
#visual-guidelines .document-sections-v2 .chapters-list .population-list-items .population-group-item.dragging,
#mda-topic .document-sections-v2 .chapters-list .population-list-items .population-group-item.dragging,
#document-sections-v2-container .document-sections-v2 .chapters-list .population-list-items .population-group-item.dragging {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
#visual-guidelines .document-sections-v2 .sidebar,
#mda-topic .document-sections-v2 .sidebar,
#document-sections-v2-container .document-sections-v2 .sidebar {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-left: 20px;
  -webkit-box-shadow: 0 0 6px rgba(0,0,0,0.161);
  box-shadow: 0 0 6px rgba(0,0,0,0.161);
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-menu,
#mda-topic .document-sections-v2 .sidebar .sidebar-menu,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-menu {
  margin: 0 5px;
  padding: 0;
  list-style-type: none;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon,
#mda-topic .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #fbfaf8;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin: 0 5px 5px 0;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon:hover,
#mda-topic .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon:hover,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon:hover {
  background-color: #f3f1e9;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon.sidebar-menu-item-icon-selected,
#mda-topic .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon.sidebar-menu-item-icon-selected,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-menu .sidebar-menu-item .sidebar-menu-item-icon.sidebar-menu-item-icon-selected {
  background-color: #f3f1e9;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content,
#mda-topic .document-sections-v2 .sidebar .sidebar-content,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content {
  width: 350px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top .suggesting-container,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top .suggesting-container,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top .suggesting-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top .suggesting-container .suggesting,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top .suggesting-container .suggesting,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-top .suggesting-container .suggesting {
  margin-right: 10px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters {
  padding-right: 10px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-item-add,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-item-add,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-item-add {
  text-align: center;
  padding-bottom: 5px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-item-add .sidebar-chapters-item-add-text,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-item-add .sidebar-chapters-item-add-text,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-item-add .sidebar-chapters-item-add-text {
  color: #0b6dad;
  font-style: italic;
  cursor: pointer;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items {
  counter-reset: item;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item {
  padding: 6px 6px 6px 0;
  font-size: 14px;
  background-color: #fbfaf8;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item .sidebar-chapter-content,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item .sidebar-chapter-content,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item .sidebar-chapter-content {
  counter-increment: item;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item .sidebar-chapter-content .sidebar-chapter-content-title .title-text:before,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item .sidebar-chapter-content .sidebar-chapter-content-title .title-text:before,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item .sidebar-chapter-content .sidebar-chapter-content-title .title-text:before {
  content: counters(item, ".") ". ";
  font-weight: bold;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item:hover,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item:hover,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item:hover {
  background-color: #f6f3ed;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item:hover .document-sections-title .title-icon,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item:hover .document-sections-title .title-icon,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item:hover .document-sections-title .title-icon {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item.dragging,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item.dragging,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item.dragging {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item.over,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item.over,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapters-items-container .sidebar-chapters-items .sidebar-chapters-list-item .sidebar-chapter-item.over {
  background-color: #ddeaf9;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form {
  margin: 5px 0;
  font-size: 14px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field {
  margin: 5px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field .sidebar-chapter-form-field-label,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field .sidebar-chapter-form-field-label,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field .sidebar-chapter-form-field-label {
  width: 55px;
  cursor: default;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field .sidebar-chapter-form-field-input:not([type=checkbox]),
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field .sidebar-chapter-form-field-input:not([type=checkbox]),
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-field .sidebar-chapter-form-field-input:not([type=checkbox]) {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-buttons,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-buttons,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-buttons {
  text-align: right;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-buttons .sidebar-chapter-form-button,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-buttons .sidebar-chapter-form-button,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .sidebar-chapters .sidebar-chapter-form .sidebar-chapter-form-buttons .sidebar-chapter-form-button {
  margin: 0 3px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .sidebar-filter-icon,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .sidebar-filter-icon,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .sidebar-filter-icon {
  width: 30px;
  float: right;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container {
  margin: 0 15px 0 0;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container button:before,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container button:before,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container button:before {
  margin-right: 3px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container .reject:before,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container .reject:before,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container .reject:before {
  font-size: 12px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container .label-text,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container .label-text,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .accept-reject-buttons .btn-icon-container .label-text {
  font-size: 13px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list {
  background-color: #e4e6e9;
  padding: 10px 20px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item {
  padding: 10px;
  border: 1px solid #d9d9d9;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease, box-shadow 0.3s;
  -moz-transition: -moz-transform 0.3s ease, box-shadow 0.3s;
  -o-transition: -o-transform 0.3s ease, box-shadow 0.3s;
  -ms-transition: -ms-transform 0.3s ease, box-shadow 0.3s;
  transition: transform 0.3s ease, box-shadow 0.3s;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .epiditor--container p,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .epiditor--container p,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .epiditor--container p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment {
  border-left: 6px solid #26c6da;
  margin-bottom: 10px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment .discussion-item,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment .discussion-item,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment .discussion-item {
  margin-bottom: 0;
  padding-bottom: 0;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment.nestedComment,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment.nestedComment,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment.nestedComment {
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
  border-top: 1px solid #26c6da;
  margin-top: 5px;
  padding-top: 5px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment.nestedComment:hover,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment.nestedComment:hover,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.comment.nestedComment:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.suggestion,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.suggestion,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.suggestion {
  border-left: 6px solid #fda;
  word-break: break-all;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item:hover,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item:hover,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item:hover,
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.active,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.active,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.active {
  -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.active,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.active,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.active {
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  -o-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  transform: translateX(-20px);
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.editing .epiditor--pm-editor-root,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.editing .epiditor--pm-editor-root,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.editing .epiditor--pm-editor-root {
  border: 1px solid #d9d9d9;
  margin: 10px 0;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.editing .mark-for-role-buttons,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.editing .mark-for-role-buttons,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item.editing .mark-for-role-buttons {
  margin: 0px 0px 10px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item:not(:first-child),
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item:not(:first-child),
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item:not(:first-child) {
  margin-top: 10px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .ts,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .ts,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .ts {
  font-size: 11px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .comment__reply-container,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .comment__reply-container,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .comment__reply-container {
  position: relative;
  margin-top: 0px;
  margin-bottom: 10px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .reply-to-comment-editor,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .reply-to-comment-editor,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .reply-to-comment-editor {
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons {
  margin: 0px -10px 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button {
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  filter: alpha(opacity=10);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-flex: 1 0 0;
  -ms-flexbox: 1 0 0;
  flex: 1 0 0;
  padding: 2px;
  font-size: 11px;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button.marked,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button.marked,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button.marked {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
#visual-guidelines .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button.enabled:hover,
#mda-topic .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button.enabled:hover,
#document-sections-v2-container .document-sections-v2 .sidebar .sidebar-content .sidebar-content-main .discussions .items-list .discussion-item .mark-for-role-buttons button.enabled:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
#visual-guidelines .document-sections-title,
#mda-topic .document-sections-title,
#document-sections-v2-container .document-sections-title {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#visual-guidelines .document-sections-title .title-input,
#mda-topic .document-sections-title .title-input,
#document-sections-v2-container .document-sections-title .title-input,
#visual-guidelines .document-sections-title .title-text,
#mda-topic .document-sections-title .title-text,
#document-sections-v2-container .document-sections-title .title-text {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
#visual-guidelines .document-sections-title .title-icon,
#mda-topic .document-sections-title .title-icon,
#document-sections-v2-container .document-sections-title .title-icon {
  margin-left: 5px;
}
.PopupBox_Content .statuses-options .status-option {
  margin: 5px 0;
}
.PopupBox_Content .statuses-options .status-content {
  width: 100px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  height: 15px;
  line-height: 15px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.PopupBox_Content .statuses-options .status-content:hover {
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.161);
  box-shadow: 0 3px 6px rgba(0,0,0,0.161);
}
.PopupBox_Content .statuses-options .status-in-progress {
  background-color: #ffba6c;
}
.PopupBox_Content .statuses-options .status-in-review {
  background-color: #ff5186;
}
.PopupBox_Content .statuses-options .status-updated {
  background-color: #6ec073;
}
.PopupBox_Content .statuses-options .status-done {
  background-color: #0089ba;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body {
  z-index: 10;
}
.insert-recommendation-modal .choose-appendices-title {
  border-top: 1px solid #bfbfbf;
  padding-top: 5px;
  margin-top: 10px;
  font-weight: bold;
}
.add-section-comment-button {
  width: 32px;
  height: 32px;
  padding: 4px 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #26c6da;
  -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s ease;
  -moz-transition: -moz-transform 0.2s ease;
  -o-transition: -o-transform 0.2s ease;
  -ms-transition: -ms-transform 0.2s ease;
  transition: transform 0.2s ease;
}
.add-section-comment-button:hover {
  -webkit-transform: scale(1.15, 1.15);
  -moz-transform: scale(1.15, 1.15);
  -o-transform: scale(1.15, 1.15);
  -ms-transform: scale(1.15, 1.15);
  transform: scale(1.15, 1.15);
}
.sidebar-filters {
  position: relative;
  min-width: 300px;
  padding: 35px 10px 10px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
}
.sidebar-filters .sidebar-filter-close-icon {
  position: absolute;
  right: 10px;
  top: 5px;
}
.sidebar-filters .filter-label {
  width: 120px;
}
.sidebar-filters .btn-icon-container {
  margin-left: 5px;
}
.sidebar-filters .btn-icon-container button:before {
  font-size: 12px;
}
.sidebar-filters .options-select-container {
  width: 100%;
}
#visual-guidelines .chapter-header {
  border-bottom: 1px solid #d9d9d9;
}
#visual-guidelines .title-text:before,
#visual-guidelines .chapter-header-text:before {
  content: '' !important;
  counters: none;
}
#visual-guidelines .recommendation-item {
  position: relative;
  margin-left: 10px;
}
#visual-guidelines .recommendation-item.dragging {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
#visual-guidelines .recommendation-content {
  margin-bottom: 10px;
  background: #fbfaf8;
  padding: 10px;
  font-size: 14px;
  width: 100%;
}
#visual-guidelines .recommendation-content h3 {
  font-size: 14px;
  margin: 5px 0px 0px;
}
#visual-guidelines .recommendation-content .recommendation-content__text-field .gdt-editor-container {
  height: 100%;
}
#visual-guidelines .recommendation-content .recommendation-content__text-field .gdt-editor-container .public-DraftEditor-content {
  min-height: 40px;
}
.drag-marker {
  display: block;
  min-height: 20px;
}
.drag-marker:before {
  display: block;
  min-height: 20px;
  position: absolute;
  left: -10px;
  font-family: 'gdt';
  content: '\e042';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  color: #d9d9d9;
  font-size: 24px;
  position: absolute;
  top: -3px;
  left: -12px;
}
