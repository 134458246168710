@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.ReactModal__Overlay {
  z-index: 101;
  box-sizing: border-box;
}
.ReactModal__Content .modal-close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 32px;
  color: #808080;
  cursor: pointer;
}
.ReactModal__Content .modal-close:before {
  content: '\00d7';
}
.limits-exceeded-warning {
  text-align: center;
}
.limits-exceeded-warning .buttons {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
}
.limits-exceeded-warning .buttons a {
  margin-left: 5px;
}
.limits-exceeded-warning .buttons button {
  margin-right: 5px;
}
.limits-exceeded-warning .bold {
  font-weight: bold;
}
.limits-exceeded-warning .website-link {
  margin: 10px 0px 20px;
}
.limits-exceeded-warning .website-link a {
  text-decoration: none;
  color: #009cca;
}
