@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.document-publish {
  background-color: #fff;
  padding-bottom: 10px;
  padding-right: 10px;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
}
.document-publish h3 {
  margin: 0;
  padding: 0;
}
.document-publish .buttons-row button {
  width: 300px;
}
.mobile-contact-form form {
  padding: 0 0.5rem;
}
.mobile-contact-form form label,
.mobile-contact-form form input:not([type=submit]),
.mobile-contact-form form textarea {
  display: block;
  width: 100%;
  margin-top: 1rem;
}
.mobile-contact-form form textarea {
  height: 10rem;
}
.mobile-preview-view {
  width: 500px;
}
.mobile-preview-view.edit-mode {
  width: 800px;
}
.mobile-preview-view.edit-mode .original-text {
  display: block;
}
.mobile-preview-view .preview-frame {
  background: url("../images/iphone-frame.png") top left no-repeat;
  width: 290px;
  height: 600px;
}
.mobile-preview-view .original-text {
  display: none;
  width: 300px;
  height: 600px;
  position: absolute;
  top: 50px;
  left: 310px;
  bottom: 0;
}
.mobile-preview-view .original-text .fields {
  height: 450px;
  overflow-y: auto;
}
.mobile-preview-view .original-text .fields > div {
  width: 100%;
  min-height: 200px;
  border: 1px solid #bfbfbf;
  background: #fff;
}
.mobile-preview-view .preview-sidebar {
  position: absolute;
  bottom: 100px;
  right: 10px;
  width: 200px;
}
.mobile-preview-view #preview-content {
  border: none;
  background: #fff;
  position: relative;
  left: 23px;
  top: 87px;
  width: 238px;
  height: 421px;
}
.mobile-preview-view #qrcode {
  text-align: center;
  width: 290px;
}
.mobile-preview-view .info {
  margin: 1rem;
  text-align: center;
}
.mobile-preview-view .buttons-block {
  margin-top: 10px;
}
.mobile-preview-view .buttons-block button.close {
  float: right;
}
.mobile-preview-view .buttons-block button.send-preview {
  float: left;
}
.mobile-preview-view button.hidden {
  display: none !important;
}
.top-acceptence-notification,
.dbep-publication-notification,
.uploading-dbep,
.dbep-uploading-modal,
.results-dbep-upload {
  margin-top: 20px;
  text-align: center;
}
.top-acceptence-notification .questions-list,
.dbep-publication-notification .questions-list,
.uploading-dbep .questions-list,
.dbep-uploading-modal .questions-list,
.results-dbep-upload .questions-list {
  max-height: 300px;
  overflow-y: auto;
}
.top-acceptence-notification .items,
.dbep-publication-notification .items,
.uploading-dbep .items,
.dbep-uploading-modal .items,
.results-dbep-upload .items {
  max-height: initial;
}
.top-acceptence-notification .questions-list-container__group-title,
.dbep-publication-notification .questions-list-container__group-title,
.uploading-dbep .questions-list-container__group-title,
.dbep-uploading-modal .questions-list-container__group-title,
.results-dbep-upload .questions-list-container__group-title,
.top-acceptence-notification .questions-list-container__question,
.dbep-publication-notification .questions-list-container__question,
.uploading-dbep .questions-list-container__question,
.dbep-uploading-modal .questions-list-container__question,
.results-dbep-upload .questions-list-container__question {
  text-align: left;
}
.top-acceptence-notification .questions-list-container__question,
.dbep-publication-notification .questions-list-container__question,
.uploading-dbep .questions-list-container__question,
.dbep-uploading-modal .questions-list-container__question,
.results-dbep-upload .questions-list-container__question {
  height: 40px;
  line-height: 40px;
  margin-left: 40px;
  position: relative;
  overflow: visible;
}
.top-acceptence-notification .questions-list-container__question .dbep-publication__check-item,
.dbep-publication-notification .questions-list-container__question .dbep-publication__check-item,
.uploading-dbep .questions-list-container__question .dbep-publication__check-item,
.dbep-uploading-modal .questions-list-container__question .dbep-publication__check-item,
.results-dbep-upload .questions-list-container__question .dbep-publication__check-item {
  text-align: left;
  display: block;
  height: 40px;
}
.top-acceptence-notification .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox,
.dbep-publication-notification .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox,
.uploading-dbep .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox,
.dbep-uploading-modal .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox,
.results-dbep-upload .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox {
  position: relative;
  left: -73px;
}
.top-acceptence-notification .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox input,
.dbep-publication-notification .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox input,
.uploading-dbep .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox input,
.dbep-uploading-modal .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox input,
.results-dbep-upload .questions-list-container__question .dbep-publication__check-item .dbep-publication-question__checkbox input {
  width: 12px;
  height: 12px;
}
.top-acceptence-notification .questions-list-container__question .dbep-publication__check-item .caption,
.dbep-publication-notification .questions-list-container__question .dbep-publication__check-item .caption,
.uploading-dbep .questions-list-container__question .dbep-publication__check-item .caption,
.dbep-uploading-modal .questions-list-container__question .dbep-publication__check-item .caption,
.results-dbep-upload .questions-list-container__question .dbep-publication__check-item .caption {
  position: absolute;
  font-weight: normal;
  top: 0px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
}
.top-acceptence-notification .questions-list-container__questions-group.with-caption,
.dbep-publication-notification .questions-list-container__questions-group.with-caption,
.uploading-dbep .questions-list-container__questions-group.with-caption,
.dbep-uploading-modal .questions-list-container__questions-group.with-caption,
.results-dbep-upload .questions-list-container__questions-group.with-caption {
  height: auto;
  min-height: 50px;
  margin-left: 0px;
  padding-left: 10px;
}
.top-acceptence-notification .questions-list-container__questions-group.with-caption .questions-list-container__group-title,
.dbep-publication-notification .questions-list-container__questions-group.with-caption .questions-list-container__group-title,
.uploading-dbep .questions-list-container__questions-group.with-caption .questions-list-container__group-title,
.dbep-uploading-modal .questions-list-container__questions-group.with-caption .questions-list-container__group-title,
.results-dbep-upload .questions-list-container__questions-group.with-caption .questions-list-container__group-title {
  line-height: 40px;
  padding-left: 0;
  font-weight: bold;
}
.dbep-publication-question-filter {
  margin-top: 10px;
  text-align: left;
}
.dbep-publication-question-filter .dbep-publication-question-filter__text {
  display: block;
}
.dbep-publication-question-filter .dbep-publication-question-filter__input-with-icon {
  display: inline-block;
  padding-right: 8px;
  background-color: #f2f2f2;
}
.dbep-publication-question-filter .dbep-publication-question-filter__input-with-icon:before {
  content: "";
}
.dbep-publication-question-filter .dbep-publication-question-filter__input-with-icon:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e016';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.dbep-publication-question-filter .dbep-publication-question-filter__input-with-icon:after {
  vertical-align: baseline;
}
.dbep-publication-question-filter .dbep-publication-question-filter__input-with-icon input {
  background-color: #f2f2f2;
  border: none;
}
.dbep-publication-select-all-questions {
  margin: 5px 0;
  display: block;
  text-align: left;
}
.dbep-publication-questions-list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
}
.dbep-publication-questions-list .dbep-publication-question {
  margin-top: 3px;
}
.dbep-publication-questions-list .dbep-publication__check-item {
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.dbep-publication-questions-list .dbep-publication__check-item input {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.dbep-publication-questions-list .dbep-publication__check-item span {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  padding: 4px;
  margin-left: 8px;
}
.dbep-publication-questions-list .dbep-publication-question__text {
  background-color: #f2f2f2;
}
.dbep-publication-select-all-questions__text {
  text-align: left;
}
.disabled-holder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.uploading-dbep-progress-bar {
  border: 1px #000 solid;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  width: 620px;
}
.uploading-dbep-progress-bar-inner {
  background: #c9dac2;
  padding: 0;
  margin: 0;
  height: 100%;
  display: block;
}
.uploading-dbep-progress-bar-text {
  position: absolute;
  width: 620px;
  margin: 0;
  padding-top: 4px;
}
.dissemination-view {
  overflow-y: auto;
  margin-top: 10px;
}
.dissemination-view .dissemination-screen {
  margin: 0 20px;
  overflow: auto;
}
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item {
  min-width: 300px;
  padding: 30px;
  border: 1px solid #009cca;
  border-radius: 18px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-flex: 1 1 33.333333333333336%;
  -ms-flexbox: 1 1 33.333333333333336%;
  flex: 1 1 33.333333333333336%;
  margin-bottom: 20px;
}
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item:not(:last-child) {
  margin-right: 30px;
}
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item > h4,
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item .dissemination-item__description,
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item .dissemination-item__image {
  text-align: center;
}
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item > h4 {
  height: 40px;
}
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item .dissemination-item__description {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item .dissemination-item__image img {
  height: 250px;
}
.dissemination-view .dissemination-screen .dissemination-screen__dissemination-items .dissemination-items__dissemination-item .dissemination-item__button button {
  width: 100%;
}
.dissemination-da-example,
.dissemination-mobile-publish {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.dissemination-da-example a,
.dissemination-mobile-publish a,
.dissemination-da-example button,
.dissemination-mobile-publish button {
  margin-top: 30px;
  color: #fff;
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.dissemination-mobile-publish button {
  -webkit-flex: 1 1 50%;
  -ms-flexbox: 1 1 50%;
  flex: 1 1 50%;
}
.dissemination-mobile-publish button:not(:last-child) {
  margin-right: 10px;
}
.mobile-view .marketing-screen__img {
  text-align: center;
}
.mobile-view .marketing-screen__img img {
  max-height: calc(100vh - 150px);
}
