@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
#document-sections-container {
  background-color: #fff;
}
#document-sections-container .document-sections {
  width: 100%;
  display: inline-block;
}
#document-sections-container .document-sections iframe {
  width: 100%;
}
#document-sections-container .document-sections .te {
  display: inline-block;
  width: 78%;
  margin: 0;
}
#document-sections-container .disabled {
  text-decoration: line-through;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
#document-sections-container .disabled:hover {
  text-decoration: line-through !important;
}
#document-sections-container .sections {
  padding-left: 20px;
  display: inline-block;
  vertical-align: top;
  width: 20%;
}
#document-sections-container .sections .sections-list {
  font-size: 16px;
}
#document-sections-container .sections ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#document-sections-container .sections ul h2 {
  font-size: 16px;
  font-size: 1.6rem;
}
#document-sections-container .sections ul li {
  cursor: pointer;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
}
#toolbar-container menu[type="toolbar"].document-sections-view button.edit {
  display: none;
}
.references-chapter .reference {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 16px 0;
}
.references-chapter .reference .reference-content {
  -webkit-flex: 1 0 80%;
  -ms-flexbox: 1 0 80%;
  flex: 1 0 80%;
}
.references-chapter .reference .attachment-link {
  width: 75px;
  margin-left: 10px;
}
