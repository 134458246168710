@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.question-with-outcomes-container {
  background: #fff;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
}
.question-with-outcomes-container .outcomes-table-container {
  overflow-x: hidden;
}
.question-with-outcomes-container > .outcomes-container,
.question-with-outcomes-container > .footnotes-container {
  min-width: 1162px;
}
.question-with-outcomes-container .questions-view {
  margin-bottom: 10px;
}
.question-with-outcomes-container .questions-view .standard-list {
  background: #eaeaea;
}
.question-with-outcomes-container .questions-view .standard-list li.question {
  position: relative;
}
.question-with-outcomes-container .diagnostic-meta {
  margin: 0px 5px 15px 0;
}
.question-with-outcomes-container .diagnostic-meta div.pooled-values-container,
.question-with-outcomes-container .diagnostic-meta div.effect-prevalences-container {
  display: inline-block;
  margin-right: 20px;
}
.question-with-outcomes-container .diagnostic-meta .diagnostic-effect td,
.question-with-outcomes-container .diagnostic-meta .diagnostic-prevalences td {
  background-color: #e9e9e9;
}
.question-with-outcomes-container .diagnostic-meta .diagnostic-effect .value-cell,
.question-with-outcomes-container .diagnostic-meta .diagnostic-prevalences .value-cell {
  background-color: #f2f2f2;
}
