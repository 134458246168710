@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
body {
  margin: 0;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: url("../images/bg.png") repeat;
  opacity: 0.85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  filter: alpha(opacity=85);
  z-index: 9999;
}
.authentication-view-keycloak .authentication-view-keycloak__inner {
  background: #fff;
  width: 350px;
  height: 130px;
  position: absolute;
  left: calc(50vw - 175px);
  top: calc(50vh - 65px);
  z-index: 10009;
  padding: 20px;
}
.authentication-view-keycloak .authentication-view-keycloak__message {
  font-size: 14px;
  text-align: center;
}
.authentication-view-keycloak .authentication-view-keycloak__actions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.authentication-view .authentication .log-in-form .log-in-form__title,
.authentication-view .authentication .register-form .register-form__title {
  font-weight: normal;
  font-size: 16px;
  text-align: center;
}
.authentication-view .authentication .external-login {
  margin-top: 20px;
}
.authentication-view .authentication .external-login .external-login__title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}
.authentication-view .authentication .external-login .external-login__buttons {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button {
  -webkit-flex: 1 1 50%;
  -ms-flexbox: 1 1 50%;
  flex: 1 1 50%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button:not(:last-child) {
  margin-right: 5px;
}
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button:visited,
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button:focus {
  color: #fff;
}
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button:hover {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button.external-login__archie {
  background: #002d63;
}
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button.external-login__google {
  background: #de4b38;
}
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button img,
.authentication-view .authentication .external-login .external-login__buttons .external-login__buttons__button >div {
  pointer-events: none;
}
.authentication-view__enterprise {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  overflow: auto;
}
.authentication-view__enterprise .triangle,
.authentication-view__enterprise .authentication {
  width: 50%;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
}
.authentication-view__enterprise .triangle {
  background-image: url("../images/triangle.svg");
  background-position: bottom right;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
.authentication-view__enterprise .authentication {
  width: 300px;
  margin: 0 auto;
  padding: 0 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.authentication-view__enterprise .authentication .form-content {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.authentication-view__enterprise .authentication .logo {
  height: 86px;
  top: 0;
  text-align: center;
  margin-bottom: 25px;
}
.authentication-view__enterprise .authentication .logo img {
  height: 100%;
  pointer-events: none;
}
.authentication-view__enterprise .authentication .acp-logo,
.authentication-view__enterprise .authentication .ash-logo,
.authentication-view__enterprise .authentication .minsal-logo,
.authentication-view__enterprise .authentication .cadth-logo,
.authentication-view__enterprise .authentication .tb-logo {
  width: 100%;
  margin-bottom: 30px;
  text-align: right;
}
.authentication-view__enterprise .authentication .acp-logo img {
  height: 75px;
}
.authentication-view__enterprise .authentication .nice-logo {
  width: 100%;
  margin-bottom: 30px;
}
.authentication-view__enterprise .authentication .nice-logo img {
  height: 84px;
}
.authentication-view__enterprise .authentication .ash-logo img {
  height: 150px;
}
.authentication-view__enterprise .authentication .minsal-logo img {
  height: 150px;
}
.authentication-view__enterprise .authentication .cadth-logo img {
  height: 40px;
}
.authentication-view__enterprise .authentication .tb-logo img {
  height: 60px;
}
.authentication-view__enterprise .authentication .eu-logo {
  width: 100%;
  margin-bottom: 30px;
  text-align: right;
}
.authentication-view__enterprise .authentication .conitec-logos,
.authentication-view__enterprise .authentication .covid-logos,
.authentication-view__enterprise .authentication .elsevier-logos {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}
.authentication-view__enterprise .authentication .covid-logos {
  height: 130px;
}
.authentication-view__enterprise .authentication .covid-logos img {
  height: 115px;
}
.authentication-view__enterprise .authentication .conitec-logos {
  height: 200px;
}
.authentication-view__enterprise .authentication .conitec-logos img {
  height: 185px;
}
.authentication-view__enterprise .authentication .elsevier-logos img:first-child {
  margin-right: 20px;
}
.authentication-view__enterprise .authentication .mdg-logo img {
  height: 35px;
  margin-bottom: 80px;
}
.authentication-view__enterprise .authentication form {
  width: auto;
  padding: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.authentication-view__enterprise .authentication form label {
  position: relative;
  display: block;
  padding: 5px 0;
}
.authentication-view__enterprise .authentication form label input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
}
.authentication-view__enterprise .authentication form button.log-in {
  display: block;
  width: auto;
  line-height: 35px;
  margin: 10px 0;
  padding: 0 15px;
  font-size: 15px;
}
.authentication-view__enterprise .authentication form .forgot-password-with-languages {
  margin-top: 0;
}
.authentication-view__enterprise .authentication .contact-details {
  margin-left: 0px;
  width: 300px;
  margin-top: 60px;
  text-align: center;
}
.authentication-view__enterprise .authentication .copyright-notice {
  margin-bottom: 25px;
  white-space: pre;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  text-align: right;
}
.authentication-view__enterprise .authentication .copyright-notice a {
  margin-right: 10px;
}
.authentication-view__enterprise .authentication .language {
  width: 130px;
}
.authentication-view__enterprise .authentication a {
  line-height: 23px;
  color: #0071bc;
  text-decoration: none;
}
.authentication-view__enterprise .authentication a:hover {
  font-weight: bold;
}
.authentication-view__enterprise.rcp .contact-details {
  width: 650px;
}
.authentication-view {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #f7f7f7;
  background-image: url("../images/bg-pattern.svg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.authentication-view h1.title {
  font-size: 20px;
  text-align: center;
  font-weight: normal;
}
.authentication-view .authentication {
  z-index: 10000;
  margin: auto 0;
}
.authentication-view .authentication label {
  cursor: default;
}
.authentication-view .info {
  font-size: 16px;
  text-align: center;
}
.authentication-view .info,
.authentication-view .error-shared {
  margin-top: 2rem;
}
.authentication-view .error-login-or-email,
.authentication-view .error-shared {
  color: #f00;
  text-align: center;
}
.authentication-view .error {
  color: #f00;
  text-align: center;
  margin: 0.5rem 0 0.8rem;
}
.authentication-view .error button {
  color: #2d3236;
  display: inline;
  float: none;
  font-size: 13px;
  font-size: 1.3rem;
  margin-top: 0.2rem;
}
.authentication-view .info {
  color: #2d3236;
}
.authentication-view .current-login {
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 0px;
}
.authentication-view .footer {
  font-size: 16px;
  line-height: 21px;
  color: #2d3236;
  margin: 0;
}
.authentication-view .footer span {
  float: left;
  margin-top: 0.8rem;
}
.authentication-view .footer button {
  color: #2d3236;
}
.authentication-view .footer p {
  margin-top: 8px;
}
.authentication-view .footer a {
  color: #fff;
  text-decoration: none;
}
.authentication-view .footer a:hover {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.authentication-view .footer .languages-selector .options-select-container {
  float: right;
  margin-top: 0.8rem;
  background-color: #fff;
}
.authentication-view .footer .languages-selector .options-select-container .select-input {
  border-bottom: 1px solid transparent;
}
.authentication-view .footer.language {
  font-size: 15px;
}
.authentication-view .footer.language .new-to-gdt,
.authentication-view .footer.language .registration-view-switch {
  line-height: 2.5rem;
}
.authentication-view .copyright-notice {
  text-align: center;
  margin: auto 0 5px;
}
.authentication-view .copyright-notice,
.authentication-view .copyright-notice a {
  color: #2d3236;
}
.authentication-view .copyright-notice a {
  margin-left: 1rem;
}
.authentication-view .authentication-form {
  width: 415px;
  background: #fff;
  padding: 25px;
  padding-top: 40px;
  -webkit-box-shadow: 4px 4px 10px -2px #414042;
  box-shadow: 4px 4px 10px -2px #414042;
  border: 1px solid #bfbfbf;
  position: relative;
  color: #424242;
}
.authentication-view .authentication-form.subscriptions {
  width: initial;
  background: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}
.authentication-view .authentication-form .authentication-form__action-switch {
  margin-top: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.authentication-view .authentication-form .authentication-form__action-switch > div {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 15px;
}
.authentication-view .authentication-form .authentication-form__action-switch button {
  font-weight: bold;
  color: #26c6da;
  margin: 0;
  font-size: 15px;
}
.authentication-view .footer-language-selector {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 10px;
}
.authentication-view .footer-language-selector > span {
  margin-right: 5px;
}
.authentication-view .footer-language-selector > .languages-selector {
  background: #fff;
}
.authentication-view__enterprise form {
  width: 415px;
  background: #fff;
  padding: 25px;
  padding-top: 40px;
  -webkit-box-shadow: 4px 4px 10px -2px #414042;
  box-shadow: 4px 4px 10px -2px #414042;
  border: 1px solid #bfbfbf;
  position: relative;
  color: #424242;
}
.authentication-view__enterprise label,
.authentication-view label {
  font-size: 13px;
  font-size: 1.3rem;
}
.authentication-view__enterprise input,
.authentication-view input {
  display: block;
  height: 4rem;
  width: 100%;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 1.5rem;
  background-color: #fff;
}
.authentication-view__enterprise input:invalid,
.authentication-view input:invalid {
  background: #fff;
}
.authentication-view__enterprise input.validation-error,
.authentication-view input.validation-error {
  border: 1px solid #f00;
}
.authentication-view__enterprise input::-webkit-input-placeholder,
.authentication-view input::-webkit-input-placeholder,
.authentication-view__enterprise input:-moz-placeholder,
.authentication-view input:-moz-placeholder,
.authentication-view__enterprise input::-moz-placeholder,
.authentication-view input::-moz-placeholder,
.authentication-view__enterprise input:-ms-input-placeholder,
.authentication-view input:-ms-input-placeholder {
  color: #424242;
}
.authentication-view__enterprise input[type=submit],
.authentication-view input[type=submit] {
  margin: 1rem auto 0;
  padding: 0 7.5rem;
  width: 100%;
}
.authentication-view__enterprise input[type=submit].loading,
.authentication-view input[type=submit].loading {
  background: #2caac4 url("../images/loader.gif") 10px center no-repeat;
}
.authentication-view__enterprise input[type=submit].sign-up,
.authentication-view input[type=submit].sign-up {
  padding: 0 4rem;
}
.authentication-view__enterprise .forgot-password-with-languages,
.authentication-view .forgot-password-with-languages {
  margin-top: 0.8rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.authentication-view__enterprise .forgot-password-with-languages .forgot-password,
.authentication-view .forgot-password-with-languages .forgot-password {
  margin-top: 0;
}
.authentication-view__enterprise .forgot-password,
.authentication-view .forgot-password,
.authentication-view__enterprise .forgot-login-switch,
.authentication-view .forgot-login-switch {
  color: #999;
  font-size: 12px;
  font-size: 1.2rem;
}
.authentication-view__enterprise .logo,
.authentication-view .logo {
  display: block;
  width: 100%;
  background-position: center center;
  position: relative;
  top: -10px;
  height: 50px;
  text-align: center;
}
.authentication-view__enterprise .logo img,
.authentication-view .logo img {
  pointer-events: none;
}
.authentication-view__enterprise form.password-reset input,
.authentication-view form.password-reset input {
  margin-bottom: 13px;
}
.authentication-view__enterprise button,
.authentication-view button {
  color: #fff;
  margin-top: 0.8rem;
}
.authentication-view__enterprise button:focus,
.authentication-view button:focus {
  outline: none;
}
.authentication-view__enterprise button.forgot-password,
.authentication-view button.forgot-password {
  float: none;
}
.authentication-view__enterprise button.registration-view-switch,
.authentication-view button.registration-view-switch,
.authentication-view__enterprise button.login-switch,
.authentication-view button.login-switch,
.authentication-view__enterprise button.user-switch,
.authentication-view button.user-switch,
.authentication-view__enterprise button.back-to-login-switch,
.authentication-view button.back-to-login-switch {
  float: left;
  color: #26c6da;
}
.authentication-view__enterprise button.language-select,
.authentication-view button.language-select {
  padding-left: 10px;
  width: 150px;
  line-height: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #2d3236;
  text-align: left;
  position: relative;
}
.authentication-view__enterprise button.language-select:before,
.authentication-view button.language-select:before {
  content: "";
}
.authentication-view__enterprise button.language-select:after,
.authentication-view button.language-select:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.authentication-view__enterprise button.language-select:after,
.authentication-view button.language-select:after {
  float: right;
  margin-right: 10px;
}
.user-view {
  background-color: #eaeaea;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  background: #eaeaea;
  position: fixed;
  top: 45px;
  z-index: 999;
  width: 345px;
  right: 5px;
  padding: 15px;
}
.user-view button.danger,
.user-view input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
.user-view button.danger:hover:not(:disabled),
.user-view input.danger:hover:not(:disabled) {
  background-color: rgba(242,222,222,0.5);
}
.user-view button.danger[disabled],
.user-view input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
.user-view button.finish,
.user-view input.finish {
  background-color: #c9dac2;
  color: #036707;
}
.user-view button.finish:hover:not(:disabled),
.user-view input.finish:hover:not(:disabled) {
  background-color: rgba(201,218,194,0.5);
}
.user-view button.finish[disabled],
.user-view input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
.user-view button.loading,
.user-view input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
.user-view .arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #eaeaea;
  margin: -25px -5px 0 auto;
}
.user-view .last-sync,
.user-view .info {
  margin-top: 1rem;
}
.user-view form {
  margin-top: 1rem;
}
.user-view form input[type=password] {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 1.5rem;
  height: 3rem;
  width: 100%;
}
.user-view form input[type=password]:invalid {
  background: #fff;
}
.user-view form .error {
  color: #f00;
}
.user-view button.log-out {
  background-color: #fbeed5;
  color: #9e7c00;
  float: left;
}
.user-view button.log-out:hover:not(:disabled) {
  background-color: rgba(251,238,213,0.5);
}
.user-view button.log-out[disabled] {
  background-color: rgba(251,238,213,0.24);
  color: rgba(158,124,0,0.24);
}
.user-view input.log-in {
  background-color: #d2dfed;
  color: #1a008d;
  float: right;
}
.user-view input.log-in:hover:not(:disabled) {
  background-color: rgba(210,223,237,0.5);
}
.user-view input.log-in[disabled] {
  background-color: rgba(210,223,237,0.24);
  color: rgba(26,0,141,0.24);
}
.user-view button.log-out,
.user-view input.log-in {
  font-size: 14px;
  font-size: 1.4rem;
  width: 150px;
  margin-top: 1rem;
  padding: 0.5rem 2.5rem;
  border: 1px solid #bfbfbf;
}
.user-view button.log-out.loading,
.user-view input.log-in.loading {
  background: url("../images/loader.gif") 10px center no-repeat;
}
label.tos-acceptance,
label.newsletter-opt-in {
  display: block;
  padding-left: 2em;
  text-indent: -2em;
  cursor: default;
}
label.tos-acceptance input,
label.newsletter-opt-in input {
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  padding: 0;
  vertical-align: middle;
  margin-right: 6px;
}
label.tos-acceptance a,
label.newsletter-opt-in a {
  color: #808080;
  text-decoration: none;
}
label.tos-acceptance .muted-text,
label.newsletter-opt-in .muted-text {
  color: #a9a9a9;
}
form.licences {
  width: 750px;
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
form.licences header h1 {
  margin: 0 0 2.5rem;
  font-size: 16px;
  font-size: 1.6rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
form.licences header .previous-subscription {
  text-align: center;
  font-style: italic;
  background-color: #fff0bb;
  font-weight: normal;
  line-height: 3.5rem;
}
form.licences .subscription-type {
  background-color: #fff;
  border: 1px solid #2baae2;
}
form.licences .subscription-type h2 {
  margin: 0;
}
form.licences .subscription-type.standard {
  margin-bottom: 1.2rem;
  padding: 3.5rem 3.3rem;
}
form.licences .subscription-type.standard h2 {
  color: #009cca;
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}
form.licences .subscription-type.standard hr {
  border-color: #2baae2;
  margin: 1.6rem 0 1.2rem;
}
form.licences .subscription-type.standard .subscription-content .functionalities {
  margin: 0;
  padding: 0;
}
form.licences .subscription-type.standard .subscription-content .for-whom {
  margin-top: 2.5rem;
}
form.licences .subscription-type.standard .subscription-content .requirement {
  margin: 3.2rem 0 5.1rem;
  white-space: pre-wrap;
}
form.licences .subscription-type.standard .subscription-content .disclaimer {
  margin-bottom: 1.2rem;
  font-size: 14px;
  font-size: 1.4rem;
  color: #b3b3b2;
  text-align: center;
}
form.licences .subscription-type.standard .more-info {
  text-align: center;
  background-color: #fff8de;
  padding: 8px 4px;
  margin-bottom: 8px;
}
form.licences .subscription-type.academic {
  padding: 3rem;
}
form.licences .subscription-type.academic h2 {
  color: #009cca;
  font-size: 30px;
  font-size: 3rem;
  text-transform: uppercase;
}
form.licences .subscription-type.academic .requirement {
  margin-bottom: 3rem;
  text-align: center;
  font-weight: bold;
}
form.licences .subscription-type .subscription-content {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 16px;
  font-size: 1.6rem;
}
form.licences .subscription-type .subscription-content .bold {
  font-weight: bold;
}
form.licences .subscription-type .subscription-content .col {
  -webkit-flex: 1 1 50%;
  -ms-flexbox: 1 1 50%;
  flex: 1 1 50%;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
form.licences .subscription-type .subscription-content .flex-grow {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
form.licences .subscription-type .subscription-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
form.licences .subscription-type .subscription-content .for-whom {
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}
form.licences .subscription-type .subscription-content .buttons button {
  margin: 0;
}
form.licences .subscription-type .subscription-content .buttons button:not(:last-child) {
  margin-bottom: 1.6rem;
}
footer {
  text-align: center;
  margin-top: 1rem;
}
footer a,
footer a:link,
footer a:visited,
footer a:hover,
footer a:active {
  color: inherit;
  text-decoration: none;
}
footer a,
footer a:link,
footer a:visited,
footer a:hover,
footer a:active {
  font-style: italic;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #fff;
}
footer a:hover {
  font-weight: bold;
}
.academic-confirmation .ep-modal__content {
  width: 750px;
  padding: 32px 26px;
}
.academic-confirmation .ep-modal__content h2 {
  text-align: center;
  margin-bottom: 20px;
}
.academic-confirmation .ep-modal__content .content-container {
  font-size: 16px;
  font-size: 1.6rem;
}
.academic-confirmation .ep-modal__content .content-container .introduction {
  margin-bottom: 26px;
}
.academic-confirmation .ep-modal__content .content-container h4 {
  margin: 0;
  margin-top: 26px;
}
.academic-confirmation .ep-modal__content .content-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.academic-confirmation .ep-modal__content .content-container ul label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 14px;
  font-size: 1.4rem;
}
.academic-confirmation .ep-modal__content .content-container ul label input[type="checkbox"] {
  position: relative;
  top: 0.4rem;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 1rem;
}
.academic-confirmation .ep-modal__content .content-container .warning {
  padding: 1.2rem 0;
  margin: 1.6rem 0;
  background-color: #fff8dd;
}
.academic-confirmation .ep-modal__content .content-container .warning.affiliation {
  height: 6rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.academic-confirmation .ep-modal__content .content-container .warning.affiliation .warning-icon {
  margin: 0 1.2rem;
  color: #f67c61;
  height: 3.5rem;
}
.academic-confirmation .ep-modal__content .content-container .warning.affiliation .warning-icon:before {
  font-size: 35px;
  font-size: 3.5rem;
  position: relative;
  top: 0.8rem;
}
.academic-confirmation .ep-modal__content .content-container .warning.proof {
  font-weight: bold;
  text-align: center;
  background-color: #fff0bb;
}
.academic-confirmation .ep-modal__content .content-container input[type="text"] {
  width: 100%;
  height: 6.8rem;
}
.academic-confirmation .ep-modal__content .content-container .hint {
  text-align: center;
  margin: 15px 0;
  font-size: 14px;
  font-size: 1.4rem;
}
