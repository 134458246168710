@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.toggle-panel .header {
  cursor: pointer;
  padding: 0;
  padding-left: 5px;
  margin-top: 25px;
}
.toggle-panel .header h2 {
  margin: 0;
}
.toggle-panel .header h2:before {
  font-family: 'gdt';
  content: '\e019';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.toggle-panel .header h2 * {
  margin-left: 0.5rem;
}
.toggle-panel .header h2:before {
  margin-right: 10px;
}
.toggle-panel .header.open h2:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.toggle-panel .header.open h2 * {
  margin-left: 0.5rem;
}
.toggle-panel .content {
  display: none;
  padding: 0;
  border: 0;
}
.toggle-panel .content.open {
  display: block;
}
