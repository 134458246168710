@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.soj-section {
  margin-right: 10px;
}
.summary-of-judgements {
  height: 100%;
  width: 100%;
}
.summary-of-judgements table,
.summary-of-judgements tbody,
.summary-of-judgements tr,
.summary-of-judgements td,
.summary-of-judgements th {
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}
.summary-of-judgements td {
  background-clip: padding-box;
  border: 2px solid #fff;
}
.summary-of-judgements thead th {
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
}
.summary-of-judgements .header th {
  padding: 0;
  height: 57px;
  max-height: 57px;
  vertical-align: middle;
}
.summary-of-judgements .header .criteria {
  min-width: 166px;
}
.summary-of-judgements .header .summary {
  min-width: 675px;
}
.summary-of-judgements .header .importance {
  min-width: 93px;
}
.summary-of-judgements .header .importance.short {
  width: 140px;
}
.summary-of-judgements tr {
  vertical-align: top;
  min-height: 40px;
}
.summary-of-judgements tr:hover .soj__hide-row-button {
  display: block;
}
.summary-of-judgements td {
  text-align: center;
}
.summary-of-judgements td.option,
.summary-of-judgements td.options,
.summary-of-judgements td.options-container,
.summary-of-judgements td.additional-options {
  border: 0;
  background-clip: padding-box;
}
.summary-of-judgements td.additional-options {
  border-left: 2px solid #fff;
}
.summary-of-judgements td.options-container {
  border-top: 1px solid #fff;
  border-right: 10px solid #fff;
  border-bottom: 2px solid #fff;
}
.summary-of-judgements td.option {
  padding: 10px;
  border-left: 2px solid #fff;
}
.summary-of-judgements td.option:first-child {
  border-left: 0;
}
.summary-of-judgements .section-name {
  background-color: #dedede;
  width: 200px;
  min-width: 200px;
  height: 40px;
  text-transform: uppercase;
  text-align: right;
  padding: 5px 10px;
  line-height: 10pt;
  vertical-align: middle;
  position: relative;
}
.summary-of-judgements .section-name:hover {
  background: #ddeaf9;
}
.summary-of-judgements .section-name .soj__hide-row-button {
  display: none;
  position: absolute;
  bottom: -1px;
  left: 5px;
}
.summary-of-judgements .section-name .soj__hide-row-button button.eye {
  font-size: 10px;
}
.summary-of-judgements .section-name p {
  margin: 0;
}
.summary-of-judgements tr.hidden-section .section-name {
  height: 20px;
}
.summary-of-judgements tr.hidden-section .options-container {
  height: 20px;
  background: #dedede;
}
.summary-of-judgements .options-container {
  height: 40px;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}
.summary-of-judgements .options-container table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  border: 0;
}
.summary-of-judgements .options-container table tr {
  border: 0;
}
.summary-of-judgements .options-container table .option {
  position: relative;
  vertical-align: middle;
}
.summary-of-judgements .options-container table .options {
  width: 77%;
  color: #999;
  padding: 0;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}
.summary-of-judgements .options-container table .options td.option {
  background: #efefef;
  background-clip: padding-box;
}
.summary-of-judgements .options-container table .options .option.small {
  width: 20%;
}
.summary-of-judgements .options-container table .options .option.big {
  width: 25%;
}
.summary-of-judgements .options-container table .options .option.dark-cell {
  background: #ccc;
}
.summary-of-judgements .options-container table .options .option.checked {
  color: #fff;
}
.summary-of-judgements .options-container table .options .option.checked:nth-child(1) {
  background-color: #99afc6;
}
.summary-of-judgements .options-container table .options .option.checked:nth-child(2) {
  background-color: #7194bd;
}
.summary-of-judgements .options-container table .options .option.checked:nth-child(3) {
  background-color: #37679e;
}
.summary-of-judgements .options-container table .options .option.checked:nth-child(4) {
  background-color: #154982;
}
.summary-of-judgements .options-container table .options .option.checked:nth-child(5) {
  background-color: #14457c;
}
.summary-of-judgements .options-container table .additional-options {
  width: 23%;
  color: #999;
  padding: 0;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
  background: #e5e5e5;
}
.summary-of-judgements .options-container table .additional-options table {
  border: 0;
}
.summary-of-judgements .options-container table .additional-options table tbody {
  border: 0;
}
.summary-of-judgements .options-container table .additional-options table .option {
  background: #e5e5e5;
  background-clip: padding-box;
  width: 50%;
  color: #ccc;
}
.summary-of-judgements .options-container table .additional-options table .option.checked {
  color: #2d3236;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option,
.summary-of-judgements tr.equity .options-container .options table tr td.option {
  position: relative;
  background-clip: padding-box;
  padding-bottom: 35px;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:after,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:after,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:after,
.summary-of-judgements tr.equity .options-container .options table tr td.option:after {
  position: absolute;
  bottom: 5px;
  right: 0px;
  font-family: 'gdt';
  display: block;
  width: 100%;
  height: 25px;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option.checked,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option.checked,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option.checked,
.summary-of-judgements tr.equity .options-container .options table tr td.option.checked {
  color: #fff;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(1).checked,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(1).checked,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(1).checked,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(1).checked {
  background-color: #ff6d3e;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(1):after,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(1):after,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(1):after,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(1):after {
  content: "";
  background: url("../images/svg/soj-double-arrow-left.svg") no-repeat 50% 50%;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(2).checked,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(2).checked,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(2).checked,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(2).checked {
  background-color: #f59038;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(2):after,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(2):after,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(2):after,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(2):after {
  content: "";
  background: url("../images/svg/soj-arrow-left.svg") no-repeat 50% 50%;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(3).checked,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(3).checked,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(3).checked,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(3).checked {
  color: #2d3236;
  background-color: #ffde64;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(3):after,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(3):after,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(3):after,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(3):after {
  content: "";
  background: url("../images/svg/soj-circle.svg") no-repeat 50% 50%;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(4).checked,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(4).checked,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(4).checked,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(4).checked {
  background-color: #a7d887;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(4):after,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(4):after,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(4):after,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(4):after {
  content: "";
  background: url("../images/svg/soj-arrow.svg") no-repeat 50% 50%;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(5).checked,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(5).checked,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(5).checked,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(5).checked {
  background-color: #66ad40;
}
.summary-of-judgements tr.balance-of-effects .options-container .options table tr td.option:nth-child(5):after,
.summary-of-judgements tr.resources-required .options-container .options table tr td.option:nth-child(5):after,
.summary-of-judgements tr.cost-effectiveness .options-container .options table tr td.option:nth-child(5):after,
.summary-of-judgements tr.equity .options-container .options table tr td.option:nth-child(5):after {
  content: "";
  background: url("../images/svg/soj-double-arrow.svg") no-repeat 50% 50%;
}
.summary-of-judgements td.importance {
  text-transform: uppercase;
  vertical-align: middle;
  font-weight: bold;
  cursor: pointer;
  background: #f2f2f2;
  color: #2d3236;
}
.soj-editor {
  background-color: #f6f6f6;
  padding: 10px;
}
.soj-editor .title {
  font-weight: bold;
  margin-bottom: 20px;
}
.soj-editor .buttons-react {
  margin-top: 10px;
}
.soj-editor .main-question {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.soj-editor .main-question .label {
  text-transform: uppercase;
}
.soj-editor .main-question .question {
  padding-left: 10px;
  font-weight: bold;
}
.soj-editor .evaluation-question {
  display: flex;
  flex-direction: row;
  font-size: inherit;
  line-height: 30px;
}
.soj-editor select {
  margin-left: 20px;
  border: 1px solid #fff;
  height: 32px;
  -webkit-appearance: menulist;
  -webkit-rtl-ordering: logical;
}
.soj__hide-column-button {
  position: absolute;
  bottom: 0px;
  right: -8px;
  z-index: 2;
  font-size: 14px;
}
.soj__hide-column-button button.eye {
  font-size: 10px;
}
.soj__hide-column-button button.blind-eye {
  font-size: 14px;
}
.soj__hide-column-button--section-hide {
  bottom: -23px;
}
.soj-importance-editor-dialog {
  width: 500px;
  line-height: 15px;
}
.soj-clear-dialog {
  width: 550px;
  padding: 10px;
}
.summary-of-judgements.adolopments {
  table-layout: fixed;
}
.summary-of-judgements.adolopments .header .importance {
  width: 150px;
}
.summary-of-judgements.adolopments .header .criteria {
  width: 200px;
}
.summary-of-judgements.adolopments th.option {
  background: #fff;
}
.summary-of-judgements.adolopments th.option .section-title-original {
  padding-left: 10px;
  border-left: 15px solid #29abe2;
}
.summary-of-judgements.adolopments th.option .section-title-adolopment {
  padding-left: 10px;
  border-left: 15px solid #f7931e;
}
.summary-of-judgements.adolopments th.option,
.summary-of-judgements.adolopments td.option {
  text-align: left;
  background-clip: padding-box;
  border-left: 15px solid #fff;
  border-bottom: 2px solid #fff;
  width: calc((100% - 530px) / 2);
}
.summary-of-judgements.adolopments td.option {
  background: #efefef;
}
.summary-of-judgements.adolopments th.option {
  background: #fff;
}
.summary-of-judgements.adolopments th.importance,
.summary-of-judgements.adolopments td.importance {
  width: 150px;
}
.summary-of-judgements.adolopments td.option.readOnly {
  color: #666;
}
.summary-of-judgements.adolopments td.option.changed {
  background: #fff0bb;
}
