.epiditor--container {
  position: relative;
  font-family: "PT Sans", sans-serif;
  /* Make sure li selections wrap around markers */
}
.epiditor--container ::-moz-selection,
.epiditor--container ::selection {
  background-color: yellow;
}
.epiditor--container * {
  box-sizing: border-box;
}
.epiditor--container a,
.epiditor--container a:visited,
.epiditor--container a:active {
  color: #004eff;
}
.epiditor--container h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.epiditor--container h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.epiditor--container h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.epiditor--container h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.epiditor--container h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.epiditor--container p,
.epiditor--container h1,
.epiditor--container h2,
.epiditor--container h3,
.epiditor--container h4,
.epiditor--container h5,
.epiditor--container h6 {
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.epiditor--container li > p,
.epiditor--container li > h1,
.epiditor--container li > h2,
.epiditor--container li > h3,
.epiditor--container li > h4,
.epiditor--container li > h5,
.epiditor--container li > h6 {
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}
.epiditor--container .epiditor--pm-editor-root {
  outline: none;
  background-color: #fff;
  overflow: auto;
}
.epiditor--container .ProseMirror {
  padding: 0.5rem;
}
.epiditor--container .ProseMirror:not(.minimal) {
  min-height: 200px;
}
.epiditor--container .epiditor--dropdown-menu-item {
  cursor: pointer;
  font-size: 12px;
  padding: 5px 12px;
  text-align: center;
  white-space: nowrap;
}
.epiditor--container .epiditor--dropdown-menu-item:hover, .epiditor--container .epiditor--dropdown-menu-item.active {
  background-color: #f2f2f2;
}
.epiditor--container .epiditor--tooltip {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  white-space: nowrap;
  z-index: 10;
}
.epiditor--container .epiditor--tooltip.transition {
  transition: top 0.1s ease, left 0.1s ease;
}
.epiditor--container .epiditor--tooltip.visible {
  visibility: visible;
  pointer-events: initial;
}
.epiditor--container .ProseMirror {
  position: relative;
}
.epiditor--container .ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
}
.epiditor--container .ProseMirror pre {
  white-space: pre-wrap;
}
.epiditor--container .ProseMirror li {
  position: relative;
}
.epiditor--container .ProseMirror-hideselection *::selection {
  background: transparent;
}
.epiditor--container .ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}
.epiditor--container .ProseMirror-hideselection {
  caret-color: transparent;
}
.epiditor--container .ProseMirror-selectednode {
  outline: 2px solid #8cf;
}
.epiditor--container li.ProseMirror-selectednode {
  outline: none;
}
.epiditor--container li.ProseMirror-selectednode:after {
  content: "";
  position: absolute;
  left: -32px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  border: 2px solid #8cf;
  pointer-events: none;
}
.epiditor--container .fancy-scroll {
  /* Firefox */
  /* Chrome, Edge, and Safari */
}
.epiditor--container .fancy-scroll {
  scrollbar-width: auto;
  scrollbar-color: #5c7080 transparent;
}
.epiditor--container .fancy-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.epiditor--container .fancy-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.epiditor--container .fancy-scroll::-webkit-scrollbar-thumb {
  background-color: #5c7080;
  border-radius: 20px;
  border: 3px solid transparent;
}

.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}

.ProseMirror ul,
.ProseMirror ul ul ul ul {
  list-style-type: disc;
}
.ProseMirror ul ul,
.ProseMirror ul ul ul ul ul {
  list-style-type: circle;
}
.ProseMirror ul ul ul,
.ProseMirror ul ul ul ul ul ul {
  list-style-type: square;
}
.ProseMirror ol,
.ProseMirror ol ol ol ol {
  list-style-type: decimal;
}
.ProseMirror ol ol,
.ProseMirror ol ol ol ol ol {
  list-style-type: lower-alpha;
}
.ProseMirror ol ol ol,
.ProseMirror ol ol ol ol ol ol {
  list-style-type: lower-roman;
}

.ProseMirror.ProseMirror-focused .epiditor--table-cell-menu-portal {
  display: initial;
}
.ProseMirror .epiditor--table-cell-menu-portal {
  display: none;
}
.ProseMirror .tableWrapper {
  overflow-x: auto;
}
.ProseMirror .tableWrapper.remove-target table td, .ProseMirror .tableWrapper.remove-target table th,
.ProseMirror .tableWrapper td.remove-target,
.ProseMirror .tableWrapper th.remove-target {
  background-color: rgba(255, 186, 108, 0.1490196078);
}
.ProseMirror .tableWrapper td.toggle-header,
.ProseMirror .tableWrapper th.toggle-header {
  background-color: rgba(255, 186, 108, 0.4);
}
.ProseMirror .tableWrapper td.insert-target:before,
.ProseMirror .tableWrapper th.insert-target:before {
  position: absolute;
  display: block;
  background-color: #0089ba;
  content: " ";
  width: 0;
  height: 0;
}
.ProseMirror .tableWrapper td.insert-target.col-insert-before:before,
.ProseMirror .tableWrapper th.insert-target.col-insert-before:before {
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
}
.ProseMirror .tableWrapper td.insert-target:not([colspan]).col-insert-after:before,
.ProseMirror .tableWrapper th.insert-target:not([colspan]).col-insert-after:before {
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
}
.ProseMirror .tableWrapper td.insert-target.row-insert-before:before,
.ProseMirror .tableWrapper th.insert-target.row-insert-before:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
.ProseMirror .tableWrapper td.insert-target:not([rowspan]).row-insert-after:before,
.ProseMirror .tableWrapper th.insert-target:not([rowspan]).row-insert-after:before {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
.ProseMirror.resize-cursor {
  cursor: col-resize;
  cursor: ew-resize;
}
.ProseMirror table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  overflow: hidden;
  transition: border 0.2s ease, background-color 0.2s ease;
}
.ProseMirror table td,
.ProseMirror table th {
  padding: 8px;
  border: 1px solid #707070;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  transition: border 0.2s ease, background-color 0.2s ease;
}
.ProseMirror table .selectedCell {
  background: #bcebff;
  border: 1px solid #0089ba;
}
.ProseMirror table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #0089ba;
  pointer-events: none;
}

.ProseMirror [data-text-align=start] {
  text-align: left;
  text-align: start;
}
.ProseMirror [data-text-align=end] {
  text-align: right;
  text-align: end;
}
.ProseMirror [data-text-align=center] {
  text-align: center;
}
.ProseMirror [data-text-align=justify] {
  text-align: justify;
}

.epiditor--container .epiditor--annotation {
  background-color: #ffddaa;
  transition: background 0.3s ease;
}
.epiditor--container .epiditor--annotation.active {
  background-color: #f78849;
}
.epiditor--container .add-annotation-widget {
  position: absolute;
  transition: top 0.3s ease;
  transform: translateX(-50%);
}
.epiditor--container .add-annotation-widget .addAnnotationControl {
  width: 32px;
  height: 32px;
  padding: 4px 0;
  border-radius: 50%;
  background-color: #26c6da;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
  transition: transform 0.2s ease;
}
.epiditor--container .add-annotation-widget .addAnnotationControl:hover {
  transform: scale(1.15, 1.15);
}

.epiditor--container .changed {
  color: #23b750;
  box-shadow: inset 0 0 4px 0px #23b750;
  transition: background 0.3s ease;
}
.epiditor--container img.changed-reverted {
  padding: 1px;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
.epiditor--container .changed-reverted {
  color: #23b750;
  text-decoration: line-through;
  background-color: rgba(255, 186, 108, 0.1490196078);
}
.epiditor--container .changed.active,
.epiditor--container .changed-reverted.active {
  box-shadow: inset 0 0 10px 5px #f5de72;
}

.epiditor--resizable-image-wrap {
  display: inline-block;
  position: relative;
  line-height: 0;
}
.epiditor--resizable-image-wrap .epiditor--image-resize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #25a1ff;
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle {
  position: absolute;
  top: 0;
  left: 0;
  height: 7px;
  width: 7px;
  border: inherit;
  background-color: #fff;
  transform: translate(-50%, -50%);
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle.n {
  left: 50%;
  cursor: n-resize;
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle.s {
  left: 50%;
  top: 100%;
  cursor: s-resize;
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle.w {
  top: 50%;
  cursor: w-resize;
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle.e {
  top: 50%;
  left: 100%;
  cursor: e-resize;
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle.ne {
  left: 100%;
  cursor: ne-resize;
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle.nw {
  cursor: nw-resize;
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle.sw {
  top: 100%;
  cursor: sw-resize;
}
.epiditor--resizable-image-wrap .epiditor--image-resize .resize-handle.se {
  top: 100%;
  left: 100%;
  cursor: se-resize;
}

.epiditor--tooltip.image-position-tooltip .epiditor--image-alignment-toggles {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #ffffff;
  border-radius: 5px;
}
.epiditor--tooltip.image-position-tooltip .epiditor--image-alignment-toggles .epiditor--toolbarControl:not(:last-child) {
  margin-right: 5px;
}

.epiditor--cellMenu {
  position: absolute;
  cursor: pointer;
  z-index: 21;
  transition: top 0.1s ease, left 0.1s ease;
}
.epiditor--cellMenu .toggle {
  padding: 3px;
  font-size: 10px;
  vertical-align: top;
  fill: rgba(112, 112, 112, 0.3);
  border: 1px solid transparent;
  transition: background-color 0.2s ease, border 0.2s ease;
  border-radius: 3px;
}
.epiditor--cellMenu.active .toggle,
.epiditor--cellMenu .toggle:hover {
  border: 1px solid rgba(112, 112, 112, 0.3);
  fill: #707070;
}
.epiditor--cellMenu .menu-item {
  white-space: nowrap;
  padding: 10px 5px;
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.epiditor--cellMenu .menu-item:hover {
  background-color: #f2f2f2;
}
.epiditor--cellMenu .menu-item.disabled {
  cursor: not-allowed;
  color: #d9d9d9;
}
.epiditor--cellMenu .menu-item .menu-item__tick {
  padding-right: 5px;
}
.epiditor--cellMenu .menu-item .menu-item__tick svg {
  width: 12px;
  height: 10px;
}
.epiditor--cellMenu .menu-item .menu-item__label {
  flex: 1;
}

.epiditor--toolbar {
  position: relative;
  z-index: 10;
  display: flex;
  flex-flow: row wrap;
  padding: 2px;
  border: 1px solid #d9d9d9;
  background: #f2f2f2 0% 0% no-repeat padding-box;
}

.epiditor--toolbarDivider {
  display: inline-block;
  vertical-align: top;
  height: 25px;
  margin: 0 5px;
  border-right: 1px solid #d9d9d9;
}

.epiditor--toolbarControl {
  display: inline-flex;
  width: 25px;
  height: 25px;
  padding: 0.2rem;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  vertical-align: top;
}
.epiditor--toolbarControl.active, .epiditor--toolbarControl:hover {
  background-color: #d9d9d9;
}
.epiditor--toolbarControl.disabled {
  cursor: not-allowed;
  color: #9a9999;
  fill: #9a9999;
}

.epiditor--dropdown {
  position: relative;
  display: inline-flex;
  vertical-align: top;
}
.epiditor--dropdown .dropdown-content {
  position: absolute;
  top: 100%;
  z-index: 2;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  overflow: auto;
}

.epiditor--highlighter-color-options {
  font-size: 14px;
}
.epiditor--highlighter-color-options .main-toggle {
  display: flex;
  flex-direction: column;
}
.epiditor--highlighter-color-options .options-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.epiditor--highlighter-color-options .options-group:not(:first-of-type) {
  margin-top: 5px;
}
.epiditor--highlighter-color-options .color-option {
  display: inline-flex;
  height: 20px;
  width: 20px;
  vertical-align: top;
  border: 1px solid #848484;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.epiditor--highlighter-color-options .color-option:not(:first-of-type) {
  margin-left: 5px;
}
.epiditor--highlighter-color-options .color-option:hover, .epiditor--highlighter-color-options .color-option.active {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
.epiditor--highlighter-color-options .reset-highlight-option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
}
.epiditor--highlighter-color-options .reset-highlight-option .color-option {
  margin-right: 5px;
}
.epiditor--highlighter-color-options hr {
  margin: 5px 0;
}

.epiditor--table-insert-sizer .table-sizer {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.epiditor--table-insert-sizer .size {
  text-align: center;
}
.epiditor--table-insert-sizer .table-sizer-selected-area {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #bcebff;
  transition: width 0.1s linear, height 0.1s linear;
}
.epiditor--table-insert-sizer .table-sizer-area {
  transition: width 0.1s, height 0.1s;
  background-size: 20px 20px, 20px 20px, 2px 100%, 100% 2px;
  background-position: top left, top left, top right, bottom left;
  background-repeat: repeat, repeat, no-repeat, no-repeat;
  background-image: linear-gradient(to right, grey 2px, transparent 2px), linear-gradient(to bottom, grey 2px, transparent 2px), linear-gradient(to right, grey, grey), linear-gradient(to bottom, grey, grey);
}

.epiditor--textStyleControlLabel {
  height: 25px;
  width: 85px;
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
}
.epiditor--textStyleControlLabel.disabled {
  cursor: not-allowed;
  color: #d9d9d9;
}

.epiditor--textStyleOption {
  padding: 5px 0 5px 5px;
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
}
.epiditor--textStyleOption:hover, .epiditor--textStyleOption.active {
  background-color: #f2f2f2;
}
.epiditor--textStyleOption.disabled {
  cursor: not-allowed;
  color: #d9d9d9;
}

.epiditor--hyperlinkEditor {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-family: "PT Sans", sans-serif;
}
.epiditor--hyperlinkEditor .toolbar,
.epiditor--hyperlinkEditor .form {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.epiditor--hyperlinkEditor .form {
  min-width: 300px;
}
.epiditor--hyperlinkEditor .form input {
  flex: 1;
  margin-right: 10px;
  padding: 6px;
  border: 1px solid #d9d9d9;
  font-family: inherit;
  font-size: 14px;
  outline: none;
  border-radius: 2px;
}
.epiditor--hyperlinkEditor .form .apply-btn {
  padding: 6px 10px;
  border-radius: 2px;
  border: none;
  background-color: #f2f2f2;
  color: #0089ba;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.epiditor--hyperlinkEditor .form .apply-btn:hover, .epiditor--hyperlinkEditor .form .apply-btn:active {
  background-color: #d8d6d6;
}
.epiditor--hyperlinkEditor .toolbar a.open-link:hover,
.epiditor--hyperlinkEditor .toolbar button:hover {
  background-color: #d9d9d9;
}
.epiditor--hyperlinkEditor .toolbar a.open-link {
  display: inline-flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  border-radius: 3px;
}
.epiditor--hyperlinkEditor .toolbar button {
  height: 25px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 2px;
}

.epiditor--imageInsertDialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body {
  position: relative;
  top: 30%;
  width: 540px;
  margin: 20px auto;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-size: 14px;
  font-family: "PT Sans", sans-serif;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body input[type=file] {
  display: none;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field:not(:last-child) {
  margin-bottom: 10px;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field .failed {
  color: red;
  text-decoration: line-through;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field label {
  flex: none;
  min-width: 75px;
  margin-right: 15px;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field .file-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field .file-label button.file-remove {
  width: 20px;
  height: 15px;
  background-color: transparent;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field .file-label button:hover {
  transform: scale(1.2);
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field button {
  height: 30px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  background-color: #0089ba;
  color: #fff;
  border-radius: 2px;
  border: none;
  flex: 1;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field button:hover {
  background-color: #035b7a;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .field input {
  height: 30px;
  padding: 0 5px;
  flex: 1;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .footer-actions {
  display: flex;
  margin-top: 15px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .footer-actions button {
  height: 30px;
  min-width: 60px;
  background-color: #9dbfcc;
  border-radius: 2px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .footer-actions button.cancel {
  margin-right: 10px;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .footer-actions button.cancel:hover {
  background-color: #7c9aa7;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .footer-actions button.apply {
  background-color: #0089ba;
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .footer-actions button.apply[disabled] {
  cursor: not-allowed;
  background-color: rgba(0, 136, 186, 0.4392156863);
}
.epiditor--imageInsertDialog-overlay .epiditor--imageInsertDialog-body .footer-actions button.apply:not([disabled]):hover {
  background-color: #035b7a;
}

/*# sourceMappingURL=epiditor.css.map */
