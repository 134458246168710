@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.notifier-screen.notifier-theme-gdt {
  background-color: #fff;
}
.notifier.notifier-theme-gdt.notifier-info > div {
  background-color: #fff !important;
  color: #2d3236 !important;
}
.notifier.notifier-theme-gdt.notifier-info > div .notifier-close {
  color: #2d3236;
}
.notifier.notifier-theme-gdt {
  font-size: 15px;
  font-size: 1.5rem;
  text-align: center;
}
.notifier.notifier-theme-gdt .notifier-loader {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background: url("../images/loader.gif") center center no-repeat;
}
.notifier.notifier-theme-gdt .notifier-inner {
  padding: 15px;
  min-width: 300px;
}
.notifier.notifier-theme-gdt .notifier-inner > .row {
  margin-top: 25px !important;
}
.notifier.notifier-theme-gdt .notifier-close {
  font-size: 14px;
  min-width: 0;
  padding: 0;
  margin: 0;
  background: transparent !important;
  border: 1px solid transparent;
  width: 22px;
  height: 22px;
  -webkit-border-radius: 11px;
  border-radius: 11px;
  top: 2px;
  right: 2px;
}
.notifier.notifier-theme-gdt .notifier-close:hover {
  background: rgba(109,109,109,0.2) !important;
}
.notifier.notifier-theme-gdt .notifier-btns {
  margin-top: 15px;
  text-align: center;
}
.notifier.notifier-theme-gdt .notifier-btns button,
.notifier.notifier-theme-gdt input[type=submit] {
  border: solid 1px #d1d1d1;
  min-width: 102px;
  padding: 5px 30px;
  margin: 0 5px;
}
.notifier.notifier-theme-gdt button.link {
  text-shadow: 0 1px 0 #e9e9e9;
  color: #444;
  border-bottom: dotted 1px #444;
  padding: 9px 0 1px;
}
.notifier.notifier-theme-gdt button.link:hover,
.notifier.notifier-theme-gdt button.link.hover {
  color: #000;
  border-bottom: solid 1px #000;
  text-shadow: 0 1px 0 #dbdbdb;
}
.notifier.notifier-theme-gdt > div {
  background: #fff;
  border: 1px solid #bfbfbf;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  padding: 30px 30px 30px;
  color: #2d3236;
}
.notifier.notifier-theme-gdt.notifier-warning > div {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  color: #2d3236;
}
.notifier.notifier-theme-gdt.notifier-warning > div button {
  background-color: #f67c61;
  color: #fff;
}
.notifier.notifier-theme-gdt.notifier-warning > div button:hover:not(:disabled) {
  background-color: #f7846b;
}
.notifier.notifier-theme-gdt.notifier-warning > div button[disabled] {
  background-color: rgba(246,124,97,0.24);
  color: rgba(255,255,255,0.24);
}
.notifier.notifier-theme-gdt.notifier-warning > div button.danger,
.notifier.notifier-theme-gdt.notifier-warning > div input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
.notifier.notifier-theme-gdt.notifier-warning > div button.danger:hover:not(:disabled),
.notifier.notifier-theme-gdt.notifier-warning > div input.danger:hover:not(:disabled) {
  background-color: #f5e5e5;
}
.notifier.notifier-theme-gdt.notifier-warning > div button.danger[disabled],
.notifier.notifier-theme-gdt.notifier-warning > div input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
.notifier.notifier-theme-gdt.notifier-warning > div button.finish,
.notifier.notifier-theme-gdt.notifier-warning > div input.finish {
  background-color: #c9dac2;
  color: #036707;
}
.notifier.notifier-theme-gdt.notifier-warning > div button.finish:hover:not(:disabled),
.notifier.notifier-theme-gdt.notifier-warning > div input.finish:hover:not(:disabled) {
  background-color: #cfdec8;
}
.notifier.notifier-theme-gdt.notifier-warning > div button.finish[disabled],
.notifier.notifier-theme-gdt.notifier-warning > div input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
.notifier.notifier-theme-gdt.notifier-warning > div button.loading,
.notifier.notifier-theme-gdt.notifier-warning > div input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
.notifier.notifier-theme-gdt.notifier-error > div {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  color: #2d3236;
}
.notifier.notifier-theme-gdt.notifier-error > div button {
  background-color: #d53d10;
  color: #fff;
}
.notifier.notifier-theme-gdt.notifier-error > div button:hover:not(:disabled) {
  background-color: #de4011;
}
.notifier.notifier-theme-gdt.notifier-error > div button[disabled] {
  background-color: rgba(213,61,16,0.24);
  color: rgba(255,255,255,0.24);
}
.notifier.notifier-theme-gdt.notifier-error > div button.danger,
.notifier.notifier-theme-gdt.notifier-error > div input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
.notifier.notifier-theme-gdt.notifier-error > div button.danger:hover:not(:disabled),
.notifier.notifier-theme-gdt.notifier-error > div input.danger:hover:not(:disabled) {
  background-color: #f5e5e5;
}
.notifier.notifier-theme-gdt.notifier-error > div button.danger[disabled],
.notifier.notifier-theme-gdt.notifier-error > div input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
.notifier.notifier-theme-gdt.notifier-error > div button.finish,
.notifier.notifier-theme-gdt.notifier-error > div input.finish {
  background-color: #c9dac2;
  color: #036707;
}
.notifier.notifier-theme-gdt.notifier-error > div button.finish:hover:not(:disabled),
.notifier.notifier-theme-gdt.notifier-error > div input.finish:hover:not(:disabled) {
  background-color: #cfdec8;
}
.notifier.notifier-theme-gdt.notifier-error > div button.finish[disabled],
.notifier.notifier-theme-gdt.notifier-error > div input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
.notifier.notifier-theme-gdt.notifier-error > div button.loading,
.notifier.notifier-theme-gdt.notifier-error > div input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
.notifier.notifier-theme-gdt.notifier-info > div button {
  background-color: #009cca;
  color: #fff;
}
.notifier.notifier-theme-gdt.notifier-info > div button:hover:not(:disabled) {
  background-color: #00a4d4;
}
.notifier.notifier-theme-gdt.notifier-info > div button[disabled] {
  background-color: rgba(0,156,202,0.24);
  color: rgba(255,255,255,0.24);
}
.notifier.notifier-theme-gdt.notifier-success > div {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  color: #2d3236;
}
.notifier.notifier-theme-gdt.notifier-success > div button {
  background-color: #00c0b3;
  color: #fff;
}
.notifier.notifier-theme-gdt.notifier-success > div button:hover:not(:disabled) {
  background-color: #00cabd;
}
.notifier.notifier-theme-gdt.notifier-success > div button[disabled] {
  background-color: rgba(0,192,179,0.24);
  color: rgba(255,255,255,0.24);
}
.notifier.notifier-theme-gdt.notifier-success > div button.danger,
.notifier.notifier-theme-gdt.notifier-success > div input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
.notifier.notifier-theme-gdt.notifier-success > div button.danger:hover:not(:disabled),
.notifier.notifier-theme-gdt.notifier-success > div input.danger:hover:not(:disabled) {
  background-color: #f5e5e5;
}
.notifier.notifier-theme-gdt.notifier-success > div button.danger[disabled],
.notifier.notifier-theme-gdt.notifier-success > div input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
.notifier.notifier-theme-gdt.notifier-success > div button.finish,
.notifier.notifier-theme-gdt.notifier-success > div input.finish {
  background-color: #c9dac2;
  color: #036707;
}
.notifier.notifier-theme-gdt.notifier-success > div button.finish:hover:not(:disabled),
.notifier.notifier-theme-gdt.notifier-success > div input.finish:hover:not(:disabled) {
  background-color: #cfdec8;
}
.notifier.notifier-theme-gdt.notifier-success > div button.finish[disabled],
.notifier.notifier-theme-gdt.notifier-success > div input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
.notifier.notifier-theme-gdt.notifier-success > div button.loading,
.notifier.notifier-theme-gdt.notifier-success > div input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
.notifier.notifier-theme-gdt.notifier-dialog .notifier-message {
  text-align: left;
}
.notifier.notifier-theme-gdt.notifier-dialog .notifier-close {
  font-size: 26px;
  font-weight: bold;
  color: #adb7be;
  min-width: 0;
  padding: 0;
  margin: 0;
  background: transparent !important;
  border: 1px solid transparent;
  width: 14px;
  height: 14px;
  line-height: 18px;
  top: 10px;
  right: 10px;
  z-index: 999;
}
.notifier.notifier-theme-gdt.notifier-dialog .notifier-close span {
  display: none;
}
.notifier.notifier-theme-gdt.notifier-dialog .notifier-close:before {
  content: '\00d7';
}
.notifier.notifier-theme-gdt.notifier-dialog .notifier-close:hover {
  background: none !important;
  color: #66696c;
}
.notifier.notifier-theme-gdt.notifier-dialog .notifier-title {
  margin-bottom: 15px;
  color: #2d3236;
  font-size: 22px;
}
.notifier.notifier-theme-gdt.notifier-with-close-btn .notifier-title {
  padding-right: 60px;
}
.notifier.notifier-theme-gdt.info-box > div {
  color: #2d3236;
  background: #fff;
  padding: 3rem 2rem;
}
.notifier.notifier-theme-gdt.info-box > div .notifier-title {
  margin: 1.5rem 0 3rem;
  font-size: 24px;
  font-size: 2.4rem;
}
.notifier.notifier-theme-gdt.info-box > div h2 {
  margin: 3rem 0 0;
}
.notifier.notifier-theme-gdt.info-box > div p {
  margin-top: 2px;
}
.notifier.notifier-theme-gdt.info-box > div .thumbnail {
  position: relative;
  text-align: center;
  margin: 0 auto;
}
.notifier.notifier-theme-gdt.info-box > div .thumbnail:before {
  font-family: 'gdt';
  content: '\e016';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.notifier.notifier-theme-gdt.info-box > div .thumbnail * {
  margin-left: 0.5rem;
}
.notifier.notifier-theme-gdt.info-box > div .thumbnail:before {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  color: #fff;
  font-size: 36px;
  font-size: 3.6rem;
  margin-top: -1.8rem;
  margin-left: -1.8rem;
}
.notifier.notifier-theme-gdt.info-box > div .thumbnail:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.3);
  cursor: pointer;
}
.notifier.notifier-theme-gdt.info-box > div .thumbnail:hover:after {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-title {
  margin: 3rem 2rem 0;
  padding: 0;
  font-size: 24px;
  font-size: 2.4rem;
  color: #2d3236;
}
