@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.footnotes-tab-panel,
.references-tab-panel {
  display: none;
}
.footnotes-tab-panel.active,
.references-tab-panel.active {
  display: block;
}
.footnotes-container .filter-by-active-cell-btn {
  position: relative;
  margin-left: 5px;
}
.footnotes-container .footnotes {
  width: 100%;
}
.footnotes-container .footnotes .footnotes-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  background-color: #2caac4;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.footnotes-container .footnotes .footnotes-header .switcher-container {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  padding-left: 10px;
  line-height: 32px;
  background-color: #fff;
  border-top-left-radius: 3px;
  margin: 2px 2px 6px 2px;
}
.footnotes-container .footnotes .footnotes-header .tabspane {
  -webkit-flex: 0 0 340px;
  -ms-flexbox: 0 0 340px;
  flex: 0 0 340px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-lex;
  display: -ms-inline-flexox;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 40px;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.footnotes-container .footnotes .footnotes-header .tab {
  -webkit-flex: 0 0 167px;
  -ms-flexbox: 0 0 167px;
  flex: 0 0 167px;
  margin: 2px 2px 6px 2px;
  line-height: 32px;
  color: #fff;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: #3783a5;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.footnotes-container .footnotes .footnotes-header .tab[data-tab="references"] {
  border-top-right-radius: 3px;
}
.footnotes-container .footnotes .footnotes-header .tab--selected {
  line-height: 32px;
  background-color: #2caac4;
}
.footnotes-container .footnotes .footnotes-block {
  background-color: #fff;
  min-height: 50px;
  max-height: 170px;
  overflow-y: auto;
}
.footnotes-container .footnotes .footnotes-block .no-footnotes {
  margin-top: 10px;
  padding: 5px;
  height: 40px;
  background-color: #f2f2f2;
}
.footnotes-container .footnotes .references-list,
.footnotes-container .footnotes .footnotes-list {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 5px;
  position: relative;
  counter-reset: li;
  background-color: #f2f2f2;
}
.footnotes-container .footnotes .references-list li,
.footnotes-container .footnotes .footnotes-list li {
  padding: 0 5px;
  border: 2px solid #fff;
  min-height: 40px;
  line-height: ;
  position: relative;
  font-size: 14px;
}
.footnotes-container .footnotes .references-list li div.note,
.footnotes-container .footnotes .footnotes-list li div.note {
  position: relative;
  height: ;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.footnotes-container .footnotes .references-list li.active,
.footnotes-container .footnotes .footnotes-list li.active {
  background-color: #f7f7f7;
}
.footnotes-container .footnotes .footnotes-list li {
  display: table;
  width: 100%;
}
.footnotes-container .footnotes .footnotes-list li .display-footnote,
.footnotes-container .footnotes .footnotes-list li .edit-footnote {
  display: table-cell;
}
.footnotes-container .footnotes .footnotes-list li .display-footnote .inner,
.footnotes-container .footnotes .footnotes-list li .edit-footnote .inner {
  display: table;
  width: 100%;
}
.footnotes-container .footnotes .footnotes-list li .note,
.footnotes-container .footnotes .footnotes-list li .footnote-input-container,
.footnotes-container .footnotes .footnotes-list li .counter,
.footnotes-container .footnotes .footnotes-list li .item-controls {
  display: table-cell;
}
.footnotes-container .footnotes .footnotes-list li .counter,
.footnotes-container .footnotes .footnotes-list li .item-controls {
  line-height: 40px;
  vertical-align: middle;
  width: 30px;
}
.footnotes-container .footnotes .footnotes-list li .footnote-input-container {
  position: relative;
  padding-right: 10px;
}
.footnotes-container .footnotes .footnotes-list li .footnote-input-container textarea {
  width: 100%;
  padding: 5px;
}
.footnotes-container .footnotes .footnotes-list li .footnote-input-container .limit-info,
.footnotes-container .footnotes .footnotes-list li .footnote-input-container .inner-text {
  color: #000080;
  font-size: 12px;
}
.footnotes-container .footnotes .footnotes-list li .footnote-input-container .inner-text {
  position: static;
  display: block;
  text-align: right;
}
