@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.dropdown-menu-options {
  background-color: #fff;
  border: 1px solid #0089ba;
  box-shadow: 0 3px 6px rgba(0,0,0,0.161);
  margin: 0;
  padding: 10px;
  list-style-type: none;
}
.dropdown-menu-options .dropdown-menu-option {
  margin: 0;
  padding: 5px;
  cursor: pointer;
}
.dropdown-menu-options .dropdown-menu-option.active {
  font-weight: bold;
}
.dropdown-menu-options .dropdown-menu-option:hover {
  background-color: #d1dfec;
}
.dropdown-menu-options .dropdown-menu-option.edit:before {
  font-family: 'gdt';
  content: '\e012';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.dropdown-menu-options .dropdown-menu-option.edit * {
  margin-left: 0.5rem;
}
.dropdown-menu-options .dropdown-menu-option.edit:before {
  margin-right: 5px;
  color: #0089ba;
}
.dropdown-menu-options .dropdown-menu-option.delete:before {
  font-family: 'gdt';
  content: '\e022';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.dropdown-menu-options .dropdown-menu-option.delete * {
  margin-left: 0.5rem;
}
.dropdown-menu-options .dropdown-menu-option.delete:before {
  margin-right: 5px;
  color: #0089ba;
}
.dropdown-menu-options .dropdown-menu-option.separator {
  width: 100%;
  margin: 5px 0px;
  padding: 0px;
  height: 1px;
  background: #bfbfbf;
}
