@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.duplicates .header-table {
  margin-right: 10px;
}
.duplicates .group-tables {
  height: calc(100vh - 160px);
  overflow-y: scroll;
}
.duplicates table thead {
  margin-right: 13px;
}
.duplicates table td,
.duplicates table th {
  padding: 5px;
}
.duplicates table td.authors,
.duplicates table th.authors {
  width: auto;
}
.duplicates table td.title,
.duplicates table th.title {
  width: 30%;
}
.duplicates table td.year,
.duplicates table th.year {
  width: 10%;
}
.duplicates table td.publishedIn,
.duplicates table th.publishedIn {
  width: 15%;
}
.duplicates table td.volume,
.duplicates table th.volume {
  width: 5%;
}
.duplicates table td.issue,
.duplicates table th.issue {
  width: 10%;
}
.duplicates table td.pages,
.duplicates table th.pages {
  width: 8%;
}
.duplicates table .buttons-row {
  background-color: #fff;
}
.duplicates table .buttons-row:hover {
  background-color: #fff;
}
.duplicates table .buttons-row td {
  border: none;
  text-align: center;
}
.duplicates table .buttons-row td .buttons button {
  min-width: 100px;
  margin-right: 10px;
}
.duplicates .duplicates-group {
  margin-bottom: 20px;
}
.compare-duplicates-modal {
  position: relative;
  min-width: 300px;
  width: 45%;
  margin-top: 10%;
}
.compare-duplicates-modal .comparator-area {
  width: 100%;
  max-height: calc(100vh - 210px);
  box-sizing: border-box;
  padding-top: 20px;
}
.compare-duplicates-modal .comparator-area .duplicate-reference {
  margin-top: 20px;
}
.compare-duplicates-modal .comparator-area input[type="checkbox"] {
  margin-right: 10px;
}
.compare-duplicates-modal .comparator-area label {
  font-weight: normal;
  display: table-row;
}
.compare-duplicates-modal .comparator-area label > div {
  display: table-cell;
  vertical-align: top;
}
.compare-duplicates-modal .buttons {
  text-align: center;
  margin-top: 40px;
}
.compare-duplicates-modal .buttons button {
  margin-right: 20px;
}
