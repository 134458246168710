@font-face {
  font-family: 'PTSans';
  src: url("./../fonts/pt_sans/regular/PTS55F-webfont.eot");
  src: url("./../fonts/pt_sans/regular/PTS55F-webfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/pt_sans/regular/PTS55F-webfont.woff") format('woff'), url("./../fonts/pt_sans/regular/PTS55F-webfont.ttf") format('truetype'), url("./../fonts/pt_sans/regular/PTS55F-webfont.svg#pt_sansbold") format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PT Sans';
  src: url("./../fonts/pt_sans/regular/PTS55F-webfont.eot");
  src: url("./../fonts/pt_sans/regular/PTS55F-webfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/pt_sans/regular/PTS55F-webfont.woff") format('woff'), url("./../fonts/pt_sans/regular/PTS55F-webfont.ttf") format('truetype'), url("./../fonts/pt_sans/regular/PTS55F-webfont.svg#pt_sansbold") format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PTSans';
  src: url("./../fonts/pt_sans/italic/PTS56F-webfont.eot");
  src: url("./../fonts/pt_sans/italic/PTS56F-webfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/pt_sans/italic/PTS56F-webfont.woff") format('woff'), url("./../fonts/pt_sans/italic/PTS56F-webfont.ttf") format('truetype'), url("./../fonts/pt_sans/italic/PTS56F-webfont.svg#pt_sansbold") format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'PT Sans';
  src: url("./../fonts/pt_sans/italic/PTS56F-webfont.eot");
  src: url("./../fonts/pt_sans/italic/PTS56F-webfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/pt_sans/italic/PTS56F-webfont.woff") format('woff'), url("./../fonts/pt_sans/italic/PTS56F-webfont.ttf") format('truetype'), url("./../fonts/pt_sans/italic/PTS56F-webfont.svg#pt_sansbold") format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'PTSans';
  src: url("./../fonts/pt_sans/bold/PTS75F-webfont.eot");
  src: url("./../fonts/pt_sans/bold/PTS75F-webfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/pt_sans/bold/PTS75F-webfont.woff") format('woff'), url("./../fonts/pt_sans/bold/PTS75F-webfont.ttf") format('truetype'), url("./../fonts/pt_sans/bold/PTS75F-webfont.svg#pt_sansbold") format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'PT Sans';
  src: url("./../fonts/pt_sans/bold/PTS75F-webfont.eot");
  src: url("./../fonts/pt_sans/bold/PTS75F-webfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/pt_sans/bold/PTS75F-webfont.woff") format('woff'), url("./../fonts/pt_sans/bold/PTS75F-webfont.ttf") format('truetype'), url("./../fonts/pt_sans/bold/PTS75F-webfont.svg#pt_sansbold") format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'PTSans';
  src: url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.eot");
  src: url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.woff") format('woff'), url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.ttf") format('truetype'), url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.svg#pt_sansbold") format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'PT Sans';
  src: url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.eot");
  src: url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.woff") format('woff'), url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.ttf") format('truetype'), url("./../fonts/pt_sans/bolditalic/PTS76F-webfont.svg#pt_sansbold") format('svg');
  font-weight: bold;
  font-style: italic;
}
