@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.gdt-editor-container {
  padding: 5px;
  position: relative;
  border: 1px solid #bfbfbf;
}
.gdt-editor-container .public-DraftEditor-content {
  min-height: 150px;
}
.gdt-editor-container.readonly {
  padding: 0;
  border: none;
}
.gdt-editor-container .alignment--left .public-DraftStyleDefault-block {
  text-align: left;
}
.gdt-editor-container .alignment--center .public-DraftStyleDefault-block {
  text-align: center;
}
.gdt-editor-container .alignment--right .public-DraftStyleDefault-block {
  text-align: right;
}
.gdt-editor-container td[name="impact"] {
  text-align: left;
}
.gdt-editor-container td[name="impact"] ul {
  padding-left: 15px;
  margin-left: 0px;
}
.gdt-editor-container td[name="impact"] ul li {
  margin-left: 0px;
  padding-left: 0px;
}
.gdt-editor-container .style-controls-container {
  position: absolute;
  top: -35px;
  background-color: #ddeaf9;
  box-shadow: rgba(0,0,0,0.118) 0px 1px 6px, rgba(0,0,0,0.118) 0px 1px 4px;
  border: 1px solid #bfbfbf;
  border-right: 0;
  z-index: 3;
}
.gdt-editor-container .style-controls-container .sub-controls {
  position: absolute;
  top: 28px;
  left: 0;
  background-color: #ddeaf9;
  min-width: 25px;
}
.gdt-editor-container .style-controls-container .sub-controls .style-control {
  display: block;
  width: 80px;
  text-align: left;
  border-right: 0;
}
.gdt-editor-container .style-controls-container .sub-controls .style-control.icon {
  width: 25px;
  text-align: center;
}
.gdt-editor-container .style-controls-container .style-control {
  display: inline-block;
  position: relative;
  height: 100%;
  min-width: 25px;
  text-align: center;
  font-weight: bold;
  padding: 4px;
  border-right: 1px solid #fff;
  transition: background-color 0.3s;
}
.gdt-editor-container .style-controls-container .style-control.with-nested {
  min-width: 25px;
  border-bottom: 0;
}
.gdt-editor-container .style-controls-container .style-control:hover {
  cursor: pointer;
  background-color: #abcbff;
}
.gdt-editor-container .style-controls-container .style-control.active {
  background-color: #abcbff;
  font-weight: bold;
}
.gdt-editor-container .style-controls-container .style-control span {
  font-size: 10px;
}
.gdt-editor-container .style-controls-container .style-control span.bold:before {
  font-family: 'gdt';
  content: '\e044';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.bold * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.italic:before {
  font-family: 'gdt';
  content: '\e046';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.italic * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.underline:before {
  font-family: 'gdt';
  content: '\e045';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.underline * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.align:before {
  font-family: 'gdt';
  content: '\e048';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.align * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.fsize:before {
  font-family: 'gdt';
  content: '\e043';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.fsize * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.clearformat:before {
  font-family: 'gdt';
  content: '\e050';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.clearformat * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.link:before {
  font-family: 'gdt';
  content: '\e051';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.link * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.small {
  font-size: 10px;
}
.gdt-editor-container .style-controls-container .style-control span.normal {
  font-size: 14px;
}
.gdt-editor-container .style-controls-container .style-control span.large {
  font-size: 18px;
}
.gdt-editor-container .style-controls-container .style-control span.huge {
  content: 'huge';
  font-size: 32px;
}
.gdt-editor-container .style-controls-container .style-control span.left:before {
  font-family: 'gdt';
  content: '\e047';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.left * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.center:before {
  font-family: 'gdt';
  content: '\e048';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.center * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.right:before {
  font-family: 'gdt';
  content: '\e049';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.right * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.footnote:before {
  font-family: 'gdt';
  content: '\e093';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.footnote * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.ul:before {
  font-family: 'gdt';
  content: '\e094';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.ul * {
  margin-left: 0.5rem;
}
.gdt-editor-container .style-controls-container .style-control span.highlight:before {
  font-family: 'gdt';
  content: '\e096';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.gdt-editor-container .style-controls-container .style-control span.highlight * {
  margin-left: 0.5rem;
}
.gdt-editor-container input[type=file] {
  display: none;
}
.gdt-editor-container .ep-editor-inline-ref,
.gdt-editor-container .ep-editor-inline-ref__text {
  transition: background-color 0.3s ease;
}
.gdt-editor-container .ep-editor-inline-ref.highlighted,
.gdt-editor-container .ep-editor-inline-ref__text.highlighted {
  background-color: #d2dfed;
}
.gdt-editor-container .ep-editor-inline-ref.highlighted .ep-editor-inline-ref__index,
.gdt-editor-container .ep-editor-inline-ref__text.highlighted .ep-editor-inline-ref__index,
.gdt-editor-container .ep-editor-inline-ref.highlighted:after,
.gdt-editor-container .ep-editor-inline-ref__text.highlighted:after {
  color: #f00;
}
.gdt-editor-container .ep-editor-inline-ref.highlighted:after,
.gdt-editor-container .ep-editor-inline-ref:hover:after {
  color: #f00;
}
.gdt-editor-container .ep-editor-inline-ref__text:hover .ep-editor-inline-ref__index {
  color: #f00;
}
