@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.confirmation-modal {
  top: 300px;
  width: 400px;
  padding: 10px;
}
.confirmation-modal .confirmation {
  background-color: #fff;
  height: 100%;
  width: 100%;
  min-width: 200px;
  position: relative;
}
.confirmation-modal .confirmation .question {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 20px;
}
.confirmation-modal .confirmation .message {
  margin-bottom: 20px;
}
.confirmation-modal .confirmation .buttons {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.confirmation-modal .confirmation .buttons .btn {
  -webkit-flex: 1 0 49%;
  -ms-flexbox: 1 0 49%;
  flex: 1 0 49%;
  line-height: 28px;
}
.confirmation-modal .confirmation .buttons .btn:first-child {
  margin-right: 10px;
}
.confirmation-modal .template-name {
  font-weight: bold;
}
.time-to-event-modal {
  margin: 25px 25px 0px;
  max-width: 500px;
}
.time-to-event-modal button {
  width: 100%;
}
.time-to-event-modal label {
  display: block;
  margin: 10px 0px;
}
.time-to-event-modal .close {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
}
.time-to-event-modal input[type="text"] {
  width: 100%;
  margin-bottom: 20px;
}
.password-confirmation-dialog .password-confirmation-content > div {
  margin: 10px 0;
}
